import { Icon } from 'components';
import DashboardNavChildren from './DashboardNavChildren';
import styles from '../dashboard.module.css';

function DashboardNavParent({
	name,
	icon: IconSvg,
	subList,
	activePath,
	navigateToPath,
	open,
	setOpen,
}) {
	const onClick = () => {
		setOpen();
	};

	return (
		<div className={styles.dashboardNavParentRoot}>
			<div className={styles.dashboardNavParentContainer} onClick={onClick}>
				<Icon divStyle={styles.dashboardIconContainer}>
					<IconSvg className={styles.dashboardNavItemIcon} />
				</Icon>
				<div className={styles.boldWeight}>{name}</div>
				<div className={styles.dashboardNavItemActiveDot}></div>
			</div>
			<DashboardNavChildren
				{...{
					subList,
					navigateToPath,
					activePath,
					open,
				}}
			/>
		</div>
	);
}

export default DashboardNavParent;
