import { useParams } from 'react-router';
import { createExportRequest } from 'services';
import { useCreateDispatches } from 'helpers/hooks';

export default function useExportPdfCallbacks({ getState, setOpen }) {
	const { childId } = useParams();
	const { setLoader, setSnackbar } = useCreateDispatches();

	const handleValidate = () => {
		const { reason, receiverName, receiverRelation } = getState();

		let validationError = '';
		if (!reason || !receiverName || !receiverRelation) {
			validationError +=
				'Name, Relation and Reason fields are all required for exports';
		}
		if (!validationError) {
			return true;
		} else {
			setSnackbar({ variant: 'error', message: validationError });
		}
	};

	const onExportPdf = async () => {
		setLoader(true);
		const state = getState();
		createExportRequest({ childId, ...state })
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Export successful. Please check your email.',
				});
				setOpen(false);
			})
			.catch((err) => {
				setSnackbar({
					variant: 'error',
					message:
						(err.response && err.response.data.message) ||
						'This request cannot be processed at this time.',
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};

	return { onExportPdf, handleValidate };
}
