import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getHeight } from 'redux/selectors';
import { DatePicker, TextArea, TextField } from 'components';
import CategorySelect from './CategorySelect';
import styles from '../editPost.module.css';

export default function EditPostBasicInfo({
	handlers: { handleChange, handleDate } = {},
	post: { category, title, body, posted } = {},
}) {
	const height = useSelector(getHeight);
	const { feedType } = useParams();

	return (
		<>
			<DatePicker
				label='Date'
				className={styles.addPostInputField}
				value={posted}
				onChange={handleDate}
				required={true}
			/>
			{feedType !== 'services' && (
				<CategorySelect
					none={false}
					required={true}
					value={category}
					className={styles.addPostInputField}
					variant='outlined'
					handleChange={handleChange}
					inputStyle={{ color: 'black', fontWeight: 'normal' }}
				/>
			)}
			<TextField
				className={[styles.addPostInputField, styles.inputBody].join(' ')}
				value={title}
				label='Title'
				onChange={handleChange}
				required={true}
				name='title'
			/>
			<TextArea
				className={[styles.addPostInputField, styles.inputBody].join(' ')}
				value={body}
				label='Description'
				required={true}
				onChange={handleChange}
				minRows={5}
				maxRows={(height / 30) * 0.3}
				name='body'
			/>
		</>
	);
}
