import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsSm } from 'redux/selectors';
import { Add } from '@material-ui/icons';
import { RoundButton } from 'components';

export const AddVaultPostButton = () => {
	const { childId } = useParams();
	const user = useSelector((state) => state.user.user);
	const { pathname } = useLocation();
	const { push } = useHistory();
	const isSm = useSelector(getIsSm);

	const plusButtonStyle = {
		height: '56px',
		width: '56px',
		fontSize: '24px',
		position: 'absolute',
		bottom: !isSm ? '24px' : '12px',
		right: !isSm ? '24px' : '12px',
		zIndex: '20',
	};

	const addPost = (pushRoute) => {
		push(`/vault/${pushRoute}/${childId}`);
	};

	return (
		/(timeline|files)/.test(pathname) && (
			<>
				{user.type !== 'child' && (
					<RoundButton
						Icon={Add}
						onClick={() => addPost('post')}
						style={plusButtonStyle}
						hoverText={`Add a Vault Post`}
						hoverSide='left'
					/>
				)}
			</>
		)
	);
};

export default AddVaultPostButton;
