export const getLifebooksChildren = ({ lifebooks }) => lifebooks.children;

export const getLifebooksChildrenHash = ({ lifebooks }) =>
	lifebooks.childrenHash;

export const getLifebooksChildrenLoaded = ({ lifebooks }) =>
	lifebooks.childrenLoaded;

export const getLifebooksChildrenSort = ({ lifebooks }) => lifebooks.childSort;

export const getLifebooksChild = ({ lifebooks }) => lifebooks.child;

export const getLifebooksSchools = ({ lifebooks }) => lifebooks.schools;

export const getLifebooksSchoolsHash = ({ lifebooks }) => lifebooks.schoolsHash;

export const getLifebooksCategories = ({ lifebooks }) => lifebooks.categories;

export const getLifebooksCategoriesHash = ({ lifebooks }) =>
	lifebooks.categoriesHash;

export const getLifebooksHouses = ({ lifebooks }) => lifebooks.houses;

export const getLifebooksHousesHash = ({ lifebooks }) => lifebooks.housesHash;

export const getLifebooksHousesLoaded = ({ lifebooks }) =>
	lifebooks.housesLoaded;

export const getLifebooksNsps = ({ lifebooks }) => lifebooks.nsps;

export const getLifebooksNspsHash = ({ lifebooks }) => lifebooks.nspsHash;

export const getLifebooksCollapse = ({ lifebooks }) => lifebooks.collapse;

export const getLifebooksHouseChildren = (houseId) => {
	return ({ lifebooks }) =>
		(houseId &&
			lifebooks.children?.filter((val) => val.house?.id === houseId)) ||
		[];
};
