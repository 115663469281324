import { useSelector } from 'react-redux';
import { getRightContentLessThanMin } from 'redux/selectors';
import PostMenu from './PostMenu';
import PostContent from './PostContent';
import PostVideo from './PostVideo';
import PostImage from './PostImage';
import ChangeRequestMessage from './ChangeRequestMessage';
import { PostNoteListContainer } from 'components';
import clsx from 'clsx';
import styles from '../timeline.module.css';

export default function Post({ post, index, disabled }) {
	const isLessThanMinWidth = useSelector(getRightContentLessThanMin);
	const hasPhotos = post?.photos?.length >= 1;
	const hasVideos = post?.videos?.length >= 1;

	const getLayoutClass = () => {
		switch (true) {
			case hasPhotos && !isLessThanMinWidth:
				return styles.postContainerWithImage;
			case hasPhotos && isLessThanMinWidth:
				return styles.postContainerWithImageStacked;
			default:
				return null;
		}
	};

	return (
		<div className={styles.postRoot}>
			{!disabled && <PostMenu post={post} index={index} />}
			<ChangeRequestMessage {...post} />
			<div className={clsx(styles.postContainer, getLayoutClass())}>
				<div className={styles.postContent}>
					<PostContent post={post} />
				</div>
				{hasVideos ? <PostVideo post={post} /> : null}
				{hasPhotos ? (
					<div className={styles.postImage}>
						<PostImage post={post} index={index} disabled={disabled} />
					</div>
				) : null}
			</div>
			{!disabled && <PostNoteListContainer collapsible post={post} />}
		</div>
	);
}
