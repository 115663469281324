import { formatDateRender } from 'helpers';
import styles from '../exportRequest.module.css';

const ExportDetails = (props) => {
	const expiration = formatDateRender(props?.request?.expiresAt);
	const reason =
		'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test test';

	return (
		<div className={styles.exportDetails}>
			<div className={styles.detailsTop}>
				<div className={styles.reasonText}>Reason:</div>
				<div className={styles.reason}>{reason}</div>
			</div>
			<div className={styles.detailsBottom}>
				<div className={styles.headerText}>Request Expiration:</div>
				<div className={styles.text}>{expiration}</div>
			</div>
		</div>
	);
};

export default ExportDetails;
