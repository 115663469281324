import { useSelector } from 'react-redux';

export default function useMappedChildren() {
	const childList = useSelector((state) => state.lifebooks.children);

	if (childList.length > 0) {
		const mapper = (val) => {
			return { ...val, name: `${val.firstname} ${val.lastname}` };
		};

		return childList.map(mapper);
	} else {
		return [];
	}
}
