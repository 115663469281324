import { useSelector } from 'react-redux';
import ChildImage from './ChildImage';
import styles from '../familyConnect.module.css';

const MappedChild = (props) => {
	const { isMd, isSm } = useSelector(({ mediaQuery }) => mediaQuery);

	return (
		<div
			key={props.id}
			onClick={() => props.onClick(props.id)}
			className={[
				styles.childContainer,
				props.id === props.selectedId && styles.selected,
				isMd && !isSm && styles.containerMedium,
				isSm && styles.containerSmall,
			].join(' ')}>
			<div
				className={[
					styles.containerTop,
					isMd && !isSm && styles.containerTopMedium,
					isSm && styles.containerTopSmall,
				].join(' ')}>
				<ChildImage picture={props.picture} />
			</div>
			<div
				className={[
					styles.bottomContainer,
					isMd && styles.bottomMedium,
					isSm && styles.bottomSmall,
					props.id === props.selectedId && styles.bold,
				].join(' ')}>
				{props.name}
			</div>
		</div>
	);
};

export default MappedChild;
