import styles from '../userList.module.css';

const HomeTile = (props) => {
	const { name } = props;

	return (
		<div className={styles.outerDiv}>
			<div className={styles.headerText}>{name}</div>
		</div>
	);
};

export default HomeTile;
