import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DatePicker({
	label,
	value,
	name,
	required,
	onChange,
	className,
	maxDate,
	maxDateMessage,
	minDate,
	minDateMessage,
	disableFuture = true,
}) {
	const minMaxProps = {};
	if (maxDate) {
		minMaxProps.maxDate = maxDate;
		minMaxProps.maxDateMessage = maxDateMessage || '';
	} else {
		delete minMaxProps.maxDate;
		delete minMaxProps.maxDateMessage;
	}

	if (minDate) {
		minMaxProps.minDate = minDate;
		minMaxProps.minDateMessage = minDateMessage || '';
	} else {
		delete minMaxProps.minDate;
		delete minMaxProps.minDateMessage;
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				className={className}
				inputVariant='outlined'
				format='MM/dd/yyyy'
				margin='normal'
				fullWidth={true}
				allowKeyboardControl={true}
				id={`date-picker-inline-${name}`}
				disableFuture={disableFuture}
				required={required}
				maxDateMessage='Date can not be after current date.'
				label={label}
				emptyLabel='No Date Selected'
				value={value}
				name={name}
				onChange={onChange}
				InputAdornmentProps={{
					position: 'start',
				}}
				{...minMaxProps}
			/>
		</MuiPickersUtilsProvider>
	);
}
