import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

const styles = () => ({
	squareIconButtonRoot: {
		borderRadius: '0px',
	},
	squareIconButtonColumn: {
		'& > .MuiIconButton-label': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
});

const useStyles = makeStyles(styles);

const SquareIconButton = ({ children, className, vert, ...props }) => {
	const classes = useStyles();
	const direction = vert ? classes.squareIconButtonColumn : '';
	return (
		<IconButton
			className={clsx(classes.squareIconButtonRoot, className, direction)}
			{...props}>
			{children}
		</IconButton>
	);
};

export default SquareIconButton;
