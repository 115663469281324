import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
	SquareIconButton,
	SecondaryButton,
	DeleteButton,
	OptionButton,
} from 'components';
import { Menu, Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';

const style = (theme) => {
	const { palette: { primary: { divider } = {} } = {} } = theme;
	return {
		selectMenuRoot: {
			maxHeight: '700px',
			[theme.breakpoints.down('sm')]: {
				maxHeight: '500px',
			},
		},
		selectMenuItemRoot: {
			padding: '0px 12px',
		},
		selectMenuParentMenuItem: {
			fontWeight: 'bolder',
			borderTop: `1px solid ${divider}`,
			color: 'black',
		},
		selectMenuCloseItem: {
			borderTop: `1px solid ${divider}`,
			color: theme.palette.secondary.light,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '24px',
			padding: '6px 0 0',
		},
		selectMenuComponentItem: {
			height: 'min-content',
		},
	};
};

const useStyles = makeStyles(style);

export default function SelectMenu(props) {
	const classes = useStyles();
	const {
		menuItems,
		menuAlign = 'left',
		Icon,
		square,
		disabled,
		deleteButton,
		secondary,
		optionButton,
		divButton,
		style,
		className = '',
		paperStyles,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		e?.stopPropagation();
		setAnchorEl(null);
	};

	const menuItemsMap =
		menuItems &&
		menuItems.map((val, i) => {
			const {
				value,
				label,
				onClick,
				disabled,
				checked = false,
				isParent,
			} = val || {};
			return val ? (
				isParent ? (
					<MenuItem
						className={clsx(
							`${className || 'option-menu'}-item`,
							classes.selectMenuParentMenuItem
						)}
						key={`${i}-${value}`}
						value={value}
						disabled={true}>
						{label}
					</MenuItem>
				) : (
					<FormControlLabel
						key={`${i}-${value}`}
						className={clsx(
							classes.selectMenuItemRoot,
							`${className || 'option-menu'}-item`
						)}
						control={
							<Checkbox
								disabled={disabled}
								checked={checked}
								onClick={(e) => {
									onClick();
								}}
							/>
						}
						label={label}
					/>
				)
			) : null;
		});

	const Button = square
		? SquareIconButton
		: deleteButton
		? DeleteButton
		: secondary
		? SecondaryButton
		: optionButton
		? OptionButton
		: IconButton;

	const button = divButton ? (
		<div
			disabled={disabled}
			onClick={handleClick}
			style={style}
			className={className}>
			{Icon ? <Icon /> : null}
			{props.children}
		</div>
	) : (
		<Button disabled={disabled} onClick={handleClick}>
			{Icon ? <Icon /> : null}
			{props.children}
		</Button>
	);
	return (
		<div className={clsx('select-list-tile-menu option-menu', className)}>
			{button}
			<Menu
				anchorEl={anchorEl}
				id='tile-menu'
				className={classes.selectMenuRoot}
				keepMounted
				open={open}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: menuAlign }}
				transformOrigin={{ vertical: 'top', horizontal: menuAlign }}
				onClose={handleClose}
				PaperProps={{
					style: {
						width: 200,
						...paperStyles,
					},
				}}>
				{menuItemsMap}
			</Menu>
		</div>
	);
}
