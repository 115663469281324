import { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { TextFieldRow, StaticTextRow } from 'components';
import styles from '../account.module.css';

export function ProfilePassword(props) {
	const { oldPassword, newPassword, newPwConfirm, onSubmit } = props;
	const [hideFields, setHideFields] = useState(Boolean(oldPassword));

	const inputArray = [
		oldPassword && {
			value: 'oldPassword',
			input: oldPassword,
		},
		{
			value: 'newPassword',
			input: newPassword,
		},
		{
			value: 'newPwConfirm',
			input: newPwConfirm,
			submit: true,
		},
	];
	const textFieldMap = (array) => {
		return array.map((val, i) => {
			if (val) {
				const { value, input, submit, name } = val;
				return (
					<div key={`${name}-${i}`}>
						<Tooltip
							title={
								Boolean(
									input.label === 'New Password' || input.label === 'Password'
								)
									? 'Minimum of 8 characters.'
									: ''
							}
							placement='top-start'
							disableHoverListener={true}>
							<TextFieldRow
								className={styles.profilePasswordField}
								type='password'
								variant='outlined'
								{...input}
								name={name || value}
								onKeyDown={
									submit && onSubmit
										? (e) => {
												if (e.keyCode === 13) {
													onSubmit();
												}
										  }
										: null
								}
							/>
						</Tooltip>
					</div>
				);
			} else return null;
		});
	};

	return (
		<div className={styles.profilePasswordRoot}>
			{hideFields ? (
				<StaticTextRow
					label='Password'
					value={
						<>
							********
							<span
								className={styles.profilePasswordEdit}
								onClick={() => {
									setHideFields(false);
								}}>
								EDIT
							</span>
						</>
					}
				/>
			) : (
				textFieldMap(inputArray)
			)}
		</div>
	);
}

export default ProfilePassword;
