import styles from '../casa.module.css';

const ErrorScreen = () => {
	return (
		<div className={styles.welcomeRoot}>
			<div className={styles.welcomeSubject}>
				Sorry an error has occured while loading this information. Please try to
				reload the page or try again later.
			</div>
		</div>
	);
};

export default ErrorScreen;
