import { useSelector } from 'react-redux';
import { includeType } from 'helpers';
import Dashboard from './Dashboard';
import OnboardBanner from './components/OnboardBanner';
import styles from './dashboard.module.css';

export default function DashboardWrappwer({ loading, children }) {
	const { user } = useSelector((state) => state.user);
	const { height, isMd } = useSelector((state) => state.mediaQuery);

	let hideDashboard =
		(includeType(['child']) && user.status !== 'active') || !user?.id;

	return (
		<div className={styles.appContent}>
			{hideDashboard ? null : <Dashboard />}
			<div className={styles.routeContainer} style={isMd ? { height } : {}}>
				{loading ? <div /> : children}
				{user.status === 'onboarding' ? <OnboardBanner /> : null}
			</div>
		</div>
	);
}
