import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../pageLayout.module.css';

function Loader() {
	return (
		<div className={styles.loaderWindow}>
			<div className={styles.loaderContainer}>
				<CircularProgress className={styles.loader} />
			</div>
		</div>
	);
}

export default Loader;
