import useManageChildBasicPermission from '../../utils/useManageChildPermissions';
import { TextFieldRow, StaticTextRow } from 'components';

export default function ChildName({ name, disabled }) {
	const permissions = useManageChildBasicPermission();

	const buildFullName = () => {
		const firstname = name.firstname.value;
		const middlename = name.middlename.value;
		const lastname = name.lastname.value;
		return `${firstname}${middlename ? ' ' + middlename : ''} ${lastname}`;
	};

	switch (permissions['name']) {
		case 'edit':
			return Object.values(name).map((val) => {
				if (val.label === 'First Name' || val.label === 'Last Name') {
					val.required = true;
				}

				return (
					<TextFieldRow
						key={val.label}
						variant='outlined'
						disabled={disabled}
						{...val}
					/>
				);
			});
		case 'view':
			return <StaticTextRow label='Name' value={buildFullName()} />;
		default:
			return null;
	}
}
