import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/index';
import { LifebookNav, PageLayout } from 'components';
import { useCreateDispatches } from 'helpers/hooks';
import MobileHeader from 'components/Headers/MobileHeader';
import { useCalendarActivityMapper } from '../utils/useCalendarActivityMapper';
import { useGetCalendarActivity } from '../utils/useGetCalendarActivity';
import styles from './calendarActivity.module.css';

const CalendarActivity = () => {
	const isMd = useSelector((state) => state.mediaQuery.isMd);
	const child = useSelector((state) => state.lifebooks.child);

	const [activity, setActivity] = useState(null);
	const [lastPostActivityId, setlastPostActivityId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();
	const { setSnackbar } = useCreateDispatches();
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const { push } = useHistory();
	const { childId } = useParams();

	useEffect(() => {
		if (!child.id) {
			push('/calendar');
		}
	}, []);

	const getActivity = useGetCalendarActivity({
		setActivity,
		setlastPostActivityId,
	});

	const populateActivityFeed = useCallback(async () => {
		dispatchLoader(true);
		setIsLoading(true);
		await getActivity({ childId }).finally(() => {
			setIsLoading(false);
			dispatchLoader(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	useEffect(() => {
		populateActivityFeed();
	}, [populateActivityFeed]);

	const handleScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (
			activity &&
			activity.length &&
			lastPostActivityId &&
			!isLoading &&
			scrollTop >= scrollHeight - clientHeight - 5
		) {
			lazyLoadActivity();
		}
	};

	const lazyLoadActivity = async () => {
		if (lastPostActivityId) {
			dispatchLoader(true);
			setIsLoading(true);
			await getActivity({ childId, lastPostActivityId })
				.then((result) => {
					setActivity([...activity, ...result.data.postActivities]);
					setlastPostActivityId(result.data.lastPostActivityId || null);
					setIsLoading(false);
					dispatchLoader(false);
				})
				.catch((err) => {
					setIsLoading(false);
					dispatchLoader(false);
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					);
				});
		}
	};

	const activityMapper = useCalendarActivityMapper(lastPostActivityId);

	const activityMap =
		activity && activity.length && activity.map(activityMapper);

	return (
		<PageLayout
			header={isMd ? <MobileHeader /> : null}
			footer={<LifebookNav />}
			title={`${child.firstname} ${child.lastname}'s Calendar`}>
			<div className={styles.activityFeedRoot} onScroll={handleScroll}>
				{activity ? (
					activity.length ? (
						activityMap
					) : (
						<div className={styles.noPostRoot}>
							<img src='/assets/ella-elephant.svg' alt='Ella Elephant' />
							<div className={styles.noPostTitle}>{'No Activities Found'}</div>
						</div>
					)
				) : null}
			</div>
		</PageLayout>
	);
};

export default CalendarActivity;
