import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../..';
import { getPostsRoot } from '../../../redux/selectors';
import { NavigateBefore } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

export default function NavBack({ onClick, className }) {
	const root = useSelector(getPostsRoot);

	const blockNavBack = ['auditoraccess', 'connect'].includes(root);

	if (blockNavBack) return null;

	return onClick ? (
		<IconButton onClick={onClick} className={className}>
			<Icon>
				<NavigateBefore />
			</Icon>
		</IconButton>
	) : null;
}
