import { useCallback, useEffect, useState } from 'react';

export default function useImgSrcSet(photo, setDownloadSrc) {
	const [src, setSrc] = useState(null);
	const [loading, setLoading] = useState(true);

	const onSrcSet = useCallback(
		(src) => {
			setSrc(src);
			setDownloadSrc && setDownloadSrc(src);
		},
		[setDownloadSrc]
	);

	useEffect(() => {
		if (photo?.url) {
			// get from AWS
			onSrcSet(photo.url);
			setLoading(false);
		} else {
			onSrcSet(URL.createObjectURL(photo.file));
			setLoading(false);
		}
	}, [photo, onSrcSet]);

	return { src, loading };
}
