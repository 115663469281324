import { Switch, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AccessRoute } from 'routing';
import { includeType } from 'helpers';
import { EditPost, Welcome, Feed, ManageChild, ExitedCare } from 'components';

export default function LifebooksRoutes() {
	const params = useParams();
	const { childId, feedType } = params;
	const { user } = useSelector(({ user }) => user);

	return (
		<Switch>
			{includeType(['child']) && user.status !== 'active' ? null : (
				<AccessRoute
					key='lifebooks/post'
					path='/lifebooks/post/:childId/:postId?'
					authorized={{
						agencyuser: true,
						house: true,
						child: { status: ['active'] },
						csw: true,
						contributor: true,
						casauser: true,
					}}
					fallBackRoute={includeType(['child']) ? `/lifebooks/` : null}>
					<EditPost root='lifebooks' />
				</AccessRoute>
			)}
			<AccessRoute
				key={`exit`}
				path='/lifebooks/manage/exited'
				component={ExitedCare}
				authorized={{ agencyuser: { role: ['admin', 'manager'] } }}
				fallBackRoute='/lifebooks/'
			/>
			<AccessRoute
				key={`manageChild-${childId}`}
				path='/lifebooks/manage/:childId?'
				component={ManageChild}
				authorized={{
					agencyuser: { role: ['admin', 'manager', 'social-worker'] },
					csw: true,
					casauser: true,
				}}
				authorizedRole='resource-parent'
				fallBackRoute='/lifebooks/'
			/>
			<AccessRoute
				key={`manageChild-${childId}`}
				path='/lifebooks/manage'
				component={ManageChild}
				authorized={{
					agencyuser: { role: ['admin', 'manager', 'social-worker'] },
					csw: true,
					casauser: true,
				}}
				fallBackRoute='/lifebooks/'
			/>
			<Route key={childId + feedType} path='/lifebooks/:feedType/:childId'>
				<Feed root='lifebooks' />
			</Route>
			<AccessRoute
				key='lifebooks'
				exact
				path='/lifebooks/'
				authorized={{
					agencyuser: true,
					contributor: true,
					csw: true,
					casauser: true,
				}}
				fallBackRoute={`/lifebooks/${
					user.type === 'child' ? 'timeline/' + user.id : ''
				}`}>
				<Welcome user={user} />
			</AccessRoute>
		</Switch>
	);
}
