import { validatePhoneNumber } from './utils/validate';

export const handleAgencyValidation = (agencyName, phone) => {
	const phoneValid = validatePhoneNumber(phone);

	if (!agencyName) {
		return { isValid: false, message: 'Agency name is required' };
	}

	if (phone && !phoneValid) {
		return { isValid: false, message: 'Phone number is not valid' };
	}

	return { isValid: true };
};

export const handleBranchValidation = (branchName) => {
	if (!branchName) {
		return { isValid: false, message: 'Branch name is required' };
	}

	return { isValid: true };
};
