import { apiV2 } from '../axios';

export const getPostActivity = async ({
	childId,
	postId,
	lastPostActivityId,
}) => {
	let query = '?';
	if (postId) {
		query += `postId=${postId}`;
	} else {
		query += `childId=${childId}`;
	}
	if (lastPostActivityId) {
		query += `&lastPostActivityId=${lastPostActivityId}`;
	}
	return await apiV2.get(`/post/activity${query}`);
};

export const getHouseActivity = async ({ childId }) => {
	return await apiV2.get(`/child/${childId}/activity`);
};

export const getExportActivity = async ({ childId }) => {
	return await apiV2.get(`/export?childId=${childId}&q=history`);
};

export const getVaultActivity = async (childId, lastEventActivityId) => {
	if (lastEventActivityId) {
		return await apiV2.get(
			`/vault/docs/activity?childId=${childId?.childId}&lastEventActivityId=${lastEventActivityId}`
		);
	} else {
		return await apiV2.get(`/vault/docs/activity?childId=${childId?.childId}`);
	}
};

export const getCalendarActivity = async (childId, lastEventActivityId) => {
	if (lastEventActivityId) {
		return await apiV2.get(
			`/event/activity?childId=${childId?.childId}&lastEventActivityId=${lastEventActivityId}`
		);
	} else {
		return await apiV2.get(`/event/activity?childId=${childId?.childId}`);
	}
};

export const getAgencyActivity = async (agencyId, lastEventActivityId) => {
	if (lastEventActivityId) {
		return await apiV2.get(
			`/agency/${agencyId}/activity&lastEventActivityId=${lastEventActivityId}`
		);
	} else {
		const response = await apiV2.get(`/agency/${agencyId}/activity`);
		return response;
	}
};
