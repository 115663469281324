import ActivityItem from '../components/ActivityItem';

export const capitalizeFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const handleDescription = (val) => {
	if (val.nameChanged) {
		return 'Agency name changed';
	}
	if (val.emailChanged) {
		return `Agency email has been ${val.activity}`;
	}
	if (val.officeChanged) {
		return `Agency office has been ${val.activity}`;
	}
	if (val.faxChanged) {
		return `Agency fax has been ${val.activity}`;
	}
	if (val.addressChanged) {
		return `Agency address has been ${val.activity}`;
	}
	if (val.branchCreated) {
		return `Agency has created branch`;
	}
	if (val.branchNameChanged) {
		return 'Branch changed name';
	}
	if (val.branchDeleted) {
		return 'Branch has been deleted';
	}
	if (val.agencyUserAdded) {
		return 'Agency user has been added';
	}
	if (val.agencyUserDeleted) {
		return 'Agency user has been deleted';
	}

	return 'Agency Activity';
};

export function useAgencyActivityMapper(lastPostActivityId) {
	return (val, index, arr) => {
		const description = handleDescription(val);

		return (
			<ActivityItem
				key={val.id}
				activity={{
					...val,
					description,
					index,
					category: val.category ? val.category : 'uncategorized',
					only: arr.length === 1 && index === 0,
					last: !lastPostActivityId && index === arr.length - 1,
				}}
			/>
		);
	};
}
