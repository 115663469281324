import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextFieldRow, TextField } from 'components';
import { stateOptions } from 'helpers';

export default function addressMapper(
	array,
	onChange,
	classes = {},
	enterSubmit
) {
	return array.map((val) => {
		const { value, name, type, label, required, disabled } = val;
		return (
			<React.Fragment key={name}>
				{type === 'select' ? (
					<TextFieldRow label={label} required={required}>
						<Autocomplete
							options={stateOptions}
							required={required}
							getOptionLabel={(option) => option.state || ''}
							onChange={(_e, value) => {
								onChange({ target: { name: 'state', value } });
							}}
							value={value}
							name={name}
							getOptionSelected={(option, value) => option.id === value.id}
							autoComplete
							autoHighlight
							autoSelect
							renderInput={(params) => (
								<>
									<TextField
										{...params}
										className={classes.inputField}
										variant='outlined'
									/>
								</>
							)}
						/>
					</TextFieldRow>
				) : (
					<TextFieldRow
						className={classes.inputField}
						required={required}
						disabled={disabled}
						onKeyDown={(e) =>
							type === 'submit' && !!enterSubmit && enterSubmit(e)
						}
						variant='outlined'
						onChange={onChange}
						value={value}
						name={name}
						label={label}
					/>
				)}
			</React.Fragment>
		);
	});
}
