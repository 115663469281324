import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as selector from 'redux/selectors';
import { getRelationsList } from 'services';
import { listSortPersonByName, errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useManageSocialWorkerState() {
	const [loaded, setLoaded] = useState(false);
	const [role, setRole] = useState('social-worker');
	const childList = useSelector(selector.getLifebooksChildren);
	const [assignedChildren, setAssignedChildren] = useState([]);
	const [assignedManager, setAssignedManager] = useState([]);
	const { swId } = useParams();

	const { setLoader, setSnackbarError } = useCreateDispatches();

	const agencyHash = useSelector(selector.getAgencyHash);
	const socialWorker = useSelector(selector.getAgencyUser(swId));
	const { firstname, lastname, email } = socialWorker;
	const managerList = useSelector(selector.getAgencyManagerUsers);
	const [pageTitle, setPageTitle] = useState('Add Social-Worker');

	useEffect(() => {
		const title =
			firstname && lastname
				? `${socialWorker.firstname} ${socialWorker.lastname}`
				: email;
		setPageTitle(title);

		const getData = async () => {
			setLoader(true);
			setLoaded(false);
			setRole('social-worker');
			try {
				const { data } = await getRelationsList(swId, 'social-worker');
				const cleanData = data?.filter(
					(value, index, self) =>
						index === self?.findIndex((t) => t?.id === value?.id)
				);
				setAssignedChildren(listSortPersonByName(cleanData));

				const manager = agencyHash[socialWorker?.managerAgencyUserId];
				setAssignedManager(manager ? [manager] : []);
			} catch (err) {
				const { response } = err || {};
				setSnackbarError({ response, ...errors.MANAGE_SW_REL }, { log: true });
			}
			setLoaded(true);
			setLoader(false);
		};

		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swId]);

	return {
		pageTitle,
		childList,
		assignedChildren,
		managerList,
		assignedManager,
		role,
		setRole,
		loaded,
	};
}
