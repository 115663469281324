import { useState } from 'react';
import { connect } from 'react-redux';
import { setModal } from 'redux/index';
import IconButton from '@material-ui/core/IconButton';
import { GetApp, Close } from '@material-ui/icons';
import * as Comp from 'components';
import PostMenu from './PostMenu';
import styles from '../timeline.module.css';

function FullScreenImage(props) {
	const { post, setModal, index, disabled } = props;
	const { photo, photos } = post;
	const [src, setSrc] = useState(null);

	let downloadImage = (url) => {
		window.location = url;
	};

	return (
		<div className={styles.fullScreenRoot}>
			<Comp.LazyCarousel
				photos={photos?.length ? photos : [photo]}
				setSrc={setSrc}
			/>
			<div className={styles.fullScreenContent}>
				<div className={styles.fullScreenTitleSection}>
					<div className={styles.fullScreenTitle}>{post.title}</div>
					<div className={styles.fullScreenClose}>
						{!disabled && <PostMenu post={post} index={index} />}
						<IconButton
							tooltip='Download Full Resolution Image'
							onClick={() => downloadImage(src)}>
							<Comp.Icon>
								<GetApp />
							</Comp.Icon>
						</IconButton>
						<IconButton
							tooltip='close fullscreen'
							onClick={() => setModal(false)}>
							<Comp.Icon>
								<Close />
							</Comp.Icon>
						</IconButton>
					</div>
				</div>
				<div className={styles.fullScreenInfo}>{post.body}</div>
				{!disabled && <Comp.PostNoteListContainer collapsible post={post} />}
			</div>
		</div>
	);
}

export default connect(null, { setModal })(FullScreenImage);
