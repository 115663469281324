import { useParams } from 'react-router';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';
import * as service from 'services';

const activityParams = {
	activity: {
		callback: service.getPostActivity,
		dataPath: `data.postActivities`,
	},
	profileActivity: {
		callback: service.getHouseActivity,
		dataPath: `data`,
	},
	exportActivity: {
		callback: service.getExportActivity,
		dataPath: `data`,
	},
	snapshotActivity: {
		callback: service.getSnapshotActivity,
		dataPath: `data.postActivities`,
	},
	snapshotProfileActivity: {
		callback: service.getSnapshotProfileActivity,
		dataPath: `data`,
	},
	snapshotExportActivity: {
		callback: service.getSnapshotExportActivity,
		dataPath: `data`,
	},
};

export function useGetActivity({ setActivity, setlastPostActivityId } = {}) {
	const { setSnackbar } = useCreateDispatches();
	const { feedType } = useParams();
	const root = useSelector(getPostsRoot);
	const isArchive = root === 'archive';
	let paramType = feedType;

	if (isArchive) {
		if (feedType === 'profileActivity') {
			paramType = 'snapshotProfileActivity';
		} else if (feedType === 'exportActivity') {
			paramType = 'snapshotExportActivity';
		} else {
			paramType = 'snapshotActivity';
		}
	}

	const params = activityParams[paramType];

	if (!params || !params.callback) return () => Promise.resolve(null);

	return async (childId) => {
		return await params
			.callback(childId)
			.then((result) => {
				setActivity?.(get(result, params.dataPath));
				setlastPostActivityId(get(result, 'data.lastPostActivityId') || null);
				return result;
			})
			.catch((err) => {
				setActivity([]);
				setSnackbar(
					{
						variant: 'error',
						message:
							err.response?.data.message ||
							'Something went wrong. Please try again later',
					},
					{ log: true }
				);
			});
	};
}
