import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export default function Icon({ iconStyle, divStyle, children, className }) {
	const useStyles = makeStyles(() => ({
		root: {
			display: 'flex',
			alignItems: 'center',
			'& > svg': {
				fontSize: '24px',
				...iconStyle,
			},
			...divStyle,
		},
	}));

	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className ? className : null)}>
			{children}
		</div>
	);
}
