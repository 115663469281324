import clsx from 'clsx';
import styles from '../dashboard.module.css';

export function IndicatorDot(props) {
	const { className } = props;
	return (
		<div
			className={clsx(styles.indicatorDotRoot, className ? className : null)}
		/>
	);
}

export default IndicatorDot;
