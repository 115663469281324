const experimentsState = {
  cswAccount: !!process.env.REACT_APP_CSW_EXP
};
  
  
  export default function experimentsReducer(state = experimentsState, action) {
    switch (action.type) {
      default:
        return state;
    }
  }
  