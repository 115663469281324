import React from 'react';
import { useDispatch } from 'react-redux';
import { setMobileList } from 'redux/index';
import { IndicatorCount, Avatar } from 'components';
import { withRouter } from 'react-router-dom';
import styles from '../casa.module.css';

// TODO CONVERT SCSS STYLES //

function CasaSelectListTile(props) {
	const {
		value,
		select,
		children,
		pathParam,
		activeClass: customActiveClass,
		indicatorCount,
		noAvatar,
	} = props;

	const dispatch = useDispatch();

	const onSelect = (e) => {
		dispatch(setMobileList(false));
		select(e, value);
	};

	const selectNull = () => {
		return null;
	};

	const selectable = Boolean(select);

	const activeClass =
		pathParam === value?.id
			? `select-list-tile-active ${customActiveClass}`
			: '';

	const alt = (typeof children === 'string' ? children : children?.[0]) || '';

	const avatar = <Avatar image={value?.photoUrl} alt={alt} size='small' />;

	return children || children?.[0] ? (
		<React.Fragment>
			<div
				className={`select-list-tile ${
					selectable ? 'selectable' : ''
				} ${activeClass}`}
				onClick={selectable ? onSelect : selectNull}>
				{!noAvatar ? (
					<div>{avatar}</div>
				) : (
					<div className='select-list-tile-no-avatar' />
				)}
				<div className='select-list-tile-name'>{children}</div>
				{indicatorCount ? (
					<IndicatorCount className={styles.selectListTileIndicator}>
						{indicatorCount}
					</IndicatorCount>
				) : null}
			</div>
		</React.Fragment>
	) : null;
}

export default withRouter(CasaSelectListTile);
