import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader, store, setAllCasaPermissions } from 'redux/index';
import * as services from 'services';
import { casaData } from '../../utils';
import AssignedChildren from '../../Components/AssignedChildren';
import CasaPermissions from './CasaPermissions';
import CasaUserList from './CasaUserList';
import CasaSelector from 'components/Casa/Components/CasaSelector';
import * as Comp from 'components';
import styles from '../CasaAgency.module.css';

const ManageCasaAgency = () => {
	const tileList = useSelector((state) => state.casa.casaUsers);
	const children = useSelector((state) => state.lifebooks.children);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const permissions = casaData(casaPermissions);

	const [child, setChild] = useState('');
	const [childList, setChildList] = useState([]);
	const [assignedChildren, setAssignedChildren] = useState([]);

	const history = useHistory();
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const { state, location } = useLocation();

	const casaAdminList = tileList?.filter((user) => user.role === 'admin');

	useEffect(() => {
		loadChildren();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children, state]);

	useEffect(() => {
		if (state.status !== 'pending') {
			services.getCasaUsers(state.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.id]);

	const loadChildren = async () => {
		dispatchLoader(true);
		const assigned = children.filter((child) => child.casaId === state.id);
		const unAssigned = children.filter((child) => !child.casaId);
		setAssignedChildren(assigned);
		setChildList(unAssigned);
		dispatchLoader(false);
	};

	const onCancel = () => {
		history.goBack();
	};

	const setPermissions = async () => {
		let keys = [];

		permissions?.forEach((p) => {
			if (p.value === true) {
				keys.push(p.key);
			}
		});

		await services.setChildCasaPermissions(keys, null, child, state.id);
	};

	const assignChildToCasa = async () => {
		await services.addChildToCasa(state?.id, child, 'add');
	};

	const onSubmit = async () => {
		dispatchLoader(true);
		await assignChildToCasa();
		await setPermissions();
		setChild('');
		await loadChildren();
		await services.setChildrenList();
		dispatchLoader(false);
	};

	const handleSelectChild = (e) => {
		store.dispatch(setAllCasaPermissions([]));
		setChild(e.target.value);
	};

	let buttonArray = [
		<Comp.SecondaryButton key='account-cancel' onClick={onCancel}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-save-casa' onClick={onSubmit}>
			Save
		</Comp.PrimaryButton>,
	];

	const addressFields = [
		{ id: 1, label: 'Street', value: state.address?.street },
		{ id: 2, label: 'Street 2', value: state.address?.street2 },
		{ id: 3, label: 'City', value: state.address?.city },
		{ id: 4, label: 'State', value: state.address?.state },
		{ id: 5, label: 'Postal', value: state.address?.postal },
	];

	const mapAddressFields = addressFields.map((field) => {
		return (
			<Comp.StaticTextRow
				key={field.id}
				label={field.label}
				value={field.value}
				variant='outlined'
			/>
		);
	});

	return (
		<Comp.PageLayout
			title={'Manage Casa'}
			align='left'
			buttonArray={buttonArray}>
			<Comp.SectionHeader>Casa Name</Comp.SectionHeader>
			<div className={styles.manageCasaCenteredFlex}>
				<Comp.StaticTextRow
					label={'Casa Name'}
					value={state.name}
					variant='outlined'
				/>
				<Comp.StaticTextRow
					label={'Phone Number'}
					value={state.office}
					variant='outlined'
				/>
			</div>
			<Comp.SectionHeader>Address</Comp.SectionHeader>
			<div className={styles.manageCasaCenteredFlex}>
				{mapAddressFields}
				{state.status === 'pending' ? null : (
					<CasaUserList tileList={casaAdminList || []} />
				)}
			</div>
			{assignedChildren?.length > 0 && (
				<div className={styles.manageCasaWidth}>
					<Comp.SectionHeader>Assigned Children</Comp.SectionHeader>
					<div className={styles.manageCasaWidth}>
						<AssignedChildren
							assignedChildren={assignedChildren}
							user={location?.state}
						/>
					</div>
				</div>
			)}
			<div className={styles.manageCasaWidth}>
				<CasaSelector
					label='Children'
					textFieldLabel='Assign Child'
					menuList={childList || []}
					handleMenuSelect={(e) => handleSelectChild(e)}
					selected={child}
					handleRemove={() => setChild('')}
				/>
			</div>

			{child && <CasaPermissions permissions={permissions || []} />}
		</Comp.PageLayout>
	);
};

export default ManageCasaAgency;
