import { formatDateRender, validDate } from 'helpers';
import styles from '../timeline.module.css';

export default function PostCreatedBy({ createdBy: { name } = {}, posted }) {
	const formattedDate = validDate(posted) && formatDateRender(posted, 'MMDDYY');
	const getCreatedByString = () => {
		switch (true) {
			case Boolean(name && formattedDate):
				return `${name} posted on ${formattedDate}`;
			case Boolean(!name && formattedDate):
				return `Posted on ${formattedDate}`;
			case Boolean(name && !formattedDate):
				return `Posted by ${name}`;
			default:
				return null;
		}
	};

	return <div className={styles.postCreatedByRoot}>{getCreatedByString()}</div>;
}
