import { apiV2 } from '../axios';

export const getRelationsList = async (userId, relationType = 'sibling') => {
	// relationType can equal sibling //
	try {
		return await apiV2.get(
			`/relation?relationType=${relationType}&userId=${userId}`
		);
	} catch (e) {
		return {};
	}
};

export const setRelation = async (
	firstOwner,
	secondOwner,
	relationType = 'sibling'
) => {
	const requestBody = {
		firstOwnerType: firstOwner.type || 'child',
		firstOwnerId: firstOwner.id,
		secondOwnerType: secondOwner.type || 'child',
		secondOwnerId: secondOwner.id,
		relationType,
	};
	return await postRelation(requestBody);
};

export const postRelation = async (requestBody) => {
	// sibling for sibling connection //
	return await apiV2.post(`/relation/`, requestBody);
};

export const setMultipleRelations = async (
	owner,
	relations,
	relationType = 'sibling'
) => {
	const promiseArray = [];
	Object.values(relations).forEach((relation) => {
		const { relationId } = relation;
		if (relationId) {
			promiseArray.push(removeRelation(relationId));
		} else {
			promiseArray.push(setRelation(owner, relation, relationType));
		}
	});

	return await Promise.all(promiseArray).then(() => {
		return Promise.resolve('success');
	});
};

export const removeRelation = async (relationId) => {
	return apiV2.delete(`/relation/${relationId}`);
};

export const setSiblingRelation = async (firstId, secondId) => {
	const reqBody = {
		firstSiblingChildId: firstId,
		secondSiblingChildId: secondId,
	};
	return apiV2.post('/relation/sibling', reqBody);
};

export const deleteSiblingRelation = async (relationId) => {
	return apiV2.delete(`/relation/${relationId}`);
};
