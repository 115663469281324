import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import styles from '../headers.module.css';

export default function SectionLayout({ label, children, className }) {
	return (
		<div className={clsx(styles.sectionLayoutRoot, className)}>
			{label && (
				<Typography className={styles.sectionLayoutLabel}>{label}</Typography>
			)}
			<div className={styles.sectionLayoutContent}>{children}</div>
		</div>
	);
}
