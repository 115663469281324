import { useEffect } from 'react';
import * as Router from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store, setCasa } from 'redux/index';
import { useMobileListCheck } from 'helpers';
import { UserList, SlideContainer, Welcome } from 'components';
import ManageCasaAgency from './components/ManageCasaAgency';
import ManageCasaUserAgency from './components/ManageCasaUserAgency';
import ManageCasaChild from '../Components/ManageCasaChild';
import ErrorScreen from '../Components/ErrorScreen';
import InviteCasa from './components/InviteCasa';
import styles from './CasaAgency.module.css';

const CasaAgency = () => {
	const casaList = useSelector((state) => state.casa.casaConnections);
	const casa = useSelector((state) => state.casa.casa);
	const userRole = useSelector((state) => state.user.user.role);

	const { push, goBack } = Router.useHistory();
	const { pathname } = Router.useLocation();

	useMobileListCheck(pathname === '/casaAgency' || pathname === '/casaAgency/');

	useEffect(() => {
		if (!pathname.includes('manage') && !pathname.includes('child')) {
			store.dispatch(setCasa({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const closeInvite = (e) => {
		e.stopPropagation();
		push({ pathname: '/casaAgency' });
	};

	const selectCasa = (e, casa) => {
		e.stopPropagation();
		store.dispatch(setCasa(casa));
		push({ pathname: `/casaAgency/manage/${casa.id}`, state: casa });
	};

	const handleInvite = () => {
		push({ pathname: '/casaAgency/invite' });
	};

	let casaConnections = casaList === 'error' ? [] : casaList;

	return (
		<div className={styles.casaAgencyFull}>
			<SlideContainer>
				<UserList
					list={casaConnections || []}
					addToggle={userRole === 'admin' ? handleInvite : null}
					handleSelect={selectCasa}
					label='Casa'
					pathParam='casaId'
					paramId={casa.id}
					addLabel='Connect With CASA'
				/>
			</SlideContainer>
			<div className={styles.casaAgencyContainer}>
				{casaList === 'error' ? (
					<ErrorScreen />
				) : (
					<Router.Switch>
						<Router.Route path='/casaAgency/manage/:casaId'>
							<ManageCasaAgency onCancel={() => goBack()} />
						</Router.Route>
						<Router.Route path='/casaAgency/user/:casaId'>
							<ManageCasaUserAgency onCancel={() => goBack()} />
						</Router.Route>
						<Router.Route path='/casaAgency/invite/'>
							<InviteCasa selectClose={(e) => closeInvite(e)} />
						</Router.Route>
						<Router.Route path='/casaAgency/child/:childId'>
							<ManageCasaChild />
						</Router.Route>
						<Router.Route path='/'>
							<Welcome />
						</Router.Route>
					</Router.Switch>
				)}
			</div>
		</div>
	);
};

export default Router.withRouter(CasaAgency);
