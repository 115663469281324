import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectorList } from 'components';

export default function TagSibling(props) {
	const { relations, setRelations } = props;
	const [menuList, setMenuList] = useState([]);
	const [tileList, setTileList] = useState([]);

	const siblingList = useSelector(
		({ lifebooks }) => lifebooks.child?.relations
	);
	const { childrenHash, child } = useSelector(({ lifebooks }) => lifebooks);
	const { relations: childRelations } = child;
	let childRelationsHash = useMemo(() => [], []);

	useEffect(() => {
		if (!childRelationsHash.length && childRelations?.length) {
			childRelations.forEach((val) => {
				childRelationsHash[val.secondOwnerId] = val;
			});
		}
	}, [childRelations, childRelationsHash]);

	useEffect(() => {
		if (siblingList?.length && childrenHash) {
			let menuMap = siblingList.reduce(
				(mapped, { archived, status, relationType, secondOwnerId }) => {
					const relation = secondOwnerId;
					if (
						childrenHash[relation] &&
						!archived &&
						status === 'approved' &&
						relationType === 'sibling'
					) {
						mapped.push(childrenHash[relation]);
					}
					return mapped;
				},
				[]
			);
			setMenuList(menuMap);
		}
	}, [childrenHash, siblingList]);

	useEffect(() => {
		if (relations?.length) {
			const mappedTileList = relations.map((val) => {
				return childrenHash[val.secondOwnerId];
			});
			setTileList(mappedTileList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTileListChange = (val) => {
		setTileList(val);
		const mappedRelationList = val.map((relation) => {
			return childRelationsHash[relation.id].id;
		});
		setRelations(mappedRelationList);
	};

	return siblingList?.length ? (
		<SelectorList
			label='Add Post to Siblings'
			textFieldLabel='Siblings'
			menuList={menuList}
			setMenu={setMenuList}
			tileList={tileList}
			setTileList={handleTileListChange}
			full
		/>
	) : null;
}
