import LinearProgress from '@material-ui/core/LinearProgress';
import useImgSrcSet from '../utils/useImgSrcSet';
import clsx from 'clsx';
import styles from '../addImage.module.css';

function CarouselImage(props) {
	const { photo, setDownloadSrc } = props;

	const { src, loading } = useImgSrcSet(photo, setDownloadSrc);

	return (
		<div className={styles.carouselImageRoot}>
			{loading ? (
				<LinearProgress />
			) : photo?.heic ? (
				<>
					<div
						className={clsx(styles.carouselImage, styles.carouselUnavailable)}
						src={window.location.origin + '/assets/ella-elephant.svg'}
						alt='Preview Unavailable'>
						<div className={styles.carouselImageUnavailableText}>
							Image Preview Unavailable
						</div>
					</div>
				</>
			) : src ? (
				<>
					<img className={styles.carouselImage} src={src} alt='first slide' />
				</>
			) : null}
		</div>
	);
}

export default CarouselImage;
