import { updateAgency } from 'services';
import { errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { handleAgencyValidation } from 'helpers/validation/branchValidation';
import { useAddressGeocode } from 'components/UtilityComponents/AddressForm/AddressForm';
import { PrimaryButton } from 'components';

export const useGetAgencyButtons = (getBody) => {
	const { setLoader, setSnackbar, setSnackbarError } = useCreateDispatches();
	const getGeocode = useAddressGeocode();

	function SaveButton() {
		const errorHandler =
			(error) =>
			({ response }) => {
				setSnackbarError({ response, ...error }, { log: true });
			};

		const formatRequest = async (reqBody, geoCodeRes) => {
			return await updateAgency({
				...reqBody,
				address: { ...geoCodeRes, state: geoCodeRes.state.value },
			});
		};

		const onClick = async () => {
			setLoader(true);
			await getGeocode()
				.then(async (geoCodeRes) => {
					const { reqBody } = getBody();

					const validation = handleAgencyValidation(
						reqBody.name,
						reqBody.phone
					);

					if (validation.isValid) {
						await formatRequest(reqBody, geoCodeRes)
							.then(() => {
								setSnackbar({
									variant: 'success',
									message: 'Agency successfully updated.',
								});
							})
							.catch(errorHandler(errors.AGENCY_UPDATE));
					} else {
						setSnackbar({
							variant: 'error',
							message: validation.message,
						});
					}
				})
				.catch(errorHandler(errors.GEOCODE('Agency')))
				.finally(() => {
					setLoader(false);
				});
		};

		return <PrimaryButton onClick={onClick}>Save Changes</PrimaryButton>;
	}

	return [<SaveButton key='save' />];
};
