export const stateMap = {
  AL: {value: "AL", state: 'Alabama'},
  AK: {value: "AK", state: 'Alaska'},
  AZ: {value: "AZ", state: 'Arizona'},
  AR: {value: "AR", state: 'Arkansas'},
  CA: {value: "CA", state: 'California'},
  CO: {value: "CO", state: 'Colorado'},
  CT: {value: "CT", state: 'Connecticut'},
  DE: {value: "DE", state: 'Delaware'},
  DC: {value: "DC", state: 'District Of Columbia'},
  FL: {value: "FL", state: 'Florida'},
  GA: {value: "GA", state: 'Georgia'},
  HI: {value: "HI", state: 'Hawaii'},
  ID: {value: "ID", state: 'Idaho'},
  IL: {value: "IL", state: 'Illinois'},
  IN: {value: "IN", state: 'Indiana'},
  IA: {value: "IA", state: 'Iowa'},
  KS: {value: "KS", state: 'Kansas'},
  KY: {value: "KY", state: 'Kentucky'},
  LA: {value: "LA", state: 'Louisiana'},
  ME: {value: "ME", state: 'Maine'},
  MD: {value: "MD", state: 'Maryland'},
  MA: {value: "MA", state: 'Massachusetts'},
  MI: {value: "MI", state: 'Michigan'},
  MN: {value: "MN", state: 'Minnesota'},
  MS: {value: "MS", state: 'Mississippi'},
  MO: {value: "MO", state: 'Missouri'},
  MT: {value: "MT", state: 'Montana'},
  NE: {value: "NE", state: 'Nebraska'},
  NV: {value: "NV", state: 'Nevada'},
  NH: {value: "NH", state: 'New Hampshire'},
  NJ: {value: "NJ", state: 'New Jersey'},
  NM: {value: "NM", state: 'New Mexico'},
  NY: {value: "NY", state: 'New York'},
  NC: {value: "NC", state: 'North Carolina'},
  ND: {value: "ND", state: 'North Dakota'},
  OH: {value: "OH", state: 'Ohio'},
  OK: {value: "OK", state: 'Oklahoma'},
  OR: {value: "OR", state: 'Oregon'},
  PA: {value: "PA", state: 'Pennsylvania'},
  RI: {value: "RI", state: 'Rhode Island'},
  SC: {value: "SC", state: 'South Carolina'},
  SD: {value: "SD", state: 'South Dakota'},
  TN: {value: "TN", state: 'Tennessee'},
  TX: {value: "TX", state: 'Texas'},
  UT: {value: "UT", state: 'Utah'},
  VT: {value: "VT", state: 'Vermont'},
  VA: {value: "VA", state: 'Virginia'},
  WA: {value: "WA", state: 'Washington'},
  WV: {value: "WV", state: 'West Virginia'},
  WI: {value: "WI", state: 'Wisconsin'},
  WY: {value: "WY", state: 'Wyoming'}
};

export const stateOptions = Object.values(stateMap).map((value) => {
	return value;
});
