import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Visitation = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 105.492 96.701'
			{...props}>
			<g
				id='Group_213'
				data-name='Group 213'
				transform='translate(-365.254 -862.552)'>
				<g id='Group_212' data-name='Group 212'>
					<g id='Group_208' data-name='Group 208'>
						<g id='iconmonstr-home-6' transform='translate(365.254 862.552)'>
							<path
								id='iconmonstr-home-6-2'
								data-name='iconmonstr-home-6'
								d='M92.306,52.746V96.7H65.933V70.328H39.56V96.7H13.187V52.746H0L52.746,0l52.746,52.746Zm-4.4-25.964V4.4H74.724v9.2Z'
								transform='translate(0 0)'
							/>
						</g>
						<g id='placeholder' transform='translate(403.899 886.527)'>
							<path
								id='Path_255'
								data-name='Path 255'
								d='M14.147,0H14.1A14.116,14.116,0,0,0,0,14.1C0,18.364,2.179,21.688,4.606,25L14.08,37.92,23.6,25c2.428-3.311,4.606-6.635,4.606-10.9A14.116,14.116,0,0,0,14.147,0ZM14.1,23.684A9.583,9.583,0,1,1,23.684,14.1,9.594,9.594,0,0,1,14.1,23.684Zm0,0'
								fill='#fff'
							/>
							<path
								id='Path_256'
								data-name='Path 256'
								d='M172.005,150.934a3.916,3.916,0,1,0,3.916,3.916A3.92,3.92,0,0,0,172.005,150.934Zm0,0'
								transform='translate(-157.905 -141.019)'
								fill='#fff'
							/>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default Visitation;
