import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { useSetState } from 'helpers';

const defaultState = { file: '', photo: '', newFile: false, photos: null };

export default function useManageChildImageState() {
	const { childId } = useParams();
	const [state, setState] = useSetState({ ...defaultState });

	const deletePhoto = () => {
		setState({ ...defaultState, newFile: true });
	};

	const { photo, id, ownershipCodeVerified } = useSelector(getLifebooksChild);

	useEffect(() => {
		if ((childId || ownershipCodeVerified) && photo) {
			setState({ photo });
		} else {
			deletePhoto();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId, photo, id]);

	return { ...state, setState, deletePhoto };
}
