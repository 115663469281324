const calendarState = {
	calendar: [],
	calendarEvent: {
		start: new Date(),
		end: new Date(),
		title: '',
		description: '',
		notify: true,
		childId: '',
		createdAt: '',
	},
	calendarModal: false,
	eventType: 'new',
};

const SET_CALENDAR = 'SET_CALENDAR';
const TOGGLE_CALENDAR_MODAL = 'TOGGLE_CALENDAR_MODAL';
const SET_CALENDAR_EVENT = 'SET_CALENDAR_EVENT';
const SET_EVENT_TYPE = 'SET_EVENT_TYPE';

export default function calendarReducer(state = calendarState, action) {
	switch (action.type) {
		case SET_CALENDAR:
			if (action.payload !== 'error') {
				const updatedCalendar = action.payload?.map((e) => {
					const start = new Date(e.start);
					const end = new Date(e.end);
					return { ...e, start: start, end: end };
				});
				return { ...state, calendar: updatedCalendar };
			} else {
				return { ...state };
			}

		case TOGGLE_CALENDAR_MODAL:
			return { ...state, calendarModal: action.payload };

		case SET_CALENDAR_EVENT:
			return {
				...state,
				calendarEvent: action.payload,
			};

		case SET_EVENT_TYPE:
			return { ...state, eventType: action.payload };

		default:
			return state;
	}
}

export function toggleCalendarModal(modal) {
	return {
		type: TOGGLE_CALENDAR_MODAL,
		payload: modal,
	};
}

export function setCalendarEvent(event) {
	return {
		type: SET_CALENDAR_EVENT,
		payload: event,
	};
}

export function setEventType(type) {
	return {
		type: SET_EVENT_TYPE,
		payload: type,
	};
}

export function setCalendar(calendar) {
	return {
		type: SET_CALENDAR,
		payload: calendar,
	};
}
