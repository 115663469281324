import {
	casaPermissionsToObject,
	createAllPermissions,
} from '../../components/Casa/utils/casaUtils';

const casaState = {
	initialCasaPermissions: {},
	casaPermissions: {},
	casaUserPermissions: {},
	casa: {
		id: '',
		name: '',
		office: '',
		address: {},
	},
	casaUsers: [],
	selectedCasaUser: {},
	casaConnections: [],
	contributorChildren: [],
	casaUser: {},
};

const SET_CASA = 'SET_CASA';
const SET_CASA_USERS = 'SET_CASA_USERS';
const SET_CASA_USER = 'SET_CASA_USER';
const SET_CASA_CONNECTIONS = 'SET_CASA_CONNECTIONS';
const SET_ALL_CASA_PERMISSIONS = 'SET_ALL_CASA_PERMISSIONS';
const SET_CASA_PERMISSION = 'SET_CASA_PERMISSION';
const SET_INITIAL_CASA_PERMISSIONS = 'SET_INITIAL_CASA_PERMISSIONS';
const SET_CONTRIBUTOR_CHILDREN = 'SET_CONTRIBUTOR_CHILDREN';
const SET_CASA_INPUT = 'SET_CASA_INPUT';
const SET_CASA_USER_PERMISSIONS = 'SET_CASA_USER_PERMISSIONS';
const SET_ALL_CASA_USER_PERMISSIONS = 'SET_ALL_CASA_USER_PERMISSIONS';

export default function casaReducer(state = casaState, action) {
	switch (action.type) {
		case SET_ALL_CASA_PERMISSIONS:
			let permissionObject = action.payload;
			const isArray = Array.isArray(action.payload);
			if (permissionObject === 'clear') {
				permissionObject = {};
			} else if (permissionObject === 'all') {
				permissionObject = createAllPermissions;
			} else {
				if (isArray) {
					permissionObject = casaPermissionsToObject(action.payload);
				}
			}

			return { ...state, casaPermissions: permissionObject };

		case SET_ALL_CASA_USER_PERMISSIONS:
			let permissions = action.payload;
			const isAr = Array.isArray(action.payload);
			if (permissions === 'error') {
				permissions = 'error';
			} else if (permissions === 'clear') {
				permissions = {};
			} else if (permissions === 'all') {
				permissions = createAllPermissions;
			} else {
				if (isAr) {
					permissions = casaPermissionsToObject(action.payload);
				}
			}
			return { ...state, casaUserPermissions: permissions };

		case SET_CASA_USER_PERMISSIONS:
			return {
				...state,
				casaUserPermissions: {
					...state.casaUserPermissions,
					...action.payload,
				},
			};

		case SET_CASA_PERMISSION:
			return {
				...state,
				casaPermissions: { ...state.casaPermissions, ...action.payload },
			};

		case SET_INITIAL_CASA_PERMISSIONS:
			let initialPermissions = {};
			if (action.payload !== 'clear') {
				initialPermissions = casaPermissionsToObject(action.payload);
			}
			return {
				...state,
				initialCasaPermissions: initialPermissions,
			};

		case SET_CONTRIBUTOR_CHILDREN:
			return {
				...state,
				contributorChildren: action.payload,
			};

		case SET_CASA:
			return { ...state, casa: action.payload };

		case SET_CASA_USERS:
			return { ...state, casaUsers: action.payload };

		case SET_CASA_USER:
			return { ...state, selectedCasaUser: action.payload };

		case SET_CASA_CONNECTIONS:
			if (action.payload?.casa === 'error') {
				return { ...state, casaConnections: 'error' };
			} else {
				const userType = action.payload.userType;
				const casa = action.payload.casa;
				let casaList = [];
				if (userType === 'agencyuser') {
					if (casa.length > 0) {
						casa.map((connection) => {
							connection.casa.connectId = connection.id;
							connection.casa.status = connection.status;
							casaList.push(connection.casa);
							return connection;
						});
					}
				} else {
					if (casa.length > 0) {
						casa.map((connection) => {
							connection.agency.connectId = connection.id;
							connection.agency.status = connection.status;
							casaList.push(connection.agency);
							return connection;
						});
					}
				}
				return { ...state, casaConnections: casaList };
			}

		case SET_CASA_INPUT:
			if (action.payload === 'clear') {
				return {
					...state,
					casaUser: {},
				};
			} else {
				return {
					...state,
					casaUser: {
						...state.casaUser,
						...action.payload,
					},
				};
			}

		default:
			return state;
	}
}

export function setAllCasaPermissions(casaPermissions) {
	return {
		type: SET_ALL_CASA_PERMISSIONS,
		payload: casaPermissions,
	};
}

export function setAllCasaUserPermissions(casaPermissions) {
	return {
		type: SET_ALL_CASA_USER_PERMISSIONS,
		payload: casaPermissions,
	};
}

export function setInitialCasaPermissions(casaPermissions) {
	return {
		type: SET_INITIAL_CASA_PERMISSIONS,
		payload: casaPermissions,
	};
}

export function setCasaPermission(casaPermissions) {
	return {
		type: SET_CASA_PERMISSION,
		payload: casaPermissions,
	};
}

export function setCasaUserPermissions(casaPermissions) {
	return {
		type: SET_CASA_USER_PERMISSIONS,
		payload: casaPermissions,
	};
}

export function setCasa(casa) {
	return {
		type: SET_CASA,
		payload: casa,
	};
}

export function setCasaUsers(casa) {
	return {
		type: SET_CASA_USERS,
		payload: casa,
	};
}

export function setCasaUser(user) {
	return {
		type: SET_CASA_USER,
		payload: user,
	};
}

export function setCasaConnections(casa, userType) {
	return {
		type: SET_CASA_CONNECTIONS,
		payload: { casa, userType },
	};
}

export function setContributorChildren(children) {
	return {
		type: SET_CONTRIBUTOR_CHILDREN,
		payload: children,
	};
}

export function setCasaInput(user) {
	return {
		type: SET_CASA_INPUT,
		payload: user,
	};
}
