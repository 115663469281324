import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import addressReducer from './reducers/addressReducer';
import contributorReducer from './reducers/contributorReducer';
import experimentsReducer from './reducers/experimentsReducer';
import homesReducer from './reducers/homesReducer';
import lifebooksReducer from './reducers/lifebooksReducer';
import loaderReducer from './reducers/loaderReducer';
import mediaQueryReducer from './reducers/mediaQueryReducer';
import overLaysReducer from './reducers/overLaysReducer';
import postReducer from './reducers/postsReducer';
import snackbarReducer from './reducers/snackbarReducer';
import userReducer from './reducers/userReducer';
import vaultReducer from './reducers/vaultReducer';
import promise from 'redux-promise-middleware';
import casaReducer from './reducers/casaReducer';
import calendarReducer from './reducers/calendarReducer';
import videoReducer from './reducers/videoReducer';
import familyConnectionsReducer from './reducers/famillyConnectionsReducer';

const devTools =
	process.env.REACT_APP_ENVIRONMENT === 'dev' &&
	window.__REDUX_DEVTOOLS_EXTENSION__
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: (f) => f;

export default createStore(
	combineReducers({
		address: addressReducer,
		contributor: contributorReducer,
		experiments: experimentsReducer,
		homes: homesReducer,
		lifebooks: lifebooksReducer,
		loader: loaderReducer,
		mediaQuery: mediaQueryReducer,
		overLay: overLaysReducer,
		posts: postReducer,
		snackbar: snackbarReducer,
		user: userReducer,
		casa: casaReducer,
		familyConnections: familyConnectionsReducer,
		vault: vaultReducer,
		calendar: calendarReducer,
		video: videoReducer,
	}),

	compose(applyMiddleware(promise), devTools)
);
