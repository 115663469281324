import { useState, useEffect } from 'react';
import * as Router from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMobileListCheck } from 'helpers';
import Connected from './components/Connected';
import { UserList, SlideContainer, Welcome } from 'components';
import styles from './CasaUser.module.css';

const CasaConnections = () => {
	const connectionList = useSelector((state) => state.casa.casaConnections);

	const [active, setActive] = useState(null);

	const { push, goBack } = Router.useHistory();
	const { pathname } = Router.useLocation();

	useMobileListCheck(pathname === '/casaConnections/');

	useEffect(() => {
		if (!pathname.includes('manage')) {
			setActive(null);
		}
	}, [pathname]);

	const selectCasa = (e, casa) => {
		setActive(casa.id);
		e.stopPropagation();
		push({ pathname: `/casaConnections/manage/${casa.id}`, state: casa });
	};

	return (
		<div className={styles.casaWrapper}>
			<SlideContainer>
				<UserList
					list={connectionList || []}
					handleSelect={selectCasa}
					label='Agencies'
					paramId={active}
				/>
			</SlideContainer>
			<div className={styles.casaConnectionsContainer}>
				<Router.Switch>
					<Router.Route path='/casaConnections/manage/:casaId'>
						<Connected onCancel={() => goBack()} />
					</Router.Route>
					<Router.Route path='/'>
						<Welcome />
					</Router.Route>
				</Router.Switch>
			</div>
		</div>
	);
};

export default Router.withRouter(CasaConnections);
