const tryAgain = (str) => `${str} Please try again later.`;
const genericUpdate = tryAgain(
	'Information could not be updated at this time.'
);

// NOTE: These are attached to setSnackbarError actions.
// If the error does not have response data, these will be used as defaults
export const AGENCY_UPDATE = {
	location: 'Manage Agency Update',
	message: tryAgain('Agency could not be Saved at this time.'),
};

export const BRANCH_SAVE = {
	location: 'Manage Branch Save',
	message: tryAgain('Branch could not be saved at this time.'),
};

export const LOAD_POSTS = {
	location: 'Fetch Posts Load',
	message: tryAgain('Posts could not be loaded.'),
};

export const LAZY_LOAD_POSTS = {
	...LOAD_POSTS,
	location: 'Fetch Posts Lazy Load',
};

export const MANAGE_CHILD_RESTART = {
	location: 'Manage Child Restart',
	message: genericUpdate,
};

export const MANAGE_CHILD_SW_REL = {
	location: 'Manage Child SW Relations',
};

export const MANAGE_HOUSE_SAVE = {
	location: 'Manage House Save',
	message: genericUpdate,
};

export const MANAGE_SW_REL = {
	location: 'Manage SW Relations',
};

export const MANAGER_BASIC_STATE = {
	location: 'Manager State',
};

export const PASSWORD_REQUEST = {
	location: 'Password Request',
	message: tryAgain('Request could not be completed.'),
};

export const READ_ONLY_PASSWORD = {
	location: 'Read Only Password Confirm',
	message: 'Logged out due to maximum password attempts',
};

export const READ_ONLY_SWITCH = {
	location: 'Read Only View Switch',
	message: tryAgain(`Read-Only couldn't be accessed at this time.`),
};

export const USER_REINVITE = (type) => ({
	location: `${type} reinvite`,
});

export const USER_ARCHIVE = (type) => ({
	location: `Manage ${type} Archive`,
	message: tryAgain('User could not be removed at this time.'),
});

export const USER_SAVE = (type) => ({
	location: `Manage ${type} Save`,
	message: tryAgain('User could not be saved at this time.'),
});

export const GEOCODE = (location) => ({
	location: `Manage ${location} Geocode`,
	message: 'Address could not be verified',
});
