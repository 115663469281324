import { useParams, useHistory, useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getPostsRoot, getUserState, getLifebooksChild } from 'redux/selectors';
import { Welcome, PrimaryButton } from 'components';
import EllaLogo from 'images/Ella-logo-only.svg';
import styles from '../pageLayout.module.css';
import clsx from 'clsx';

const NoPost = (props) => {
	const { children } = props;

	const root = useSelector(getPostsRoot);
	const user = useSelector(getUserState);
	const child = useSelector(getLifebooksChild);
	const { feedType, childId } = useParams();
	const { search } = useLocation();
	const { push } = useHistory();

	const parsed = queryString.parse(search);

	const isNsp = feedType === 'services';
	const hasServiceSelector = isNsp && root !== 'archive';

	const addPost = () => {
		push(`/lifebooks/post/${childId}`);
	};

	const noPostContent = (title) => {
		return (
			<div className={styles.noPostRoot}>
				<img src='/assets/ella-elephant.svg' alt='Ella Elephant' />
				<div className={styles.noPostTitle}>{title}</div>
				<div className={styles.noPostButtonContainer}>
					{!['archive', 'auditoraccess', 'vault'].includes(root) ? (
						<PrimaryButton onClick={addPost}>Add a Memory</PrimaryButton>
					) : null}
				</div>
			</div>
		);
	};

	const noPostReturnSwitch = (type) => {
		const { firstname } = child || {};
		const firstNameString = firstname ? `${firstname}` : 'the selected child';
		const yet = !['archive'].includes(root) ? ' yet' : '';
		switch (true) {
			case !!children: // children prop is passed to component
				return noPostContent(children);
			case ['vault'].includes(root): // user is viewing the vault
				return noPostContent('This vault is empty');
			case feedType === 'services':
				// user is viewing the services
				return noPostContent(
					`There are no service posts for ${firstNameString}`
				);
			case feedType === 'pendingNsp':
			case feedType === 'pendingTL':
			case feedType === 'pending': // user is viewing the pendings
				return noPostContent(
					`There are no pending memories for ${firstNameString}`
				);
			case feedType === 'exports': // user is viewing exports
				return noPostContent(
					`There are no export requests for ${firstNameString}`
				);
			case feedType === 'drafts': // user is viewing the drafts
				return noPostContent(
					`There are no timeline drafts for ${firstNameString}`
				);
			case feedType === 'nspDrafts': // user is viewing the service drafts
				return noPostContent(
					`There are no service drafts for ${firstNameString}`
				);
			case !!parsed.category: //user has selected a category
				return noPostContent('There are no memories in the selected category');
			case type === 'child':
				return <Welcome user={user} />;
			case !!parsed.token: // audit access
			case type === 'contributor':
			case type === 'csw':
			case type === 'agencyuser':
			case type === 'casauser':
				return noPostContent(
					`There are no memories${yet} for ${firstNameString}`
				);
			default:
				return <Redirect to='/lifebooks/' />;
		}
	};

	const noPostHeightClass = isNsp
		? 'noPostHeightDefault'
		: hasServiceSelector
		? 'noPostHeightServiceSelector'
		: null;

	if (['vault'].includes(root)) {
		const description =
			user.type === 'child'
				? 'The vault is a place where your county/state worker can store posts and documents that are personal to you and can be retrieved by you on the date specified by your worker. You can also store and retrieve any personal posts and documents you create and upload in the vault.'
				: 'The vault is a place where you can store posts and personal documents of the youth that can only be retrieved until the date assigned by the youth’s county/state worker. Youth with their own accounts can also store and access personal documents and posts they have uploaded themselves.';
		return (
			<div className={styles.fullVaultEmpty}>
				<div className={styles.vaultHeader}>Ella Vault</div>
				<div className={styles.imageContainer}>
					<img style={styles.emtyLogo} src={EllaLogo} alt='Ella Elephant' />
				</div>
				<div>{description}</div>
			</div>
		);
	}

	return (
		<div className={clsx(styles.noPostContainer, styles[noPostHeightClass])}>
			{noPostReturnSwitch(user.type)}
		</div>
	);
};

export default NoPost;
