import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { editVaultAccessDate } from 'services';
import { formatDateRender } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import useManageChildPermissions from '../utils/useManageChildPermissions';
import { Tooltip } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import * as Comp from 'components';
import DatePickerRow from './DatePickerRow';
import styles from '../child.module.css';

export default function ChildVaultAccessDate() {
	const child = useSelector(getLifebooksChild);
	const { setSnackbar, setLoader } = useCreateDispatches();
	const { vaultAccessDate } = child;
	const [edit, setEdit] = useState(false);
	const [editDate, setEditDate] = useState(vaultAccessDate);
	const { vaultDate: vaultDateAccess } = useManageChildPermissions();
	const canSeeVaultAccessDate = !!vaultDateAccess;
	const canEditVaultAccessDate = vaultDateAccess === 'edit';

	const addYears = () => {
		const dob = new Date(child.dob);
		dob.setFullYear(dob.getFullYear() + 18);
		return dob;
	};

	const vDate = addYears();

	const renderDate = () => {
		if (!child.dob) {
			return 'Not Set';
		}
		const dateToRender = editDate || vaultAccessDate;
		return dateToRender
			? formatDateRender(dateToRender)
			: formatDateRender(vDate);
	};

	const onSubmit = () => {
		setLoader(true);
		editVaultAccessDate(child.id, editDate || new Date())
			.then(({ data }) => {
				setSnackbar({
					variant: 'success',
					message: 'Vault Access Date updated successfully',
				});
				setEdit(false);
				if (!editDate) {
					setEditDate(data?.vaultAccessDate || new Date());
				}
			})
			.catch((err) => {
				setSnackbar({ variant: 'error', message: err.response?.data.message });
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const label = 'Vault Access Date';

	if (!canSeeVaultAccessDate) return null;

	return (
		<div className={styles.editVaultDateSection}>
			{edit ? (
				<div className={styles.editVaultDatePicker}>
					<DatePickerRow
						label={label}
						value={editDate}
						onChange={setEditDate}
						disableFuture={false}
					/>
					<div className={styles.editVaultDateButtonContainer}>
						<Comp.SecondaryButton
							onClick={() => {
								setEditDate(vaultAccessDate);
								setEdit(false);
							}}>
							Cancel
						</Comp.SecondaryButton>
						<Comp.PrimaryButton onClick={onSubmit}>Submit</Comp.PrimaryButton>
					</div>
				</div>
			) : (
				<div className={styles.tooltipContainer}>
					<Tooltip
						title={
							'This is the date the child will be able to view vault posts and files'
						}>
						<HelpOutline className={styles.tooltipIcon} />
					</Tooltip>
					<Comp.StaticTextRow
						label={label}
						value={
							<>
								{renderDate()}
								{canEditVaultAccessDate && (
									<span
										className={styles.editVaultDateEditButton}
										onClick={() => {
											setEdit(true);
										}}>
										EDIT
									</span>
								)}
							</>
						}
					/>
				</div>
			)}
		</div>
	);
}
