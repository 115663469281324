import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as EmailValidator from 'email-validator';
import { setSnackbar } from '../redux';

export const handlePhoneInput = (number) => {
	if (number) {
		var pn = parsePhoneNumber(number, { regionCode: 'US' });
		if (pn.possible) {
			return pn.number?.e164;
		} else {
			return number;
		}
	}
};

export const formatDateRender = (value, style = 'MMDDYYY') => {
	const fullDate = new Date(value);
	const month = fullDate.getMonth() + 1;
	const date = fullDate.getDate();
	const year = fullDate.getFullYear();

	switch (style) {
		case 'MMDDYY':
			return `${month}/${date}/${year.toString().slice(2)}`;
		case 'MMDD':
			return `${month}/${date}}`;
		case 'MMDDYYY':
		default:
			return `${month}/${date}/${year}`;
	}
};

export const formatDateTextRender = (value) => {
	const date = new Date(value);
	return date.toDateString();
};

export const formatPhone = (str = '') => {
	return str.replace(/\D/g, '');
};

export const passwordTypeCheck = (password) => {
	if (password.length < 8) {
		return false;
	} else {
		return true;
	}
};

export const emailTypeCheck = (email) => {
	return EmailValidator.validate(email);
};

export const emailGovCheck = (email) => {
	let split = email.split('.');
	const extension = split[split.length - 1];
	return extension === 'gov' || extension === 'org';
};

export const inputObject = (value) => {
	return { target: { value: value || '' } };
};

export const validDate = (date) => {
	return Boolean(Date.parse(date));
};

export const getAge = (input, eventDate) => {
	const dob = new Date(input);
	const today = new Date();
	const dateToCheck = eventDate ? eventDate : today;
	const result = Math.floor(
		(dateToCheck - dob) / (1000 * 60 * 60 * 24 * 365.25)
	);
	if (typeof result === 'number') {
		return result;
	} else {
		return '';
	}
};

export const getHashtags = (str) => {
	let hashes = [];
	if (str) {
		str.split(' ').forEach((val) => {
			if (val[0] === '#') {
				hashes.push(val.substring(1, val.length));
			}
		});
		if (hashes.length) {
			return hashes;
		}
	}
};

export const utilizeFocus = () => {
	const ref = React.createRef();
	const setFocus = () => {
		ref.current && ref.current.focus();
	};
	return { setFocus, ref };
};

export const diffDays = (first, second) => {
	const firstDate = new Date(first);
	const secondDate = second ? new Date(second) : new Date();
	return Math.round(Math.abs((firstDate - secondDate) / (24 * 60 * 60 * 1000)));
};

export const diffHours = (first, second) => {
	const firstDate = new Date(first);
	const secondDate = second ? new Date(second) : new Date();
	return Math.round(Math.abs((firstDate - secondDate) / (60 * 60 * 1000)));
};

const inputTypeHandle = (type, event) => {
	const { target } = event;
	switch (type) {
		case 'date':
			if (target) return target.value;
			return event;
		case 'phone':
			return handlePhoneInput(target?.value);
		default:
			return target?.value;
	}
};

export const useFormInput = (label, initial, params) => {
	const {
		type,
		required,
		disabled,
		autoComplete,
		validationCb,
		validationMessage,
	} = params || {};
	const [value, setValue] = useState(initial);

	const handleChange = (e) => {
		if (e) {
			const { value } = e;
			const useValue = Object.keys(e).includes('value');
			let input = value;
			if (!useValue) {
				input = inputTypeHandle(type, e);
			}
			setValue(input);
		} else {
			setValue('');
		}
	};

	const defaultValidation =
		type === 'email' ? () => emailTypeCheck(value) : () => true;

	const formInput = {
		label,
		value,
		onChange: handleChange,
		autoComplete: autoComplete ? autoComplete : 'off',
		required: required ? true : false,
		select: type === 'select',
		disabled,
		validation: {
			onValidate: validationCb || defaultValidation,
			validationMessage,
		},
	};

	if (type === 'date') {
		formInput.type = 'date';
	}

	return formInput;
};

export const useInputArrayValidation = (inputs) => {
	const dispatch = useDispatch();
	let validationMessage;
	const isValidated = inputs.every(({ validation }) => {
		const validated = validation?.onValidate();
		if (!validated) {
			validationMessage = validation.validationMessage;
		}
		return validated;
	});
	if (isValidated) {
		return true;
	} else {
		dispatch(setSnackbar({ variant: 'error', message: validationMessage }));
		return false;
	}
};

export const imageAcceptedExtensions = [
	'bmp',
	'gif',
	'jfif',
	'jpg',
	'jpeg',
	'heic',
	'png',
];

export const filesAcceptedExtensions = [
	'pdf',
	'jfif',
	'jpeg',
	'jpg',
	'png',
	'gif',
	'tif',
	'tiff',
	'doc',
	'docx',
	'ppt',
	'pptx',
	'xls',
	'xlsx',
];
