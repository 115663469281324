import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import { UserList, SlideContainer } from 'components';
import { useCreateDispatches } from 'helpers/hooks';

export default function SnapshotChildListContainer({
	childList = [],
	sortMenuItems,
}) {
	const { push } = useHistory();
	const root = useSelector(getPostsRoot);
	const { setChild } = useCreateDispatches();

	const selectChild = (e, child) => {
		e.stopPropagation();
		setChild(child);
		child?.id && push(`/archive/timeline/${child.id}`);
	};

	const onAddChild = () => {
		push('/lifebooks/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={childList}
				label='Children'
				pathParam='childId'
				handleSelect={selectChild}
				addToggle={root === 'lifebooks' && onAddChild}
				addLabel='Add a Child'
				sortMenuItems={sortMenuItems}
			/>
		</SlideContainer>
	);
}
