import { connect } from 'react-redux';
import { setNotificationCount, setLoader } from 'redux/reducers';
import DashboardNav from './components/DashboardNav';
import DashboardUser from './components/DashboardUser';
import styles from './dashboard.module.css';

function Dashboard(props) {
	const { user, fullScreen, height, isMd, isSm, mobileDashboard } = props;
	const dashboardCollapse = !isMd && fullScreen;
	const dashboardSlide = isMd && !mobileDashboard;

	return (
		<div
			className={`
				${styles.dashboardContainer}
				${dashboardCollapse && styles.dashboardCollapse}
				${dashboardSlide && styles.dashboardHide}`}>
			<DashboardUser user={user} row={height < 840 && !isSm} />
			<div className={styles.dashboardNav}>
				<DashboardNav />
			</div>
			{height < 576 ? null : (
				<div className={styles.dashboardLogo}>
					<img
						src={'/assets/ella-dash-logo-white-text.svg'}
						alt='Ella Logo'
						className={styles.dashboardLogoImage}
					/>
				</div>
			)}
		</div>
	);
}

function mapStateToProps({
	user,
	overLay: { mobileDashboard, fullScreen },
	mediaQuery: { height, isMd, isSm },
}) {
	return {
		user: user.user,
		fullScreen,
		height,
		isMd,
		isSm,
		mobileDashboard,
	};
}

export default connect(mapStateToProps, {
	setNotificationCount,
	setLoader,
})(Dashboard);
