import Chip from '@material-ui/core/Chip';
import { serviceIconPack } from 'helpers';
import styles from '../timeline.module.css';

const ServiceCategoryChip = (props) => {
	if (props.category) {
		const ServiceIcon = serviceIconPack[props?.category];

		return (
			<div className={styles.postCategoryChipRoot}>
				<Chip
					label={
						<>
							<ServiceIcon className={styles.postCategoryChipIcon} />
							<span>{props?.category?.toUpperCase?.()}</span>
						</>
					}
				/>
			</div>
		);
	}

	return <></>;
};

export default ServiceCategoryChip;
