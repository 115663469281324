import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackbar } from 'redux/index';
import * as services from 'services';
import { usePasswordConfirm } from 'helpers';
import { DeleteButton } from 'components';

export default function DeleteChildButton({ child, childId, childSort }) {
	const onConfirm = usePasswordConfirm();
	const dispatch = useDispatch();
	const history = useHistory();

	const onDeleteChild = async () => {
		dispatch(setLoader(true));
		await services
			.archiveChild(childId)
			.then(() => {
				services.setChildrenList(childSort).then(() => {
					dispatch(
						setSnackbar({
							variant: 'success',
							message: 'Child updated successfully',
						})
					);
					history.push(`/children/`);
				});
			})
			.catch((error) => {
				dispatch(
					setSnackbar({
						variant: 'error',
						message: error.message,
					})
				);
			})
			.finally(() => dispatch(setLoader(false)));
	};

	return (
		<DeleteButton
			onClick={() =>
				onConfirm(
					onDeleteChild,
					"This is a permanent option and cannot be undone. Please confirm your password and the child's first and last name.",
					{
						secondaryCheck: `${child.firstname} ${child.lastname}`,
						secondaryLabel: 'Child',
						secondaryError: "Please verify the child's name and spelling.",
					}
				)
			}>
			Delete
		</DeleteButton>
	);
}
