import { createTheme } from '@material-ui/core/styles';
import { breakpoints } from '../../redux';

const theme = createTheme({
	textField: {
		backgroundColor: 'lightgrey',
	},
	typography: {
		useNextVariants: true,
	},
	breakpoints: {
		values: breakpoints,
	},
	font: {
		default: 'Nunito, sans-serif',
	},
	palette: {
		primary: {
			light: '#43BBF7',
			main: '#00A0E8', //basic-button (blue)
			dark: '#222222', //dashboard (dark blue)
			blue: '#00A0E8',
			divider: '#E8ECEE', //border (light gray)
			dividerDark: '#BEBEBE',
			purple: '#796EFF',
			contrastText: '#ffffff',
			gray: '#F6F8F9',
			white: '#FFFFFF',
			black: '#000000',
			button: '#00A0E8',
		},
		secondary: {
			light: '#008CE3',
			main: '#42BEEE', // round-button (turquoise)
			gray: '#646f79',
			dark: '#646f79',
			font: '#6F7782',
			hover: '#008CE3',
		},
		background: {
			main: 'white',
			gray: '#f6f8f9',
			active: '#C3E6FD',
			nav: '#222222',
			lifebookMenu: '#F7F7F7',
		},
		body: {
			primary: '#FFFFFF',
			secondary: '#F6F8F9',
			highlight: '#E8ECEE',
		},
		nav: {
			hover: 'rgba(255, 255, 255, 0.08)',
		},
		error: {
			main: '#FF5263',
			contrastText: '#FFFFFF',
		},
		button: {
			main: '#008CE3',
		},
		active: {
			main: '#42BEEE', //primary-active (yellow)
			dark: '#222B37',
		},
		disabled: {
			main: '#BABCBC', //light grey
			contrastText: '#000000',
		},
		font: {
			gray: '#A5A5A5',
			contrast: '#FFFFFF',
			default: '#00000',
		},
	},
	shape: {
		borderRadius: 4,
	},
	width: {
		contentMax: '722px',
		contentMin: '300px',
	},
	height: {
		LifebookNav: '60px',
	},
	transitions: {
		easing: {
			zoomAppear: {
				'0%': {
					transform: 'scale(0,0)',
				},
				'100%': {
					transform: 'scale(1,1)',
				},
			},
			zoomDisappear: {
				'0%': {
					transform: 'scale(1,1)',
				},
				'100%': {
					transform: 'scale(0,0)',
				},
			},
		},
	},
});

theme.overrides = {
	MuiDrawer: {
		paper: {
			minWidth: 256,
		},
		paperAnchorDockedLeft: {
			borderRight: 'none',
		},
	},
	MuiOutlinedInput: {
		root: {
			borderRadius: '12px',
		},
		input: {
			padding: '14px',
		},
	},
	MuiInputBase: {
		input: {
			height: '24px',
		},
	},
	MuiButton: {
		root: {
			height: '32px',
			padding: '8px 16px',
			fontWeight: '700',
			fontSize: '12px',
			lineHeight: '12px',
		},
		contained: {
			boxShadow: 'none',
		},
	},
	MuiIconButton: {
		root: {
			padding: '6px',
			fontSize: '12px',
			fontWeight: '500',
			color: '#6F7782',
		},
	},
	MuiTooltip: {
		tooltip: {
			borderRadius: '2px',
			fontSize: '12px',
		},
	},
	MuiTextField: {
		root: {
			margin: '0',
		},
	},
	MuiFormLabel: {
		asterisk: {
			color: 'red',
		},
	},
	MuiAutocomplete: {
		root: {
			width: '100%',
		},
		inputRoot: {
			height: '52px',
		},
	},
	MuiSwitch: {
		switchBase: {
			color: theme.palette.primary.main,
		},
		track: {
			'$checked$checked + &': {
				opacity: 0.35,
				backgroundColor: theme.palette.primary.blue,
			},
		},
		colorSecondary: {
			'&$checked': {
				color: theme.palette.primary.main,
			},
		},
	},
};

export default theme;
