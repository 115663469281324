import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiV2 } from '../../axios';
import { useCreateDispatches } from 'helpers/hooks';

import styles from './account.module.css';
import { PageLayout, PrimaryButton, StaticTextRow } from 'components';
import { useSelector } from 'react-redux';

const Billing = () => {
	const seatsTotal = useSelector(
		(state) => state.user?.agency?.purchasedSeatQuantity
	);
	const agencyUsers = useSelector((state) => state.user?.agencyUsers);

	const dispatch = useDispatch();
	const { setLoader } = useCreateDispatches();
	const dispatchLoader = (val) => dispatch(setLoader(val));

	const [url, setUrl] = useState();
	const [activeAgencyUsers, setActiveAgencyUsers] = useState([]);
	const [invitedAgencyUsers, setInvitedAgencyUsers] = useState([]);

	const returnUrl = process.env.REACT_APP_STRIPE_REDIRECT;
	const totalSeatsAllowed = seatsTotal + 2 || 0;
	const seatsRemaining = totalSeatsAllowed - agencyUsers.length;

	useEffect(() => {
		dispatchLoader(true);
		getUrl();
	}, []);

	useEffect(() => {
		if (agencyUsers) {
			agencyBillingData().then(() => dispatchLoader(false));
		}
	}, [agencyUsers]);

	const agencyBillingData = async () => {
		setActiveAgencyUsers(
			await agencyUsers.filter((user) => user.status === 'active')
		);

		setInvitedAgencyUsers(
			await agencyUsers.filter((user) => user.status === 'invited')
		);
		return;
	};

	const getUrl = async () => {
		await apiV2
			.get(`/billing/session?returnUrl=${returnUrl}`)
			.then((e) => setUrl(e?.data?.url))
			.catch((error) => console.log('error', error));
	};

	const handleBillingPortal = () => {
		window.location.href = url;
	};

	return (
		<PageLayout title='Billing'>
			<div className={styles.billing}>
				<StaticTextRow
					label={'SEATS PURCHASED'}
					value={seatsTotal || 0}
					labelStyle={styles.label}
				/>
				<StaticTextRow
					label={'SEATS INCLUDED'}
					value={2}
					labelStyle={styles.label}
				/>
				<StaticTextRow
					label={'TOTAL SEATS ALLOWED'}
					value={seatsTotal + 2 || 0}
					labelStyle={styles.label}
				/>
				<StaticTextRow
					label={'SEATS USED'}
					value={activeAgencyUsers.length || 0}
					labelStyle={styles.label}
				/>
				<StaticTextRow
					label={'SEATS PENDING INVITE'}
					value={invitedAgencyUsers.length || 0}
					labelStyle={styles.label}
				/>
				<StaticTextRow
					label={'SEATS LEFT'}
					value={seatsRemaining}
					labelStyle={styles.label}
				/>
				<div style={{ paddingTop: '50px' }}>
					<PrimaryButton onClick={handleBillingPortal}>
						To Billing Portal
					</PrimaryButton>
				</div>
			</div>
		</PageLayout>
	);
};

export default Billing;
