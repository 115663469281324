import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getLifebooksChild, getUserState, getPostsRoot } from 'redux/selectors';
import { useCheckUserPermissions } from 'helpers/hooks';
import { experiments } from 'helpers/';
import useNavOptions from './useNavOptions';

export default function useUserTypeOptions() {
	const child = useSelector(getLifebooksChild);
	const { includeUsers } = useCheckUserPermissions();
	const user = useSelector(getUserState);
	const root = useSelector(getPostsRoot);
	const navOptions = useNavOptions(root);

	const isActiveChild = includeUsers('activeChild');
	const location = useLocation();

	const userNavType =
		(location?.pathname?.includes('support') && 'support') ||
		(!['lifebooks', 'children'].includes(root) && root) ||
		(isActiveChild && 'activeChild') ||
		(user.type === 'contributor' && user.role) ||
		user.type;

	const {
		profile,
		timeline,
		activity,
		drafts,
		services,
		pending,
		exportRequest,
		vaultFiles,
		exit,
		exitReadOnly,
		calendar,
		calendarActivity,
		contact,
		videos,
		manual,
		vaultActivity,
	} = navOptions;

	const checkLifeBookPermission = (user, child) => {
		if (child?.privatizePosts) {
			if (user?.role === 'contributor') {
				return null;
			}
			return services;
		}

		return timeline;
	};

	const lifeBook = checkLifeBookPermission(user, child);

	const roleList = {
		child: [timeline, activity],
		activeChild: [timeline, activity, exportRequest, drafts],
		csw: [lifeBook, activity, pending, drafts, profile],
		agencyuser: [lifeBook, activity, pending, drafts, profile],
		contributor: [lifeBook, pending, drafts],
		'resource-parent': [lifeBook, pending, drafts, profile],
		archive: [lifeBook, experiments.archiveActivity(true) && activity, profile],
		auditoraccess: [lifeBook, profile, exit],
		readonly: [lifeBook, profile, exitReadOnly],
		vault: [timeline, vaultFiles, vaultActivity],
		calendar: [calendar, calendarActivity],
		support: [user.type !== 'child' && contact, videos, manual],
		connect: [timeline, profile, exit],
		casauser: [lifeBook, pending, drafts, profile],
	};
	return roleList[userNavType] || [];
}
