import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux';
import { PasswordConfirm } from '../../components';

export function usePasswordConfirm({
	onFailure,
	maxAttempts,
	getAttempts,
	setAttempts,
} = {}) {
	const dispatch = useDispatch();

	return (onSubmit, { message, email, keepLoader = true } = {}) => {
		dispatch(
			setModal(
				<PasswordConfirm
					show={true}
					showToggle={(value) => dispatch(setModal(value))}
					onSubmit={onSubmit}
					message={message}
					onFailure={onFailure}
					maxAttempts={maxAttempts}
					getAttempts={getAttempts}
					email={email}
					keepLoader={keepLoader}
					setAttempts={setAttempts}
				/>
			)
		);
	};
}
