import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { setCategory, clearCategory } from 'redux/index';
import * as selector from 'redux/selectors';
import { OptionMenu } from 'components';
import { getIcon } from 'helpers';
import SectionLayout from './SectionLayout';
import styles from '../headers.module.css';

export default function CategorySelect() {
	const dispatch = useDispatch();
	const category = useSelector(selector.getPostsCategory);
	const categories = useSelector(selector.getLifebooksCategories);
	const categoriesHash = useSelector(selector.getLifebooksCategoriesHash);
	const isMd = useSelector(selector.getIsLg);
	const dispatchCategory = (val) => dispatch(setCategory(val));

	const { pathname, search } = useLocation();
	const { push } = useHistory();

	const onCategorySelect = (value) => {
		let parseSearch = queryString.parse(search);
		setCategory(value);
		if (value) {
			dispatchCategory(value);
			parseSearch.category = value;
		} else {
			dispatch(clearCategory());
			delete parseSearch.category;
		}
		push(`${pathname}?` + queryString.stringify(parseSearch));
	};

	const categoryItems = () => {
		let itemList = [
			{
				value: '',
				label: 'All Categories',
				onClick: () => {
					onCategorySelect('');
				},
			},
		];
		categories.forEach((val, i, arr) => {
			val.onClick = () => onCategorySelect(val.id);
			if (val.id === arr[i + 1]?.parentId) {
				val.isParent = true;
			}
			itemList.push(val);
		});
		return itemList;
	};

	const CategoryIcon = getIcon(categoriesHash?.[category]);
	const categoryLabel = categoriesHash?.[category]?.label || 'ALL';

	return (
		<SectionLayout label='CATEGORY:'>
			<OptionMenu optionButton menuItems={categoryItems()}>
				<div className={styles.categoryButtonContent}>
					{!isMd ? <CategoryIcon className={styles.categoryIcon} /> : null}
					<div>{categoryLabel}</div>
				</div>
			</OptionMenu>
		</SectionLayout>
	);
}
