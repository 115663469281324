import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearContributor } from 'redux/reducers';
import { getContributorList } from 'redux/selectors';
import { UserList, SlideContainer } from 'components';

export default function ContributorListContainer() {
	const history = useHistory();
	const dispatch = useDispatch();
	const contributorList = useSelector(getContributorList);

	const contributorListMap = contributorList?.map((val) => {
		const { firstname, lastname } = val || {};
		const hasName = !!firstname && !!lastname;
		return (hasName && { ...val, name: `${firstname} ${lastname}` }) || null;
	});

	const selectContributor = (e, { id }) => {
		history.push(`/contributors/manage/${id}`);
	};

	const addContributor = () => {
		dispatch(clearContributor());
		history.push('/contributors/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={contributorListMap}
				label='Contributors'
				pathParam={'contributorId'}
				handleSelect={selectContributor}
				addToggle={addContributor}
				addLabel='Add a Contributor'
			/>
		</SlideContainer>
	);
}
