import { AddImages, SectionHeader } from 'components';
import styles from '../account.module.css';

const AccountImages = (props) => {
	const { file, photo, setState, deletePhoto, photos } = props;

	return (
		<div className={styles.imageRoot}>
			<div className={styles.imageContent}>
				<SectionHeader>Profile Photo</SectionHeader>
				<AddImages
					setState={setState}
					file={file}
					photos={(photo ? [photo] : photos) || []}
					deletePhoto={deletePhoto}
					single
				/>
			</div>
		</div>
	);
};

export default AccountImages;
