import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/reducers';
import { toggleCalendarModal } from 'redux/index';
import Modal from 'react-modal';
import moment from 'moment';
import { useCreateMetadata } from '../utils/useCreateMetadata';
import * as services from 'services';
import { getDateRange, eventValidation } from 'helpers';
import { getTitle, setDisabled } from '../utils/calendarUtils';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import * as Comp from 'components';
import { modalStyle } from '../calendarModalStyle';
import styles from '../calendar.module.css';
import EventDeleteConfirm from './EventDeleteConfirm';

const EventModal = () => {
	const calendarModal = useSelector((state) => state.calendar.calendarModal);
	const user = useSelector((state) => state.user.user);
	const child = useSelector((state) => state.lifebooks.child);
	const calendarEvent = useSelector((state) => state.calendar.calendarEvent);
	const eventType = useSelector((state) => state.calendar.eventType);
	const isMd = useSelector((state) => state.mediaQuery.isMd);

	const [title, setTitle] = useState(calendarEvent.title);
	const [startDate, setStartDate] = useState(calendarEvent.start);
	const [endDate, setEndDate] = useState(calendarEvent.end);
	const [description, setDescription] = useState(calendarEvent.description);
	const [notify, setNotify] = useState(calendarEvent.notify);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const dispatch = useDispatch();
	const createMetadata = useCreateMetadata();

	const isChild = user.type === 'child';
	const id = isChild ? user.id : child.id;
	const dateRange = getDateRange(new Date(calendarEvent.start));
	const eventTitle = getTitle(eventType, isChild);
	const disabled = setDisabled(eventType, isChild, calendarEvent, user);
	const event = {
		childId: id,
		title: title,
		description: description,
		start: startDate,
		end: endDate,
		notify: notify,
		id: calendarEvent.id ? calendarEvent.id : null,
		metadata: createMetadata(calendarEvent),
	};

	useEffect(() => {
		setStartDate(moment(calendarEvent.start));
		setEndDate(moment(calendarEvent.end));
		setTitle(calendarEvent.title);
		setDescription(calendarEvent.description);
		setNotify(calendarEvent.notify);
	}, [calendarEvent, eventType]);

	const loadChildCalendar = async () => {
		dispatch(setLoader(true));
		await services.getChildCalendar(id, dateRange);
		dispatch(setLoader(false));
	};

	const openDeleteConfirm = () => {
		setConfirmDelete(true);
	};

	const handleDelete = async () => {
		await services.deleteCalendarEvent(calendarEvent.id);
		loadChildCalendar();
		setConfirmDelete(false);
		dispatch(toggleCalendarModal(false));
	};

	const handleSubmit = async () => {
		const validation = eventValidation(id, startDate, endDate, title);

		if (!validation.isValid) {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: validation.reason,
				})
			);
			return;
		}

		let resData;

		event.start = moment(startDate).toISOString();
		event.end = moment(endDate).toISOString();

		if (eventType === 'new') {
			resData = await services.addCalendarEvent(event);
		} else {
			resData = await services.editCalendarEvent(event);
		}

		if (resData.data) {
			const eventHeader = eventType === 'new' ? 'added' : 'edited';

			loadChildCalendar();
			dispatch(
				setSnackbar({
					variant: 'success',
					message: `Successfully ${eventHeader} a calendar event`,
				})
			);
		}

		dispatch(toggleCalendarModal(false));
	};

	return (
		<div>
			<EventDeleteConfirm
				confirmDelete={confirmDelete}
				handleDelete={() => handleDelete()}
				handleClose={() => setConfirmDelete(false)}
			/>
			<Modal
				ariaHideApp={false}
				style={modalStyle}
				isOpen={calendarModal}
				transparent={false}>
				<div
					className={
						isMd ? styles.fullEventModalMobile : styles.fullEventModal
					}>
					<div className={styles.header}>{eventTitle}</div>
					<div className={styles.datesContainer}>
						<DateTimePicker
							disabled={disabled}
							className={styles.dateContainer}
							value={startDate}
							label={'Start Date *'}
							onChange={(e) => setStartDate(e)}
						/>
						<DateTimePicker
							disabled={disabled}
							className={styles.dateContainer}
							value={endDate}
							label={'End Date *'}
							onChange={(e) => setEndDate(e)}
						/>
					</div>
					<div className={styles.titleContainer}>
						<Comp.TextField
							disabled={disabled}
							variant='outlined'
							label='Title'
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required={true}
							className={styles.title}
						/>
					</div>

					<div className={styles.calendarDescriptionContainer}>
						<Comp.TextArea
							disabled={disabled}
							className={styles.calendarDescription}
							minRows={5}
							label={'Description'}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>

					<div className={styles.calendarNotify}>
						<Tooltip
							title={
								'By enabling you will receive a 24 hour reminder by email'
							}>
							<Help fontSize={'small'} className={styles.helpIcon} />
						</Tooltip>
						<Comp.FormToggleSwitch
							disabled={disabled}
							className={styles.toggle}
							labelStyle={styles.labelStyle}
							switchState={notify}
							setSwitch={setNotify}>
							Notify About Event
						</Comp.FormToggleSwitch>
					</div>

					<div className={styles.buttonContainer}>
						{eventType === 'created' && !disabled && (
							<Comp.DeleteButton onClick={openDeleteConfirm}>
								Delete
							</Comp.DeleteButton>
						)}
						<Comp.SecondaryButton
							onClick={() => dispatch(toggleCalendarModal(false))}>
							Close
						</Comp.SecondaryButton>
						{!disabled && (
							<Comp.PrimaryButton onClick={handleSubmit}>
								Submit
							</Comp.PrimaryButton>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default EventModal;
