import { validateEmail } from './utils/validate';

export const handleRpValidation = (firstname, lastname, email) => {
	const isEmailValid = validateEmail(email);

	if (!firstname || !lastname || !email) {
		return { isValid: false, message: 'Please fill out all required fields' };
	}

	if (!isEmailValid) {
		return { isValid: false, message: 'Email is invalid' };
	}

	return { isValid: true };
};

export const handleHomeValidation = (houseName) => {
	if (!houseName) {
		return { isValid: false, message: 'House name is required' };
	}

	return { isValid: true };
};
