import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/index';
import { addNote } from 'services';
import { useFormInput } from 'helpers';
import { PrimaryButton, SecondaryButton, TextArea } from 'components';
import styles from '../postNote.module.css';
import clsx from 'clsx';

export default function PostNote({ postId, onCancel, onSubmitCallback }) {
	const dispatch = useDispatch();
	const note = useFormInput('New Note', '');
	const { user } = useSelector(({ user }) => user);

	const handleOnSave = async () => {
		await addNote({
			note: note.value,
			postId,
			metadata: {
				// changed to createdBy, remove userId once confirmed //
				userId: user?.id,
				createdBy: user?.id,
				createdName: `${user.firstname} ${user.lastname.substring(0, 1)}`,
			},
		})
			.then(() => {
				onSubmitCallback();
				dispatch(
					setSnackbar({
						variant: 'success',
						message: 'Note successfully added.',
					})
				);
			})
			.catch((err) => {
				dispatch(
					setSnackbar({
						variant: 'error',
						message:
							err.response?.data?.message ||
							'Something went wrong. Please try again later.',
					})
				);
			});
	};

	return (
		<div className={styles.postNoteRoot}>
			<TextArea className={styles.postNoteTextArea} {...note} minRows={5} />
			<div
				className={clsx(
					'add-post-button-container',
					styles.postNoteButtonContainer
				)}>
				<SecondaryButton onClick={onCancel}>Cancel Note</SecondaryButton>
				<PrimaryButton onClick={handleOnSave}>Save Note</PrimaryButton>
			</div>
		</div>
	);
}
