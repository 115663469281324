export const getPostsState = ({ posts }) => posts;

export const getPostsPosts = ({ posts }) => posts.postList;

export const getHasPosts = ({ posts }) => posts.postList?.length > 0

export const getPostsCategory = ({ posts }) => posts.category;

export const getPostsDateRange = ({ posts }) => posts.dateRange;

export const getPostsPendingFilter = ({ posts }) => posts.pendingFilter;

export const getPostsRoot = ({ posts }) => posts.root;

export const getPostsFeedType = ({ posts }) => posts.feedType;

export const getPostsFeedView = ({ posts }) => posts.feedView;

export const getPostsPostIndexById = id => ({posts}) => {
  return posts.postList?.findIndex(val => val.id === id)
}