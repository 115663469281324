import useManageRPPermissions from '../utils/useManageRPPermissions';
import { SelectorList } from 'components';

export default function RPHouseList({ selectorListState }) {
	const linkTo = '/homes/manage/';
	const permissions = useManageRPPermissions();
	const disable = permissions.house !== 'edit';
	if (permissions.house === 'view') {
		const house = selectorListState?.assignedList?.[0];
		if (!house) return null;
	}

	return (
		<SelectorList
			label='HOUSE'
			textFieldLabel='House'
			linkTo={linkTo}
			sort='name'
			fromList='house'
			{...selectorListState}
			disable={disable}
		/>
	);
}
