import { useCheckUserPermissions } from 'helpers/hooks';

// NOTE: This hook is used to determine if a user should see the editable component
export default function useManageRPPermissions(child) {
	const { includeUsers } = useCheckUserPermissions();

	const view = {
		firstname: true,
		lastname: true,
		email: true,
		house: true,
	};

	const edit = {
		firstname: includeUsers('admin', 'manager', 'privileged'),
		lastname: includeUsers('admin', 'manager', 'privileged'),
		email: includeUsers('admin', 'manager', 'privileged'),
		house: includeUsers('admin', 'manager', 'privileged'),
	};

	const permissions = Object.keys(edit).reduce((acc, label) => {
		acc[label] = edit[label] ? 'edit' : view[label] ? 'view' : null;

		return acc;
	}, {});

	return permissions;
}
