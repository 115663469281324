import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import store from './redux/store';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
JavascriptTimeAgo.locale(en);

export const googleScript = document.createElement('script');

googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&loading=async&libraries=places`;
//googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`;
googleScript.async = true;
googleScript.loaded = false;
googleScript.addEventListener('load', () => {
	googleScript.loaded = true;
});
document.body.appendChild(googleScript);

export const loadGoogle = (callback) => {
	if (googleScript.loaded) {
		callback();
	} else {
		googleScript.addEventListener('load', () => {
			callback();
		});
	}
};

const root = createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
