import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import { PrimaryButton } from 'components';
import styles from '../sendInvite.module.css';

const InviteNoBranch = () => {
	const root = useSelector(getPostsRoot);
	const { push } = useHistory();

	const onAddBranch = () => {
		push('/agencies/manage');
	};

	return (
		<div className={styles.noBranchContainer}>
			<div className={styles.noBranchRoot}>
				<div className={styles.noBranchTitle}>
					A branch must be created before any users can be created.
				</div>
				<div className={styles.noBranchButtonContainer}>
					{!['archive', 'auditoraccess', 'vault'].includes(root) ? (
						<PrimaryButton onClick={onAddBranch}>Create A Branch</PrimaryButton>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default InviteNoBranch;
