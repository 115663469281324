import { useEffect } from 'react';
import * as Router from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState, getLifebooksChildren } from 'redux/selectors';
import { getCurrentChild } from 'services';
import { useMobileListCheck } from 'helpers';
import { useCreateDispatches, useCheckUserPermissions } from 'helpers/hooks';
import { ChildListContainer, Feed, EditPost, Welcome } from 'components';
import VaultActivity from './components/VaultActivity';
import VaultFiles from './components/VaultFiles';
import styles from './vault.module.css';

export function Vault(props) {
	const { childId, feedType } = Router.useParams();
	const { push } = Router.useHistory();

	const { includeUsers, excludeUsers } = useCheckUserPermissions();

	const user = useSelector(getUserState);
	const children = useSelector(getLifebooksChildren);

	const { setRoot } = useCreateDispatches();

	useEffect(() => {
		setRoot('vault');
	}, [setRoot]);

	const selectVaultChild = (e, child) => {
		e.stopPropagation();
		const route = /file/.test(feedType) ? 'files' : 'timeline';
		props.history.push(`/vault/${route}/${child.id}`);
	};

	useEffect(() => {
		if (excludeUsers('child')) {
			if (childId) {
				getCurrentChild(childId);
			} else if (!childId) {
				push('/vault/');
			}
		} else {
			getCurrentChild(user.id);
			push(`/vault/timeline/${user.id}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId, children, push, user]);

	useMobileListCheck(!childId);

	return (
		<div className={styles.vaultContainer}>
			{includeUsers('agencyuser', 'csw') && (
				<>
					<ChildListContainer selectChild={selectVaultChild} />
				</>
			)}
			<div className={styles.vaultContent}>
				<div className={styles.vaultFeed}>
					<Router.Switch>
						<Router.Route
							key={childId + feedType}
							path='/vault/post/:childId/:postId?'>
							<EditPost root='vault' />
						</Router.Route>
						<Router.Route
							key={childId + feedType}
							path='/vault/timeline/:childId'>
							<Feed />
						</Router.Route>
						<Router.Route
							key={childId + feedType}
							path='/vault/files/:childId/'>
							<VaultFiles />
						</Router.Route>
						<Router.Route
							key={childId + feedType}
							path='/vault/vaultActivity/:childId/'>
							<VaultActivity />
						</Router.Route>
						<Router.Route path='/vault/'>
							<Welcome user={user} />
						</Router.Route>
					</Router.Switch>
				</div>
			</div>
		</div>
	);
}

export default Router.withRouter(Vault);
