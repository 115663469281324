import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Feed, EditPost, ChildListContainer, ManageChild } from 'components';
import { useCreateDispatches, useGetContainerWidth } from 'helpers/hooks';
import { getWidth } from 'redux/selectors';
import styles from '../pageLayout.module.css';

const ReadOnlyContainer = (props) => {
	const { baseRoute } = props || {};
	const history = useHistory();

	const { ref: contentRef, setContainerWidth } = useGetContainerWidth();
	const width = useSelector(getWidth);

	const { child, school } = useSelector(({ lifebooks }) => {
		const child = lifebooks.child;
		const schoolsHash = lifebooks.schoolsHash;
		const school =
			child.schoolId && schoolsHash ? schoolsHash[child.schoolId] : '';
		return { child, school };
	});
	const { setRoot, setChildren, setChildrenHash, setChildSort } =
		useCreateDispatches();

	useEffect(() => {
		setRoot(baseRoute);
		setChildSort('lastname');
		if (child?.id) {
			history.push(`/${baseRoute}/timeline/${child.id}`);
		} /* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		setChildren([child]);
		setChildrenHash({ [child.id]: child });
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		setContainerWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width, contentRef.current]);

	return (
		<div className={styles.readOnlyContainer}>
			<ChildListContainer />
			<div className={styles.readOnlyContent} ref={contentRef}>
				<Switch>
					<Route
						key={`${baseRoute}/post`}
						path={`/${baseRoute}/post/:childId/:postId?`}
						authorized={{
							agencyuser: true,
							house: true,
							child: { status: ['active'] },
							csw: true,
							contributor: true,
						}}>
						<EditPost />
					</Route>
					<Route
						key={`${child.id}-${baseRoute}-profile`}
						path={`/${baseRoute}/child/:childId`}>
						<ManageChild
							disable
							file={child.photoUrl}
							childState={child}
							school={school}
							audit
						/>
					</Route>
					<Route
						key={`${child.id}-${baseRoute}-timeline`}
						path={`/${baseRoute}/:feedType/:childId`}>
						<Feed />
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default ReadOnlyContainer;
