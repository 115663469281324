import { useSelector } from 'react-redux';
import { getUserState } from '../../redux/selectors';

export function useCheckUserPermissions() {
	const {
		type,
		role,
		privileged,
		privatizedPosts,
		status,
		partner,
		exitedCare,
	} = useSelector(getUserState);

	const isAgencyUser = type === 'agencyuser';
	const isPrivileged = type === 'agencyuser' && privileged;
	const isCasaUser = type === 'casauser';
	const isChild = type === 'child';

	const checkRole = (accessKey) => {
		switch (accessKey) {
			case 'ConnectOurKids':
				return partner === accessKey;
			case 'child':
				return type === accessKey;
			case 'admin':
			case 'manager':
			case 'social-worker':
				return isAgencyUser && role === accessKey;
			case 'privileged':
				return isPrivileged;
			case 'agencyuser':
				return type === accessKey;
			case 'privatizedChild':
				return isChild && privatizedPosts;
			case 'unprivatizedChild':
				return isChild && !privatizedPosts;
			case 'activeChild':
				return isChild && status === 'active';
			case 'archivedChild':
				return isChild && !!exitedCare;
			case 'resource-parent':
			case 'contributor':
				return type === 'contributor' && role === accessKey;
			case 'csw':
				return type === 'csw';
			case 'casaadmin':
				return isCasaUser && role === accessKey;
			case 'casauser':
				return isCasaUser;
			default:
				return false;
		}
	};

	const includeUsers = (...arr) => {
		return arr.some((val) => checkRole(val));
	};

	const excludeUsers = (...arr) => {
		return arr.every((val) => !checkRole(val));
	};

	return { includeUsers, excludeUsers };
}
