import { withRouter } from 'react-router-dom';
import useRetrieveAgency from '../utils/useRetrieveAgency';
import useArrayMappers from '../utils/useArrayMappers';
import ProfileName from './ProfileName';
import ProfileEmail from './ProfileEmail';
import ProfilePassword from './ProfilePassword';
import { StaticInfo } from 'components';
import StaticEmail from 'components/Invite/components/StaticEmail';
import styles from '../account.module.css';

export function ProfileBasics(props) {
	const { agency, user, onSubmit, staticEmail } = props;
	const {
		firstname,
		lastname,
		email,
		mobile,
		oldPassword,
		newPassword,
		newPwConfirm,
		token,
	} = props.infoState || {};

	const { branchName } = useRetrieveAgency({ user, agency, token });

	const { textFieldMap } = useArrayMappers(onSubmit);

	let profileFields = [
		{ label: 'Phone', value: 'mobile', input: mobile, submit: true },
	];

	const fieldMap = textFieldMap(profileFields);
	const staticInfoArray = [
		agency && { label: 'Agency', value: agency.name, first: true },
		branchName && { label: 'Branch', value: branchName },
	];

	return (
		<form autoComplete='off' className={styles.profileRoot}>
			{staticEmail && <StaticEmail label='Email:' value={email.value} />}
			<ProfileName firstname={firstname} lastname={lastname} />
			{!staticEmail && <ProfileEmail email={email} />}

			{fieldMap}
			<StaticInfo infoArray={staticInfoArray} />
			<ProfilePassword
				oldPassword={oldPassword}
				newPassword={newPassword}
				newPwConfirm={newPwConfirm}
				onSubmit={onSubmit}
			/>
		</form>
	);
}

export default withRouter(ProfileBasics);
