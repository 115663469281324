import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as redux from 'redux/index';
import { casaData, createCasaPermissionObject } from '../utils';
import Checkbox from '@material-ui/core/Checkbox';
import { FormToggleSwitch, FormSectionHeader } from 'components';
import styles from '../casa.module.css';

const CasaUserPermissions = () => {
	const casaUser = useSelector((state) => state.casa.selectedCasaUser);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);

	const [checked, setChecked] = useState(false);

	const permissions = casaData(casaPermissions, casaUserPermissions);
	const permissionsAvailable = permissions.some(
		(permission) => permission.value
	);

	const { push } = useHistory();

	useEffect(() => {
		if (!casaUser.id) {
			push({ pathname: `/casaUsers/` });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAllToggle = () => {
		setChecked(!checked);
		let updatedPermissions = { ...casaUserPermissions };

		if (checked) {
			Object.keys(updatedPermissions).forEach(
				(v) => (updatedPermissions[v] = false)
			);
		} else {
			Object.keys(updatedPermissions).forEach(
				(v) => (updatedPermissions[v] = true)
			);
		}
		redux.store.dispatch(redux.setAllCasaUserPermissions(updatedPermissions));
	};

	const handleSingleToggle = (value, index, reqKey) => {
		const permissions = createCasaPermissionObject(reqKey, !value);
		redux.store.dispatch(redux.setCasaUserPermissions(permissions));
	};

	if (!permissionsAvailable) {
		return <div style={{ color: 'red' }}>No permissions available to set</div>;
	}

	const toggleMapper = ({ value, label, tooltip, reqKey, show }, i) => {
		if (!show) {
			return null;
		}
		return (
			<FormToggleSwitch
				tooltip={tooltip}
				helpIcon={true}
				key={`${i}-${value}`}
				switchState={value}
				setSwitch={() => handleSingleToggle(value, i, reqKey)}>
				{label}
			</FormToggleSwitch>
		);
	};

	return (
		<div className={styles.notificationsToggleInfoContainer}>
			<FormSectionHeader>
				<div className={styles.notificationToggleAll}>
					<div>Casa Permissions</div>
					<div className={styles.notificationsToggleCheck}>
						All
						<Checkbox checked={checked} onChange={handleAllToggle} />
					</div>
				</div>
			</FormSectionHeader>
			{permissions?.map(toggleMapper)}
		</div>
	);
};

export default CasaUserPermissions;
