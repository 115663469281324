import { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { getTokenType, getTokenUser } from '../../helpers';
import { useCreateDispatches, useQuery } from '../../helpers/hooks';
import { getCategories } from '../../services';
import { InvalidToken } from '../../components';
import { getFromToken } from './helperFunctions';

export const TokenRoute = (props) => {
	const { children, component: Component, key, path, ...rest } = props;
	const { setChild, setUser, setChildren } = useCreateDispatches();
	const query = useQuery();
	const storageToken = localStorage.getItem('token');
	const token = query.token || storageToken;
	const tokenType = getTokenType(token);
	const isCorrectToken = path.includes(tokenType);
	const component = Component ? <Component {...props} /> : children;

	const [authorize, setAuthorize] = useState(false);
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		const validate = async () => {
			if (isCorrectToken) {
				const child = await getFromToken(token, tokenType).catch(() => {
					setChecked(true);
				});
				const user = await getTokenUser(token);
				if (child?.data && user) {
					setChild(child.data);
					setChildren([child.data]);
					setUser(user);
					getCategories();
					setAuthorize(true);
				}
			}
			setChecked(true);
		};

		validate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCorrectToken, key, path, token]);

	return isCorrectToken ? (
		checked === true ? (
			<Route
				{...rest}
				render={() => {
					return authorize ? component : <InvalidToken error='Invalid Token' />;
				}}
			/>
		) : (
			<div>Loading</div>
		)
	) : (
		<InvalidToken error='Invalid Token' />
	);
};
