import { useState, useRef } from 'react';
import { useCreateDispatches } from 'helpers/hooks';
import useGetDisplayImage from '../utils/useGetDisplayImage';
import { ViewCarousel } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Icon } from '@material-ui/core';
import ImageSelection from './ImageSelection';
import FullScreenImage from './FullScreenImage';
import { TransparentButton } from 'components';
import styles from '../timeline.module.css';

export default function PostImage(props) {
	const { post, index, disabled, carouselDisabled } = props;
	const { setModal } = useCreateDispatches();
	const [loader, setLoader] = useState(false);
	const [img, setImg] = useState(null);
	const openFullImage = () => {
		setModal(
			<FullScreenImage
				post={post}
				index={index}
				disabled={disabled}
				src={img.src}
			/>
		);
	};

	const { hasMultiplePhotos, ...imageSelectionProps } = useGetDisplayImage(
		post.photos,
		setLoader,
		setImg
	);

	const imgRef = useRef(null);
	const { current } = imgRef;
	const showImageSelection = hasMultiplePhotos && !carouselDisabled;

	return (
		<>
			<div className={styles.postImageRoot} ref={imgRef}>
				{hasMultiplePhotos && (
					<TransparentButton
						onClick={openFullImage}
						className={styles.postImagePreviewIcon}
						contrast>
						<Icon>
							<ViewCarousel />
						</Icon>
					</TransparentButton>
				)}
				{loader ? (
					<div style={{ height: current?.offsetWidth || 0 }}>
						<LinearProgress />
					</div>
				) : (
					img?.src && (
						<img
							style={{ height: current?.offsetWidth || 0 }}
							onClick={openFullImage}
							src={`${img.src}`}
							alt={post.title}
						/>
					)
				)}
			</div>
			{showImageSelection && <ImageSelection {...imageSelectionProps} />}
		</>
	);
}
