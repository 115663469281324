import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMobileList } from 'redux/index';
import styles from '../support.module.css';

function SupportTile(props) {
	const { value, select, children } = props;

	const dispatch = useDispatch();

	const onSelect = (e) => {
		dispatch(setMobileList(false));
		select(e, value);
	};

	return children || children?.[0] ? (
		<>
			<div
				className={
					props.value?.id === props.selected
						? styles.tileContainerActive
						: styles.tileContainer
				}
				onClick={onSelect}>
				<div className={styles.listTileName}>{children}</div>
			</div>
		</>
	) : null;
}

export default withRouter(SupportTile);
