import { validateEmail } from './utils/validate';

export const relationValidation = (relationships, childIds, isEdit) => {
	if (!isEdit) {
		if (relationships.length !== childIds.length) {
			return false;
		}

		const noRelationship = relationships.some(
			(r) =>
				!r.relationship || r.relationship === 'Other' || r.relationship === ''
		);

		if (noRelationship) {
			return false;
		}
	}

	if (isEdit) {
		if (childIds.length > 0) {
			const hasRelationship = relationships.some((relationship) =>
				childIds.includes(relationship.childId)
			);
			if (!hasRelationship) {
				return false;
			}
		}

		const noRelationship = relationships.some(
			(r) =>
				!r.relationship || r.relationship === 'Other' || r.relationship === ''
		);

		if (noRelationship) {
			return false;
		}
	}

	return true;
};

export const handleContributorValidation = (
	relation,
	ids,
	edit,
	firstname,
	lastname,
	email,
	children
) => {
	const relationshipValid = relationValidation(relation, ids, edit);
	const emailValid = validateEmail(email);

	if (!firstname) {
		return { isValid: false, message: 'First name is required' };
	}

	if (!lastname) {
		return { isValid: false, message: 'Last name is required' };
	}

	if (!email) {
		return { isValid: false, message: 'Email is required' };
	}

	if (!emailValid) {
		return { isValid: false, message: 'Not a valid email' };
	}

	if (ids?.length === 0 && children?.length === 0) {
		return { isValid: false, message: 'Assign at least 1 child' };
	}

	if (!relationshipValid) {
		return { isValid: false, message: 'Relationhip is required' };
	}

	return { isValid: true };
};
