import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Education = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 137.669 80.966'
			{...props}>
			<g
				id='mortarboard_2'
				data-name='mortarboard 2'
				transform='translate(0 -42.468)'>
				<path
					id='Path_245'
					data-name='Path 245'
					d='M134.978,65.561,70.177,42.7a4.031,4.031,0,0,0-2.684,0L2.692,65.561a4.033,4.033,0,0,0-.009,7.6L67.484,96.2a4.031,4.031,0,0,0,2.7,0l64.8-23.037a4.033,4.033,0,0,0-.009-7.6Z'
					transform='translate(0)'
				/>
				<path
					id='Path_247'
					data-name='Path 247'
					d='M145.926,236.062a12.069,12.069,0,0,1-4.053-.7l-36.429-12.951v17.749c0,4.342,4.53,8.014,13.464,10.914a95.082,95.082,0,0,0,54.037,0c8.934-2.9,13.464-6.572,13.464-10.914V222.412l-36.429,12.951A12.072,12.072,0,0,1,145.926,236.062Z'
					transform='translate(-77.092 -131.56)'
				/>
			</g>
		</SvgIcon>
	);
};

export default Education;
