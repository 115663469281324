import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectorListState } from 'helpers/hooks';
import { useGetManagerButtons } from '../utils/useGetManagerButtons';
import useManageManagerState from '../utils/useManageManagerState';
import { PageLayout, RoleSelector } from 'components';
import ManagerBasicInfo from './ManagerBasicInfo';
import ManagerSocialWorkerList from './ManagerSocialWorkerList';
import ManagerBranchList from './ManagerBranchList';

export default function ManageManager() {
	const { managerId } = useParams();
	const [disableRoleMessage, setDisableRoleMessage] = useState(null);
	const {
		pageTitle,
		socialWorkerList,
		assignedSocialWorkerList,
		branchList,
		assignedBranch,
		role,
		setRole,
		loaded,
	} = useManageManagerState();

	const socialWorkerListState = useSelectorListState(
		socialWorkerList,
		assignedSocialWorkerList,
		{
			loaded,
		}
	);

	const branchListState = useSelectorListState(branchList, assignedBranch, {
		single: true,
		loaded,
	});

	const resetListChanges = () => {
		socialWorkerListState.resetChanges();
		branchListState.resetChanges();
	};

	const getDisableRole = () => {
		const { remove = [] } = socialWorkerListState.changes || {};

		// check if all assignedSWs are marked for removal
		const allRemoved = assignedSocialWorkerList?.length === remove.length;

		return !(!assignedSocialWorkerList.length > 0 || allRemoved)
			? 'Role can not be changed while Social Workers are assigned'
			: null;
	};

	useEffect(() => {
		setDisableRoleMessage(getDisableRole());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assignedSocialWorkerList.length, socialWorkerListState.changes?.remove]);

	const getBody = () => {
		const reqBody = { id: managerId };
		if (role !== 'manager') {
			reqBody.role = role;
		}
		const { add, remove } = branchListState?.changes || {};

		if (add.length > 0) {
			reqBody.branchId = add[0].id;
		} else if (remove.length > 0) {
			reqBody.branchId = '';
		}

		return {
			reqBody,
			socialWorkerChanges: socialWorkerListState.changes,
		};
	};

	const buttonArray = useGetManagerButtons(getBody, resetListChanges);

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<ManagerBasicInfo />
			<RoleSelector
				key={managerId}
				role={role}
				onChange={setRole}
				disableMessage={disableRoleMessage}
			/>
			<ManagerBranchList selectorListState={branchListState} />
			<ManagerSocialWorkerList selectorListState={socialWorkerListState} />
		</PageLayout>
	);
}
