import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackbar, store } from 'redux/index';
import { setResourceParent } from 'redux/reducers';
import * as service from 'services';
import { handleRpValidation } from 'helpers/validation/homesValidation';
import { PrimaryButton, SecondaryButton } from 'components';

function CancelButton() {
	const history = useHistory();
	const onClick = () => history.goBack();
	return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
}

function SaveButton({ getBody, resetListChanges }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { rpId } = useParams();
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const dispatchSnackbar = (val) => dispatch(setSnackbar(val));

	const isEdit = !!rpId;

	const getText = () => {
		return !isEdit ? 'Save Parent' : 'Save Changes';
	};

	const errorHandler = (err) => {
		dispatchSnackbar(
			{ variant: 'error', message: err.response?.data.message },
			{ log: true }
		);
	};

	const resourceParentRequest = async (parent, rpId) => {
		return await (isEdit
			? service.updateResourceParent(parent, rpId)
			: service.addResourceParent(parent));
	};

	const onClick = async () => {
		const { reqBody } = getBody();
		const validation = handleRpValidation(
			reqBody.firstname,
			reqBody.lastname,
			reqBody.email
		);

		if (validation?.isValid) {
			dispatchLoader(true);
			await resourceParentRequest(reqBody, rpId)
				.then(async (res) => {
					await store.dispatch(setResourceParent(res.data));
					await service.setFetchedHouseList();
					const { id } = res.data || {};
					if (!isEdit && id) {
						history.push(`/resource-parents/manage/${id}`);
					} else {
						resetListChanges();
						return res.data;
					}
				})
				.catch(errorHandler)
				.finally(() => {
					dispatchSnackbar(
						{ variant: 'success', message: 'Success' },
						{ log: true }
					);
					dispatchLoader(false);
				});
		} else {
			dispatchSnackbar(
				{ variant: 'error', message: validation.message },
				{ log: true }
			);
		}
	};

	return <PrimaryButton onClick={onClick}>{getText()}</PrimaryButton>;
}

export const getButtonArray = (getBody, resetListChanges) => {
	return [
		<CancelButton key='cancel' />,
		<SaveButton {...{ getBody, resetListChanges }} key='save' />,
	];
};
