import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as services from 'services';
import { getLabel } from '../utils/selectorUtils';
import Close from '@material-ui/icons/Close';
import RelationshipType from './RelationshipType';
import { SecondaryButton, TransparentButton, Tile } from 'components';
import styles from '../contributor.module.css';

const FullChildTile = (props) => {
	const {
		val,
		index,
		ind,
		relationships,
		handleRemove,
		handleSelect,
		handleCustomSelect,
		disabled,
	} = props;

	const [relations, setRelations] = useState([]);

	const { isSm } = useSelector(({ mediaQuery }) => mediaQuery);
	const RemoveButtonType = isSm ? TransparentButton : SecondaryButton;
	const label = getLabel(val);

	useEffect(() => {
		services.getRelationTypes().then((e) => setRelations(e));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div key={`${val.id}${index}`}>
			<Tile image={val.photoUrl} value={val}>
				{label}
				<div className={styles.childTileContainer}>
					{!disabled && (
						<RemoveButtonType onClick={(e) => handleRemove(index, e, val.id)}>
							{isSm ? <Close /> : 'Remove'}
						</RemoveButtonType>
					)}
				</div>
			</Tile>
			<RelationshipType
				label='Relationship Type'
				textFieldLabel='Select Relationship Type'
				menuList={relations || []}
				handleMenuSelect={(e) => handleSelect(e.target.value)}
				selected={relationships[ind]?.label}
				changeCustomValue={(e) => handleCustomSelect(e.target.value)}
				customValue={relationships[ind]?.value}
				disabled={disabled}
			/>
		</div>
	);
};

export default FullChildTile;
