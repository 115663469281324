import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/index';
import * as services from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { getTitle, splitCasaPermissions } from '../utils';
import * as Comp from 'components';
import CasaUserPermissions from './CasaUserPermissions';
import CasaPermissions from '../CasaAgency/components/CasaPermissions';
import RemoveChildButton from './RemoveChildButton';

const ManageCasaChild = () => {
	const user = useSelector((state) => state.user.user);
	const casaUser = useSelector((state) => state.casa.selectedCasaUser);
	const child = useSelector((state) => state.lifebooks.child);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);
	const initialCasaPermissions = useSelector(
		(state) => state.casa.initialCasaPermissions
	);

	const params = useParams();

	const { setLoader } = useCreateDispatches();
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const title = getTitle(child);

	let permissions;

	if (user.type === 'agencyuser') {
		permissions = casaPermissions;
	} else {
		permissions = casaUserPermissions;
	}

	useEffect(() => {
		if (!child.id) {
			services.getCurrentChild(params.childId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		loadPermissions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child, casaUser]);

	const loadPermissions = async () => {
		setLoader(true);
		if (child.casaId) {
			await services.getCasaPermissions(child.casaId, child.id);
			if (user.type === 'casauser' && casaUser.id) {
				await services.getCasaUserPermissions(
					child.casaId,
					child.id,
					casaUser.id
				);
			}
		}
		setLoader(false);
	};

	const handleSubmit = () => {
		const checkPermissions = isEqual(initialCasaPermissions, permissions);
		if (!checkPermissions) {
			const newPermissions = splitCasaPermissions(
				initialCasaPermissions,
				permissions
			);
			if (user.type === 'casauser') {
				services.setChildCasaPermissions(
					newPermissions.addPermissions,
					newPermissions.removePermissions,
					child.id,
					child.casaId,
					casaUser.id
				);
			} else {
				services.setChildCasaPermissions(
					newPermissions.addPermissions,
					newPermissions.removePermissions,
					child.id,
					child.casaId
				);
			}
		} else {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Please make a change and submit again',
				})
			);
		}
	};

	let buttonArray = [
		<Comp.SecondaryButton key='account-cancel' onClick={() => history.goBack()}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-save-casa-child' onClick={handleSubmit}>
			Save
		</Comp.PrimaryButton>,
	];

	if (permissions === 'error') {
		return <div style={{ color: 'red' }}>Error loading permissions</div>;
	}

	return (
		<Comp.PageLayout buttonArray={buttonArray} title={title} align='left'>
			{user.type === 'casauser' && (
				<>
					<Comp.SectionHeader>Permissions</Comp.SectionHeader>
					{permissions === 'error' ? (
						<div style={{ color: 'red' }}>Error loading permissions</div>
					) : (
						<CasaUserPermissions />
					)}
				</>
			)}
			{user.type === 'agencyuser' && (
				<>
					<>
						<Comp.SectionHeader>Permissions</Comp.SectionHeader>
						{permissions === 'error' ? (
							<div style={{ color: 'red' }}>Error loading permissions</div>
						) : (
							<CasaPermissions />
						)}
					</>
					<Comp.SectionHeader>Admin</Comp.SectionHeader>
					<RemoveChildButton userId={location?.state?.userId} />
				</>
			)}
		</Comp.PageLayout>
	);
};

export default ManageCasaChild;
