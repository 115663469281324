import React from 'react';
import { TextFieldRow } from 'components';

export default function addressMapper(array, classes = {}) {
	return array.map((val) => {
		return (
			<React.Fragment key={val.name}>
				<TextFieldRow
					className={classes.inputField}
					required={val.required}
					disabled={val.disabled}
					variant='outlined'
					value={val.value}
					name={val.name}
					label={val.label}
				/>
			</React.Fragment>
		);
	});
}
