import { MenuItem } from '@material-ui/core';
import { TextFieldRow } from 'components';

export const vaultReasons = {
	courtRequest: 'Court Request',
	ageSpecific: 'Age Specific',
	sensitiveInformation: 'Sensitive Information',
	notAppropriate: 'Not Appropriate At This Time',
	childRequest: "Per Child's Request",
	other: 'Other',
};

export default function VaultReasonDropdown({ value, onChange }) {
	const vaultObjectMap = () => {
		let array = [];
		for (let [key, value] of Object.entries(vaultReasons)) {
			array.push({ value: key, label: value });
		}
		return array;
	};

	const vaultReasonsMap = vaultObjectMap().map((val) => {
		return (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		);
	});

	return (
		<TextFieldRow
			value={value}
			label='Reason for moving to vault'
			name='vaultReason'
			onChange={onChange}
			select>
			{vaultReasonsMap}
		</TextFieldRow>
	);
}
