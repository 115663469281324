import Moment from 'react-moment';
import clsx from 'clsx';
import styles from '../activity.module.css';

export default function ActivityItem(props) {
	const { activity } = props;

	const activityDate = activity?.logged || activity?.requestedAt;

	return (
		<div
			className={[
				styles.activityRoot,
				activity.last && styles.activityLast,
			].join(' ')}>
			<div
				className={clsx(
					styles.activityLine,
					activity.index === 0
						? activity.only
							? styles.activityOnlyLine
							: styles.activityFirstLine
						: activity.last
						? styles.activityLastLine
						: null
				)}
			/>
			<div className={styles.activityCircle} />
			<div className={styles.activityContent}>
				{activityDate ? (
					<div className={styles.activityDate}>
						<Moment format='MMMM DD, YYYY'>{activityDate}</Moment>
					</div>
				) : null}
				<div className={styles.activityLabel}>{activity.description}</div>
			</div>
		</div>
	);
}
