import { useLocation, Link } from 'react-router-dom';
import styles from '../login.module.css';

export default function LoginLinks() {
	const { pathname } = useLocation();

	return (
		<div className={styles.loginFormLinkContainer}>
			{['/login/', '/login'].includes(pathname) ? (
				<>
					<Link className={styles.loginLink} to='/ownership/'>
						Enter Ownership Code
					</Link>
				</>
			) : (
				<Link className={styles.loginLink} to='/login/'>
					Return To Login
				</Link>
			)}
			<a className={styles.loginLink} href='https://ella.care/support'>
				Need Help? Contact Us
			</a>
		</div>
	);
}
