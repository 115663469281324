import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getIsSm } from 'redux/selectors';
import * as services from 'services';
import { formatDateRender } from 'helpers';
import { useBlockAddPost } from 'helpers/hooks';
import { Add } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import { RoundButton } from 'components';
import styles from '../timeline.module.css';

export const AddPostButton = ({ match: { path } = {} }) => {
	const { childId, feedType } = useParams();
	const isSm = useSelector(getIsSm);
	const history = useHistory();
	const blockAddPostAccess = useBlockAddPost();
	const [draftsList, setDraftList] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		const onGetDraft = async () => {
			let drafts = await services
				.getDrafts()
				.then((res) => {
					const list = res.data?.filter((val) => val.childId === childId) || [];
					return list;
				})
				.catch(() => {
					return [];
				});
			let items = drafts.map((val, i) => {
				const date = formatDateRender(val.updated);
				return val ? (
					<MenuItem
						key={`${date}${i}`}
						value={date}
						onClick={(e) => {
							handleClose(e);
							editDraft(val);
						}}>
						{date}
					</MenuItem>
				) : null;
			});
			setDraftList(items);
		};

		if (path === '/lifebooks/:feedType/:childId') {
			onGetDraft();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openMenu = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	const addPost = () => {
		history.push({
			pathname: `/lifebooks/post/${childId}`,
			state: { feedType },
		});
	};

	const addPostItem = [
		<MenuItem key='add-post' value='add-post' onClick={addPost}>
			Add Post
		</MenuItem>,
		<MenuItem
			className={styles.addPostDraftMenuDivider}
			disabled
			key='drafts-title'>
			Drafts:
		</MenuItem>,
	];

	const editDraft = (val) => {
		history.push({ pathname: `/lifebooks/post/${childId}/`, state: val });
	};

	const hasDrafts = draftsList.length > 0;

	return !blockAddPostAccess ? (
		<>
			<RoundButton
				Icon={Add}
				onClick={!hasDrafts ? addPost : openMenu}
				buttonClass={[styles.plusButton, isSm && styles.plusButtonSmall].join(
					' '
				)}
				hoverText={`Add a Post`}
				hoverSide='left'
			/>
			<Menu
				anchorEl={anchorEl}
				id='tile-menu'
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						width: 200,
					},
				}}>
				{addPostItem}
				{draftsList}
			</Menu>
		</>
	) : null;
};

export default AddPostButton;
