import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'

const SocialWorker = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 13.334 16'
      {...props}>
			<g id='iconmonstr-customer-9' transform='translate(6.671 8)'>
				<path
					id='iconmonstr-customer-9-2'
					data-name='iconmonstr-customer-9'
					d='M3.337,10.987,4.355,10.5a3.186,3.186,0,0,1,2.9.155c1.145.441,1.578.589,2.054.777a.645.645,0,1,1-.4,1.227c-.519-.137-1.877-.531-2.256-.621-.427-.1-.621.4-.216.546.738.262,1.777.527,2.332.672a1.289,1.289,0,0,0,1.255-.408c.6-.481,1.981-1.941,1.981-1.941a.77.77,0,0,1,1.329.481.736.736,0,0,1-.184.472c-1.554,1.864-2.042,2.431-3.049,3.488A1.943,1.943,0,0,1,8.638,16a3.189,3.189,0,0,1-.963-.167,24.568,24.568,0,0,0-4.338-1.068V10.987ZM2.67,15.35H0V10.011H2.67ZM3.337,10.1V8.954c0-1.039.041-1.633,1.237-1.909,1.31-.3,2.88-.573,2.258-1.72C4.99,1.927,6.308,0,8.286,0c1.94,0,3.29,1.855,1.453,5.325-.6,1.14.92,1.411,2.258,1.72,1.2.277,1.241.875,1.237,1.921,0,.087-.007.782,0,1.111a1.228,1.228,0,0,0-1.46.071c-.357.315-.379.336-1.19,1.087C9.416,10.681,7.61,9.979,6.437,9.582a2.932,2.932,0,0,0-1.977.05L3.337,10.1Z'
					transform='translate(-6.671 -8)'
				/>
			</g>
		</SvgIcon>
	);
};

export default SocialWorker;
