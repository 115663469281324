import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyUser, getUserStateId } from 'redux/selectors';

export default function useManageSocialWorkerState() {
	const [loaded, setLoaded] = useState(false);
	const [disableRoleMessage, setDisableRoleMessage] = useState(null);
	const [role, setRole] = useState('admin');
	const { adminId } = useParams();
	const userId = useSelector(getUserStateId);
	const admin = useSelector(getAgencyUser(adminId));
	const { firstname, lastname, email } = admin;
	const [pageTitle, setPageTitle] = useState('Add admin');

	useEffect(() => {
		setLoaded(false);
		const title =
			firstname && lastname ? `${admin.firstname} ${admin.lastname}` : email;
		setPageTitle(title);
		const isUser = userId === adminId;
		setDisableRoleMessage(isUser ? 'You cannot change your own role' : null);
		setRole('admin');
		setLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adminId]);

	return {
		pageTitle,
		role,
		setRole,
		disableRoleMessage,
		loaded,
	};
}
