import { useState } from 'react';
import { connect } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/index';
import { verifyOwnership } from 'services';
import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { handleOwnerCodeValidation } from 'helpers/validation/loginValidation';
import { InfoTag, TextField, PrimaryButton } from 'components';
import styles from '../login.module.css';

export function OwnershipCodeEntry() {
	const { setSnackbar, setLoader } = useCreateDispatches();

	let ownershipCode = useFormInput('Owner Code', '', { required: true });
	let childId = useFormInput('Child ID', '', { required: true });
	let email = useFormInput('Email', '', { required: true });

	const [submitted, setSubmitted] = useState(false);

	const handleValidate = () => {
		const validation = handleOwnerCodeValidation(
			ownershipCode.value,
			childId.value,
			email.value
		);

		if (!validation.isValid) {
			setSnackbar(
				{ variant: 'error', message: validation.message },
				{ loggedIn: false }
			);
			return false;
		} else {
			return true;
		}
	};

	const onSubmit = () => {
		if (handleValidate()) {
			setLoader(true);
			verifyOwnership({
				ownershipCode: ownershipCode.value,
				childId: childId.value,
				email: email.value,
			})
				.then(() => {
					setSubmitted(true);
					setLoader(false);
				})
				.catch(() => {
					setSnackbar({
						variant: 'error',
						message:
							'Account could not be created. Please verify your code and ID.',
					});
					setLoader(false);
				});
		}
	};

	email.onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};

	return (
		<div className={styles.ownerCodeRoot}>
			<div className={styles.ownerCodeContent}>
				{!submitted ? (
					<>
						<div className={styles.ownerCodeTitle}>Ownership Code Entry</div>
						<InfoTag className={styles.ownerCodeInfoTag}>
							Enter the owner code and id that have been provided to you and
							your email address that you want this account to be associated
							with.
						</InfoTag>
						<TextField className={styles.ownerCodeInput} {...childId} />
						<TextField className={styles.ownerCodeInput} {...ownershipCode} />
						<TextField className={styles.ownerCodeInput} {...email} />
					</>
				) : (
					<div className={styles.ownerCodeInfo}>
						Your request has been submitted. If the account recovery information
						is valid, an email will be sent to your provided email address.
					</div>
				)}
			</div>
			<div className={styles.ownerCodeButtonContainer}>
				<PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
			</div>
		</div>
	);
}

export default connect(null, { setLoader, setSnackbar })(OwnershipCodeEntry);
