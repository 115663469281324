import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as services from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import * as util from '../../utils';
import * as Comp from 'components';
import AssignedChildren from '../../Components/AssignedChildren';
import CasaUserBasicInfo from '../../Components/CasaUserBasicInfo';
import CasaExtendedPermissions from './CasaExtendedPermissions';
import CasaSelector from 'components/Casa/Components/CasaSelector';
import RemoveCasaUserButton from './RemoveCasaUserButton';
import { MenuItem } from '@material-ui/core';
import styles from '../CasaUser.module.css';

const ManageCasaUser = () => {
	const user = useSelector((state) => state.user.user);
	const children = useSelector((state) => state.lifebooks.children);
	const casaUser = useSelector((state) => state.casa.casaUser);

	const location = useLocation();
	const dispatch = useDispatch();
	const { push } = useHistory();
	const title = util.getTitle(location?.state);
	const showStatic = util.showStaticText(user, location?.state);
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const { setSnackbar, setLoader, setCasaInput } = useCreateDispatches();

	const [child, setChild] = useState('');
	const [assignedChildren, setAssignedChildren] = useState([]);
	const [notAssignedChildren, setNotAssignedChildren] = useState([]);
	const [role, setRole] = useState(location.state?.role);

	useEffect(() => {
		setChild('');
		setRole(location.state?.role);
		loadRelations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state, children]);

	const loadRelations = async () => {
		dispatchLoader(true);
		const relations = await services.getCasaRelation(location.state?.id);
		setAssignedChildren(relations?.data || []);
		const unassigned = children.filter(
			(child) => !relations?.data?.some((child2) => child.id === child2.id)
		);
		setNotAssignedChildren(unassigned);
		dispatchLoader(false);
	};

	const onSubmit = async () => {
		const valid = util.checkValidation(casaUser, location, child);

		if (casaUser.email) {
			services.updateUser(casaUser, location.state?.id).then((res) => {
				services.getCasaUsers();
				setCasaInput('clear');
				push({ state: res.data });
				dispatch(
					setSnackbar({
						variant: 'success',
						message: 'Success',
					})
				);
			});
		}

		if (valid) {
			if (role !== location.state?.role) {
				const updatedUser = await services.changeCasaRole(
					location.state?.id,
					role
				);
				location.state = updatedUser;
			}

			if (child) {
				await services
					.addChildToCasaUser(location.state?.id, [child], 'add')
					.then(() => loadRelations());
			}
		} else {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Please make an edit before saving',
				})
			);
		}
	};

	const roleListMap = util.casaRoleData.map((val) => {
		return val ? (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		) : null;
	});

	let buttonArray = [
		<Comp.SecondaryButton
			key='account-cancel'
			onClick={() => push('/casaUsers')}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-save-casa-user' onClick={onSubmit}>
			Save
		</Comp.PrimaryButton>,
	];

	return (
		<Comp.PageLayout buttonArray={buttonArray} title={title} align='left'>
			<CasaUserBasicInfo />
			<div className={styles.casaUserCenteredFlex}>
				{showStatic ? (
					<Comp.StaticTextRow value={user.role} label={'Role'} />
				) : (
					<Comp.TextFieldRow
						select
						style={{ margin: '16px 0' }}
						label='Role'
						value={role}
						onChange={(e) => setRole(e?.target.value)}>
						{roleListMap}
					</Comp.TextFieldRow>
				)}
			</div>
			{assignedChildren.length > 0 && (
				<>
					<Comp.SectionHeader>Assigned Children</Comp.SectionHeader>
					<div className={styles.casaUserWidth} style={{ width: '100%' }}>
						<AssignedChildren
							assignedChildren={assignedChildren}
							userId={location.state?.id}
						/>
					</div>
				</>
			)}
			{user.role === 'admin' &&
				location.state?.status === 'active' &&
				location.state?.role === 'mentor' && (
					<>
						<div className={styles.casaUserWidth}>
							<CasaSelector
								label='Children'
								textFieldLabel='Assign Child'
								menuList={notAssignedChildren || []}
								handleMenuSelect={(e) => setChild(e.target.value)}
								selected={child}
								handleRemove={() => setChild('')}
							/>
						</div>

						{user.id !== location.state?.id && (
							<div className={styles.casaUserCenteredFlex}>
								<Comp.SectionHeader>Management Options</Comp.SectionHeader>
								{location.state?.role === 'mentor' && (
									<CasaExtendedPermissions user={location.state} />
								)}
								<RemoveCasaUserButton userId={location.state?.id} />
							</div>
						)}
					</>
				)}
		</Comp.PageLayout>
	);
};

export default ManageCasaUser;
