import { useParams, useLocation, useHistory } from 'react-router-dom';
import { OptionMenu } from 'components';
import SectionLayout from './SectionLayout';
import styles from '../headers.module.css';

export default function ActivityHeaderFilter() {
	const { feedType } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();

	let label;
	if (['profileActivity'].includes(feedType)) {
		label = 'Profile';
	} else if (['exportActivity'].includes(feedType)) {
		label = 'Exports';
	} else {
		label = 'Posts';
	}

	const options = [
		{ label: 'Posts', path: 'activity' },
		{ label: 'Profile', path: 'profileActivity' },
		{ label: 'Exports', path: 'exportActivity' },
	];

	const handleClick = (path) => {
		if (feedType !== path) {
			history.replace({ search: '' });
			history.push(pathname.replace(feedType, path));
		}
	};

	const optionsMap = options.map(({ label, path }) => ({
		label,
		onClick: () => handleClick(path),
	}));

	return (
		<SectionLayout label={`Filter:`}>
			<OptionMenu
				optionButton
				menuItems={optionsMap}
				className={styles.serviceSwitcherMobileRoot}>
				<div>{label}</div>
			</OptionMenu>
		</SectionLayout>
	);
}
