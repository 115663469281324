import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import * as selector from 'redux/selectors';
import { getReadOnlyToken } from 'services';
import { errors } from 'helpers';
import { useCreateDispatches, useCheckUserPermissions } from 'helpers/hooks';
import AppsIcon from '@material-ui/icons/Apps';
import ViewListIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OptionMenu } from 'components';
import SectionLayout from './SectionLayout';
import styles from '../headers.module.css';

export default function ViewSwitcher() {
	const { push } = useHistory();
	const { setFeedView, setSnackbarError } = useCreateDispatches();
	const feedView = useSelector(selector.getPostsFeedView);
	const { id: childId } = useSelector(selector.getLifebooksChild);
	const { email } = useSelector(selector.getUserState);
	const { isMd } = useSelector(selector.getMediaQueryState);

	const root = useSelector(selector.getPostsRoot);
	const { includeUsers } = useCheckUserPermissions();

	useEffect(() => {
		return () => {
			setFeedView('list');
		};
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	const options = {
		list: { icon: ViewListIcon, label: 'List' },
		grid: { icon: AppsIcon, label: 'Grid' },
	};

	const optionsMap = Object.keys(options).map((option) => ({
		value: option,
		label: options[option].label,
		onClick: () => setFeedView(option),
	}));

	const onReadOnlyClick = async () => {
		await getReadOnlyToken(childId)
			.then(({ data }) => {
				if (data?.token) {
					localStorage.setItem('token', data.token);
					localStorage.setItem('originalUser', btoa(email));
					localStorage.setItem('eroa', btoa([1]));
					push(`/readonly/`);
				}
			})
			.catch(({ response }) => {
				setSnackbarError(
					{ response, ...errors.READ_ONLY_SWITCH },
					{ log: true }
				);
			});
	};

	const allowReadOnly =
		!['vault', 'archive'].includes(root) &&
		includeUsers('agencyuser', 'resource-parent', 'csw');

	const readOnlyOption = allowReadOnly
		? [
				{
					value: 'readonly',
					label: 'Read-Only',
					onClick: onReadOnlyClick,
				},
		  ]
		: [];

	const MenuIcon = options[feedView]?.icon || AppsIcon;

	return (
		<SectionLayout
			label={!isMd ? 'VIEW:' : null}
			className={!isMd ? styles.viewSwitcherRoot : null}>
			<OptionMenu
				className={styles.viewSwitcherButtonRoot}
				divButton
				menuAlign='right'
				menuItems={[...optionsMap, ...readOnlyOption]}>
				<>
					<MenuIcon className={styles.viewSwitcherIcon} />
					<ExpandMoreIcon className={styles.viewSwitcherIcon} />
				</>
			</OptionMenu>
		</SectionLayout>
	);
}
