/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as services from 'services';
import { includeType, includeActiveChild } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { getLifebooksChild } from 'redux/selectors';
import useEditPostState from './useEditPostState';

export default function useFetchPost() {
	const { state, setState, setDefaultState, handlers } = useEditPostState();
	const {
		location: { state: locationState },
	} = useHistory();

	const { setLoader } = useCreateDispatches();

	const { postId } = useParams();
	const child = useSelector(getLifebooksChild);

	const { approved } = state;

	useEffect(() => {
		// redirect away if:
		// - post is already approvewd
		// -
		if (
			(!approved && includeType(['child', 'contributor'])) ||
			includeType(['agencyuser', 'contributor', 'csw']) ||
			(includeType(['child']) && includeActiveChild())
		) {
		}
	}, []);

	useEffect(() => {
		const fetchPost = async () => {
			setLoader(true);
			await services
				.getPost(postId)
				.then((res) => {
					setState({ ...res, category: res.category?.id });
				})
				.finally(() => {
					setLoader(false);
				});
		};

		if (locationState && (locationState.id || locationState.postDraftId)) {
			setState({ ...locationState, category: locationState.category?.id });
		} else if (postId) {
			fetchPost();
		} else {
			setDefaultState();
		}
	}, [postId, child, child.id]);

	return { state, setState, setDefaultState, handlers };
}
