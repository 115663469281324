import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBranchList, getUserAgency } from 'redux/selectors';
import { UserList, SlideContainer, UserListTile } from 'components';
import FirstTile from './FirstTile';

export default function BranchListContainer({ setInvite }) {
	const { push } = useHistory();
	const branchList = useSelector(getBranchList);
	const agency = useSelector(getUserAgency);
	const { branchId } = useParams();
	const { pathname } = useLocation();

	const activeHQ = !branchId && !pathname.includes('manage');

	const selectBranch = (e, branch) => {
		e.stopPropagation();
		setInvite(false);
		push({ pathname: `/agencies/manage/${branch.id}`, state: branch });
	};

	const onAddBranch = () => {
		setInvite(false);
		push('/agencies/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={branchList}
				label='Branches'
				pathParam='branchId'
				handleSelect={selectBranch}
				addToggle={onAddBranch}
				addLabel='Add a Branch'>
				{agency ? (
					<>
						<FirstTile name={'Agency'} />
						<UserListTile
							value={agency}
							pathParam={!activeHQ ? '' : agency.id}
							select={() => push('/agencies/')}>
							{agency.name}
						</UserListTile>
						<FirstTile name={'Branches'} />
					</>
				) : null}
			</UserList>
		</SlideContainer>
	);
}
