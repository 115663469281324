import { makeStyles } from '@material-ui/core/styles';

const staticTextRowStyles = (theme) => {
	return {
		staticTextRowRoot: {
			width: '100%',
			height: '52px',
			display: 'flex',
			flexDirection: 'row',
		},
		staticTextRowLabel: {
			color: theme.palette.font.gray,
			fontSize: '16px',
			width: '140px',
			minWidth: '140px',
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			flexDirection: 'column',
		},
		staticTextRowInput: {
			width: 'calc(100% - 140px)',
			height: '52px',
			lineHeight: '52px',
			fontSize: '16px',
			paddingLeft: '12px',
			overflow: 'nowrap',
			whiteSpace: 'nowrap',
			color: theme.palette.font.default,
		},
	};
};

const useStyles = makeStyles(staticTextRowStyles);

const StaticEmail = ({ label, value, type, children }) => {
	const classes = useStyles();

	const getValue = () => {
		if (type === 'date') {
			return new Date(value).toISOString().split('T')[0];
		}
		return value;
	};

	return (
		<div className={classes.staticTextRowRoot}>
			<div className={classes.staticTextRowLabel}>{label}</div>
			<div className={classes.staticTextRowInput}>{children || getValue()}</div>
		</div>
	);
};

export default StaticEmail;
