import Note from './Note';
import styles from '../postNote.module.css';

export default function PostNoteList({ postNoteList }) {
	const slicedList = postNoteList.slice();
	const postNoteMapper = (val, i) => <Note key={val.id} {...val} />;

	return (
		<div className={styles.postNoteListRoot}>
			<>
				{!!postNoteList?.length ? (
					<>{slicedList.reverse().map(postNoteMapper)}</>
				) : null}
			</>
		</div>
	);
}
