import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'

const LifeBook = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 13.334 16'
      {...props}>
			<g
				id='iconmonstr-school-25'
				transform='translate(6.667 8)'
				style={{ isolation: 'isolate' }}>
				<path
					id='iconmonstr-school-25-2'
					data-name='iconmonstr-school-25'
					d='M7,11.68a7.709,7.709,0,0,1,3.667-1.333v4.32A12.9,12.9,0,0,0,7,16Zm-.667,0a7.709,7.709,0,0,0-3.667-1.333v4.32A12.9,12.9,0,0,1,6.334,16ZM2,13.625a6.815,6.815,0,0,1-2-.958l0-1.35C-.005,9.9.059,9.088,1.766,8.711c1.87-.413,3.866-.878,2.827-2.347C1.992,2.681,4.054,0,6.667,0s4.664,2.661,2.073,6.364c-1.019,1.457.917,1.925,2.828,2.347,1.713.379,1.771,1.194,1.765,2.623l0,1.333a6.815,6.815,0,0,1-2,.958v-4l-.721.059A8.358,8.358,0,0,0,6.667,11.1,8.358,8.358,0,0,0,2.722,9.682L2,9.623v4Z'
					transform='translate(-6.667 -8)'
				/>
			</g>
		</SvgIcon>
	);
};

export default LifeBook;
