import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { verifyRestartCode } from 'services';

export default function useManageChildCodeState() {
	const { childId } = useParams();
	const [ownershipCodeVerified, setOwnershipCodeVerified] = useState(false);
	const [codeOpen, setCodeOpen] = useState(false);
	const ownershipCode = useFormInput('Code', '');
	const ownershipCodeId = useFormInput('Child Id', '');

	const { setChild, setLoader, setSnackbar } = useCreateDispatches();

	const clearInputs = () => {
		ownershipCode.onChange({ value: '' });
		ownershipCodeId.onChange({ value: '' });
	};

	useEffect(() => {
		if (!codeOpen) {
			setOwnershipCodeVerified(false);
			clearInputs();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeOpen]);

	useEffect(() => {
		setOwnershipCodeVerified(false);
		clearInputs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	const verifyCode = async () => {
		setLoader(true);
		await verifyRestartCode(ownershipCodeId.value, ownershipCode.value)
			.then((childRes) => {
				setChild({ ...childRes.data, ownershipCodeVerified: true } || {});
				setOwnershipCodeVerified(true);
			})
			.catch(() => {
				setSnackbar({ variant: 'error', message: 'Invalid credentials' });
			})
			.finally(() => {
				setLoader(false);
			});
	};

	return {
		textFieldArray: [ownershipCode, ownershipCodeId],
		codeOpen,
		setCodeOpen,
		ownershipCodeVerified,
		verifyCode,
	};
}
