import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { exitCare, setChildrenList } from 'services';
import { usePasswordConfirm } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { OptionMenu } from 'components';

export default function ExitCareButton({ childSort }) {
	const child = useSelector((state) => state.lifebooks.child);
	const { childId } = useParams();
	const onConfirm = usePasswordConfirm();
	const { setSnackbar, setLoader } = useCreateDispatches();
	const history = useHistory();

	const onExitCare = async (exitReason) => {
		await exitCare(childId, exitReason)
			.then(async (exitResponse) => {
				await setChildrenList(childSort).then(() => {
					setSnackbar({
						variant: 'success',
						message: 'Child successfully updated',
					});
					setLoader(false);
					history.push(`/lifebooks/manage/exited?success=${!!exitResponse}`);
				});
			})
			.catch((err) => {
				setSnackbar({
					variant: 'error',
					message:
						(err.response && err.response.data.message) ||
						'This request cannot be processed at this time.',
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const menuItems = [
		{
			label: 'Family Reunification',
			value: 'familyReunification',
		},
		{
			label: 'Adopted',
			value: 'adopted',
		},
		{
			label: 'Transferring Care',
			value: 'transfer',
		},
		{
			label: 'Aging Out',
			value: 'agedOut',
		},
		{
			label: 'Other',
			value: 'other',
		},
	];

	const menuItemMap = menuItems.map((val) => {
		const { label, value } = val;
		return {
			label,
			value,
			onClick: () => {
				if (!child.csw) {
					setSnackbar({
						variant: 'error',
						message:
							'In order to exit care you must first add a state/county social worker.',
					});
					return;
				} else {
					onConfirm(
						() => {
							setLoader(true);
							onExitCare(value);
						},
						{ keepLoader: false }
					);
				}
			},
		};
	});

	return (
		<OptionMenu
			menuItems={[
				{ label: 'Please select reason', value: '', disabled: true },
				...menuItemMap,
			]}
			secondary>
			Exiting Care
		</OptionMenu>
	);
}
