import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'redux/index';
import { setContributorRelationships } from 'redux/reducers';
import { listSortPersonByName } from 'helpers';
import * as Utils from '../utils/selectorUtils';
import { SectionHeader, AddField } from 'components';
import FullChildTile from './FullChildTile';
import styles from '../contributor.module.css';

const ContributorSelectorList = (props = {}) => {
	const {
		assignedList,
		setAssignedList,
		optionsList,
		setOptionsList,
		handleChangeAdd,
		handleChangeRemove,
		disabled,
	} = props;

	const contributorRelationships = useSelector(
		(state) => state.contributor.contributor.relationships
	);

	const [listUpdate, setListUpdate] = useState(false);
	const [relationships, setRelationships] = useState(contributorRelationships);

	useEffect(() => {
		setRelationships(contributorRelationships);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listUpdate, contributorRelationships]);

	const handleMenuSelect = (e) => {
		const { value } = e.target;
		if (value > -1 && value !== 'function') {
			const updatedLists = Utils.addChild(optionsList, assignedList, value);
			handleChangeAdd && handleChangeAdd(updatedLists.changed);
			setAssignedList(listSortPersonByName(updatedLists.tile, 'lastname'));
			setOptionsList(updatedLists.menu);
		}
	};

	const handleRemove = (index, e, id) => {
		e.stopPropagation();
		const newRelationships = Utils.removeRelation(
			contributorRelationships,
			relationships,
			id
		);
		store.dispatch(setContributorRelationships(newRelationships));
		const updatedLists = Utils.removeChild(optionsList, assignedList, index);
		handleChangeRemove && handleChangeRemove(updatedLists.changed);
		setAssignedList(updatedLists.tile);
		setOptionsList(listSortPersonByName(updatedLists.menu, 'lastname'));
	};

	const tileMapper = (val, index) => {
		const ind = contributorRelationships.findIndex((e) => e.childId === val.id);

		const handleSelect = async (e) => {
			const updated = Utils.relationship(relationships, val, e, ind);
			await store.dispatch(setContributorRelationships(updated));
			setListUpdate(!listUpdate);
		};

		const handleCustomSelect = async (e) => {
			const updated = Utils.relationship(relationships, val, e, ind, true);
			await store.dispatch(setContributorRelationships(updated));
			setListUpdate(!listUpdate);
		};

		if (!val) {
			return null;
		}

		const valueProps = { assignedList, val, index, ind, relationships };
		const functionProps = { handleRemove, handleSelect, handleCustomSelect };

		return (
			<FullChildTile
				disabled={disabled}
				key={val.id + index}
				{...valueProps}
				{...functionProps}
			/>
		);
	};

	const tileMap =
		!!assignedList &&
		Boolean(assignedList.length) &&
		assignedList.map(tileMapper);

	const textFieldLabel = 'Child';
	const label = 'Assign Children';
	const id = label?.toLowerCase().replace(/ /g, '-') + '-selector-list-';
	const singleProps = {
		id,
		label,
		textFieldLabel,
		optionsList,
		required: true,
	};
	const functionProps = { handleMenuSelect };

	return (
		<div className={styles.selectorListRoot} id={id + 'root'}>
			<SectionHeader hideTop>
				<div>{'ASSIGN CHILDREN'}</div>
			</SectionHeader>
			{!disabled && <AddField {...singleProps} {...functionProps} />}
			<div>{tileMap}</div>
		</div>
	);
};

export default ContributorSelectorList;
