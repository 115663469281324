import { useSelector, useDispatch } from 'react-redux';
import { setAddress } from 'redux/index';
import { getAddress } from 'redux/selectors';
import { getGeocode, stateMap } from 'helpers';

export const getStateOptionValue = (state) => {
	return stateMap[state];
};

export const useAddressGeocode = () => {
	const addressState = useSelector(getAddress);
	const dispatch = useDispatch();
	const dispatchAddress = (val) => dispatch(setAddress(val));

	return async () =>
		await getGeocode(addressState).then((res) => {
			res.state = getStateOptionValue(res.state);
			dispatchAddress(res);
			return res;
		});
};
