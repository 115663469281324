import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SectionHeader } from 'components';
import CasaSelectListTile from 'components/Casa/Components/CasaSelectListTile';
import { getRelationsList } from 'services';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import styles from '../child.module.css';

export default function ChildContributor() {
	const childId = useSelector((state) => state.lifebooks.child.id);
	const [expanded, setExpanded] = useState(false);
	const [contributors, setContributors] = useState([]);

	useEffect(() => {
		try {
			getRelationsList(childId, 'contributor').then((res) => {
				setContributors(res?.data);
			});
		} catch {
			setContributors([]);
		}
	}, [childId]);

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	const tileMapper = (val, index) => {
		if (!val) {
			return null;
		}

		return (
			<div key={`${val.id}${index}`}>
				<CasaSelectListTile
					selectable={false}
					value={`Name: ${val.firstname} ${val.lastname}`}>
					{`${val.firstname} ${val.lastname}`}
				</CasaSelectListTile>
			</div>
		);
	};

	const tileMap = contributors.map(tileMapper);

	return (
		<>
			{contributors?.length > 0 ? (
				<div className={styles.childCswRoot}>
					<SectionHeader>
						<div className={styles.basicHeader}>
							<div className={styles.headerText}>CONTRIBUTORS</div>
							{expanded ? (
								<ExpandLess onClick={() => handleOpenClose()} />
							) : (
								<ExpandMore onClick={() => handleOpenClose()} />
							)}
						</div>
					</SectionHeader>
					{expanded && (
						<div className={styles.selectorListTileList}>{tileMap}</div>
					)}
				</div>
			) : null}
		</>
	);
}
