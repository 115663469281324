const PostVideo = (props) => {
	return (
		<>
			<video controls preload='auto' id='my-video'>
				<source src={props.post?.videos[0]?.url} />
			</video>
		</>
	);
};

export default PostVideo;
