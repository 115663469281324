const defaultVaultState = {
	vaultReason: false,
	vaultPost: {},
	vaultFiles: [],
	otherFiles: [],
	bcFile: {},
	ssFile: {},
	idFile: {},
};

const SET_VAULT_REASON = 'SET_VAULT_REASON';
const SET_VAULT_POST = 'SET_VAULT_POST';
const SET_VAULT_FILES = 'SET_VAULT_FILES';

export default function postReducer(state = { ...defaultVaultState }, action) {
	switch (action.type) {
		case SET_VAULT_FILES: {
			const files = action.payload;
			let bc = {};
			let ss = {};
			let id = {};
			let otherFiles = [];

			files.forEach((file) => {
				if (file?.label === 'Birth Certificate') {
					bc = file;
				} else if (file?.label === 'Social Security') {
					ss = file;
				} else if (file?.label === 'ID Card') {
					id = file;
				} else {
					otherFiles.push(file);
				}
			});
			return {
				...state,
				vaultFiles: action.payload,
				bcFile: bc,
				ssFile: ss,
				idFile: id,
				otherFiles: otherFiles,
			};
		}
		case SET_VAULT_REASON: {
			return {
				...state,
				vaultReason: action.payload,
			};
		}
		case SET_VAULT_POST: {
			return {
				...state,
				vaultPost: action.payload,
			};
		}
		default:
			return state;
	}
}

export function setVaultFiles(files) {
	return {
		type: SET_VAULT_FILES,
		payload: files,
	};
}

export function setVaultReason(showVault) {
	return {
		type: SET_VAULT_REASON,
		payload: showVault,
	};
}

export function setVaultPost(post) {
	return {
		type: SET_VAULT_POST,
		payload: post,
	};
}
