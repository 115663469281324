import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackbar } from 'redux/index';
import { inviteAuditor } from 'services';
import { usePasswordConfirm } from 'helpers';
import { handleAuditInviteValidation } from 'helpers/validation/inviteValidation';
import { PrimaryButton, TextFieldRow } from 'components';
import styles from './childAudit.module.css';

export default function AuditInviteForm({ childId, fetchAuditors }) {
	const dispatch = useDispatch();
	const onConfirm = usePasswordConfirm();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	const handleValidate = () => {
		const validation = handleAuditInviteValidation(name, email);

		if (validation.isValid) {
			return true;
		} else {
			dispatch(setSnackbar({ variant: 'error', message: validation.message }));
			return false;
		}
	};

	const submitInvite = async () => {
		const reqBody = {
			name: name,
			email: email,
			childId,
		};

		await inviteAuditor(reqBody)
			.then(() => {
				fetchAuditors();

				dispatch(
					setSnackbar({
						variant: 'success',
						message: 'Audit link successfuly sent',
					})
				);
			})
			.catch((err) => {
				dispatch(
					setSnackbar({
						variant: 'error',
						message:
							err.response?.data.message || 'Audit link creation unsuccessful',
					})
				);
			})
			.finally(() => {
				setName('');
				setEmail('');
				dispatch(setLoader(false));
			});
	};

	const onInvite = async () => {
		if (handleValidate()) {
			onConfirm(submitInvite);
		}
	};

	return (
		<div className={styles.auditInviteRoot}>
			<TextFieldRow
				variant='outlined'
				autoComplete='off'
				label='Name'
				value={name}
				onChange={(e) => setName(e.target.value)}
				required={true}
			/>
			<TextFieldRow
				variant='outlined'
				autoComplete='off'
				label='Email'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				required={true}
			/>
			<div className={styles.auditInviteButton}>
				<PrimaryButton onClick={onInvite}>Send Link</PrimaryButton>
			</div>
		</div>
	);
}
