import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Family = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 102.076 87.691'
			{...props}>
			<g id='family-silhouette' transform='translate(0 -6.952)'>
				<g id='Group_199' data-name='Group 199' transform='translate(0 6.952)'>
					<circle
						id='Ellipse_2'
						data-name='Ellipse 2'
						cx='10.653'
						cy='10.653'
						r='10.653'
						transform='translate(40.383 37.562)'
					/>
					<path
						id='Path_250'
						data-name='Path 250'
						d='M54.457,64.556H45.418A13.656,13.656,0,0,0,31.78,78.2V89.253l.028.172.76.237a62.081,62.081,0,0,0,18.546,2.992c10.023,0,15.832-2.859,16.191-3.039l.712-.363.073,0V78.194A13.646,13.646,0,0,0,54.457,64.556Z'
						transform='translate(1.098 -4.962)'
					/>
					<circle
						id='Ellipse_3'
						data-name='Ellipse 3'
						cx='17.185'
						cy='17.185'
						r='17.185'
						transform='translate(12.105 0)'
					/>
					<path
						id='Path_251'
						data-name='Path 251'
						d='M70.924,41.322A17.185,17.185,0,1,0,53.74,24.135,17.185,17.185,0,0,0,70.924,41.322Z'
						transform='translate(1.856 -6.952)'
					/>
					<path
						id='Path_252'
						data-name='Path 252'
						d='M42.683,63.683a12.785,12.785,0,0,1,2-20.816,21.841,21.841,0,0,0-8.1-1.563H22a22.027,22.027,0,0,0-22,22V81.142l.044.278,1.228.382a100.327,100.327,0,0,0,29.441,4.817V79A15.818,15.818,0,0,1,42.683,63.683Z'
						transform='translate(0 -5.765)'
					/>
					<path
						id='Path_253'
						data-name='Path 253'
						d='M78.155,41.3H63.575a21.834,21.834,0,0,0-8.094,1.563,12.782,12.782,0,0,1,2,20.814A15.789,15.789,0,0,1,69.446,79v7.619A100.345,100.345,0,0,0,98.886,81.8l1.228-.382.046-.278V63.306A22.035,22.035,0,0,0,78.155,41.3Z'
						transform='translate(1.917 -5.765)'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default Family;
