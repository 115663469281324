import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import styles from '../addImage.module.css';

export default function AddImageLoading() {
	return (
		<div className={clsx(styles.addImageRoot, styles.addImagePreview)}>
			<CircularProgress className={styles.loader} />
		</div>
	);
}
