import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTokenType } from '../helpers'

const PrivateRoute = (props) => {
  const { component: Component, user, ...rest } = props;

  const token = localStorage.getItem('token');
  const isDefaultTokenType = getTokenType(token) === 'default';
  if (!isDefaultTokenType) {
    localStorage.removeItem('token')
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return !token? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/lifebooks/', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = ({user}) => {
  return { user }
}

export default connect(mapStateToProps)(PrivateRoute);
