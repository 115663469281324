import SectionLayout from './SectionLayout';
import DraftsSwitcher from './DraftsSwitcher';
import styles from '../headers.module.css';

export default function Switcher() {
	const feedTypes = [
		{ path: 'drafts', label: 'TIMELINE' },
		{ path: 'nspDrafts', label: 'SERVICES' },
	];

	return (
		<SectionLayout
			label={`Filter:`}
			className={styles.serviceSwitcherMobileRoot}>
			<DraftsSwitcher feedTypes={feedTypes} />
		</SectionLayout>
	);
}
