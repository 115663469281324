import { StaticTextRow } from 'components';
import styles from '../account.module.css';

export default function ProfileEmailStatic({ email, setEdit }) {
	return (
		<StaticTextRow
			label={email.label}
			value={
				<div className={styles.profileEmailStatic}>
					{email.value}
					<span className={styles.profileEmail} onClick={() => setEdit(true)}>
						EDIT
					</span>
				</div>
			}
		/>
	);
}
