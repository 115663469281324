import { ListSubheader } from '@material-ui/core';
import { TextFieldRow, SectionHeader } from 'components';
import clsx from 'clsx';
import styles from '../sendInvite.module.css';

export default function InviteAssignment({ onSelect, listMap, label, value }) {
	return (
		<>
			<SectionHeader>{`Assign ${label}`}</SectionHeader>
			<TextFieldRow
				className={clsx(styles.field, 'invites-type-selector')}
				value={value}
				required
				select
				variant='outlined'
				label={label}
				onChange={onSelect}>
				<ListSubheader disabled={true} className={styles.subheader}>
					{`Select ${label}`}
				</ListSubheader>
				{listMap}
			</TextFieldRow>
		</>
	);
}
