export const getTitle = (eventType, isChild) => {
	if (eventType === 'new') {
		return 'Add Event';
	}
	const event = isChild ? 'View Event' : 'Edit Event';
	return event;
};

export const dayOld = (date) => {
	const created = new Date(date).getTime();
	const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
	return created < oneDay;
};

export const setDisabled = (eventType, isChild, calendarEvent, user) => {
	const isOwnPost = calendarEvent.childId === user.id;
	const oldPost = dayOld(calendarEvent.createdAt);

	if (eventType === 'new') {
		return false;
	}

	if (isChild) {
		if (isOwnPost && !oldPost) {
			return false;
		} else {
			return true;
		}
	}

	return false;
};
