import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackbar } from 'redux/index';
import { getPostsState } from 'redux/selectors';
import { postDraft } from 'services';
import { useCheckPostPermissions } from 'helpers/hooks';
import { mixpanelTrackPost } from 'helpers';
import useEditPostCallbacks from '../utils/useEditPostCallbacks';
import { SecondaryButton } from 'components';

export default function EditPostDraftButtons({ getPostState }) {
	const dispatch = useDispatch();
	const { childId, postId } = useParams();
	const post = useSelector(getPostsState);
	const { allowDraft } = useCheckPostPermissions(post);

	const isDraft = postId?.startsWith?.('dft_');

	const { onSuccessRedirect, onErrorCallback, createBody } =
		useEditPostCallbacks(getPostState);

	const onSaveDraft = async () => {
		const postState = getPostState();
		const { id } = postState;
		const reqBody = await createBody(postState);
		dispatch(setLoader(true));
		postDraft(reqBody)
			.then(({ data }) => {
				mixpanelTrackPost(id ? 'editDraft' : 'createDraft', postState, data);
				setSnackbar({ variant: 'success', message: 'Post Draft saved' });
				let redirect = `/lifebooks/drafts/${childId}`;

				if (data.nsp) {
					redirect = `/lifebooks/nspDrafts/${childId}`;
				}

				onSuccessRedirect(redirect);
			})
			.catch(onErrorCallback);
	};

	return allowDraft() ? (
		<>
			{(!postId || isDraft) && (
				<SecondaryButton onClick={onSaveDraft}>Save Draft</SecondaryButton>
			)}
		</>
	) : null;
}
