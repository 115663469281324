import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyUser } from 'redux/selectors';
import StaticTextRow from 'components/UtilityComponents/Form/StaticTextRow';
import styles from '../socialWorkers.module.css';

const SocialWorkerBranch = () => {
	const { swId } = useParams();
	const sw = useSelector(getAgencyUser(swId));

	if (sw.branch) {
		return (
			<div className={styles.socialWorkerBasicInfoRoot}>
				<StaticTextRow
					label='Branch'
					variant='outlined'
					value={sw.branch.name}
				/>
			</div>
		);
	}
	return <></>;
};

export default SocialWorkerBranch;
