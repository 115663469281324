import { store, setSnackbar } from '../redux';

export const showSnack = (action, res) => {
	if (action === 'success') {
		const message = res || 'Success';
		store.dispatch(
			setSnackbar({
				variant: 'success',
				message: message,
			})
		);
	} else {
		const error = res || 'Sorry, Please Try Again';
		store.dispatch(
			setSnackbar({
				variant: 'error',
				message: error,
			})
		);
	}
	return;
};
