export function checkIsInThePast(date) {
	const today = new Date();

	// This line sets the hour of the current date to midnight
	// so the comparison only returns `true` if the passed in date
	// is at least yesterday
	today.setHours(0, 0, 0, 0);

	return date < today;
}

export function checkIsDayOld(date) {
	const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
	return new Date(date).getTime() > oneDay;
}
