import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from 'redux/index';
import { getAddress } from 'redux/selectors';
import { stateMap } from 'helpers';
import addressMapper from './utils/addressMapper';
import styles from './addressForm.module.css';
import clsx from 'clsx';
import AddressAutocomplete from './AddressAutoComplete';

export { useAddressGeocode } from './utils/addressHandler';

export default function AddressForm({ full = true }) {
	const { street, street2, city, state, postal } = useSelector(getAddress);

	const dispatch = useDispatch();
	const dispatchAddress = (val) => dispatch(setAddress(val));

	const onChange = (e) => {
		dispatchAddress({ [e.target.name]: e.target.value });
	};

	const stateValue = stateMap[state] || stateMap[state?.value] || '';

	const inputFields = [
		{ label: 'Street', name: 'street', value: street, required: true },
		{ label: 'Street 2', name: 'street2', value: street2 },
		{ label: 'City', name: 'city', value: city, required: true },
		{
			label: 'State',
			name: 'state',
			value: stateValue,
			type: 'select',
			required: true,
		},
		{ label: 'Zip', name: 'postal', value: postal, type: 'submit' },
	];

	const inputMap = addressMapper(inputFields, onChange, styles);

	return (
		<div
			className={clsx(
				styles.addressFillContainer,
				full ? styles.addressFillFullWidth : styles.addressFillStandardWidth
			)}>
			<AddressAutocomplete />
			<div className={styles.addressFillForm}>{inputMap}</div>
		</div>
	);
}
