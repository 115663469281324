import { useState } from 'react';
import { store, setSnackbar } from 'redux/index';
import * as services from 'services';
import { handleCasaUserInviteValidation } from 'helpers/validation/casaValidation';
import { MenuItem } from '@material-ui/core';
import * as Comp from 'components';

const SendCasaUserInvite = (props) => {
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');

	const roleList = [
		{ value: 'mentor', label: 'Mentor' },
		{ value: 'admin', label: 'Admin' },
	];

	const roleListMap = roleList.map((val) => {
		return val ? (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		) : null;
	});

	const onSubmit = () => {
		const form = {
			email: email,
			role: role,
			type: 'casauser',
		};

		const validation = handleCasaUserInviteValidation(email, role);

		if (validation?.isValid) {
			services.inviteCasaUser(form);
			setEmail('');
			setRole('');
		} else {
			store.dispatch(
				setSnackbar({
					variant: 'error',
					message: validation?.message,
				})
			);
		}
	};

	let buttonArray = [
		<Comp.SecondaryButton key='account-cancel' onClick={props.onCancel}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-casa-user-invite' onClick={onSubmit}>
			Save
		</Comp.PrimaryButton>,
	];

	return (
		<Comp.PageLayout
			title={'Invite Casa User'}
			buttonArray={buttonArray}
			align='left'>
			<Comp.SectionHeader>Casa User Information</Comp.SectionHeader>
			<Comp.EmailTextFieldRow
				key={'casa-input-email'}
				variant='outlined'
				value={email}
				name={'email'}
				required={true}
				label={'Email'}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<Comp.TextFieldRow
				select
				required={true}
				label='Role'
				value={role}
				onChange={(e) => setRole(e.target.value)}>
				{roleListMap}
			</Comp.TextFieldRow>
		</Comp.PageLayout>
	);
};

export default SendCasaUserInvite;
