import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/index';
import * as services from 'services';
import { filesAcceptedExtensions } from 'helpers';
import { CloudUpload } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import {
	PrimaryButton,
	DatePicker,
	TextFieldRow,
	SectionHeader,
} from 'components';
import styles from '../vault.module.css';

const AddVaultFiles = () => {
	const child = useSelector((state) => state.lifebooks.child);
	const user = useSelector((state) => state.user.user);
	const vaultFiles = useSelector((state) => state.vault.vaultFiles);

	const [accessDate, setAccessDate] = useState();
	const [label, setLabel] = useState('');
	const [file, setFile] = useState();

	const fileInputRef = useRef();
	const dispatch = useDispatch();

	const vaultFileLabels = vaultFiles.map((file) => file.label.toLowerCase());
	const checkFiles = vaultFileLabels.includes(label.toLowerCase());

	const checkVaultFileNames = () => {
		const lowerCaseLabel = label.toLowerCase();

		if (
			lowerCaseLabel === 'birth certificate' ||
			lowerCaseLabel === 'social security' ||
			lowerCaseLabel === 'id card' ||
			checkFiles
		) {
			return false;
		}

		return true;
	};

	const getAccessDate = () => {
		if (user.type === 'child') {
			setAccessDate(new Date());
			return;
		}

		if (child.dob) {
			const aDate = new Date(child.dob).setFullYear(
				new Date(child.dob).getFullYear() + 18
			);
			const convertedDate = new Date(aDate);
			setAccessDate(convertedDate);
			return;
		}

		const newDate = new Date();
		setAccessDate(newDate);
		return;
	};

	useEffect(() => {
		getAccessDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child]);

	const compatibilityString = filesAcceptedExtensions.reduce(
		(acc, cur, i, arr) => {
			return i === arr.length - 1 ? acc + ` and ${cur}` : acc + `${cur}, `;
		},
		`${filesAcceptedExtensions[0]}, `
	);

	const handleClick = () => {
		fileInputRef.current.click();
	};

	const handleAddFile = (e) => {
		const file = e?.target?.files[0];
		const verified = services.getAcceptedVaultFile(file, compatibilityString);
		if (verified) {
			setFile(file);
		} else {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: `Acceptable file formats are ${compatibilityString}`,
				})
			);
		}
	};

	const handleDate = (e) => {
		setAccessDate(e);
	};

	const handleSubmit = () => {
		dispatch(setLoader(true));
		if (file && label) {
			const isValidFileName = checkVaultFileNames();

			if (!isValidFileName) {
				dispatch(
					setSnackbar({
						variant: 'error',
						message: 'Please choose a unique file name',
					})
				);
				dispatch(setLoader(false));
				return;
			}
			services
				.uploadVaultFile(child.id, file, label, accessDate)
				.then(() => {
					setLabel('');
					setFile();
					dispatch(
						setSnackbar({
							variant: 'success',
							message: 'Files uploaded successfully',
						})
					);
					services.getChildVaultFiles(child.id);
				})
				.catch((err) => {
					dispatch(
						setSnackbar(
							{ variant: 'error', message: err.response?.data.message },
							{ log: true }
						)
					);
				})
				.finally(() => {
					dispatch(setLoader(false));
				});
		} else {
			if (!file) {
				dispatch(
					setSnackbar(
						{
							variant: 'error',
							message: 'Please add a file before submitting',
						},
						{ log: true }
					)
				);
			}
			if (!label) {
				dispatch(
					setSnackbar(
						{
							variant: 'error',
							message: 'Please add a label before submitting',
						},
						{ log: true }
					)
				);
			}
			if (!accessDate) {
				dispatch(
					setSnackbar(
						{
							variant: 'error',
							message: 'Please add a date before submitting',
						},
						{ log: true }
					)
				);
			}
			dispatch(setLoader(false));
			return;
		}
	};

	return (
		<>
			<div>
				<div className={styles.fileNameContainer}>
					<TextFieldRow
						className={styles.fileName}
						variant='outlined'
						label='File Name:'
						value={label}
						onChange={(e) => setLabel(e.target.value)}
						required={true}
					/>
				</div>
				<div className={styles.addFile}>
					<div className={styles.updateAddContainer}>
						{file && (
							<Tooltip
								title={'File has been uploaded and awaiting submit'}
								placement='top-start'>
								<CloudUpload className={styles.checkbox} />
							</Tooltip>
						)}
						<PrimaryButton onClick={handleClick}>Add File</PrimaryButton>
						<input
							ref={fileInputRef}
							type='file'
							className={styles.fileInput}
							onChange={(e) => handleAddFile(e)}
						/>
					</div>
					{user.type !== 'child' && (
						<div className={styles.vaultDateContainer}>
							<DatePicker
								onChange={(e) => handleDate(e)}
								value={accessDate}
								required
								disableFuture={false}
								label={'Access Date'}
							/>
						</div>
					)}
					<div className={styles.submitButtonContainer}>
						<PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
					</div>
				</div>
			</div>
			<SectionHeader>Uploaded Files</SectionHeader>
		</>
	);
};

export default AddVaultFiles;
