import { useEffect } from 'react';
import { withRouter, Switch, useLocation } from 'react-router-dom';
import { AccessRoute } from 'routing/AccessRoute';
import { useSelector } from 'react-redux';
import { getContributorContributorLoaded } from 'redux/selectors';
import * as services from 'services';
import { useMobileListCheck } from 'helpers';
import ContributorListContainer from './components/ContributorListContainer';
import ManageContributor from './components/ManageContributor';
import { Welcome } from 'components';
import styles from './contributor.module.css';

const Contributor = (props) => {
	const user = useSelector((state) => state.user.user);
	const childrenList = useSelector((state) => state.lifebooks.children);
	const { pathname } = useLocation();

	const {
		match: {
			params: { contributorId },
		},
	} = props;

	useMobileListCheck(pathname === '/contributors/');

	const getContributorList = services.useGetContributorList();

	useEffect(() => {
		const getAssignableChildren = async () => {
			await services.getContributorApprovedChildren(childrenList, user.id);
		};

		if (user.type === 'casauser') {
			getAssignableChildren();
		}

		getContributorList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const contributorsLoaded = useSelector(getContributorContributorLoaded);

	return (
		<div className={styles.contributorContainer}>
			<ContributorListContainer />
			<div className={styles.contributorContent}>
				{contributorsLoaded ? (
					<Switch>
						<AccessRoute
							key={`contributor-manage-${contributorId || 'manage'}`}
							path='/contributors/manage/:contributorId?'
							authorized={{
								agencyuser: true,
								casauser: true,
							}}
							fallBackRoute='/contributors/'
							component={ManageContributor}
							privileged={true}
						/>
						<AccessRoute
							key={`contributor-view-${contributorId}`}
							path='/contributors/'
							authorized={{
								agencyuser: { role: ['admin', 'manager', 'social-worker'] },
								casauser: true,
							}}
							component={Welcome}
						/>
					</Switch>
				) : (
					<Welcome />
				)}
			</div>
		</div>
	);
};

export default withRouter(Contributor);
