export const parentNav = (parent, subList) => {
	return { ...parent, subList };
};

export const rename = (section, name) => {
	return { ...section, name };
};

export const getRole = ({ privileged, role, type }) => {
	switch (type) {
		case 'agencyuser':
			return privileged ? 'privileged' : role;
		case 'contributor':
			return role;
		default:
			return type;
	}
};