import { experiments } from 'helpers';
import { useCheckPostPermissions } from 'helpers/hooks';

export default function useMenuPermissions(post) {
	const {
		allowEdit,
		allowCreateChangeRequest,
		allowApprove,
		allowDelete,
		allowSendToVault,
		moveFromVault,
		allowMoveServicePost,
	} = useCheckPostPermissions(post);

	const permissions = {
		edit: allowEdit(),
		changeRequest:
			experiments.changeRequest(true) && allowCreateChangeRequest(),
		approve: allowApprove(),
		delete: allowDelete(),
		vault: allowSendToVault(),
		moveFromVault: moveFromVault(),
		move: allowMoveServicePost(),
	};

	return permissions;
}
