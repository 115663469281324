import { connect, useSelector } from 'react-redux';
import { DashboardToggle, Avatar } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../dashboard.module.css';
import { dashboardUserToggle } from '../dashboardStyles';

function DashboardUser({ isSm, isMd }) {
	const user = useSelector((state) => state.user.user);

	return (
		<div className={styles.dashboardUserRoot}>
			{isSm || isMd ? (
				<DashboardToggle className={makeStyles(dashboardUserToggle)} />
			) : null}
			<Avatar
				identity={user}
				alt={`${user.firstname} ${user.lastname}`}
				image={user.photo?.url}
			/>
			{user.firstname ? (
				<div className={styles.dashboardUserContainer}>
					<div className={styles.dashboardUserName}>
						{user.firstname} {user.lastname}
					</div>
				</div>
			) : null}
		</div>
	);
}

const mapStateToProps = ({ mediaQuery: { isSm, isMd } }) => {
	return { isSm, isMd };
};
export default connect(mapStateToProps)(DashboardUser);
