export const checkValidation = (casaUser, location, child) => {
	let email = true;
	let role = true;
	let addedChild = true;

	if (!casaUser.email) {
		email = false;
	}

	if (role === location.state?.role) {
		role = false;
	}

	if (!child) {
		addedChild = false;
	}

	if (!email && !role && !addedChild) {
		return false;
	} else {
		return true;
	}
};
