export const handlePostValidation = (postState) => {
	if (!postState?.posted) {
		return { isValid: false, message: 'Date is a required field' };
	}

	if (!postState?.category) {
		return { isValid: false, message: 'Category is a required field' };
	}

	if (!postState?.title) {
		return { isValid: false, message: 'Title is a required field' };
	}

	if (!postState.body) {
		return { isValid: false, message: 'Description is a required field' };
	}

	return { isValid: true };
};
