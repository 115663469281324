import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import * as selector from 'redux/selectors';
import { useSetState } from 'helpers';
import useSupportFormCallBacks from '../utils/useSupportFormCallbacks';
import {
	PageLayout,
	StaticInfo,
	PrimaryButton,
	TextArea,
	LifebookNav,
} from 'components';
import DefaultHeader from 'components/UtilityComponents/PageLayout/components/DefaultHeader';
import SupportFormTextFields from './SupportFormTextFields';
import styles from '../support.module.css';
import MobileHeader from 'components/Headers/MobileHeader';

export function SupportForm(props) {
	const { navBack } = props;
	const user = useSelector(selector.getUserState);
	const userId = useSelector(selector.getUserStateId);
	const isMd = useSelector(selector.getIsMd);
	const agency = useSelector(selector.getUserAgency);

	const defaultState = {
		email: user?.email || '',
		subject: '',
		message: '',
		organization: agency?.name || '',
		firstname: user.firstname || '',
		lastname: user.lastname || '',
		referer: 'https://lifebook.ella.care/',
		userId: user.id || '',
		location: user?.id ? 'inside web app' : 'outside web app',
	};

	const [state, setState] = useSetState(defaultState);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({
			[name]: value,
		});
	};

	const [reCaptchaValue, setReCaptchaValue] = useState(null);

	const [submitted, setSubmitted] = useState(false);

	const getRecaptchaValue = () => reCaptchaValue;
	const getState = () => state;

	const { onSubmit, onCancel } = useSupportFormCallBacks(
		getState,
		setSubmitted,
		getRecaptchaValue
	);

	const handleSubmit = async () => {
		onSubmit();
		setState(defaultState);
	};

	const buttonArray = [
		<PrimaryButton key={'submit-support-button'} onClick={handleSubmit}>
			Submit
		</PrimaryButton>,
	];

	return (
		<PageLayout
			title='Need Help?'
			navBack={navBack}
			footer={<LifebookNav />}
			header={isMd ? <MobileHeader /> : <DefaultHeader title={'Contact Us'} />}>
			<div className={styles.supportFormContent}>
				{!submitted ? (
					<>
						<StaticInfo
							infoArray={[
								{
									label: 'Contact Us',
									value:
										'Feel free to contact to us with any questions or concerns and we will reach out to help you.',
								},
							]}
						/>
						<SupportFormTextFields state={state} setState={setState} />
						<TextArea
							className={styles.supportFormField}
							minRows={5}
							label='Message'
							name='message'
							value={state.message}
							required
							onChange={handleChange}
						/>
						{!userId ? (
							<div className={styles.supportFormReCaptcha}>
								<ReCAPTCHA
									sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
									onChange={setReCaptchaValue}
								/>
							</div>
						) : null}
						<div className={styles.buttonContainer}>{buttonArray}</div>
					</>
				) : (
					<StaticInfo
						infoArray={[
							{
								label: 'Thank You',
								value:
									'Your message has been received and will be reviewed. We appreciate you contacting us with questions or concerns and will get back to you soon.',
							},
						]}
					/>
				)}
			</div>
		</PageLayout>
	);
}

export default SupportForm;
