import { parsePhoneNumber } from 'awesome-phonenumber';

export const validateEmail = (email) => {
	if (email?.includes('@') && email?.includes('.')) {
		return true;
	} else {
		return false;
	}
};

export const validatePassword = (password) => {
	if (password?.length < 8) {
		return false;
	} else {
		return true;
	}
};

export const validateChildId = (childId) => {
	if (childId?.includes('chd') || childId?.includes('usr')) {
		return true;
	} else {
		return false;
	}
};

export const validatePhoneNumber = (number) => {
	const pn = parsePhoneNumber(number, { regionCode: 'US' });

	if (pn.valid) {
		return true;
	}

	return false;
};
