import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { setLoader, store } from 'redux/index';
import queryString from 'query-string';
import uuidv4 from 'uuid/v4';

const apiV1 = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

const onBoardCheck = (config, user) => {
	const urlArray = ['/child/', '/house', '/post/'];
	if (
		config &&
		urlArray.includes(config.url) &&
		config.method === 'post' &&
		user.status === 'onboarding'
	) {
		return true;
	} else {
		return false;
	}
};

apiV1.interceptors.request.use(
	(config) => {
		const { user } = store.getState().user || {};

		if (user.status === 'onboarding' && onBoardCheck(config, user)) {
			config.data.metadata = { ...config.data.metadata, onBoardData: 'true' };
		}
		if (
			['/session/authenticate', '/session/authenticate/auto'].includes(
				config.url
			)
		) {
			return config;
		}
		if (config.url[0] === '/') {
			try {
				const query = window.location.search;
				const parsedQuery = query ? queryString.parse(query) : {};
				const queryToken = parsedQuery.token;
				const storageToken = localStorage.getItem('token');
				let token = queryToken || storageToken;
				const { ownerId, ownerType } = jwt_decode(token);
				const mfaToken =
					!!ownerId && localStorage.getItem(`mfaToken-${ownerId}`);
				let sessionHash = localStorage.getItem('session');
				if ((ownerType === 'auditoraccess' && token) || sessionHash) {
					const session =
						sessionHash && !queryToken
							? JSON.parse(atob(sessionHash))
							: uuidv4();
					const encoded = session && token && btoa(`${session.uuid}:${token}`); //base64 encode
					config.headers['Authorization'] = `Basic ${encoded}`;
				}
				if (mfaToken) {
					config.headers['Ella-MFA-Token'] = mfaToken;
				}
			} catch (e) {
				console.log(e);
			}
		}
		if (config.url.includes('s3.') && !config.headers['Content-Type']) {
			config.headers['Content-Type'] = 'image/jpeg';
		}

		return config;
	},

	(error) => {
		return Promise.reject(error);
	}
);

apiV1.interceptors.response.use(
	(res) => res,
	(err) => {
		const { status, data: { context } = {} } = err?.response || {};
		if (status === 401 && context === 'mfa-required') {
			store.dispatch(setLoader(false));
			if (!window?.location.pathname.includes('/confirm2fa')) {
				window.location.href = '/confirm2fa/';
			}
		}
		throw err;
	}
);

export default apiV1;
