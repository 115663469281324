import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CSWVisit = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 102.306 102.803'
			{...props}>
			<g
				id='Group_207'
				data-name='Group 207'
				transform='translate(-915.899 -620)'>
				<g
					id='heart_2_'
					data-name='heart (2)'
					transform='translate(915.899 620)'>
					<g id='Group_206' data-name='Group 206' transform='translate(0 0)'>
						<path
							id='Path_258'
							data-name='Path 258'
							d='M29.539,35.515h0a11.586,11.586,0,0,0-8.924,4.123,11.429,11.429,0,0,0-8.924-4.123A11.69,11.69,0,0,0,0,47.2C0,58.269,19.31,69.072,20.092,69.489a.939.939,0,0,0,1.044,0c.783-.418,20.093-11.064,20.093-22.284A11.69,11.69,0,0,0,29.539,35.515Z'
							transform='translate(0 -35.514)'
						/>
					</g>
				</g>
				<path
					id='Path_259'
					data-name='Path 259'
					d='M48.084,189.582a19.162,19.162,0,1,0-20.524,0A37.827,37.827,0,0,0,0,225.982c0,20.889,75.641,20.889,75.641,0A37.827,37.827,0,0,0,48.084,189.582Z'
					transform='translate(942.564 481.155)'
				/>
			</g>
		</SvgIcon>
	);
};

export default CSWVisit;
