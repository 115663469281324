import { useState, useEffect } from 'react';
import { getSnapshotChildList } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { listSortPersonByName } from 'helpers';

export default function useSnapshotChildListfetch() {
	const { setLoader, setSnackbarError, setListLoader } = useCreateDispatches();
	const [childList, setChildList] = useState([]);
	const [childHash, setChildHash] = useState({});
	const sortList = (list, sort) =>
		setChildList(listSortPersonByName(list, sort));

	const fetchList = async () => {
		setListLoader(true);
		await getSnapshotChildList()
			.then((res) => {
				setChildList(listSortPersonByName(res, 'lastname'));
				setChildHash(res);
			})
			.catch((err) => {
				setSnackbarError({ response: err.response }, { log: true });
			})
			.finally(() => {
				setListLoader(false);
				setLoader(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		fetchList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const mapper = (val) => {
		return { ...val, name: `${val.firstname} ${val.lastname}` };
	};

	const sortMenuItems = [
		{
			value: 'firstname',
			label: 'First Name',
			onClick: () => sortList(childList, 'firstname'),
		},
		{
			value: 'lastname',
			label: 'Last Name',
			onClick: () => sortList(childList, 'lastname'),
		},
	];

	return {
		childList: childList.map(mapper),
		childHash,
		setChildList,
		reload: fetchList,
		sortMenuItems,
	};
}
