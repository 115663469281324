import get from 'lodash/get';
import { useCreateDispatches } from 'helpers/hooks';
import * as service from 'services';

const activityParams = {
	callback: service.getCalendarActivity,
	dataPath: `data.eventActivities`,
};

export function useGetCalendarActivity({
	setActivity,
	setlastPostActivityId,
} = {}) {
	const { setSnackbar } = useCreateDispatches();

	const params = activityParams;

	if (!params || !params.callback) return () => Promise.resolve(null);

	return async (childId) => {
		return await service
			.getCalendarActivity(childId)
			.then((result) => {
				setActivity?.(get(result, params.dataPath));
				setlastPostActivityId(get(result, 'data.lastPostActivityId') || null);
				return result;
			})
			.catch((err) => {
				setActivity([]);
				setSnackbar(
					{
						variant: 'error',
						message:
							err.response?.data.message ||
							'Something went wrong. Please try again later',
					},
					{ log: true }
				);
			});
	};
}
