import { useSelector } from 'react-redux';
import ExportContainer from './components/ExportContainer';
import styles from './exportRequest.module.css';

const ExportRequest = () => {
	const exportRequests = useSelector((state) => state.lifebooks.exportRequests);

	const requests = exportRequests.map((e) => (
		<ExportContainer request={e} key={e.id} />
	));

	return <div className={styles.exportRequest}>{requests}</div>;
};

export default ExportRequest;
