import { useState } from 'react';
import styles from '../timeline.module.css';

export default function PostBody({ body }) {
	const [shortDescription, setShortDescription] = useState(true);
	const descriptionLength = () => {
		setShortDescription(!shortDescription);
	};
	return (
		<div className={styles.postBody}>
			{!body || body.length < 300 ? (
				body
			) : body && body.length >= 300 && shortDescription ? (
				<>
					{body.substring(0, 300)}
					<span onClick={descriptionLength}> ...See More</span>
				</>
			) : (
				<>
					{body} <span onClick={descriptionLength}> ...See Less</span>
				</>
			)}
		</div>
	);
}
