import { validatePhoneNumber, validateEmail } from './utils/validate';

export const handleCasaValidation = (name, phone) => {
	const validPhoneNumber = validatePhoneNumber(phone);

	if (!name) {
		return { isValid: false, message: 'Name is required' };
	}

	if (phone && !validPhoneNumber) {
		return { isValid: false, message: 'Phone number is not valid' };
	}

	return { isValid: true };
};

export const handleCasaUserInviteValidation = (email, role) => {
	const emailValid = validateEmail(email);

	if (role === '') {
		return { isValid: false, message: 'Role is required' };
	}

	if (!email) {
		return { isValid: false, message: 'Email is required' };
	}

	if (!emailValid) {
		return { isValid: false, message: 'Email is invalid' };
	}

	return { isValid: true };
};
