import { MenuItem, ListSubheader } from '@material-ui/core';
import { TextField } from 'components';
import styles from '../selectorList.module.css';

const AddField = ({
	id,
	children,
	label,
	textFieldLabel,
	handleMenuSelect,
	optionsList,
	required,
	single = false,
}) => {
	const menuMapper = (val, index) => {
		if (val) {
			const label = val.firstname
				? `${val.firstname} ${val.lastname}`
				: val.name
				? val.name
				: val.email;
			return (
				<MenuItem key={val.id} value={index}>
					{label}
				</MenuItem>
			);
		} else return null;
	};

	const menuMap = Boolean(optionsList?.length) && optionsList.map(menuMapper);

	let labelPrefix;
	if (single) {
		labelPrefix = 'Select';
	} else {
		if (single) {
			labelPrefix = 'Update';
		} else {
			labelPrefix = 'Add';
		}
	}

	return (
		<TextField
			select
			id={id + 'textField'}
			className={styles.addFieldRoot}
			required={required}
			value=''
			variant='outlined'
			label={textFieldLabel ? `${labelPrefix} ${textFieldLabel}` : ''}
			onChange={handleMenuSelect}>
			{children ? children : null}
			<ListSubheader disabled={true}>{label}</ListSubheader>
			{menuMap.length > 0 ? (
				menuMap
			) : (
				<MenuItem disabled>{`No Available ${textFieldLabel}`}</MenuItem>
			)}
		</TextField>
	);
};

export default AddField;
