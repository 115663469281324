import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyUser } from 'redux/selectors';
import { reinviteAgencyUser } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { StaticTextRow, EmailStaticTextRow, SectionHeader } from 'components';
import styles from '../administrators.module.css';

export default function AdminBasicInfo() {
	const { adminId } = useParams();
	const { firstname, lastname, email, status } = useSelector(
		getAgencyUser(adminId)
	);
	const { setLoader, setSnackbar, setSnackbarError } = useCreateDispatches();

	const onResendInvite = async () => {
		setLoader(true);
		await reinviteAgencyUser(adminId)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Invite successfully re-sent.',
				});
			})
			.catch((err) => {
				setSnackbarError({ response: err.response }, { log: true });
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const inputs = [
		{ label: 'First Name', value: firstname },
		{ label: 'Last Name', value: lastname },
	];
	const inputMap = inputs?.map((val) => {
		return val.value ? (
			<StaticTextRow key={val.label} variant='outlined' {...val} />
		) : null;
	});

	return (
		<>
			<SectionHeader>Admin Information</SectionHeader>
			<div className={styles.adminInfoContainer}>
				{inputMap}
				<EmailStaticTextRow
					value={email}
					status={status}
					onResend={onResendInvite}
				/>
			</div>
		</>
	);
}
