const loaderState = false;

const SET_LOADER = 'SET_LOADER';

export default function loaderReducer(state = loaderState, action) {
	switch (action.type) {
		case SET_LOADER:
			return action.payload;
		default:
			return state;
	}
}

export function setLoader(loader) {
	return {
		type: SET_LOADER,
		payload: loader,
	};
}
