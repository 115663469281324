import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import Activities from './components/Activities';

export default function ActivityFeed(_props) {
	const root = useSelector(getPostsRoot) || 'lifebooks';

	return (
		<Switch>
			<Route
				path={`/${root}/:feedType(activity|profileActivity|exportActivity)/:childId`}>
				<Activities root={root} />
			</Route>
		</Switch>
	);
}
