import { useSelector } from 'react-redux';
import { Icon } from 'components';
import IndicatorDot from './IndicatorDot';
import clsx from 'clsx';
import styles from '../dashboard.module.css';

function DashboardNavItem({ name, icon: IconSvg, active, onClick }) {
	const user = useSelector((state) => state.user.user);

	const navBold =
		name === 'Casa' ||
		user.type === 'child' ||
		(user.type !== 'contributor' &&
			user.type !== 'csw' &&
			name === 'Support') ||
		(user.type === 'casauser' && name === 'Contributors')
			? true
			: false;

	return (
		<div
			className={clsx(
				styles.dashboardNavItemRoot,
				active ? styles.dashboardNavItemActive : ''
			)}>
			<div className={styles.dashboardNavItemContainer} onClick={onClick}>
				{IconSvg && (
					<Icon divStyle={styles.dashboardIconContainer}>
						<IconSvg className={styles.dashboardNavItemIcon} />
					</Icon>
				)}
				<div
					className={clsx(
						styles.dashboardNavItemName,
						navBold ? styles.boldWeight : styles.normalWeight
					)}>
					{name}
				</div>
				<div className={styles.dashboardNavItemActiveDot}>
					{active ? (
						<IndicatorDot className={styles.dashboardNavItemDot} />
					) : null}
				</div>
			</div>
		</div>
	);
}

export default DashboardNavItem;
