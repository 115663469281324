import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, ListSubheader } from '@material-ui/core';
import { FormSectionHeader, SecondaryButton, TextField } from 'components';
import CasaSelectListTile from './CasaSelectListTile';
import styles from '../casa.module.css';

function CasaSelector(props) {
	const history = useHistory();
	const {
		children,
		selected,
		label,
		textFieldLabel,
		menuList,
		changes,
		handleMenuSelect,
		handleRemove,
		disable,
		linkTo,
	} = props;

	const [selectedObject, setSelectedObject] = useState({});
	useEffect(() => {
		let result = menuList.filter((val) => {
			return val.id === selected;
		});
		setSelectedObject(result[0]);
	}, [selected, menuList]);

	const menuMapper = (val, i) => {
		const label = val.firstname
			? `${val.firstname} ${val.lastname}`
			: val.name
			? val.name
			: val.email;
		return (
			<MenuItem key={`${val.id}-${i}`} value={val.id}>
				{label}
			</MenuItem>
		);
	};

	const menuMap =
		menuList && Boolean(menuList.length) && menuList.map(menuMapper);

	const tile = Boolean(selectedObject) ? (
		<CasaSelectListTile
			value={selectedObject}
			select={
				linkTo ? () => history.push(`${linkTo}${selectedObject.id}`) : null
			}
			activeClass={styles.activeTile}
			pathParam={
				changes && changes[selectedObject.id] ? selectedObject.id : ''
			}>
			{selectedObject.firstname
				? `${selectedObject.firstname} ${selectedObject.lastname}`
				: selectedObject.name
				? selectedObject.name
				: selectedObject.email}
			{!disable ? (
				<div className={styles.selectorListButtonContainer}>
					<SecondaryButton
						onClick={(e) => {
							e.stopPropagation();
							handleRemove();
						}}>
						Remove
					</SecondaryButton>
				</div>
			) : null}
		</CasaSelectListTile>
	) : null;

	const menuSelect = (e) => {
		handleMenuSelect(e);
	};

	const id = label?.toLowerCase().replace(/ /g, '-') + '-single-selector-';

	return (
		<div className={styles.selectorListRoot} id={id + 'root'}>
			<FormSectionHeader hideTop>
				<div className={styles.label}>{label || ''}</div>
			</FormSectionHeader>
			{!disable ? (
				<TextField
					select
					id={id + 'textField'}
					className={styles.selectorListTextField}
					value={selected || ''}
					variant='outlined'
					label={textFieldLabel || ''}
					onChange={menuSelect}>
					{
						// use props.children to pass extra menu options
						children ? children : null
					}
					<ListSubheader
						value=''
						disabled={true}
						className={styles.selectorListSubheader}>
						{label}
					</ListSubheader>
					{menuMap}
				</TextField>
			) : null}
			<div className={styles.tileList}>{tile}</div>
		</div>
	);
}

export default CasaSelector;
