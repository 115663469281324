export const videoMarkers = (userType, userRole) => {
	if (userType === 'agencyuser') {
		if (userRole === 'admin') {
			return adminMarkers;
		} else if (userRole === 'manager') {
			return managerMarkers;
		} else {
			return socialWorkerMarkers;
		}
	}

	if (userType === 'contributor') {
		if (userRole === 'contributor') {
			return contributorMarkers;
		} else {
			return resourceParentMarkers;
		}
	}

	if (userType === 'casauser') {
		if (userRole === 'admin') {
			return casaAdminMarkers;
		} else {
			return casaMentorMarkers;
		}
	}

	if (userType === 'child') {
		return childMarkers;
	}
};

const adminMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 10, displayTime: '0:10' },
	{ id: 3, label: 'Log In / Log Out', time: 40, displayTime: '0:40' },
	{ id: 4, label: 'Admin User Features', time: 84, displayTime: '1:24' },
	{ id: 5, label: 'Inviting Users', time: 105, displayTime: '1:45' },
	{ id: 6, label: 'Children', time: 318, displayTime: '5:18' },
	{ id: 7, label: 'Vault', time: 677, displayTime: '11:17' },
	{ id: 8, label: 'Homes', time: 736, displayTime: '12:16' },
	{ id: 9, label: 'Agency', time: 774, displayTime: '12:54' },
	{ id: 10, label: 'CASA', time: 957, displayTime: '15:57' },
	{ id: 11, label: 'Support', time: 1080, displayTime: '18:00' },
	{ id: 12, label: 'Account', time: 1140, displayTime: '19:00' },
];

const socialWorkerMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 12, displayTime: '0:12' },
	{ id: 3, label: 'Log In / Log Out', time: 40, displayTime: '0:40' },
	{ id: 4, label: 'Children', time: 92, displayTime: '1:32' },
	{ id: 5, label: 'Vault', time: 400, displayTime: '6:40' },
	{ id: 6, label: 'Calendar', time: 606, displayTime: '10:06' },
	{ id: 7, label: 'Homes', time: 721, displayTime: '12:01' },
	{ id: 8, label: 'Support', time: 781, displayTime: '13:01' },
	{ id: 9, label: 'Account', time: 842, displayTime: '14:02' },
];

const managerMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 13, displayTime: '0:13' },
	{ id: 3, label: 'Log In / Log Out', time: 48, displayTime: '0:48' },
	{ id: 4, label: 'Manager User Features', time: 97, displayTime: '1:37' },
	{ id: 5, label: 'Children', time: 183, displayTime: '3:03' },
	{ id: 6, label: 'Vault', time: 567, displayTime: '9:27' },
	{ id: 7, label: 'Homes', time: 749, displayTime: '12:29' },
	{ id: 8, label: 'Agency', time: 792, displayTime: '13:12' },
	{ id: 10, label: 'Support', time: 914, displayTime: '15:14' },
	{ id: 11, label: 'Account', time: 972, displayTime: '16:12' },
];

const contributorMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 13, displayTime: '0:13' },
	{ id: 3, label: 'Log In / Log Out', time: 52, displayTime: '0:52' },
	{ id: 4, label: 'LifeBooks', time: 100, displayTime: '1:40' },
	{ id: 5, label: 'Support', time: 232, displayTime: '3:52' },
	{ id: 6, label: 'Account', time: 300, displayTime: '5:00' },
];

const resourceParentMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 12, displayTime: '0:12' },
	{ id: 3, label: 'Log In / Log Out', time: 51, displayTime: '0:51' },
	{ id: 4, label: 'LifeBooks', time: 100, displayTime: '1:40' },
	{ id: 5, label: 'Support', time: 233, displayTime: '3:53' },
	{ id: 6, label: 'Account', time: 298, displayTime: '4:58' },
];

const casaAdminMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Log In / Log Out', time: 30, displayTime: '0:30' },
	{ id: 3, label: 'Admin User Features', time: 70, displayTime: '1:10' },
	{ id: 4, label: 'Vault', time: 150, displayTime: '2:30' },
	{ id: 5, label: 'Calendar', time: 380, displayTime: '6:20' },
	{ id: 6, label: 'Casa Management', time: 492, displayTime: '8:02' },
	{ id: 7, label: 'Support', time: 580, displayTime: '9:40' },
];

const casaMentorMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Log In / Log Out', time: 25, displayTime: '0:25' },
	{ id: 3, label: 'Mentor User Features', time: 62, displayTime: '1:02' },
	{ id: 4, label: 'Account', time: 143, displayTime: '2:23' },
	{ id: 5, label: 'Support', time: 165, displayTime: '2:45' },
];

const childMarkers = [
	{ id: 1, label: 'Welcome To Ella', time: 1, displayTime: '0:00' },
	{ id: 2, label: 'Create Account', time: 11, displayTime: '0:11' },
	{ id: 3, label: 'Log In', time: 50, displayTime: '0:50' },
	{ id: 4, label: 'LifeBook', time: 105, displayTime: '1:45' },
	{ id: 5, label: 'Vault', time: 273, displayTime: '4:33' },
	{ id: 6, label: 'Calendar', time: 432, displayTime: '7:12' },
	{ id: 7, label: 'Account', time: 553, displayTime: '9:13' },
	{ id: 8, label: 'Support', time: 601, displayTime: '10:01' },
	{ id: 9, label: 'Log Out', time: 631, displayTime: '10:31' },
];
