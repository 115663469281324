import { FormToggleSwitch } from 'components';
import { InfoOutlined } from '@material-ui/icons';
import styles from '../account.module.css';

export default function ChildToggles({ privatize, setPrivatize }) {
	return (
		<>
			<FormToggleSwitch switchState={privatize} setSwitch={setPrivatize}>
				Privatize posts
			</FormToggleSwitch>
			<div className={styles.childToggleInfoContainer}>
				<InfoOutlined />
				<div className={styles.childToggleInfo}>
					Privatizing your timeline will block the content of your posts from
					other users.
				</div>
			</div>
		</>
	);
}
