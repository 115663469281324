import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { getOwnUser } from 'services';

const styles = () => ({
	loadingContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	loadingLogo: {
		width: '156px',
		height: '96px',
	},
});

const useStyles = makeStyles(styles);
export const AccessRoute = (props) => {
	const {
		key,
		id,
		path,
		location,
		component: Component,
		children,
		authorizedType,
		authorizedRole,
		fallBackRoute,
		param,
		width,
		height,
		authorized,
		isMd,
		access,
		privileged,

		...rest
	} = props;
	const classes = useStyles();
	const component = Component ? <Component {...props} /> : children;
	const { pathname } = location;
	let routeParam = path.includes(':') ? pathname.split('/').pop() : '';
	if (!routeParam.includes('_')) {
		routeParam = '';
	}
	const [authorize, setAuthorize] = useState(false);
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		let unmounted = false;

		async function fetchData() {
			const response = await getOwnUser();
			const typeToCheck = response && authorized?.[response.type];

			const isObjectAuthorized =
				typeToCheck &&
				Object.keys(typeToCheck).some((key) => {
					const checkValue = typeToCheck[key];
					switch (true) {
						case Array.isArray(checkValue):
							return checkValue.includes(response[key]);
						case typeof checkValue === 'boolean':
							return checkValue === response[key];
						default:
							return false;
					}
				});

			const isRoleAuthorized =
				authorizedRole && authorizedRole.includes(response.role);
			const isTypeAuthorized =
				authorizedType && authorizedType.includes(response.type);

			if (
				isRoleAuthorized ||
				isTypeAuthorized ||
				typeToCheck === true ||
				isObjectAuthorized ||
				response.privileged
			) {
				if (!unmounted) {
					setAuthorize(true);
				}
			} else {
				if (!unmounted) {
					setAuthorize(false);
				}
			}
			if (response && !unmounted) setChecked(true);
		}
		fetchData();

		return () => {
			unmounted = true;
		};
	}, [authorizedType, authorizedRole, authorized, pathname, path, privileged]);

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			setAuthorize(false);
		}
	}, [id]);

	return checked === true ? (
		<Route
			{...rest}
			render={() => {
				return localStorage.getItem('token') && authorize ? (
					component
				) : (
					<Redirect
						to={fallBackRoute ? fallBackRoute + routeParam : '/agency/'}
					/>
				);
			}}
		/>
	) : (
		<div className={classes.loadingContainer}>
			<img
				className={classes.loadingLogo}
				src='/assets/ella-elephant-gray.svg'
				alt='Ella Elephant'
			/>
		</div>
	);
};

const mapStateToProps = ({ user, mediaQuery: { width, height, isMd } }) => {
	return { id: user.user.id, width, height, isMd };
};

export default withRouter(connect(mapStateToProps)(AccessRoute));
