import { TextFieldRow, SectionHeader, StaticTextRow } from 'components';
import styles from '../homes.module.css';

export default function HomeBasicInfo({ inputs, disable }) {
	const inputMap = inputs?.map((val) => {
		if (disable) {
			return <StaticTextRow key={val.label} variant='outlined' {...val} />;
		} else {
			return <TextFieldRow key={val.label} variant='outlined' {...val} />;
		}
	});

	return (
		<>
			<SectionHeader>HOUSEHOLD INFORMATION</SectionHeader>
			<div className={styles.manageHomeRoot}>{inputMap}</div>
		</>
	);
}
