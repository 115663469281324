export const getOverLayState = ({ overLay }) => overLay;

export const getOverLayModalArray = ({ overLay }) => overLay.modalArray;

export const getOverLaySlideOut = ({ overLay }) => overLay.slideOut;

export const getOverLayFullScreen = ({ overLay }) => overLay.fullScreen;

export const getOverLayListLoader = ({ overLay }) => overLay.listLoader;

export const getOverLayLoader = ({ overLay }) => overLay.loader;

export const getOverLayBannerButtons = ({ overLay }) => overLay.bannerButtons;

export const getOverLayMobileDashboard = ({ overLay }) => overLay.mobileDashboard;

export const getOverLayMobileList = ({ overLay }) => overLay.mobileList;

export const getOverLayNotificationsCount = ({ overLay }) => overLay.notificationsCount;