import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const staticTextRowStyles = (theme) => {
	return {
		staticTextRowRoot: {
			width: '100%',
			height: '52px',
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				height: 'unset',
				marginTop: '8px',
				borderTop: `1px solid ${theme.palette.primary.divider}`,
			},
		},
		staticTextRowLabel: {
			color: theme.palette.font.gray,
			fontSize: '16px',
			width: '140px',
			minWidth: '140px',
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: '8px',
			},
		},
		staticTextRowInput: {
			width: 'calc(100% - 140px)',
			height: '52px',
			lineHeight: '52px',
			fontSize: '16px',
			paddingLeft: '12px',
			overflow: 'nowrap',
			whiteSpace: 'nowrap',
			color: theme.palette.font.default,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	};
};

const useStyles = makeStyles(staticTextRowStyles);

const StaticTextRow = ({ label, value, type, children, labelStyle }) => {
	const classes = useStyles();

	const getValue = () => {
		if (type === 'date') {
			return new Date(value).toISOString().split('T')[0];
		}
		return value;
	};

	return (
		<div className={classes.staticTextRowRoot}>
			<div className={clsx(classes.staticTextRowLabel, labelStyle)}>
				{label}
			</div>
			<div className={classes.staticTextRowInput}>{children || getValue()}</div>
		</div>
	);
};

export default StaticTextRow;
