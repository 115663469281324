import { connect } from 'react-redux';
import { setCollapse } from 'redux/index';

function FirstTile(props) {
	const { name } = props;
	return <div className='first-tile-container'>{name}</div>;
}

const mapDispatchToProps = {
	setCollapse,
};

export default connect(null, mapDispatchToProps)(FirstTile);
