import { useSelector } from 'react-redux';
import { getLifebooksChild, getPostsRoot } from 'redux/selectors';
import SectionLayout from '../components/SectionLayout';
import ServiceSwitcherMobile from './ServiceSwitcherMobile';
import styles from '../headers.module.css';

export default function ServiceSwitcher() {
	const { privatizePosts } = useSelector(getLifebooksChild);
	const userType = useSelector((state) => state.user.user.type);
	const userRole = useSelector((state) => state.user.user.role);
	const root = useSelector(getPostsRoot);

	if (privatizePosts && userType !== 'child') {
		return null;
	}

	if (userRole === 'contributor') {
		return null;
	}

	const blockedRoots = ['connect'];

	return !blockedRoots.includes(root) ? (
		<SectionLayout
			label={userType === 'child' ? 'VIEW MY' : "VIEW CHILD'S"}
			className={styles.serviceSwitcherMobileRoot}>
			<ServiceSwitcherMobile />
		</SectionLayout>
	) : null;
}
