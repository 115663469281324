import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import { useCheckUserPermissions } from 'helpers/hooks';

// NOTE: This hook is used to determine if a user should see the editable component
export default function useManageChildPermissions() {
	const { includeUsers } = useCheckUserPermissions();
	const root = useSelector(getPostsRoot);
	const child = useSelector((state) => state.lifebooks.child);
	const user = useSelector((state) => state.user.user);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);

	let perms;

	if (user.type === 'casauser') {
		if (user.role === 'admin') {
			perms = casaPermissions;
		} else {
			perms = casaUserPermissions;
		}
	}

	const isArchive = root === 'archive';

	const view = {
		image: true,
		name: true,
		placementDate: true,
		dob: true,
		gender: true,
		caseNumber: true,
		mobile: true,
		email: true,
		ethnicity: true,
		siblings: !isArchive && includeUsers('agencyuser'),
		sw: !isArchive && includeUsers('agencyuser'),
		home: !isArchive && includeUsers('agencyuser'),
		csw: !isArchive && includeUsers('agencyuser'),
		audit: false,
		admin: !isArchive && includeUsers('agencyuser'),
		vaultDate: !isArchive && includeUsers('agencyuser'),
		school: true,
	};

	const edit = {
		image: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		name: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		placementDate: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		dob: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		gender: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		ethnicity: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		caseNumber: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		mobile: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		email: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		sw: includeUsers('admin', 'manager'),
		home: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		csw: includeUsers('admin', 'manager'),
		siblings: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		vaultDate: includeUsers('csw'),
		school: includeUsers('admin', 'manager', 'privileged', 'casauser'),
		audit: includeUsers('agencyuser', 'csw'),
	};

	if (user.type === 'casauser' && child.agencyId) {
		if (!perms?.basic_write_profile) {
			edit.image = false;
			edit.name = false;
			edit.dob = false;
			edit.gender = false;
			edit.ethnicity = false;
			edit.mobile = false;
			edit.email = false;
		}

		if (!perms?.mgmt_write_profile) {
			edit.placementDate = false;
			edit.caseNumber = false;
			edit.school = false;
		}
	} else {
		if (user.role === 'mentor' && !user.privileged) {
			edit.image = false;
			edit.name = false;
			edit.dob = false;
			edit.gender = false;
			edit.ethnicity = false;
			edit.mobile = false;
			edit.email = false;
			edit.caseNumber = false;
			edit.school = false;
			edit.placementDate = false;
		}
	}

	// NOTE: child admin permissions are managed by useManageChildAdminPermissions

	const permissions = Object.keys(edit).reduce((acc, label) => {
		if (isArchive) {
			acc[label] = view[label] ? 'view' : null;
		} else {
			acc[label] = edit[label] ? 'edit' : view[label] ? 'view' : null;
		}

		return acc;
	}, {});

	return permissions;
}
