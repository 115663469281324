import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setModal } from 'redux/index';
import Slider from 'react-slick';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Icon } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { CarouselImage, TransparentButton } from 'components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../addImage.module.css';

const useStyles = makeStyles((theme) => ({
	carouselSlider: {
		zIndex: 10,
		'& .slick-track': {
			margin: 'auto',
		},
		'& > button': {
			zIndex: 100,
			height: '40px',
			width: '40px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
		'& > .slick-prev': {
			left: '10px',
		},
		'& > .slick-next': {
			right: '10px',
		},
		'& > ul': {
			zIndex: 100,
			'& li': {
				marginBottom: '24px',
				'& > button': {
					width: '16px',
					height: '16px',
					borderRadius: '50%',
					backgroundColor: 'white',
				},
				'& button::before': {
					display: 'none',
				},
			},
			'& .slick-active': {
				'& > button': {
					backgroundColor: theme.palette.active.main,
				},
			},
		},
		'& .slick-slide': {
			height: '80%',
			width: '100%',
		},
	},
}));

function LazyCarousel(props) {
	let classes = useStyles();
	const { photos, initialSlide, setModal, setSrc, removeFile } = props;
	const [currentSlide, setCurrentSlide] = useState(initialSlide || 0);
	const settings = {
		className: classes.carouselSlider,
		dots: true,
		lazyLoad: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		initialSlide: initialSlide || 0,
		afterChange: (cur) => {
			setCurrentSlide(cur);
		},
	};

	const [slides, setSlides] = useState(null);
	useEffect(() => {
		let photoArray = photos.map((val, i) => {
			return <CarouselImage key={i} photo={val} setDownloadSrc={setSrc} />;
		});
		setSlides(photoArray);
	}, [photos, setSrc]);

	useEffect(() => {
		if (slides && !slides.length) {
			setModal(false);
		}
	}, [setModal, slides]);

	return (
		<div className={styles.carouselRoot}>
			{removeFile && (
				<TransparentButton
					onClick={() => {
						removeFile(currentSlide);
					}}
					style={{ color: 'white' }}
					className={styles.carouselDelete}>
					<Icon>
						<DeleteForever />
					</Icon>
				</TransparentButton>
			)}
			<TransparentButton
				onClick={() => {
					setModal(false);
				}}
				style={{ color: 'white' }}
				className={styles.carouselClose}>
				<Icon>
					<Close />
				</Icon>
			</TransparentButton>
			{slides && <Slider {...settings}>{slides}</Slider>}
		</div>
	);
}

export default connect(null, { setModal })(LazyCarousel);
