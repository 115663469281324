import { useEffect, useState } from 'react';
import { getDisplayPostImage } from 'services';

export default function useGetDisplayImage(photos, setLoader, setImg) {
	const [imageAttempt, setImageAttempt] = useState(0);
	const [index, setIndex] = useState(0);
	const maxIndex = photos.length - 1;
	const hasMultiplePhotos = !!maxIndex;

	useEffect(() => {
		let isMounted = true;
		const displayImage = () => {
			setImageAttempt(0);
			setLoader(true);
			const tryDisplay = async () => {
				const displayPhoto = photos[index];
				if (Number(imageAttempt) < 5) {
					const onLoad = () => {
						if (isMounted) {
							setLoader(false);
							setImg(img);
						}
					};
					const onError = (err) => {
						setImageAttempt(imageAttempt + 1);
						setTimeout(tryDisplay, 3000);
					};
					const img = await getDisplayPostImage(displayPhoto, onLoad, onError);
				} else {
					setLoader(false);
				}
			};
			tryDisplay();
		};

		displayImage();
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photos, index]);

	const nextImg = () => {
		if (index === maxIndex) {
			setIndex(0);
		} else {
			setIndex(index + 1);
		}
	};

	const prevImg = () => {
		if (index === 0) {
			setIndex(maxIndex);
		} else {
			setIndex(index - 1);
		}
	};

	return { nextImg, prevImg, index, maxIndex, hasMultiplePhotos };
}
