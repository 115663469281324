import { SelectorList } from 'components';

export default function ManagerSocialWorkerList({ selectorListState }) {
	const linkTo = '/socialworkers/manage/';
	return (
		<SelectorList
			label='Social-Workers'
			textFieldLabel='Social-Worker'
			linkTo={linkTo}
			fromList='social-worker'
			{...selectorListState}
		/>
	);
}
