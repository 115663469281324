import { SelectorList } from 'components';

export default function SocialWorkerManagerList({ selectorListState }) {
	const linkTo = '/managers/manage/';

	return (
		<SelectorList
			label='Managers'
			textFieldLabel='Manager'
			linkTo={linkTo}
			fromList='manager'
			single
			{...selectorListState}
		/>
	);
}
