import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';

const SecondaryButton = ({ style, className, ...props }) => {
	const useStyles = makeStyles((theme) => ({
		root: {
			justifyContent: 'center',
			padding: '0',
		},
		button: {
			border: theme.palette.primary.dark,
			borderStyle: 'solid',
			borderWidth: '1px',
			backgroundColor: 'white',
			color: theme.palette.primary.dark,
			marginLeft: '8px',
			fontSize: '12px',
			padding: '8px 16px',
			letterSpacing: '0.08em',
			borderRadius: '24px',
			boxSizing: 'border-box',
			'&:hover': {
				color: theme.palette.primary.main,
				backgroundColor: 'white',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '12px',
			},
			...style,
		},
	}));

	const classes = useStyles();

	return (
		<React.Fragment>
			<Button
				variant='contained'
				className={clsx(classes.button, className ? className : null)}
				onClick={props.onClick}
				{...props}>
				{props.children}
			</Button>
		</React.Fragment>
	);
};

export default SecondaryButton;
