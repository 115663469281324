import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useState, useEffect } from 'react';
import { useCreateDispatches } from 'helpers/hooks';
import styles from './addressForm.module.css';
import clsx from 'clsx';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
export { useAddressGeocode } from './utils/addressHandler';

const AddressAutocomplete = ({ full = true }) => {
	const { setAddress } = useCreateDispatches();

	const [value, setValue] = useState('');

	useEffect(() => {
		onChange();
	}, [value]);

	const clearValue = () => {
		setValue('');
	};

	const onChange = () => {
		let streetNumber = '';
		let streetAddress = '';
		let street2 = '';
		let city = '';
		let state = '';
		let zip = '';
		let country = '';

		if (value?.value?.place_id) {
			geocodeByPlaceId(value?.value?.place_id)
				.then((results) => {
					results[0]?.address_components?.map((address) => {
						if (address.types.includes('street_number')) {
							streetNumber = address.long_name;
						}
						if (address.types.includes('route')) {
							streetAddress = address.long_name;
						}
						if (address.types.includes('subpremise')) {
							street2 = address.long_name;
						}
						if (address.types.includes('locality')) {
							city = address.long_name;
						}
						if (address.types.includes('administrative_area_level_1')) {
							state = address.short_name;
						}
						if (address.types.includes('postal_code')) {
							zip = address.long_name;
						}
						if (address.types.includes('country')) {
							country = address.short_name;
						}
					});
					setAddress({
						street: `${streetNumber} ${streetAddress}`,
						street2: street2,
						city: city,
						state: state,
						postal: zip,
						country: country,
					});
				})
				.catch(() => {
					setValue('');
				});
		}
	};

	return (
		<div
			className={clsx(
				styles.addressFillContainer,
				full ? styles.addressFillFullWidth : styles.addressFillStandardWidth
			)}>
			<div className={styles.addressFillForm}>
				<div style={{ height: '100px' }}>
					<GooglePlacesAutocomplete
						isClearable={true}
						selectProps={{
							value,
							onChange: setValue,
							clearValue: clearValue,
							isClearable: () => true,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddressAutocomplete;
