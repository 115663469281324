import { useSelector } from 'react-redux';
import { getAddress } from 'redux/selectors';
import { stateMap } from 'helpers';
import staticAddressMapper from './utils/staticAddressMapper';
import styles from './addressForm.module.css';
import clsx from 'clsx';

export { useAddressGeocode } from './utils/addressHandler';

export default function StaticAddressForm({ full = true }) {
	const { street, street2, city, state, postal } = useSelector(getAddress);

	const handleState = stateMap[state] || stateMap[state?.value] || '';
	const stateValue = handleState.state ? handleState.state : 'undefined';

	const inputFields = [
		{
			label: 'Street',
			name: 'street',
			value: street,
			required: true,
			disabled: true,
		},
		{ label: 'Street 2', name: 'street2', value: street2, disabled: true },
		{
			label: 'City',
			name: 'city',
			value: city,
			required: true,
			disabled: true,
		},
		{
			label: 'State',
			name: 'state',
			value: stateValue,
			type: 'select',
			required: true,
			disabled: true,
		},
		{
			label: 'Zip',
			name: 'postal',
			value: postal,
			type: 'submit',
			disabled: true,
		},
	];

	const inputMap = staticAddressMapper(inputFields, styles);

	return (
		<div
			className={clsx(
				styles.addressFillContainer,
				full ? styles.addressFillFullWidth : styles.addressFillStandardWidth
			)}>
			<div className={styles.addressFillForm}>{inputMap}</div>
		</div>
	);
}
