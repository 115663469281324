import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

const TransparentButton = (props) => {
	const { tooltip, className, children, onClick, contrast } = props;
	const { height, width, iconSize } = props.style || {};

	const styles = (theme) => ({
		root: {
			justifyContent: 'center',
			padding: '0',
		},
		transparentButton: {
			minWidth: '0',
			height: '36px',
			width: '36px',
			padding: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			borderRadius: '5px',
			cursor: 'pointer',
			zIndex: '50',
			color: 'black',
			boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.25)',
			'&:hover': {
				backgroundColor: theme.palette.background.gray,
				color: '#151B26',
			},
			...props.style,
			[theme.breakpoints.down('sm')]: {
				height: '30px',
				width: '30px',
			},
		},
		buttonColor: {
			backgroundColor: 'rgba(255, 255, 255, 0)',
		},
		buttonContrast: {
			color: 'rgba(0, 0, 0, .25)',
			backgroundColor: 'rgba(255, 255, 255, 0.25)',
		},
		icon: {
			//change size of icon symbol based on iconSize style prop
			height: iconSize
				? iconSize
				: height
				? `${+height.substring(0, height.length - 2) * 0.8}px`
				: '48px',
			width: iconSize
				? iconSize
				: width
				? `${+width.substring(0, width.length - 2) * 0.8}px`
				: '48px',
			[theme.breakpoints.down('sm')]: {
				height: iconSize
					? iconSize
					: height
					? `${+height.substring(0, height.length - 2) * 0.4}px`
					: '24px',
				width: iconSize
					? iconSize
					: width
					? `${+width.substring(0, width.length - 2) * 0.4}px`
					: '28px',
			},
		},
	});

	const useStyles = makeStyles(styles);
	const classes = useStyles();

	const toolTipWrapped = (children) => {
		return Boolean(tooltip) ? (
			<Tooltip title={tooltip}>{children}</Tooltip>
		) : (
			children
		);
	};

	return (
		<React.Fragment>
			{toolTipWrapped(
				<Button
					variant='contained'
					className={clsx(
						classes.transparentButton,
						className,
						contrast ? classes.buttonContrast : classes.buttonColor
					)}
					onClick={onClick}>
					{children}
				</Button>
			)}
		</React.Fragment>
	);
};

export default TransparentButton;
