import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/reducers';
import * as helper from 'helpers';

const useInfoState = () => {
	return {
		firstname: helper.useFormInput('First Name', '', { required: true }),
		lastname: helper.useFormInput('Last Name', '', { required: true }),
		mobile: helper.useFormInput('Phone', '', { type: 'phone' }),
		email: helper.useFormInput('Email', ''),
		oldPassword: helper.useFormInput('Current Password', ''),
		newPassword: helper.useFormInput('New Password', ''),
		newPwConfirm: helper.useFormInput('Confirm Password', ''),
	};
};

const useNotificationToggles = (user) => {
	const [teenActivity, setTeenActivity] = useState(
		user.notifications?.teenActivity
	);
	const [pendingPost, setPendingPost] = useState(
		user.notifications?.pendingPost
	);
	const [postInactivity, setPostInactivity] = useState(
		user.notifications?.postInactivity
	);

	return [
		{
			value: teenActivity,
			toggle: setTeenActivity,
			label: 'Teen Activity',
			reqKey: 'notificationsTeenActivity',
		},
		{
			value: pendingPost,
			toggle: setPendingPost,
			label: 'Pending Posts',
			reqKey: 'notificationsPendingPost',
		},
		{
			value: postInactivity,
			toggle: setPostInactivity,
			label: 'Post Inactivity',
			reqKey: 'notificationsPostInactivity',
		},
	];
};

const notificationReqBuilder = (notifications) => {
	return notifications.reduce((acc, { reqKey, value }) => {
		acc[reqKey] = value;
		return acc;
	}, {});
};

const useValidate = () => {
	const dispatch = useDispatch();

	return (infoState) => {
		const { firstname, lastname, oldPassword, newPassword, newPwConfirm } =
			infoState || {};
		let validationError = '';
		if (helper.excludeType(['child'])) {
			if (!firstname.value || !lastname.value) {
				validationError = 'Name fields are required';
			}
		}
		if (newPassword.value && !oldPassword.value) {
			validationError =
				'Current password is required to create a new password.';
		} else if (
			newPassword.value &&
			!helper.passwordTypeCheck(newPassword.value)
		) {
			validationError = 'Passwords must be at least 8 characters.';
		} else if (newPassword.value !== newPwConfirm.value) {
			validationError = `Please reconfirm new password`;
		}
		if (!validationError) {
			return true;
		} else {
			dispatch(setSnackbar({ variant: 'error', message: validationError }));
			return false;
		}
	};
};

export {
	useInfoState,
	useNotificationToggles,
	notificationReqBuilder,
	useValidate,
};
