import { useCheckUserPermissions } from 'helpers/hooks';
import useArrayMappers from '../utils/useArrayMappers';
import { StaticTextRow } from 'components';
import styles from '../account.module.css';

export default function ProfileName({ firstname, lastname }) {
	const { textFieldMap } = useArrayMappers();
	const { includeUsers } = useCheckUserPermissions();

	const textFields = [
		{ value: 'firstname', input: firstname },
		{ value: 'lastname', input: lastname },
	];

	return includeUsers('agencyuser', 'csw', 'casauser') ? (
		<div className={styles.profileNameContainer}>
			{textFieldMap(textFields)}
		</div>
	) : (
		<StaticTextRow
			{...{ label: 'Name', value: `${firstname.value} ${lastname.value}` }}
		/>
	);
}
