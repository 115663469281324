import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { reInviteChild, inviteChild } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import useManageChildPermissions from '../../utils/useManageChildPermissions';
import ChildName from './ChildName';
import ChildEmail from './ChildEmail';
import ChildGender from './ChildGender';
import ChildEthnicity from './ChildEthnicity';
import DatePickerRow from '../DatePickerRow';
import ChildVaultAccessDate from '../ChildVaultAccessDate';
import * as Comp from 'components';
import styles from './ChildBasicInfo.module.css';

export default function ChildBasicInfo(props) {
	const { childId } = useParams();
	const child = useSelector(getLifebooksChild);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);
	const user = useSelector((state) => state.user.user);

	const [basicOpen, setBasicOpen] = useState(true);
	const [manageOpen, setManageOpen] = useState(false);

	const { status } = child;
	const { setLoader, setSnackbar } = useCreateDispatches();
	const permissions = useManageChildPermissions();
	const { firstname, middlename, lastname } = props.state;

	let casaPermission;

	if (user.type === 'casauser') {
		if (user.role === 'admin') {
			casaPermission = casaPermissions;
		} else {
			casaPermission = casaUserPermissions;
		}
	}

	const onResendInvite = async () => {
		if (child.status === 'created') {
			inviteChild(childId)
				.then(() => {
					setSnackbar({
						variant: 'success',
						message: 'Invitation successfully sent',
					});
				})
				.catch((err) => {
					const { message } = err.response.data || {};
					setSnackbar({
						variant: 'error',
						message: message || 'Invitation could not be sent at this time.',
					});
				})
				.finally(() => {
					setLoader(false);
				});
			return;
		}
		if (childId) {
			setLoader(true);
			reInviteChild(childId)
				.then(() => {
					setSnackbar({
						variant: 'success',
						message: 'Invitation successfully resent',
					});
				})
				.catch((err) => {
					const { message } = err.response.data || {};
					setSnackbar({
						variant: 'error',
						message: message || 'Invitation could not be sent at this time.',
					});
				})
				.finally(() => {
					setLoader(false);
				});
		}
	};

	let basicState = {};
	let advancedState = {};

	user.type === 'casauser' &&
	child.agencyId &&
	!casaPermission?.basic_read_profile
		? (basicState = {})
		: (basicState = {
				dob: props.state?.dob,
				gender: props.state?.gender,
				ethnicity: props.state?.ethnicity,
				mobile: props.state?.mobile,
				email: props.state?.email,
		  });

	user.type === 'casauser' &&
	child.agencyId &&
	!casaPermission?.mgmt_read_profile
		? (advancedState = {})
		: (advancedState = {
				caseNumber: props.state?.caseNumber,
				placementDate: props.state?.placementDate,
		  });

	const mapper = (fields) => {
		const inputMap = Object.keys(fields)?.map((label) => {
			const { type, ...val } = fields[label];

			if (props.ownershipCodeVerified) {
				val.disabled = true;
			}

			if (!val.value) {
				val.value = '';
			}

			switch (true) {
				case !permissions[label]:
					return null;
				case permissions[label] === 'view':
					return val?.value ? (
						<Comp.StaticTextRow
							key={`${val.label}-static`}
							{...val}
							type={type}
						/>
					) : (
						<Comp.StaticTextRow key={`${val.label}-static`} label={val.label} />
					);
				case val.label === 'Gender':
					return (
						<ChildGender key={`${val.label}-manageChildGender`} {...val} />
					);
				case type === 'date':
					return <DatePickerRow key={val.label} required {...val} />;
				case val.label === 'Ethnicity':
					return <ChildEthnicity key={val.label} {...val} />;
				case val.label === 'Email':
					return (
						<ChildEmail
							onResend={onResendInvite}
							key={val.label}
							{...val}
							status={status}
							email={child.email}
						/>
					);
				default:
					return (
						<Comp.TextFieldRow key={val.label} variant='outlined' {...val} />
					);
			}
		});
		return inputMap;
	};

	const basicMap = mapper(basicState);
	const advancedMap = mapper(advancedState);

	const showSchool =
		permissions.school === 'view' && props.school?.schoolName?.value;

	return (
		<div className={styles.manageChildBasicInfoRoot}>
			<div>
				<Comp.SectionHeader>
					<div className={styles.basicHeader}>
						<div className={styles.headerText}>BASIC INFO</div>
						{basicOpen ? (
							<ExpandLess
								onClick={() => setBasicOpen(!basicOpen)}
								className={styles.basicExpandIcon}
							/>
						) : (
							<ExpandMore
								onClick={() => setBasicOpen(!basicOpen)}
								className={styles.basicExpandIcon}
							/>
						)}
					</div>
				</Comp.SectionHeader>

				<div className={styles.manageChildBasicInfoRoot}>
					{basicOpen && (
						<>
							<ChildName
								disabled={props.ownershipCodeVerified}
								name={{ firstname, middlename, lastname }}
							/>
							{basicMap}
							{childId && (
								<Comp.StaticTextRow label='Ella ID:' value={childId} />
							)}
						</>
					)}
				</div>
			</div>
			<div>
				{user.type === 'casauser' &&
				child.agencyId &&
				!casaPermission?.mgmt_read_profile ? null : (
					<Comp.SectionHeader>
						<div className={styles.basicHeader}>
							<div className={styles.headerText}>MANAGEMENT INFO</div>
							{manageOpen ? (
								<ExpandLess
									onClick={() => setManageOpen(!manageOpen)}
									className={styles.basicExpandIcon}
								/>
							) : (
								<ExpandMore
									onClick={() => setManageOpen(!manageOpen)}
									className={styles.basicExpandIcon}
								/>
							)}
						</div>
					</Comp.SectionHeader>
				)}
				{manageOpen && (
					<>
						{advancedMap}
						{showSchool && (
							<Comp.StaticTextRow
								label='School'
								value={props.school?.schoolName?.value}
							/>
						)}
						{!props.ownershipCodeVerified && user.type !== 'casauser' && (
							<ChildVaultAccessDate />
						)}
					</>
				)}
			</div>
		</div>
	);
}
