import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setModal } from 'redux/index';

const Modal = (props) => {
	const { modalArray } = props;

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const escFunction = (e) => {
		if (e.keyCode === 27) {
			setModal(false);
		}
	};

	const clickClose = (e) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			props.setModal(false);
		}
	};

	return modalArray?.length > 0 ? (
		<div className='modal-container' onClick={clickClose}>
			{modalArray[0]}
		</div>
	) : null;
};

const mapStateToProps = ({ overLay }) => {
	return { modalArray: overLay.modalArray };
};

const mapDispatchToProps = {
	setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
