import styles from '../ChildAdmin.module.css';

export default function ExportPdfInfo() {
	const items = ['Audit', 'Legal', 'For Family'];

	return (
		<div className={styles.exportPdfInfoRoot}>
			Please provide a reason for requesting a Lifebook Export. Common reasons
			may be:
			<ul className={styles.exportPdfInfoList}>
				{items.map((val, i) => (
					<li key={`list-item-${i}`} className={styles.exportPdfInfoItem}>
						{val}
					</li>
				))}
			</ul>
		</div>
	);
}
