import React from 'react';
import { connect } from 'react-redux';
import { setSnackbar, closeSnackbar } from 'redux/index';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
	blank: InfoIcon,
};

const wrapperStyles = makeStyles((theme) => ({
	success: {
		backgroundColor: green[700],
	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	blank: {
		backgroundColor: green[0],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

function Wrapper(props) {
	const classes = wrapperStyles();
	const { className, message, variant, onClose, ...rest } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby='client-snackbar'
			message={
				<span id='client-snackbar' className={classes.message}>
					{props.open ? (
						<Icon className={clsx(classes.icon, classes.iconVariant)} />
					) : null}
					{message}
				</span>
			}
			action={[
				<IconButton
					key='close'
					aria-label='close'
					color='inherit'
					onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...rest}
		/>
	);
}

Wrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'blank'])
		.isRequired,
};

const snackbarStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
}));

function Snackbar(props) {
	const { open, variant, message } = props.snackbar;
	const classes = snackbarStyles();
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			//prevents default clickaway
			return;
		}
		props.closeSnackbar();
	};
	return (
		<MaterialSnackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={props.snackbar && props.snackbar.open}
			autoHideDuration={4000}
			onClose={handleClose}>
			<Wrapper
				onClose={handleClose}
				className={classes.margin}
				variant={variant ? variant : 'blank'}
				message={message}
				open={open}
			/>
		</MaterialSnackbar>
	);
}

const mapStateToProps = ({ snackbar }) => {
	return { snackbar };
};

const mapDispatchToProps = {
	setSnackbar,
	closeSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
