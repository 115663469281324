import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getIsMd } from '../../../redux/selectors';
import { TextField } from '../..';
import Tooltip from '@material-ui/core/Tooltip';

const textFieldRowStyles = (theme) => {
	return {
		textFieldRowRoot: {
			width: '100%',
			height: '68px',
			maxWidth: theme.width.contentMax,
			minWidth: theme.width.contentMin,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				minWidth: 'unset',
			},
		},
		textFieldRowLabel: {
			color: theme.palette.font.gray,
			fontSize: '16px',
			width: '150px',
			minWidth: '150px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignContent: 'center',
			flexDirection: 'row',
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		textFieldRowLabelRequired: {
			'&::after': {
				color: theme.palette.error.main,
				content: '" *"',
			},
		},
		textFieldRowInput: {
			width: 'calc(100% - 140px)',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	};
};

const useStyles = makeStyles(textFieldRowStyles);

const TextFieldRow = ({
	label,
	children,
	tooltip,
	required = false,
	...rest
}) => {
	const classes = useStyles();
	const isMd = useSelector(getIsMd);

	const tooltipWrap = (
		children,
		{ placement = 'top-start', ...params } = {}
	) => {
		if (tooltip)
			return (
				<Tooltip placement={placement} {...params} title={tooltip}>
					<>{children}</>
				</Tooltip>
			);

		return children;
	};

	return (
		<div className={classes.textFieldRowRoot}>
			{tooltipWrap(
				<div
					className={clsx(
						classes.textFieldRowLabel,
						required && classes.textFieldRowLabelRequired
					)}>
					{label}
				</div>
			)}
			<div className={classes.textFieldRowInput}>
				{children && !rest.select
					? children
					: tooltipWrap(
							<TextField
								required={required}
								{...rest}
								label={isMd && label ? label : null}>
								{children}
							</TextField>,
							{
								disableHoverListener: true,
							}
					  )}
			</div>
		</div>
	);
};

export default TextFieldRow;
