import Avatar from 'components/UtilityComponents/Icons/Avatar';
import styles from '../exportRequest.module.css';

const ExportRequester = (props) => {
	const photo = props?.request?.requestedUser?.photo?.url;
	const requesterName = `${props?.request?.requestedUser?.firstname} ${props?.request?.requestedUser?.lastname}`;

	return (
		<div className={styles.requester}>
			<div className={styles.requestFrom}>
				<div className={styles.headerText}>Request From:</div>
				<div className={styles.text}>{requesterName}</div>
			</div>
			<div className={styles.requesterImage}>
				<Avatar alt={requesterName} image={photo} />
			</div>
		</div>
	);
};

export default ExportRequester;
