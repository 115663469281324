import { Switch } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import styles from './form.module.css';

export function FormToggleSwitch(props) {
	const { children, switchState, setSwitch, className, labelStyle } = props;
	return (
		<div className={className ? className : styles.formToggleContainer}>
			<div className={labelStyle ? labelStyle : styles.formToggleLabel}>
				{children}
				{props.helpIcon && (
					<div style={{ paddingLeft: '5px' }}>
						<Tooltip title={props.tooltip}>
							<Help fontSize={'small'} />
						</Tooltip>
					</div>
				)}
			</div>

			<div>
				{switchState ? '(On)' : '(Off)'}
				<Switch
					disabled={props.disabled ? props.disabled : false}
					className={styles.formToggleSwitch}
					checked={switchState}
					onChange={() => setSwitch(!switchState)}
				/>
			</div>
		</div>
	);
}

export default FormToggleSwitch;
