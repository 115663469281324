import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import * as services from 'services';
import { experiments } from 'helpers';

const isV2 = experiments.v2Api(true);

export default function useGetPostFunction() {
	const { feedType } = useParams();
	const root = useSelector(getPostsRoot);
	switch (true) {
		case root === 'archive':
			return services.getSnapshotPostList;
		case root === 'vault':
			return services.getChildVault;
		case isV2 && feedType === 'pending':
			return services.getPendingPostList;
		case isV2 && feedType === 'pendingTL':
			return services.getTimelinePendingPosts;
		case isV2 && feedType === 'pendingNsp':
			return services.getNspPendingPosts;
		case isV2 && feedType === 'services':
			return services.getServicePostList;
		case isV2 && feedType === 'drafts':
			return services.getDrafts;
		case isV2 && feedType === 'nspDrafts':
			return services.getNspDrafts;
		default:
			return services.getPostList;
	}
}
