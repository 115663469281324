import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState, getLifebooksChild } from '../../../redux/selectors';
import { getAge } from '../../../helpers';
import { useCheckUserPermissions } from '../../../helpers/hooks';
import * as services from '../../../services';

const createPhotoArray = async (photos, childId) => {
	let photoArray = [];
	let promiseArray = [];
	photos.forEach((file) => {
		if (!!file?.location) {
			//string is already upload location
			photoArray.push(file.location);
		} else {
			promiseArray.push(services.getLocationAndUpload(file.file, childId));
		}
	});

	let photoLocationArray = await Promise.all(promiseArray);
	photoArray = [...photoArray, ...photoLocationArray];
	return photoArray;
};

const useCreateMetadata = () => {
	const user = useSelector(getUserState);
	const child = useSelector(getLifebooksChild);

	return (reqBody) => {
		const { posted, metadata, postDraftId } = reqBody;
		let reqMetadata = { ...metadata } || {};
		reqMetadata.lastUpdated = new Date();
		reqMetadata.lastUpdatedBy = user.id;
		reqMetadata.lastUpdatedName = `${user.firstname} ${user.lastname.substring(
			0,
			1
		)}`;

		if (!metadata || (metadata && !metadata.created)) {
			const { schoolId, houseId, dob } = child;
			reqMetadata = {
				...reqMetadata,
				age: getAge(dob, posted),
				created: new Date(),
				createdBy: user.id,
				createdName: `${user.firstname} ${user.lastname.substring(0, 1)}`,
			};
			if (schoolId) {
				reqMetadata.schoolId = schoolId;
			}
			if (houseId) {
				reqMetadata.houseId = houseId;
			}
		}

		if (postDraftId) {
			if (reqMetadata.noteDraft) {
				delete reqMetadata.noteDraft;
			}
			reqMetadata.postDraftId = postDraftId;
			reqBody.metadata = reqMetadata;
		}

		for (let key in reqMetadata) {
			if (!reqMetadata[key]) {
				delete reqMetadata[key];
			}
		}
		return reqMetadata;
	};
};

export default function useCreateBody() {
	const { childId, postId } = useParams();
	const { includeUsers } = useCheckUserPermissions();
	const createMetaData = useCreateMetadata();

	return async (postState) => {
		const {
			category,
			title,
			body,
			relations,
			servicesCategory,
			photos,
			nsp,
			nspId,
			change,
			postDraftId,
		} = postState;

		const reqBody = {
			childId,
			approved: includeUsers('agencyuser'),
			category,
			metadata: createMetaData(postState),
			title,
			body,
			relations,
		};

		if (photos) {
			reqBody.photos = await createPhotoArray(photos, childId);
		}

		if (postDraftId) {
			reqBody.postDraftId = postDraftId;
		}

		if (nsp || nspId) {
			reqBody.nsp = true;
			reqBody.nspId = nspId;
			reqBody.servicesCategory = servicesCategory;
		}

		for (let key in change) {
			reqBody[key] = postState[key];
		}
		if (postId) {
			reqBody.postId = postId;
		}

		return reqBody;
	};
}
