import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenType, experiments, mixpanelInit } from '../';
import { useCreateDispatches } from './useCreateDispatches';
import { useQuery } from './useQuery';
import { initializeUser, logoutUser } from 'services';

export function useAppInitialize() {
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();
	const query = useQuery();
	const { setLoader } = useCreateDispatches();

	const loadingFalse = () => {
		setLoader(false);
		setLoading(false);
	};

	const storageChange = (e) => {
		if (e.key === 'token') {
			if (
				!localStorage.getItem('token') ||
				(!experiments.v2Api(true) && !localStorage.getItem('session'))
			) {
				logoutUser();
			}
		}
	};

	const initialize = () => {
		const { token: queryToken } = query || {};
		if (queryToken && !pathname.includes('/verify')) {
			localStorage.setItem('token', queryToken);
		}
		const storageToken = localStorage.getItem('token');
		if (storageToken) {
			const tokenType = getTokenType(storageToken);
			if (tokenType === 'default') {
				setLoader(true);
				initializeUser(null, loadingFalse);
			} else {
				loadingFalse();
				// NOTE: This line fixes the routing if a user loads a page
				// that is invalid for the token type
				if (!pathname?.startsWith(`/${tokenType}`)) {
					localStorage.removeItem('token');
					localStorage.removeItem('originalUser');
					localStorage.removeItem('eroa');
				}
			}
		} else {
			loadingFalse();
		}
	};

	useEffect(() => {
		initialize();
		mixpanelInit();
		window.addEventListener('storage', storageChange);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading };
}
