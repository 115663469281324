import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getHouse } from 'services';
import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useManageHouseState() {
	const { clearAddress, setAddress, setLoader, setSnackbar } =
		useCreateDispatches();

	const { houseId } = useParams();

	const name = useFormInput('House Name', '', { required: true });

	const [pageTitle, setPageTitle] = useState('Add Household');
	const [assignedParentList, setAssignedParentList] = useState([]);
	const [assignedParentListLoaded, setAssignedParentListLoaded] =
		useState(false);

	useEffect(() => {
		clearAddress();

		const setHouse = async () => {
			setLoader(true);
			await getHouse(houseId)
				.then(({ data }) => {
					const { name: dataName, resourceParents, address } = data || {};
					name.onChange({ value: dataName });
					dataName && setPageTitle(`${dataName} Household`);
					setAssignedParentList(resourceParents);
					setAssignedParentListLoaded(true);
					setAddress(address || {});
				})
				.catch((err) => {
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					);
				})
				.finally(() => {
					setLoader(false);
				});
		};
		if (houseId) {
			setHouse();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [houseId]);

	return { name, pageTitle, assignedParentList, assignedParentListLoaded };
}
