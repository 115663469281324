export const supportSectionTitles = {
	video: 'Videos',
};

export const videoData = (userType, userRole) => {
	if (userType === 'agencyuser') {
		if (userRole === 'admin') {
			return {
				id: 'admin',
				title: 'Support For Admin Users',
			};
		} else if (userRole === 'manager') {
			return {
				id: 'manager',
				title: 'Support For Manager Users',
			};
		} else {
			return {
				id: 'socialWorker',
				title: 'Support For Social Worker Users',
			};
		}
	}

	if (userType === 'contributor') {
		if (userRole === 'resourceParent') {
			return {
				id: 'resourceParent',
				title: 'Support For Resource Parents',
			};
		} else {
			return {
				id: 'contributor',
				title: 'Support For Contributors',
			};
		}
	}

	if (userType === 'casauser') {
		if (userRole === 'admin') {
			return {
				id: 'casaAdmin',
				title: 'Support For Casa Admin',
			};
		} else {
			return {
				id: 'casaMentor',
				title: 'Support For Casa Mentor',
			};
		}
	}

	if (userType === 'child') {
		return {
			id: 'child',
			title: 'Support For Children',
		};
	}
};

export const manualData = (userType, userRole) => {
	// AGENCY USERS //
	if (userType === 'agencyuser') {
		if (userRole === 'admin') {
			return {
				id: 'adminManual',
				title: 'User Manual',
				user: 'agency_user_admin.pdf',
			};
		}
		if (userRole === 'manager') {
			return {
				id: 'managerManual',
				title: 'User Manual',
				user: 'agency_user_manager.pdf',
			};
		} else {
			return {
				id: 'socialWorkerManual',
				title: 'Social Worker Manual',
				user: 'agency_user_social_worker.pdf',
			};
		}
	}

	if (userType === 'contributor') {
		return {
			id: 'contributorManual',
			user: 'contributor.pdf',
			title: 'User Manual',
		};
	}

	if (userType === 'child') {
		return {
			id: 'childManual',
			user: 'teen.pdf',
			title: 'User Manual',
		};
	}
	// CASA USERS //
	if (userType === 'casauser') {
		if (userRole === 'admin') {
			return {
				id: 'casaAdminManual',
				user: 'casa_admin.pdf',
				title: 'User Manual',
			};
		} else {
			return {
				id: 'casaMentorManual',
				user: 'casa_mentor.pdf',
				title: 'User Manual',
			};
		}
	}

	return;
};
