import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from '../..';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {
	const { divider, dividerDark } = theme.palette?.primary || {};
	return {
		formSectionHeader: {
			height: '72px',
			width: '100%',
			maxWidth: theme.width.contentMax,
			minWidth: theme.width.contentMin,
			margin: '8px 0 8px 0',
			padding: '24px 0 8px 0',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			fontSize: '14px',
			position: 'relative',
		},
		top: {
			borderTop: `1px solid ${dividerDark || divider}`,
		},
		bottom: {
			borderBottom: `1px solid ${dividerDark || divider}`,
		},
	};
});

const SectionHeader = (props) => {
	const { children, onClick, buttonLabel, top, hideBottom, className } = props;
	const classes = useStyles();
	const hasTop = top ? classes.top : null;
	const bottom = hideBottom ? null : classes.bottom;
	const isString = typeof children === 'string';

	return (
		<div className={clsx(classes.formSectionHeader, hasTop, bottom, className)}>
			{isString ? children.toUpperCase() : children}
			{onClick ? (
				<SecondaryButton
					style={{ position: 'absolute', right: '0' }}
					onClick={onClick}>
					{buttonLabel}
				</SecondaryButton>
			) : null}
		</div>
	);
};

export default SectionHeader;
