import { useEffect, useState, useCallback } from 'react';
import { withRouter, Switch, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as services from 'services';
import * as helper from 'helpers';
import { SendInvites } from 'components';
import { BranchListContainer, ManageBranch, ManageAgency } from './components';
import styles from './branches.module.css';

function Agency(props) {
	const {
		match: { url },
	} = props;
	const { branchId } = useParams();

	const [invite, setInvite] = useState(false);
	const [branchList, setBranchList] = useState([]);

	helper.useMobileListCheck(url === '/agencies/');

	const getList = useCallback(async () => {
		services.getBranchList().then((result) => {
			setBranchList(helper.listSortOrganizationByName(result.data || []));
		});
		services.getAgencyUsers();
	}, []);

	useEffect(() => {
		getList();
	}, [getList]);

	return (
		<div className={styles.agencyFullContainer}>
			<BranchListContainer setInvite={setInvite} />
			<div className={styles.agencySwitchContainer}>
				<Switch>
					<Route
						path='/agencies/manage/:branchId?'
						key='manageBranch'
						authorizedRole={['admin']}
						fallBackRoute='/agencies/'>
						<ManageBranch key={`branch-${branchId}`} />
					</Route>
					<Route path='/agencies/:branchId'></Route>
					<Route path='/agencies/'>
						<ManageAgency />
					</Route>
				</Switch>
				{invite ? (
					<div className={styles.agencyContainer}>
						<SendInvites
							onClose={() => setInvite(false)}
							type={invite.type}
							initialBranch={invite.initialValue}
							branchList={branchList}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

const mapStateToProps = ({ user }) => {
	return { agency: user.agency };
};

export default connect(mapStateToProps, {})(withRouter(Agency));
