import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getAgencySWUsers } from 'redux/selectors';
import { getRelationsList } from 'services';
import { listSortPersonByName, errors } from 'helpers';
import { useSelectorListState, useCreateDispatches } from 'helpers/hooks';
import useManageChildPermissions from './useManageChildPermissions';

export default function useManageChildSWState() {
	const { childId } = useParams();
	const permissions = useManageChildPermissions();
	const [loaded, setLoaded] = useState(false);
	const [assignedSocialWorkers, setAssignedSocialWorkers] = useState([]);
	const socialWorkerList = useSelector(getAgencySWUsers);
	const { setSnackbarError, setLoader } = useCreateDispatches();

	const renderList = childId && !!permissions['sw'];

	useEffect(() => {
		const setSWRelations = async () => {
			setLoader(true);
			setLoaded(false);
			try {
				const { data } = await getRelationsList(childId, 'social-worker');
				setAssignedSocialWorkers(listSortPersonByName(data));
			} catch (err) {
				setSnackbarError(
					{ response: err.response, ...errors.MANAGE_CHILD_SW_REL },
					{ log: true }
				);
			}
			setLoaded(true);
			setLoader(false);
		};

		if (childId && renderList) {
			setSWRelations();
		} else {
			setAssignedSocialWorkers([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	return useSelectorListState(socialWorkerList, assignedSocialWorkers, {
		loaded,
	});
}
