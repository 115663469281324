import { useState } from 'react';
import VaultHeader from './VaultHeader';
import VaultFileOther from './VaultFileOther';
import AddVaultFiles from './AddVaultFiles';
import styles from '../vault.module.css';

const VaultFileItems = (props) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={styles.vaultFileItems}>
			<VaultHeader label={'Other'} open={open} setOpen={setOpen} />
			{open && (
				<>
					<AddVaultFiles />
					{props?.files?.length > 0 && (
						<div className={styles.mappedFiles}>
							{props.files.map((file) => {
								return <VaultFileOther key={file.id} file={file} />;
							})}
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default VaultFileItems;
