import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/index';
import { getChildVaultFiles } from 'services';
import { PageLayout, LifebookNav } from 'components';
import VaultFile from './VaultFile';
import VaultFileItems from './VaultFileItems';
import styles from '../vault.module.css';

const VaultFiles = () => {
	const dispatch = useDispatch();
	const childId = useSelector((state) => state.lifebooks.child.id);
	const bcFile = useSelector((state) => state.vault.bcFile);
	const ssFile = useSelector((state) => state.vault.ssFile);
	const idFile = useSelector((state) => state.vault.idFile);
	const otherFiles = useSelector((state) => state.vault.otherFiles);

	useEffect(() => {
		if (childId) {
			dispatch(setLoader(true));
			getChildVaultFiles(childId)
				.then(() => {
					dispatch(setLoader(false));
				})
				.catch(() => {
					dispatch(setLoader(false));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	return (
		<PageLayout title={'Vault Files'} footer={<LifebookNav />}>
			<div className={styles.fileFullContainer}>
				<VaultFile file={bcFile} label={'Birth Certificate'} />
				<VaultFile file={ssFile} label={'Social Security'} />
				<VaultFile file={idFile} label={'ID Card'} />
				<VaultFileItems files={otherFiles} />
			</div>
		</PageLayout>
	);
};

export default VaultFiles;
