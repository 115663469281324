import { useState } from 'react';
import { connect } from 'react-redux';
import { setMobileList } from 'redux/index';
import * as services from 'services';
import * as Comp from 'components';

const InviteCasa = (props) => {
	const [email, setEmail] = useState('');

	const requestConnection = async () => {
		await services.requestCasaConnection(email);
		setEmail('');
	};

	const buttonContainer = [
		<Comp.SecondaryButton key='cancel-casa-invite' onClick={props.selectClose}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='submit-casa-invite' onClick={requestConnection}>
			Invite
		</Comp.PrimaryButton>,
	];

	return (
		<Comp.PageLayout
			title='Invite'
			buttonArray={buttonContainer}
			navBack={props.selectClose}>
			<Comp.SectionHeader>Connect a Casa</Comp.SectionHeader>
			<Comp.TextFieldRow
				variant='outlined'
				label='Casa Admin Email'
				required={true}
				onChange={(e) => setEmail(e.target.value)}
				value={email}
			/>
		</Comp.PageLayout>
	);
};

export default connect(null, { setMobileList })(InviteCasa);
