import { InputAdornment } from '@material-ui/core';
import { StaticTextRow, TextFieldRow } from 'components';
import styles from './ChildBasicInfo.module.css';

const ChildEmail = ({
	status,
	editable,
	reinvite,
	onResend,
	email,
	...rest
}) => {
	return status === 'active' && !editable ? (
		<StaticTextRow {...{ ...rest, label: rest.label + ' (verified)' }} />
	) : (
		<TextFieldRow
			{...rest}
			InputProps={{
				endAdornment: email ? (
					<InputAdornment position='end'>
						<span className={styles.emailInputResendButton} onClick={onResend}>
							{status === 'invited' ? 'Resend Invite' : 'Invite'}
						</span>
					</InputAdornment>
				) : null,
			}}
		/>
	);
};

export default ChildEmail;
