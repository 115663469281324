import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackbar } from 'redux/index';
import { getUserStateId } from 'redux/selectors';
import * as service from 'services';
import { PrimaryButton } from 'components';

export default function InviteSubmitButton({ infoState, handleValidate }) {
	const history = useHistory();
	const userId = useSelector(getUserStateId);
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const dispatchSnackbar = (val) => dispatch(setSnackbar(val));

	const submitProfile = async () => {
		const { firstname, lastname, newPassword, mobile, email } = infoState;
		const profileReqBody = {
			firstname: firstname.value,
			lastname: lastname.value,
			email: email.value,
			password: newPassword.value,
		};

		if (mobile.value) {
			profileReqBody.mobile = mobile.value;
		}
		return await service
			.activateUser(profileReqBody, userId)
			.then(async (res) => {
				const loginReqBody = {
					email: email.value,
					password: newPassword.value,
				};
				return await service.loginEmailUser(loginReqBody).then((res) => {
					service.setHeader(res, email.value).then(() => {
						service.initializeUser('login', loadingFalse);
					});
					return;
				});
			})
			.catch((err) => {
				dispatchSnackbar(
					{
						variant: 'error',
						message:
							'Profile could not be created. Please try again at a later time.',
					},
					{ loggedIn: false }
				);
			})
			.finally(() => {
				dispatchLoader(false);
			});
	};

	const loadingFalse = () => {
		dispatchLoader(false);
		history.push(`/lifebooks`);
	};

	const handleSubmit = async () => {
		dispatchLoader(true);
		if (await handleValidate()) {
			submitProfile();
		} else {
			dispatchLoader(false);
		}
	};

	return <PrimaryButton onClick={handleSubmit}>Create Account</PrimaryButton>;
}
