import { listSortPersonByName, listSortByValues } from '../../helpers';

export const getUserState = ({ user }) => user.user;

export const getUserStateId = ({ user }) => user.user.id;

export const getUserStateType = ({ user }) => user.user.type;

export const getUserStateStatus = ({ user }) => user.user.status;

export const getUserAgency = ({ user }) => user.agency;

export const getAgencyHash = ({ user }) => user.agencyHash;

export const getAgencyUsers = ({ user }) => user.agencyUsers;

export const getAgencyUser = (id) => {
	return ({ user }) => user.agencyHash?.[id] || {};
};

export const getAgencyAdminUsers = ({ user }) => {
	const list = user.agencyUsers?.filter((val) => val.role === 'admin') || [];
	return listSortPersonByName(list, 'lastname', 'email');
};

export const getAgencyAdminHash = ({ user }) => {
	return getAgencyAdminUsers({ user }).reduce((acc, cur) => {
		acc[cur.id] = cur;
		return acc;
	}, {});
};

export const getAgencyManagerUsers = ({ user }) => {
	const list = user.agencyUsers?.filter((val) => val.role === 'manager') || [];
	return listSortPersonByName(list, 'lastname', 'email');
};

export const getAgencyManagerHash = ({ user }) => {
	return getAgencyManagerUsers({ user }).reduce((acc, cur) => {
		acc[cur.id] = cur;
		return acc;
	}, {});
};

export const getAgencySWUsers = ({ user }) => {
	const list =
		user.agencyUsers?.filter((val) => val.role === 'social-worker') || [];
	return listSortPersonByName(list, 'lastname', 'email');
};

export const getAgencySWHash = ({ user }) => {
	return getAgencySWUsers({ user }).reduce((acc, cur) => {
		acc[cur.id] = cur;
		return acc;
	}, {});
};

export const getBranchList = ({ user }) => {
	const { branchList, branchHash } = user;
	const list = branchList?.map((id) => branchHash?.[id]) || [];
	return listSortByValues(list, 'name');
};

export const getBranchHash = ({ user }) => user.branchHash;
