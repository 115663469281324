import { useParams, useLocation, useHistory } from 'react-router-dom';
import { OptionMenu } from 'components';
import styles from '../headers.module.css';

export default function ServiceSwitcherMobile({
	options = [
		{ label: 'Timeline', path: 'timeline' },
		{ label: 'Services', path: 'services' },
	],
}) {
	const { feedType } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();

	let label;
	if (['pendingTL', 'timeline'].includes(feedType)) {
		label = 'Timeline';
	} else if (['pendingNsp', 'services'].includes(feedType)) {
		label = 'Services';
	} else {
		label = 'All';
	}

	const handleClick = (path) => {
		if (feedType !== path) {
			history.replace({ search: '' });
			history.push(pathname.replace(feedType, path));
		}
	};

	const optionsMap = options.map(({ label, path }) => ({
		label,
		onClick: () => handleClick(path),
	}));

	return (
		<OptionMenu
			optionButton
			className={styles.serviceSwitcherMobileRoot}
			menuItems={optionsMap}>
			<div>{label}</div>
		</OptionMenu>
	);
}
