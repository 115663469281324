import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import * as redux from 'redux/selectors';
import { useSelectorListState, useCreateDispatches } from 'helpers/hooks';
import useManageChildPermissions from './useManageChildPermissions';

export default function useManageChildHomeState() {
	const { childId } = useParams();
	const permissions = useManageChildPermissions();
	const [loaded, setLoaded] = useState(false);
	const homesList = useSelector(redux.getHomesHouseList);

	const [assignedHome, setAssignedHome] = useState([]);
	const { house: { id: houseId } = {} } = useSelector(redux.getLifebooksChild);
	const houseHash = useSelector(redux.getHomesHouseHash);
	const { setLoader } = useCreateDispatches();

	const renderList = !!permissions['home'];

	useEffect(() => {
		const setChildHome = async () => {
			setLoader(true);
			setLoaded(false);
			if (houseId && childId) {
				setAssignedHome([houseHash[houseId]]);
			} else {
				setAssignedHome([]);
			}
			setLoaded(true);
			setLoader(false);
		};

		if (renderList) {
			setChildHome();
		} else {
			setAssignedHome([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	return useSelectorListState(homesList, assignedHome, {
		loaded,
		single: true,
	});
}
