import { useSelector } from 'react-redux';
import { PrimaryButton } from 'components';
import { resendExitCare } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import styles from './ChildAdmin.module.css';

export default function ResendExitCare() {
	const child = useSelector((state) => state.lifebooks.child);
	const { setSnackbar, setLoader } = useCreateDispatches();

	const handleResend = () => {
		setLoader(true);
		resendExitCare(child.id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Instructions sent successfully',
				});
				setLoader(false);
			})
			.catch(() => {
				setSnackbar({
					variant: 'error',
					message: 'Sorry please try again later.',
				});
				setLoader(false);
			});
	};

	return (
		<div className={styles.childAdminItemWrapper}>
			<div>
				<div className={styles.childAdminBoldLabel}>
					Resend Exit Care Instructions
				</div>
				<div>Exit care instructions will be sent to the current user</div>
			</div>
			<div className={styles.childAdminButtonContainer}>
				<PrimaryButton onClick={() => handleResend()}>
					Resend Instructions
				</PrimaryButton>
			</div>
		</div>
	);
}
