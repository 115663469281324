import { apiV2 } from '../axios';
import { setExportRequest } from '../redux/reducers/lifebooksReducer';
import { store } from '../redux';

export const listExportRequest = async () => {
	return await apiV2.get(`/export`).then((e) => {
		store.dispatch(setExportRequest(e.data));
	});
};

export const createExportRequest = async (reqBody) => {
	return await apiV2.post(`/export/request`, reqBody);
};

export const approveExportRequest = async (requestId) => {
	await apiV2.post(`/export/${requestId}/approve`).then(() => {
		listExportRequest();
	});
};

export const denyExportRequest = async (requestId) => {
	return await apiV2.post(`/export/${requestId}/deny`).then(() => {
		listExportRequest();
	});
};
