import { useHistory } from 'react-router-dom';
import { logoutUser } from 'services';
import Collapse from '@material-ui/core/Collapse';
import DashboardNavItem from './DashboardNavItem';

const DashboardNavChildren = ({
	subList,
	activePath,
	navigateToPath,
	open,
}) => {
	const history = useHistory();

	let subNavMap =
		subList &&
		Boolean(subList.length) &&
		subList.map((navItem, i) => {
			const { path } = navItem;
			const active = path === activePath;
			const onClick = () => {
				if (path === '/logout/') {
					logoutUser(history);
				} else {
					navigateToPath(path);
				}
			};

			return (
				navItem && (
					<DashboardNavItem
						key={path}
						{...{ ...navItem, icon: null }}
						active={active}
						onClick={onClick}
					/>
				)
			);
		});

	return <Collapse in={open}>{subNavMap}</Collapse>;
};

export default DashboardNavChildren;
