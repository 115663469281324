import { useSelector } from 'react-redux';
import { getHomesHouseHash } from 'redux/selectors';
import { childrenSort } from 'services';
import useMappedChildList from './useMappedChildList';
import { useCheckUserPermissions } from 'helpers/hooks';

export default function useSortMenuOptions() {
	const childList = useMappedChildList();
	const homes = useSelector(getHomesHouseHash);
	const { includeUsers } = useCheckUserPermissions();

	const changeSort = (value, hash) => {
		childrenSort(value, childList, hash);
	};

	const menuItems = [
		{
			value: 'firstname',
			label: 'First Name',
			onClick: () => changeSort('firstname'),
		},
		{
			value: 'lastname',
			label: 'Last Name',
			onClick: () => changeSort('lastname'),
		},
		includeUsers('agencyuser') && {
			value: 'house',
			label: 'Home',
			onClick: () => changeSort('house', homes),
		},
	];

	if (childList?.length <= 1) {
		return null;
	}

	return menuItems;
}
