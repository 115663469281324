import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store, setChild } from 'redux/index';
import { getNameTile } from '../utils';
import CasaSelectListTile from './CasaSelectListTile';
import RemoveChildButton from './RemoveChildButton';

const AssignedChildren = (props) => {
	const user = useSelector((state) => state.user.user);
	const { push } = useHistory();

	const toChildProfile = (child) => {
		store.dispatch(setChild(child));
		let pathName;

		if (user.type === 'casauser') {
			pathName = `/casaUsers/child/${child.id}`;
		} else {
			pathName = `/casaAgency/child/${child.id}`;
		}

		push({
			pathname: pathName,
			state: { child: child, userId: props.user?.id },
		});
	};

	const Tile = (e) => {
		const child = e.child;
		const childName = getNameTile(child);

		return (
			<div
				style={{
					cursor: 'pointer',
					backgroundColor: 'white',
				}}
				onClick={() => toChildProfile(child)}>
				<CasaSelectListTile value={child}>{childName}</CasaSelectListTile>
				{user.type === 'casauser' && user.role === 'admin' && (
					<RemoveChildButton child={child} userId={props.userId} />
				)}
			</div>
		);
	};

	const tiles = props.assignedChildren?.map((e) => (
		<Tile child={e} key={e.id} />
	));

	return <div>{tiles}</div>;
};

export default AssignedChildren;
