import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIsMd, getPostsRoot } from 'redux/selectors';
import MobileHeader from './MobileHeader';
import TimelineHeader from './TimelineHeader';
import ActivityHeader from './ActivityHeader';
import DraftsHeader from './DraftsHeader';
import PendingHeader from './PendingHeader';
import DefaultHeader from '../UtilityComponents/PageLayout/components/DefaultHeader';

export function LifebookHeader() {
	const { feedType } = useParams();
	const isMd = useSelector(getIsMd);

	const root = useSelector(getPostsRoot);

	const getHeaderComponent = () => {
		switch (feedType) {
			case feedType?.match('(a|profileA|exportA)ctivity')?.input:
				return <ActivityHeader />;
			case feedType?.match('(pending(|Nsp|TL)|exports)')?.input:
				return <PendingHeader />;
			case 'drafts':
			case 'nspDrafts':
				return <DraftsHeader />;
			case 'services':
			case 'timeline':
				return <TimelineHeader />;
			default:
				return <DefaultHeader />;
		}
	};

	const headerComponent =
		root === 'vault' ? <TimelineHeader /> : getHeaderComponent();

	return (
		<>
			{isMd && <MobileHeader />}
			{headerComponent}
		</>
	);
}

export default LifebookHeader;
