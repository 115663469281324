import { DeleteButton, SecondaryButton } from 'components';
import Modal from 'react-modal';
import { modalStyleConfirm } from '../calendarModalStyle';
import styles from '../calendar.module.css';

const EventDeleteConfirm = (props) => {
	return (
		<Modal
			ariaHideApp={false}
			style={modalStyleConfirm}
			isOpen={props.confirmDelete}
			transparent={false}>
			<div className={styles.fullConfirmContainer}>
				<div className={styles.confirmHeader}>
					Are you sure you want to delete?
				</div>
				<div className={styles.confirmButtonContainer}>
					<DeleteButton onClick={props.handleDelete}>Delete</DeleteButton>
					<SecondaryButton onClick={props.handleClose}>Close</SecondaryButton>
				</div>
			</div>
		</Modal>
	);
};

export default EventDeleteConfirm;
