import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from 'redux/index';
import { getPostsPosts } from 'redux/selectors';
import { errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import useGetPostFunction from './useGetPostFunction';

export default function useFetchPosts() {
	const posts = useSelector(getPostsPosts);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));

	const { childId, feedType } = useParams();
	const { search } = useLocation();
	const [lastPostId, setLastPostId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const { setSnackbarError, addpostList, clearPostList, setPostList } =
		useCreateDispatches();
	const fetchPostsFunction = useGetPostFunction();

	const errorHandler =
		(error) =>
		({ response }) => {
			setSnackbarError({ response, ...error }, { log: true });
		};

	useEffect(() => {
		let isMounted = true;

		if (childId) {
			setLastPostId(null);
			clearPostList();
			dispatchLoader(true);
			setIsLoading(true);
			let query;
			if (search.includes('endDate') || search.includes('startDate')) {
				query = search;
			} else {
				query = lastPostId ? search + `&lastPostId=${lastPostId}` : search;
			}
			const fetchPosts = async () => {
				if (
					user.type === 'child' &&
					window?.location?.pathname?.includes('vault')
				) {
					const today = new Date().getTime();
					const vaultAccessDate = new Date(user.vaultAccessDate).getTime();
					if (today < vaultAccessDate) {
						setPostList([]);
						setLastPostId(null);
						dispatchLoader(false);
						setIsLoading(false);
						return;
					}
				}
				await fetchPostsFunction(childId, query, feedType)
					.then(({ data }) => {
						if (isMounted) {
							setPostList(data.posts);
							setLastPostId(data.lastPostId || null);
						}
					})
					.catch(errorHandler(errors.LOAD_POSTS))
					.finally(() => {
						if (isMounted) {
							dispatchLoader(false);
							setIsLoading(false);
						}
					});
			};
			if (feedType !== 'exports') {
				fetchPosts();
			} else {
				setLastPostId(null);
				clearPostList();
				setIsLoading(false);
				dispatchLoader(false);
			}

			return () => {
				isMounted = false;
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId, feedType, search]);

	const fetchPosts = async (param) => {
		dispatchLoader(true);
		setIsLoading(true);
		const query = lastPostId ? search + `&lastPostId=${lastPostId}` : search;
		await fetchPostsFunction(childId, query)
			.then(({ data }) => {
				param === 'set' ? setPostList(data.posts) : addpostList(data.posts);
				setLastPostId(data.lastPostId || null);
			})
			.catch(errorHandler(errors.LAZY_LOAD_POSTS))
			.finally(() => {
				setIsLoading(false);
				dispatchLoader(false);
			});
	};

	const handleScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		// if no more posts or still loading, do not load more
		if (
			posts &&
			posts.length &&
			lastPostId &&
			!isLoading &&
			scrollTop >= scrollHeight - clientHeight - 5
		) {
			fetchPosts();
		}
	};

	return { lazyLoadHandler: handleScroll, isLoading };
}
