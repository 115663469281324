import useManageChildPermissions from '../utils/useManageChildPermissions';
import { AddImages, SectionHeader } from 'components';
import styles from '../child.module.css';

const ChildImage = (props) => {
	const { file, photo, setState, deletePhoto, photos, ownershipCodeVerified } =
		props;

	const { image: permission } = useManageChildPermissions();

	const canEdit = permission === 'edit';

	if (!canEdit && !photo) return null;

	return (
		<div className={styles.childImageRoot}>
			<div className={styles.childImageContent}>
				<SectionHeader>Profile Photo</SectionHeader>
				<AddImages
					setState={setState}
					file={file}
					photos={(photo ? [photo] : photos) || []}
					deletePhoto={deletePhoto}
					disable={!canEdit || ownershipCodeVerified}
					single
				/>
			</div>
		</div>
	);
};

export default ChildImage;
