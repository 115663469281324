import styles from '../contributor.module.css';

const RelationshipTile = (props) => {
	const { children } = props;

	return children || children?.[0] ? (
		<>
			<div className={styles.contributorListTile}>
				<div className={styles.contributorNoAvatar} />
				<div>{children}</div>
			</div>
		</>
	) : null;
};

export default RelationshipTile;
