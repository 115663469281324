import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getContributorContributor } from 'redux/selectors';
import * as service from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { getRelationId } from './contributorSubmit';
import { handleContributorValidation } from 'helpers/validation/contributorValidation';
import { PrimaryButton, SecondaryButton } from 'components';

function CancelButton() {
	const history = useHistory();
	const onClick = () => history.goBack();

	return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
}

function SaveButton({ getChildIds }) {
	const { setLoader, setSnackbar } = useCreateDispatches();
	const history = useHistory();
	const { contributorId } = useParams();
	const reduxContributor = useSelector(getContributorContributor);
	const setContributorList = service.useGetContributorList();

	const isEdit = !!contributorId;

	const getText = () => {
		return !isEdit ? 'Save Contributor' : 'Save Changes';
	};

	const errorHandler = (err) => {
		setSnackbar(
			{ variant: 'error', message: err.response?.data.message },
			{ log: true }
		);
		setLoader(false);
	};

	const contributorRequest = async (contributor) => {
		if (!isEdit) {
			contributor.childIds = [...(contributor.childIdsToAdd || [])];
			delete contributor.childIdsToAdd;
			delete contributor.childIdsToRemove;
		}

		return await (isEdit
			? service.updateContributor(contributor, contributorId)
			: service.addContributor(contributor));
	};

	const onClick = async () => {
		const { firstname, lastname, email, relationships } = reduxContributor;
		const { childIdsToAdd, childIdsToRemove } = getChildIds();

		const validation = handleContributorValidation(
			relationships,
			childIdsToAdd,
			isEdit,
			firstname,
			lastname,
			email,
			reduxContributor.children
		);

		if (!validation.isValid) {
			setSnackbar({
				variant: 'error',
				message: validation.message,
			});
			return;
		}

		setLoader(true);
		await contributorRequest({
			firstname,
			lastname,
			email,
			childIdsToAdd,
			childIdsToRemove,
		})
			.then(async (res) => {
				const { id } = res.data || {};

				if (relationships.length > 0) {
					service.getRelationsList(id, 'contributor').then((e) => {
						relationships.forEach(async (relationship) => {
							const relationId = getRelationId(relationship, e);
							await service.updateRelationship(
								relationId,
								relationship.relationship
							);
						});
					});
				}
				if (!isEdit) {
					await setContributorList();
				}
				setLoader(false);
				setSnackbar({
					variant: 'success',
					message: 'Success',
				});
				if (!isEdit && id) {
					history.push(`/contributors/manage/${id}`);
				} else {
					return res.data;
				}
			})
			.catch(errorHandler);
	};

	return <PrimaryButton onClick={onClick}>{getText()}</PrimaryButton>;
}

export const useButtonArray = (getChildIds) => {
	return [
		<CancelButton key='cancel' />,
		<SaveButton getChildIds={getChildIds} key='save' />,
	];
};
