import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store, setCasaPermission, setAllCasaPermissions } from 'redux/index';
import * as services from 'services';
import { casaData, createCasaPermissionObject } from '../../utils';
import Checkbox from '@material-ui/core/Checkbox';
import { FormToggleSwitch, FormSectionHeader } from 'components';
import styles from '../../casa.module.css';

const CasaPermissions = () => {
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const child = useSelector((state) => state.lifebooks.child);
	const permissions = casaData(casaPermissions);

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		loadPermissions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadPermissions = async () => {
		if (child.casaId) {
			await services.getCasaPermissions(child.casaId, child.id);
		}
	};

	const handleAllToggle = () => {
		setChecked(!checked);
		let updatedPermissions = { ...casaPermissions };

		if (checked) {
			Object.keys(updatedPermissions).forEach(
				(v) => (updatedPermissions[v] = false)
			);
		} else {
			Object.keys(updatedPermissions).forEach(
				(v) => (updatedPermissions[v] = true)
			);
		}
		store.dispatch(setAllCasaPermissions(updatedPermissions));
	};

	const handleSingleToggle = (value, index, reqKey) => {
		const permissions = createCasaPermissionObject(reqKey, !value);
		store.dispatch(setCasaPermission(permissions));
	};

	const toggleMapper = ({ value, label, tooltip, reqKey }, i) => {
		return (
			<FormToggleSwitch
				tooltip={tooltip}
				helpIcon={true}
				key={`${i}-${value}`}
				switchState={value}
				setSwitch={() => handleSingleToggle(value, i, reqKey)}>
				{label}
			</FormToggleSwitch>
		);
	};

	return (
		<div className={styles.notificationsToggleInfoContainer}>
			<FormSectionHeader>
				<div className={styles.notificationToggleAll}>
					<div>Casa Permissions</div>
					<div className={styles.notificationsToggleCheck}>
						All
						<Checkbox checked={checked} onChange={handleAllToggle} />
					</div>
				</div>
			</FormSectionHeader>
			{permissions?.map(toggleMapper)}
		</div>
	);
};

export default CasaPermissions;
