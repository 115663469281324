import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyAdminUsers } from 'redux/selectors';
import { UserList, SlideContainer } from 'components';

export default function AdminListContainer({ setInvite }) {
	const { push } = useHistory();
	const adminList = useSelector(getAgencyAdminUsers);

	const adminListMap = adminList?.map((val) => {
		const { firstname, lastname, email } = val;
		const hasName = firstname && lastname;
		const name = hasName ? `${val.firstname} ${val.lastname}` : email;
		return { ...val, name };
	});

	const selectAdmin = (e, sw) => {
		e.stopPropagation();
		setInvite(false);
		push({ pathname: `/admins/manage/${sw.id}`, state: sw });
	};

	const onAddAdmin = () => {
		setInvite({ type: 'admin' });
	};

	return (
		<SlideContainer>
			<UserList
				list={adminListMap}
				label='Admins'
				pathParam='adminId'
				handleSelect={selectAdmin}
				addToggle={onAddAdmin}
				addLabel='Add a Admin'
			/>
		</SlideContainer>
	);
}
