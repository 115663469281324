import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuditors } from 'services';
import useManageChildPermissions from '../../utils/useManageChildPermissions';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import * as AuditComp from '.';
import { SectionHeader } from 'components';
import styles from './childAudit.module.css';

export default function ChildAudit() {
	const { childId } = useParams();
	const permissions = useManageChildPermissions();

	const [openInvite, setOpenInvite] = useState(false);
	const [auditorList, setAuditorList] = useState([]);
	const [expanded, setExpanded] = useState(false);

	const fetchAuditors = async (childId) => {
		if (!childId) return;
		return await getAuditors(childId).then(({ data }) => {
			setAuditorList(data);
		});
	};

	const allowEdit = permissions.audit === 'edit';

	useEffect(() => {
		if (childId && allowEdit) {
			fetchAuditors(childId);
		}
	}, [childId, allowEdit]);

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	return childId && allowEdit ? (
		<div className={styles.manageChildAuditRoot}>
			<SectionHeader hideTop>
				<div className={styles.basicHeader}>
					<div className={styles.headerText}>AUDITOR ACCESS</div>
					{expanded ? (
						<ExpandLess onClick={() => handleOpenClose()} />
					) : (
						<ExpandMore onClick={() => handleOpenClose()} />
					)}
				</div>
			</SectionHeader>
			{expanded && (
				<>
					<div className={styles.manageChildAuditRow}>
						<div>
							<div className={styles.manageChildAuditLabel}>
								Create Audit Link
							</div>
							<div>
								Use this option to send an audit link for an auditable version
								of the LifeBook.
							</div>
						</div>
						<div className={styles.manageChildAuditButton}>
							<AuditComp.AuditInviteButton
								openInvite={openInvite}
								onOpenInvite={() => {
									setOpenInvite(!openInvite);
								}}
							/>
						</div>
					</div>
					{openInvite && (
						<AuditComp.AuditInviteForm
							childId={childId}
							fetchAuditors={() => fetchAuditors(childId)}
						/>
					)}
					{auditorList.length > 0 && (
						<AuditComp.AuditorList
							auditorList={auditorList}
							setAuditorList={setAuditorList}
						/>
					)}
				</>
			)}
		</div>
	) : null;
}
