import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Switch,
	Route,
	useParams,
	useLocation,
	useHistory,
} from 'react-router-dom';
import { useGetContainerWidth, useCreateDispatches } from 'helpers/hooks';
import { useMobileListCheck } from 'helpers';
import { CalendarActivity, Welcome } from 'components';
import ViewCalendar from './components/ViewCalendar';
import CalendarListContainer from './components/CalendarListContainer';
import styles from './calendar.module.css';

const Calendar = (props) => {
	const { fullScreen } = props;
	const { user } = useSelector(({ user }) => user);
	const params = useParams();
	const { childId, feedType } = params;
	const { pathname } = useLocation();
	const mediaQuery = useSelector((state) => state.mediaQuery);
	const { push } = useHistory();

	useMobileListCheck(pathname === '/calendar/');
	const { setRoot } = useCreateDispatches();

	const { ref: rightContentRef } = useGetContainerWidth();

	useEffect(() => {
		if (user.type === 'child') {
			push(`/calendar/${user.id}`);
		}
	}, []);

	useEffect(() => {
		setRoot('calendar');
	}, [setRoot]);

	return (
		<div
			className={styles.calendarContainer}
			style={mediaQuery.mobile ? mediaQuery.height : {}}>
			{user.type !== 'child' && <CalendarListContainer />}
			<div
				ref={rightContentRef}
				className={[
					styles.calendarRightContent,
					fullScreen && styles.calendarRightFull,
				].join(' ')}
				style={mediaQuery.mobile ? mediaQuery.height : {}}>
				<div className={styles.calendarContent}>
					<Switch>
						<Route key={childId} path='/calendar/:childId'>
							<ViewCalendar />
						</Route>
						<Route key={childId + feedType} path='/calendarActivity/:childId/'>
							<CalendarActivity />
						</Route>
						<Route path='/'>
							<Welcome user={user} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default Calendar;
