import ExportButtons from './ExportButtons';
import ExportRequester from './ExportRequester';
import ExportRequestFor from './ExportRequestFor';
import ExportDetails from './ExportDetails';
import styles from '../exportRequest.module.css';

const ExportContainer = (props) => {
	return (
		<div className={styles.exportContainer}>
			<div className={styles.leftContainer}>
				<ExportRequester request={props.request} />
			</div>
			<div className={styles.leftContainer}>
				<ExportRequestFor request={props.request} />
			</div>
			<div className={styles.middleContainer}>
				<ExportDetails request={props.request} />
			</div>
			<div className={styles.rightContainer}>
				<ExportButtons request={props.request} />
			</div>
		</div>
	);
};

export default ExportContainer;
