import { SelectorList } from 'components';

export default function HouseChildrenList({ selectorListState, disable }) {
	const linkTo = '/lifebooks/manage/';
	return (
		<SelectorList
			label='CHILDREN IN HOME'
			textFieldLabel='Child'
			linkTo={linkTo}
			disable={disable}
			{...selectorListState}
		/>
	);
}
