import { useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import {
	SquareIconButton,
	SecondaryButton,
	DeleteButton,
	OptionButton,
} from 'components';
import { Menu, MenuItem } from '@material-ui/core';

const style = (theme) => ({
	optionMenuRoot: {
		maxHeight: '700px',
		[theme.breakpoints.down('sm')]: {
			maxHeight: '500px',
		},
	},
	optionMenuParentMenuItem: {
		fontWeight: 'bolder',
		borderTop: '1px solid black',
		color: 'black',
	},
	optionMenuComponentItem: {
		height: 'min-content',
	},
	label: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
});

const useStyles = makeStyles(style);

export default function OptionMenu(props) {
	const selected = useSelector((state) => state.lifebooks.childSort);
	const classes = useStyles();
	const {
		menuItems,
		menuAlign = 'left',
		Icon,
		square,
		disabled,
		deleteButton,
		secondary,
		optionButton,
		divButton,
		style,
		className = '',
		paperStyles,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		e?.stopPropagation();
		setAnchorEl(null);
	};

	const menuItemsMap =
		menuItems &&
		menuItems.map((val, i) => {
			const { value, label, onClick, disabled, isParent } = val || {};

			return val ? (
				val.component ? (
					<div
						key={`${i}-${value}`}
						className={classes.optionMenuComponentItem}>
						{val.component(handleClose)}
					</div>
				) : (
					<MenuItem
						className={clsx(
							`${className || 'option-menu'}-item`,
							isParent ? classes.optionMenuParentMenuItem : null
						)}
						key={`${i}-${value}`}
						value={value}
						disabled={disabled}
						onClick={(e) => {
							handleClose(e);
							onClick();
						}}>
						<div className={value === selected ? classes.label : null}>
							{label} {value === selected ? <Check /> : null}
						</div>
					</MenuItem>
				)
			) : null;
		});

	const Button = square
		? SquareIconButton
		: deleteButton
		? DeleteButton
		: secondary
		? SecondaryButton
		: optionButton
		? OptionButton
		: IconButton;

	const button = divButton ? (
		<div
			disabled={disabled}
			onClick={handleClick}
			style={style}
			className={className}>
			{Icon ? <Icon /> : null}
			{props.children}
		</div>
	) : (
		<Button disabled={disabled} onClick={handleClick}>
			{Icon ? <Icon /> : null}
			{props.children}
		</Button>
	);

	return (
		<div className={clsx('select-list-tile-menu option-menu', className)}>
			{button}
			<Menu
				anchorEl={anchorEl}
				id='tile-menu'
				className={classes.optionMenuRoot}
				keepMounted
				open={open}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: menuAlign }}
				transformOrigin={{ vertical: 'top', horizontal: menuAlign }}
				onClose={handleClose}
				PaperProps={{
					style: {
						width: 200,
						...paperStyles,
					},
				}}>
				{menuItemsMap}
			</Menu>
		</div>
	);
}
