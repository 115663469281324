import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/index';
import { passwordReset } from 'services';
import { useFormInput } from 'helpers';
import { PasswordField, PrimaryButton } from 'components';
import styles from '../login.module.css';

export function PasswordReset(props) {
	const query = queryString.parse(props.location.search);
	const newPassword = useFormInput('New Password', '');
	const confirmPW = useFormInput('Confirm Password', '');
	confirmPW.onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};

	const handleValidate = () => {
		let validationError = '';
		if (newPassword.value !== confirmPW.value) {
			validationError = 'Passwords must match.';
		}
		if (!newPassword.value) {
			validationError = 'New Password is required.';
		}

		if (!validationError) {
			return true;
		} else {
			props.setSnackbar(
				{ variant: 'error', message: validationError },
				{
					loggedIn: false,
				}
			);
			return false;
		}
	};
	const onSubmit = async () => {
		if (handleValidate()) {
			let response = await passwordReset(newPassword.value, query.token);
			if (response) {
				props.setSnackbar(
					{
						variant: 'success',
						message: 'Password reset successful! Redirected to login',
					},
					{
						loggedIn: false,
					}
				);
				props.history.push('/login/');
			}
		}
	};

	return (
		<div className={styles.loginRoot}>
			<div className={styles.loginContent}>
				<div className={styles.loginTitle}>Password Reset</div>
				<div className={styles.loginForm}>
					<PasswordField
						className={styles.loginInput}
						label='New Password'
						{...newPassword}
					/>
					<PasswordField
						className={styles.loginInput}
						label='Confirm Password'
						{...confirmPW}
						margin='normal'
					/>
				</div>
			</div>
			<div className={styles.loginButtonContainer}>
				<PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
			</div>
		</div>
	);
}

export default withRouter(connect(null, { setSnackbar })(PasswordReset));
