import { useSelector } from 'react-redux';
import { getIsMd } from 'redux/selectors';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { TextFieldRow } from 'components';

const DatePickerRow = ({
	label,
	value,
	name,
	required,
	onChange,
	className,
	maxDate,
	maxDateMessage,
	minDate,
	minDateMessage,
	disableFuture = true,
	disabled,
}) => {
	const isMd = useSelector(getIsMd);
	const minMaxProps = {};
	if (maxDate) {
		minMaxProps.maxDate = maxDate;
		minMaxProps.maxDateMessage = maxDateMessage || '';
	} else {
		delete minMaxProps.maxDate;
		delete minMaxProps.maxDateMessage;
	}

	if (minDate) {
		minMaxProps.minDate = minDate;
		minMaxProps.minDateMessage = minDateMessage || '';
	} else {
		delete minMaxProps.minDate;
		delete minMaxProps.minDateMessage;
	}

	return (
		<TextFieldRow required={true} label={label}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					className={className}
					inputVariant='outlined'
					format='MM/dd/yyyy'
					margin='normal'
					fullWidth={true}
					allowKeyboardControl={true}
					id={`date-picker-inline-${name}`}
					disableFuture={disableFuture}
					required={required}
					maxDateMessage='Date can not be after current date.'
					emptyLabel='No Date Selected'
					value={value}
					name={name}
					label={isMd && label ? label : null}
					onChange={onChange}
					disabled={disabled}
					InputAdornmentProps={{
						position: 'start',
					}}
					{...minMaxProps}
				/>
			</MuiPickersUtilsProvider>
		</TextFieldRow>
	);
};

export default DatePickerRow;
