import mixpanel from 'mixpanel-browser';
import { experiments } from '../helpers';

const mixpanelToken =
	experiments.mixpanel(true) && process.env.REACT_APP_MIXPANEL_TOKEN;
const mixpanelBaseEid = `${process.env.REACT_APP_ENVIRONMENT}.web.lifebook/`;

function addBase(eid) {
	return mixpanelBaseEid + eid;
}

export function mixpanelInit() {
	return mixpanel.init(mixpanelToken);
}

export function mixpanelTrack(eid, data) {
	return !!mixpanelToken && mixpanel.track(addBase(eid), data);
}

export function mixpanelSetUser({ type, role, agencyId, userId }) {
	!!mixpanelToken && mixpanel.identify(userId);
	!!mixpanelToken && mixpanel.register(type, role, agencyId);
}

const getStatus = (action, state, res) => {
	const { id } = state;
	switch (action) {
		case 'editDraft':
		case 'createDraft':
			return {
				newStatus: 'draft',
				currentStatus: id ? 'draft' : 'none',
			};
		case 'deleteDraft':
			return {
				currentStatus: 'none',
				newStatus: 'draft',
			};
		case 'approve':
		case 'change':
		case 'create':
		case 'edit':
		default:
			return {
				newStatus: state?.status,
				currentState: res?.status,
			};
	}
};

// Note: need to know previous status and current status
export function mixpanelTrackPost(action, state = {}, res = {}) {
	const { sgpId, nspId } = state;
	const statuses = getStatus(action, state, res);
	const data = {
		action,
		sgpId,
		nspId,
		...statuses,
	};

	mixpanelTrack('post', data);
}

export function mixpanelTrackInviteSent(action, body) {
	const { id, role, type } = body || {};
	mixpanelTrack(`invite.${action}`, { id, role, type });
}

export function mixpanelTrackError({ response, message, location }) {
	// NOTE: config and data are properties of response objects
	// message and location should be used for custom (front end) errors
	const {
		config: { url, method } = {},
		data,
		status,
		request: { responseURL } = {},
	} = response || {};
	const action = method
		? method.charAt(0).toUpperCase() + method.slice(1) //capitalize first letter
		: 'FrontEnd';
	const customData = {
		location: url || responseURL || location,
		message: data?.message || message,
		status: status || '1000',
		method: action,
	};

	mixpanelTrack('error', customData);
}
