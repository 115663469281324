import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import styles from './headers.module.css';

export default function EditPostHeader({ postId }) {
	const root = useSelector(getPostsRoot);

	const postType = root === 'vault' ? 'Vault Post' : 'Post';
	const title = !!postId ? `Edit ${postType}` : `Add ${postType}`;

	return (
		<div className={styles.editPostHeaderRoot}>
			<div className={styles.editPostHeaderContent}>{title}</div>
		</div>
	);
}
