import { useEffect } from 'react';
import get from 'lodash/get';
import { useFormInput } from 'helpers';
import { TextField } from 'components';
import styles from '../userList.module.css';

const defaultSearchableProperties = ['name', 'email'];

export default function UserListSearch({
	list,
	setFilteredList,
	searchableProperties = [],
}) {
	const input = useFormInput('Search', '');
	const { value } = input;

	useEffect(() => {
		if (value) {
			const filteredList = list.filter((val = {}) => {
				const sanitizedPattern = value.replace(/[^a-zA-Z0-9 @.-]/g, '');
				const pattern = new RegExp(sanitizedPattern.toLowerCase());
				// NOTE: builds a string containing all the searchable field values
				const testValue = [
					...defaultSearchableProperties,
					...searchableProperties,
				]
					.map((property) => {
						return get(val, property);
					})
					.join('|')
					.toLowerCase();

				return pattern.test(testValue);
			});
			setFilteredList(filteredList);
		} else {
			setFilteredList([...list]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, list]);

	return (
		<div className={styles.userListSearchRoot}>
			<TextField {...input} />
		</div>
	);
}
