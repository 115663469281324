import { useSelector } from 'react-redux';
import { useSetState, getDefaultPostState } from 'helpers';
import { getUserState } from 'redux/selectors';

export default function useEditPostState() {
	const [state, setState] = useSetState(getDefaultPostState());
	const user = useSelector(getUserState);

	const setDefaultState = () => {
		setState(getDefaultPostState());
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'sgp') {
			if (value === '') {
				setState({
					title: '',
					category: '',
					sgpId: null,
					change: { ...state.change, title: true, category: true },
				});
			} else {
				setState({
					title: value.title,
					category: value.category?.id,
					sgpId: value.id,
					change: { ...state.change, title: true, category: true },
				});
			}
		} else {
			setState({
				[name]: value,
				change: { ...state.change, [name]: true },
			});
		}
	};

	const handleDate = (e) => {
		setState({
			posted: e,
			change: { ...state.change, posted: true },
		});
	};

	const deletePhoto = () => {
		setState({
			photo: '',
			file: false,
			change: { ...state.change, photo: true },
		});
	};

	return {
		state,
		setState,
		setDefaultState,
		handlers: { handleChange, handleDate, deletePhoto },
	};
}
