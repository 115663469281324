import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SectionHeader } from 'components';
import CasaSelectListTile from 'components/Casa/Components/CasaSelectListTile';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import styles from '../child.module.css';

export default function ChildCasa() {
	const childCasa = useSelector((state) => state.lifebooks.child?.casa?.name);
	const [expanded, setExpanded] = useState(false);

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{childCasa ? (
				<div className={styles.childCswRoot}>
					<SectionHeader>
						<div className={styles.basicHeader}>
							<div className={styles.headerText}>CASA</div>
							{expanded ? (
								<ExpandLess onClick={() => handleOpenClose()} />
							) : (
								<ExpandMore onClick={() => handleOpenClose()} />
							)}
						</div>
					</SectionHeader>
					{expanded && (
						<div className={styles.selectorListTileList}>
							<CasaSelectListTile selectable={false} value=''>
								{childCasa}
							</CasaSelectListTile>
						</div>
					)}
				</div>
			) : null}
		</>
	);
}
