import { connect } from 'react-redux';
import { setMobileDashboard } from 'redux/reducers';
import { Menu, MenuOpen } from '@material-ui/icons';
import { SquareIconButton } from 'components';
import clsx from 'clsx';
import styles from './buttons.module.css';

function DashboardToggle({ mobileDashboard, setMobileDashboard, className }) {
	const MenuIcon = mobileDashboard ? MenuOpen : Menu;
	const onClick = () => {
		setMobileDashboard(!mobileDashboard);
	};

	return (
		<SquareIconButton
			vert
			className={clsx(styles.dashboardToggleButton, className)}
			onClick={onClick}>
			<MenuIcon className={styles.dashboardToggleIcon} />
		</SquareIconButton>
	);
}

const mapStateToProps = ({ overLay: { mobileDashboard }, user }) => {
	return { mobileDashboard, user: user?.user };
};
export default connect(mapStateToProps, { setMobileDashboard })(
	DashboardToggle
);
