import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Medical = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 109.413 109.423'
			{...props}>
			<g
				id='hospital-medical-signal-of-a-cross-in-a-circle'
				transform='translate(-0.001)'>
				<g id='Group_193' data-name='Group 193' transform='translate(0.001)'>
					<path
						id='Path_248'
						data-name='Path 248'
						d='M54.709,0a54.712,54.712,0,1,0,54.7,54.708A54.767,54.767,0,0,0,54.709,0Zm0,100.71a46,46,0,1,1,46-46.005A46.051,46.051,0,0,1,54.709,100.71Z'
						transform='translate(-0.001)'
					/>
					<path
						id='Path_249'
						data-name='Path 249'
						d='M51.965,28.818V11.238A5.477,5.477,0,0,0,46.486,5.76H34.636a5.476,5.476,0,0,0-5.475,5.478v17.58H11.574A5.476,5.476,0,0,0,6.1,34.3V46.4a5.476,5.476,0,0,0,5.475,5.478H29.157V69.453a5.476,5.476,0,0,0,5.475,5.478H46.486a5.478,5.478,0,0,0,5.475-5.478V51.877h17.58A5.479,5.479,0,0,0,75.02,46.4V34.3a5.477,5.477,0,0,0-5.478-5.478Z'
						transform='translate(15.18 14.339)'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default Medical;
