import { useSelector } from 'react-redux';
import { getMediaQueryState } from 'redux/selectors';
import { AccountCircle, Help, ExitToApp } from '@material-ui/icons';
import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		width: '7.5vw',
		height: '7.5vw',
		display: 'flex',
		borderRadius: 17,
		marginTop: '1.5vw',
		boxShadow: '0px 3px 6px lightgray',
		flexDirection: 'column',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#00A0E8',
			color: 'white',
			fontWeight: 'bold',
			boxShadow: '0px 0px 0px',
			'& $icon': {
				color: 'white',
			},
		},
	},
	smallContainer: {
		width: '25%',
		height: '15vw',
		display: 'flex',
		borderRadius: 17,
		marginTop: '1.5vw',
		boxShadow: '0px 3px 6px lightgray',
		flexDirection: 'column',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#00A0E8',
			color: 'white',
			fontWeight: 'bold',
			boxShadow: '0px 0px 0px',
			'& $icon': {
				color: 'white',
			},
		},
	},
	iconContainer: {
		flex: 1,
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
	},
	textContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		fontSize: '1vw',
	},
	smallText: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		fontSize: '2vw',
	},
	icon: {
		color: '#A5A5A5',
		fontSize: '1.5vw',
	},
	smallIcon: {
		color: '#A5A5A5',
		fontSize: '3vw',
	},
}));

const WelcomeLink = (props) => {
	const classes = useStyles();
	const { isMd } = useSelector(getMediaQueryState);

	const icon = () => {
		if (props.icon === 'lifeBook') {
			return (
				<BookOpenPageVariant
					className={!isMd ? classes.icon : classes.smallIcon}
				/>
			);
		} else if (props.icon === 'profile') {
			return (
				<AccountCircle className={!isMd ? classes.icon : classes.smallIcon} />
			);
		} else if (props.icon === 'support') {
			return <Help className={!isMd ? classes.icon : classes.smallIcon} />;
		} else if (props.icon === 'logout') {
			return <ExitToApp className={!isMd ? classes.icon : classes.smallIcon} />;
		}
	};

	return (
		<div
			className={!isMd ? classes.container : classes.smallContainer}
			onClick={props.onClick}>
			<div className={classes.iconContainer}>{icon()}</div>
			<div className={!isMd ? classes.textContainer : classes.smallText}>
				{props.name}
			</div>
		</div>
	);
};

export default WelcomeLink;
