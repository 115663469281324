import queryString from 'query-string';
import { includeType, includePrivatize } from './';

const getIsoString = (date) => {
	const formatted = new Date(parseInt(date));
	return formatted.toISOString();
};

export const postQueryBuilder = (query, type) => {
	let parse = queryString.parse(query);
	let validatedQuery =
		parse.suggestedPost !== undefined &&
		(includeType(['contributor', 'agencyuser']) || includePrivatize())
			? 'suggestedPost=true&nsp=false&approved=false'
			: parse.pending !== undefined
			? `approved=false&hasBody=true`
			: 'approved=true';
	if (type === 'services') {
		validatedQuery += '&nsp=true';
	}
	let serviceCategory = parse.nspId ? `&nspId=${parse.nspId}` : '';
	let categoryQuery = parse.category ? `&category=${parse.category}` : '';
	let lazyQuery = parse.lastPostId ? `&lastPostId=${parse.lastPostId}` : '';
	let start = parse.startDate;
	let end = parse.endDate;
	let dateQuery =
		start && end
			? `&postedStart=${getIsoString(start)}&postedEnd=${getIsoString(end)}`
			: '';

	return (
		'&' +
		validatedQuery +
		categoryQuery +
		lazyQuery +
		dateQuery +
		serviceCategory
	);
};
