import { connect } from 'react-redux';
import { setSnackbar } from 'redux/index';
import { Close } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { FormSectionHeader, TextField } from 'components';
import styles from '../sendInvite.module.css';

function InviteForm(props) {
	const { type, header, inviteArray, setInviteArray } = props;

	const addField = () => {
		setInviteArray([...inviteArray, '']);
	};

	const removeField = (i) => {
		if (inviteArray.length > 1) {
			let newArray = [...inviteArray];
			newArray.splice(i, 1);
			setInviteArray(newArray);
		}
	};

	const inputMap = inviteArray.map((val, i) => {
		const onChange = (e) => {
			let newArray = [...inviteArray];
			newArray[i] = e.target.value;
			setInviteArray(newArray);
		};
		return (
			<TextField
				key={`${i}`}
				InputLabelProps={{
					classes: {
						root: styles.inputLabel,
					},
				}}
				required={i === 0 ? true : false}
				value={inviteArray[i]}
				onChange={onChange}
				name='Email'
				label='Email'
				margin='normal'
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<Close
								className={styles.inviteFormIcon}
								onClick={() => removeField(i)}
							/>
						</InputAdornment>
					),
				}}
			/>
		);
	});

	return (
		<div className={styles.inviteFormContainer}>
			{type ? (
				<FormSectionHeader
					onClick={addField}
					buttonLabel='Add More'>{`Invite ${header}`}</FormSectionHeader>
			) : null}
			{inputMap}
		</div>
	);
}

export default connect(null, { setSnackbar })(InviteForm);
