import useManageChildPermissions from '../utils/useManageChildPermissions';
import { SelectorList } from 'components';

export default function ChildHome({ selectorListState }) {
	const linkTo = '/homes/manage/';

	const { home: permission } = useManageChildPermissions();
	const canEdit = permission === 'edit';
	const canRender = canEdit;

	return canRender ? (
		<SelectorList
			label='Home'
			textFieldLabel='Homes'
			linkTo={linkTo}
			fromList='homes'
			single
			disable={!canEdit}
			expandable={true}
			full
			{...selectorListState}
		/>
	) : null;
}
