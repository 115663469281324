import {
	CSWVisit,
	Dental,
	Education,
	Family,
	Medical,
	MentalHealth,
	Visitation,
} from '../helpers/icons';

export const serviceIconPack = {
	Education,
	Medical,
	Dental,
	'Mental Health': MentalHealth,
	'CSW Visit': CSWVisit,
	Visitation,
	Family,
};
