import { useParams } from 'react-router';
import Collapse from '@material-ui/core/Collapse';
import * as Comp from 'components';
import styles from '../child.module.css';

export default function ChildCode(props) {
	const {
		textFieldArray,
		codeOpen,
		setCodeOpen,
		ownershipCodeVerified,
		verifyCode,
	} = props;
	const { childId } = useParams();

	const textFieldMapper = (val) => {
		return (
			<Comp.TextField
				key={val.label}
				{...val}
				disabled={ownershipCodeVerified}
			/>
		);
	};

	return !childId ? (
		<div className={styles.addChildCodeRoot}>
			<div className={styles.addChildCodeContent}>
				<Comp.SectionHeader hideTop>
					<div>Restart LifeBook</div>
					<div className={styles.addChildCodeButton}>
						<Comp.SecondaryButton
							onClick={() => setCodeOpen(!codeOpen)}
							disabled={ownershipCodeVerified}>
							{codeOpen ? 'Cancel' : 'Enter Code'}
						</Comp.SecondaryButton>
					</div>
				</Comp.SectionHeader>
				<Collapse in={codeOpen}>
					<div className={styles.addChildCodeCollapse}>
						{textFieldArray.map(textFieldMapper)}
						<div className={styles.addChildVerifyButtonContainer}>
							<Comp.PrimaryButton
								className={styles.addChildVerifyButton}
								onClick={verifyCode}
								disabled={ownershipCodeVerified}>
								Verify Code
							</Comp.PrimaryButton>
						</div>
					</div>
				</Collapse>
				{!codeOpen && (
					<Comp.InfoTag>
						Click &apos; Enter Code &apos; if child previously had an Ella
						Lifebook or is a Lifebook owner. If not proceed to Basic Info.
					</Comp.InfoTag>
				)}
			</div>
		</div>
	) : null;
}
