import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import * as reducer from 'redux/index';
import { getChildCalendar, getCurrentChild } from 'services';
import { getDateRange } from 'helpers';
import MobileHeader from 'components/Headers/MobileHeader';
import { LifebookNav, PageLayout } from 'components';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../calendar.module.css';

const ViewCalendar = () => {
	const calendar = useSelector((state) => state.calendar.calendar);
	const isMd = useSelector((state) => state.mediaQuery.isMd);
	const child = useSelector((state) => state.lifebooks.child);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const localizer = momentLocalizer(moment);
	const { childId } = useParams();

	useEffect(() => {
		getInitialCalendar();
	}, []);

	const getInitialCalendar = async () => {
		dispatch(reducer.setLoader(true));
		const dateRange = getDateRange(new Date());
		await getChildCalendar(childId, dateRange);
		if (user.type !== 'child' && !child.id) {
			await getCurrentChild(childId);
		}
		dispatch(reducer.setLoader(false));
	};

	const handleSelectDate = async (e) => {
		const currentTime = new Date(e.start).getTime();
		const endDate =
			e?.slots?.length > 1 ? e.end : new Date(currentTime + 1 * 60 * 60 * 1000);

		await dispatch(
			reducer.setCalendarEvent({
				start: e.start,
				end: endDate,
				title: '',
				description: '',
				id: '',
				notify: true,
			})
		);
		await dispatch(reducer.setEventType('new'));
		dispatch(reducer.toggleCalendarModal(true));
	};

	const handleSelectEvent = async (e) => {
		await dispatch(
			reducer.setCalendarEvent({
				start: e.start,
				end: e.end,
				title: e.title,
				description: e.description,
				id: e.id,
				notify: e.notify,
				childId: e.childId,
				createdAt: e.createdAt,
			})
		);
		await dispatch(reducer.setEventType('created'));
		dispatch(reducer.toggleCalendarModal(true));
	};

	const handleRangeChange = async (e) => {
		dispatch(reducer.setLoader(true));

		let dateRange;

		if (e.start && e.end) {
			dateRange = { start: e.start.toISOString(), end: e.end.toISOString() };
		} else if (e.length === 7) {
			dateRange = { start: e[0].toISOString(), end: e[6].toISOString() };
		} else if (e.length === 1) {
			dateRange = { start: e[0].toISOString(), end: e[0].toISOString() };
		} else {
			return;
		}

		await getChildCalendar(childId, dateRange);
		dispatch(reducer.setLoader(false));
	};

	const checkDate = (event) => {
		const createdPlusThree =
			new Date(event.createdAt).getTime() + 3 * 24 * 60 * 60 * 1000;
		const today = new Date().getTime();

		if (createdPlusThree < today) {
			return true;
		} else {
			return false;
		}
	};

	const eventPropGetter = useCallback(
		(event) => ({
			...(!checkDate(event) && { style: { backgroundColor: 'green' } }),
		}),
		[]
	);

	return (
		<PageLayout
			header={isMd ? <MobileHeader /> : null}
			footer={<LifebookNav />}
			title={`${child.firstname} ${child.lastname}'s Calendar`}>
			<div className={styles.fullCalendar}>
				<Calendar
					localizer={localizer}
					events={calendar}
					startAccessor='start'
					endAccessor='end'
					onSelectSlot={(e) => handleSelectDate(e)}
					onSelectEvent={(e) => handleSelectEvent(e)}
					onRangeChange={(e) => handleRangeChange(e)}
					eventPropGetter={eventPropGetter}
					selectable
					style={{ height: '80%', width: '90%' }}
				/>
			</div>
		</PageLayout>
	);
};

export default ViewCalendar;
