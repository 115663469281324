import store from '../redux/store';
import { apiV2 } from '../axios';
import _ from 'lodash';
import {
	setCategories,
	setCategoriesHash,
	setSchools,
	setSchoolsHash,
	setChildrenHash,
	setChildren,
	setChildSort,
} from '../redux';
import { getPhotoUrl, getAgencyUsers } from '.';
import {
	listSortOrganizationByName,
	hashList,
	listSortPersonByName,
	listSortByHouse,
	listSortBySchool,
	listSortBySW,
	listSortByBranch,
	mixpanelTrack,
} from '../helpers';
import { getBranchList } from './agency-services';

export const getCategories = async () => {
	let result = await apiV2.get('/category').then((res) => {
		store.dispatch(setCategories(res.data));
		const categoriesHash = res.data.reduce((acc, cur) => {
			acc[cur.id] = cur;
			return acc;
		}, {});
		let array = [];
		for (let value in res.data) {
			array.push({ value, label: res.data[value] });
		}

		store.dispatch(setCategoriesHash(categoriesHash));
	});
	return result;
};

export const getSchool = async (id) => {
	return await apiV2.get(`/school?schoolIds=${id}`).catch((err) => {
		return { error: true, response: err };
	});
};

export const updateChildrenList = async (child) => {
	if (child.photo && !child.photo?.url) {
		await getPhotoUrl(child.photo?.location, 'child', 120)
			.then((res) => {
				child.photoUrl = res.data.url;
				return res;
			})
			.catch((err) => {
				child.photoUrl = '';
				return err;
			});
	} else {
		child.photoUrl = child.photo?.url;
	}
	let children = await store.getState().lifebooks.children.slice();
	let sort = await store.getState().lifebooks.childSort;
	let index = children.findIndex((val) => val.id === child.id);
	if (index >= 0) {
		children[index] = child;
	} else {
		children.push(child);
	}
	await childrenSort(sort || 'house', children);
	await setSchoolList(children);
	return { success: true, data: children };
};

export const setSchoolList = async (children) => {
	await getSchoolList(children).then((response) => {
		const schoolsList = listSortOrganizationByName(response.data);
		const schoolsHash = hashList(response.data);
		store.dispatch(setSchools(schoolsList));
		store.dispatch(setSchoolsHash(schoolsHash));
		return { schoolsList, schoolsHash };
	});
};

export const childrenSort = (sort, childArray, hash) => {
	const childrenHash = hashList(childArray);
	store.dispatch(setChildrenHash(childrenHash));
	store.dispatch(setChildSort(sort));
	return store.dispatch(
		setChildren(childrenSortSelect(sort, childArray, hash))
	);
};

export const getSchoolList = async (childArray) => {
	let schoolIdListArray = [];
	childArray.forEach((value) => {
		if (value.schoolId && schoolIdListArray.indexOf(value.schoolId) < 0) {
			schoolIdListArray.push(value.schoolId);
		}
	});
	let schoolIdListString = schoolIdListArray.join(', ');
	if (schoolIdListArray.length) {
		return await getSchool(schoolIdListString);
	} else return { data: [] };
};

export const childrenSortSelect = async (sort, array, hash) => {
	switch (sort) {
		case 'firstname':
			return listSortPersonByName(array, sort);
		case 'lastname':
			return listSortPersonByName(array, sort);
		case 'house':
			let housesHash = hash
				? hash
				: await store.getState().lifebooks.housesHash;
			return listSortByHouse(array, housesHash);
		case 'sW':
			let sWHash = hash ? hash : null;
			if (!hash) {
				getAgencyUsers('social-worker');
			}
			return listSortBySW(array, sWHash);
		case 'branch':
			let branchHash =
				!hash || (typeof hash === 'object' && _.isEmpty(hash)) ? hash : null;
			if (!hash) {
				branchHash = await hashList(await getBranchList().data);
			}
			return listSortByBranch(array, branchHash);
		case 'school':
			return listSortBySchool(array, store.getState().lifebooks.schoolsHash);
		default:
			return;
	}
};

export const exportLifebookPdf = async (childId) => {
	mixpanelTrack('pdfExport.submit');
	return await apiV2.post(`/child/${childId}/export/lifebook`);
};

export const getReadOnlyToken = async (childId) => {
	return await apiV2.get(`/child/${childId}/access`);
};
