import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot, getMediaQueryState } from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';
import ServiceCategorySelect from './components/ServiceCategorySelect';
import CategorySelect from './components/CategorySelect';
import ServiceSwitcher from './components/ServiceSwitcher';
import DateRange from './components/DateRange';
import ViewSwitcher from './components/ViewSwitcher';
import { Avatar } from 'components';
import styles from './headers.module.css';

export default function TimelineHeader() {
	const { isMd, isSmd } = useSelector(getMediaQueryState);
	const { childId, feedType } = useParams();
	const { clearCategory, clearDateRange } = useCreateDispatches([]);
	const image = useSelector((state) => state.lifebooks.child.photo?.url);
	const child = useSelector((state) => state.lifebooks.child);
	const role = useSelector((state) => state.user?.user?.role);

	useEffect(() => {
		clearCategory();
		clearDateRange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	const root = useSelector(getPostsRoot);
	const allowQueries = !['archive'].includes(root);

	return (
		<div className={styles.headerRoot}>
			<div className={styles.headerHalf}>
				{!isSmd && (
					<div className={styles.imageContainer}>
						<Avatar
							identity={child}
							alt={`${child.firstname} ${child.lastname}`}
							image={image}
							size='large'
						/>
					</div>
				)}
				{root !== 'vault' && role !== 'contributor' && <ServiceSwitcher />}
			</div>
			<div className={styles.headerHalf}>
				{allowQueries && (
					<>
						{feedType === 'services' && <ServiceCategorySelect />}
						{feedType === 'timeline' || root === 'vault' ? (
							<CategorySelect />
						) : null}
						<DateRange />
					</>
				)}
				{!isMd && <ViewSwitcher />}
			</div>
		</div>
	);
}
