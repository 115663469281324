import { apiV2 as axios } from '../axios';
import { postQueryBuilder } from '../helpers';
import { getUser } from './user-services';
import { setChildrenList } from './child-services';
import store from '../redux/store';

export const getSnapshotChild = async (childId) => {
	const reduxChild = store.getState?.()?.lifebooks?.child;
	if (reduxChild?.id === childId) {
		return reduxChild;
	}

	return await axios.get(`/snapshot/child/${childId}`);
};

export const getSnapshotChildList = async () => {
	return await axios.get('/snapshot/child/').then(async (res) => {
		let promiseArray = [];
		let { data } = res || {};

		await Promise.all(promiseArray).catch((err) => {
			return err;
		});

		return data;
	});
};

export const getSnapshotPost = async (postId) => {
	return await axios.get(`/snapshot/post/${postId}`);
};

export const getSnapshotPostList = async (childId, query, type) => {
	if (type === 'timeline') {
		return await axios
			.get(`/snapshot/post?childId=${childId}${postQueryBuilder(query)}`)
			.then((res) => {
				// NOTE: disable the posts before returning the list
				const { data } = res;
				const { posts } = data;
				return {
					...res,
					data: {
						...data,
						posts: posts.map((val) => ({ ...val, disabled: true })),
					},
				};
			});
	} else {
		return await axios
			.get(`/snapshot/post/nsp?childId=${childId}${postQueryBuilder(query)}`)
			.then((res) => {
				// NOTE: disable the posts before returning the list
				const { data } = res;
				const { posts } = data;
				return {
					...res,
					data: {
						...data,
						posts: posts.map((val) => ({ ...val, disabled: true })),
					},
				};
			});
	}
};

export const getSnapshotActivity = async ({
	childId,
	postId,
	lastPostActivityId,
}) => {
	let query = '?';
	if (postId) {
		query += `postId=${postId}`;
	} else {
		query += `childId=${childId}`;
	}
	if (lastPostActivityId) {
		query += `?lastPostActivityId=${lastPostActivityId}`;
	}
	return await axios.get(`/snapshot/child/${childId}/post/activity${query}`);
};

export const getSnapshotProfileActivity = async ({ childId }) => {
	return await axios.get(`/snapshot/child/${childId}/activity`);
};

export const getSnapshotExportActivity = async ({ childId }) => {
	return await axios.get(`/snapshot/child/${childId}/export`);
};

export const verifyRestartCode = async (childId, ownershipCode) => {
	return await getUser(childId, { ownershipCode });
};

export const restartChild = async (childId, ownershipCode) => {
	return axios
		.put(`/child/${childId}/restart`, { ownershipCode })
		.then(async (res) => {
			await setChildrenList();
			return res;
		});
};
