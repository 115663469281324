import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMobileList } from '../redux/reducers/overLaysReducer';

// Sets the mobile list to visible when page is navigated to
export default function useMobileListCheck(validation) {
	const dispatch = useDispatch();
	const { mobileList } = useSelector(({ overLay }) => overLay);
	return useEffect(() => {
		if (validation) {
			if (!mobileList) {
				dispatch(setMobileList(true));
			}
		} else {
			dispatch(setMobileList(false));
		}
	}, [dispatch, validation, mobileList]);
}
