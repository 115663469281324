import { useParams, useLocation, useHistory } from 'react-router-dom';
import { OptionMenu } from 'components';
import styles from '../headers.module.css';

export default function ServiceSwitcherMobile({
	options = [
		{ path: 'drafts', label: 'Timeline' },
		{ path: 'nspDrafts', label: 'Services' },
	],
}) {
	const { feedType } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();

	let label;
	if (feedType === 'nspDrafts') {
		label = 'Services';
	} else if (feedType === 'drafts') {
		label = 'Timeline';
	}

	const handleClick = (path) => {
		if (feedType !== path) {
			history.replace({ search: '' });
			history.push(pathname.replace(feedType, path));
		}
	};

	const optionsMap = options.map(({ label, path }) => ({
		label,
		onClick: () => handleClick(path),
	}));

	return (
		<OptionMenu
			optionButton
			className={styles.serviceSwitcherMobileRoot}
			menuItems={optionsMap}>
			<div>{label}</div>
		</OptionMenu>
	);
}
