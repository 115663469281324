import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBranchHash } from 'redux/selectors';
import { getBranch } from 'services';
import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useManageBranchState() {
	const { clearAddress, setAddress, setLoader, setSnackbar } =
		useCreateDispatches();
	const { branchId } = useParams();
	const branchHash = useSelector(getBranchHash);
	const reduxBranch = branchHash?.[branchId];
	const [pageTitle, setPageTitle] = useState('Add Branch');
	const [loaded, setLoaded] = useState(false);
	const name = useFormInput('Branch Name', '', { required: true });

	useState(false);

	useEffect(() => {
		// clear data first
		clearAddress();
		name.onChange({ value: '' });

		const setData = (data) => {
			const { name: dataName, address } = data || {};
			name.onChange({ value: dataName });
			dataName && setPageTitle(`${dataName}`);

			setAddress(address || {});
		};

		const setBranch = async () => {
			if (reduxBranch) {
				setLoaded(true);
				setData(reduxBranch);
			} else {
				setLoader(true);
				await getBranch(branchId)
					.then(({ data }) => {
						setData(data);
					})
					.catch((err) => {
						setSnackbar(
							{ variant: 'error', message: err.response?.data.message },
							{ log: true }
						);
					})
					.finally(() => {
						setLoaded(true);
						setLoader(false);
					});
			}
		};
		if (branchId) {
			setBranch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId]);

	return { name, pageTitle, loaded };
}
