import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsMd } from 'redux/selectors';
import { PageLayout, LifebookNav } from 'components';
import DefaultHeader from 'components/UtilityComponents/PageLayout/components/DefaultHeader';
import MobileHeader from 'components/Headers/MobileHeader';
import { manualData } from '../utils/supportItems';
import styles from '../support.module.css';

export function SupportManual(props) {
	const user = useSelector((state) => state.user.user);
	const { navBack } = props;
	const isMd = useSelector(getIsMd);
	const driveBaseUrl = 'https://resources.ella.care/manuals/';
	const manualType = manualData(user.type, user.role);

	const [url, setUrl] = useState(`${driveBaseUrl}${manualType.user}`);

	useEffect(() => {
		setUrl(`${driveBaseUrl}${manualType.user}`);
	}, [manualType]);

	return (
		<div className={styles.supportManualContainer}>
			<PageLayout
				title={'Manual'}
				navBack={navBack}
				footer={<LifebookNav />}
				header={isMd ? <MobileHeader /> : <DefaultHeader title={'Manual'} />}>
				<div className={styles.supportManualContent}>
					<object key={url} className={styles.supportObject} data={url}>
						<div className={styles.supportManualAltTextContainer}>
							<p>
								Your web browser doesn&apos;t have a PDF plugin. Instead you can{' '}
								<a href={`${url}/view?usp=sharing`}>
									click here to download the PDF file.
								</a>
							</p>
						</div>
					</object>
				</div>
			</PageLayout>
		</div>
	);
}

export default SupportManual;
