import { useEffect } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCategory } from 'redux/index';
import LifebookHeader from '../../../Headers/LifebookHeader';
import { ActivityFeed, Timeline, PageLayout, LifebookNav } from 'components';
import styles from '../pageLayout.module.css';

export function Feed(props) {
	const {
		match: {
			params: { childId, feedType },
			path,
		},
		category,
		setCategory,
		root,
	} = props;

	useEffect(() => {
		const initialCategory = path.includes('/activity') ? 'activity' : '';
		setCategory(initialCategory);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path]);

	return (
		<div className={styles.feedContainer}>
			<PageLayout footer={<LifebookNav />} header={<LifebookHeader />}>
				<Switch>
					<Route
						key={`${childId}-activity`}
						path={`/${root}/(a|profileA|exportA)ctivity/:childId`}>
						<ActivityFeed key={childId} />
					</Route>
					<Route path={`/${root}/:feedType/:childId`}>
						<Timeline key={`${childId}${category}${feedType || ''}`} />
					</Route>
				</Switch>
			</PageLayout>
		</div>
	);
}

const mapStateToProps = ({ lifebooks, user: { user }, mediaQuery, posts }) => {
	return {
		child: lifebooks.child,
		categories: lifebooks.categories,
		categoriesHash: lifebooks.categoriesHash,
		user,
		width: mediaQuery.width,
		height: mediaQuery.height,
		category: posts.category,
		root: posts.root,
	};
};

export default connect(mapStateToProps, { setCategory })(withRouter(Feed));
