const defaultUserState = { cases: [], modal: false };

const SET_CASES = 'SET_CASES';
const SET_CONNECTIONS_MODAL = 'SET_CONNECTIONS_MODAL';

export default function userReducer(state = defaultUserState, action) {
	switch (action.type) {
		case SET_CASES:
			return { ...state, cases: action.payload };
		case SET_CONNECTIONS_MODAL:
			return { ...state, modal: action.payload };
		default:
			return state;
	}
}

export function setCases(cases) {
	return {
		type: SET_CASES,
		payload: cases,
	};
}

export function setConnectionsModal(modal) {
	return {
		type: SET_CONNECTIONS_MODAL,
		payload: modal,
	};
}
