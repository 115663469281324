import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoPosition } from 'redux/reducers';
import { getIsSm } from 'redux/selectors';
import SupportTile from './SupportTile';
import styles from '../support.module.css';
import { videoMarkers } from '../utils/videoTimeMarkers';

export function SupportNav() {
	const user = useSelector((state) => state.user.user);
	const [selected, setSelected] = useState();

	const isSm = useSelector(getIsSm);

	const dispatch = useDispatch();

	const onClick = async (val) => {
		setSelected(val.id);
		dispatch(setVideoPosition(val.time));
	};

	const itemMapper = (val) => {
		return (
			<div key={val.label}>
				<SupportTile
					selected={selected}
					value={val}
					style={{ pointer: 'cursor' }}
					select={() => onClick(val)}
					noAvatar>
					<div>
						<div>{val.label}</div>
						<div>{val.displayTime}</div>
					</div>
				</SupportTile>
			</div>
		);
	};

	const supportItems = videoMarkers(user.type, user.role).map(itemMapper);

	return (
		<div className={isSm ? styles.fullSupportNavSmall : styles.fullSupportNav}>
			<div className={isSm ? styles.tileListSmall : styles.tileList}>
				{supportItems}
			</div>
		</div>
	);
}

export default SupportNav;
