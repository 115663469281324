import PostBody from './PostBody';
import { PostCategoryChip } from 'components';
import PostCreatedBy from './PostCreatedBy';
import ServiceCategoryChip from './ServiceCategoryChip';
import styles from '../timeline.module.css';

export default function PostContent({ post }) {
	const { title, body, category, createdBy, posted } = post;

	return (
		<div className={styles.postContentRoot}>
			<div className={styles.postContentTitle}>{title}</div>
			<PostBody body={body} />
			<PostCreatedBy createdBy={createdBy} posted={posted} />
			{category?.label && !post.nsp ? (
				<PostCategoryChip category={category} nsp={post?.nsp ? true : false} />
			) : null}
			{post.nsp ? (
				<ServiceCategoryChip category={post?.nsp?.servicesCategory} />
			) : null}
		</div>
	);
}
