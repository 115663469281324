import { useState, useEffect } from 'react';

export function useSelectorListState(
	fullList = [],
	initialAssignedList = [],
	{ single = false, loaded = true } = {}
) {
	const [assignedList, setAssignedList] = useState([...initialAssignedList]);

	const [optionsList, setOptionsList] = useState([]);

	useEffect(() => {
		const getInitialOptionsList = () => {
			if (initialAssignedList.length === 0) return [...fullList];
			const filtered = fullList.filter((val) => {
				const isAssigned =
					initialAssignedList.findIndex((assigned) => {
						return assigned.id === val.id;
					}) >= 0;
				return !isAssigned;
			});
			return filtered;
		};

		if (loaded) {
			setAssignedList([...initialAssignedList]);
			setOptionsList(getInitialOptionsList());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded]);

	const [changes, setChanges] = useState({ add: [], remove: [] });

	const handleChangeRemoveFromMulti = (change) => {
		// check the 'add' list to see if the change already exists
		const addArrayIndex = changes.add.findIndex((val) => val.id === change.id);
		if (addArrayIndex >= 0) {
			let newList = [...changes.add];
			newList.splice(addArrayIndex, 1);
			setChanges({ ...changes, add: newList });
		} else {
			setChanges({ ...changes, remove: [...changes.remove, change] });
		}
	};

	const handleChangeAddFromMulti = (change) => {
		// check the 'remove' list to see if the change already exists
		const removeArrayIndex = changes.remove.findIndex(
			(val) => val.id === change.id
		);
		if (removeArrayIndex >= 0) {
			let newList = [...changes.remove];
			newList.splice(removeArrayIndex, 1);
			setChanges({ ...changes, remove: newList });
		} else {
			setChanges({ ...changes, add: [...changes.add, change] });
		}
	};

	const handleChangeRemoveFromSingle = (change) => {
		const initialValue = initialAssignedList[0] || {};
		if (change?.id === initialValue.id) {
			setChanges({ add: [], remove: [change] });
		} else {
			setChanges({ add: [], remove: [] });
		}
	};

	const handleChangeAddFromSingle = (change) => {
		const initialValue = initialAssignedList[0] || null;
		if (change?.id === initialValue?.id) {
			setChanges({ add: [], remove: [] });
		} else {
			setChanges({
				add: [change],
				remove: initialValue ? [initialValue] : [],
			});
		}
	};

	const resetChanges = () => {
		setChanges({ add: [], remove: [] });
	};

	return {
		optionsList,
		setOptionsList,
		assignedList,
		setAssignedList,
		changes,
		handleChangeAdd: single
			? handleChangeAddFromSingle
			: handleChangeAddFromMulti,
		handleChangeRemove: single
			? handleChangeRemoveFromSingle
			: handleChangeRemoveFromMulti,
		resetChanges,
		single,
		loaded,
	};
}
