import { Photo } from '@material-ui/icons';
import styles from '../addImage.module.css';

export function ImageUnavailable({ image }) {
	return (
		<>
			<div
				className={styles.addImagePreviewHeic}
				style={{
					backgroundImage: `url(${
						window.location.origin + '/assets/ella-elephant.svg'
					})`,
				}}>
				Preview Unavailable
				<div className={styles.addImagePreviewHeicText}>
					<Photo className={styles.addImagePreviewIcon} />
					{image.name ? ` ${image.name}` : null}{' '}
				</div>
			</div>
		</>
	);
}

export default ImageUnavailable;
