import { StaticTextRow } from 'components';
import styles from './form.module.css';

const EmailStaticTextRow = ({ status, onResend, value }) => {
	const showResend =
		// - has a resend callback
		// - value has not been changed
		onResend && status === 'invited';

	const staticValue = () => {
		if (showResend) {
			return (
				<div className={styles.emailStaticResendContainer}>
					{value}
					<span className={styles.emailStaticResendButton} onClick={onResend}>
						Resend Invite
					</span>
				</div>
			);
		} else {
			return value;
		}
	};

	return <StaticTextRow label='Email' value={staticValue()} />;
};

export default EmailStaticTextRow;
