import { apiV2 } from '../axios';
import {
	store,
	setAllCasaPermissions,
	setInitialCasaPermissions,
	setAllCasaUserPermissions,
} from '../redux';
import { showSnack } from '../helpers';

export const setChildCasaPermissions = async (
	addPermissions,
	removePermissions,
	id,
	casaId,
	userId
) => {
	const data = {
		childId: id,
		casaId: casaId,
		casaUserId: userId ? userId : null,
		permissionsToAdd: addPermissions ? addPermissions : null,
		permissionsToRemove: removePermissions ? removePermissions : null,
	};
	const response = await apiV2
		.post(`/casa/${casaId}/permission`, data)
		.catch(() => {
			showSnack('error');
			return;
		});

	if (response?.data) {
		store.dispatch(setInitialCasaPermissions(response.data));

		if (userId) {
			store.dispatch(setAllCasaUserPermissions(response.data));
		} else {
			store.dispatch(setAllCasaPermissions(response.data));
		}
	}

	response?.data ? showSnack('success') : showSnack('error');

	return;
};

export const getCasaPermissions = async (casaId, childId) => {
	const user = store.getState().user.user;

	const url = `/casa/${casaId}/permission?childId=${childId}`;

	const response = await apiV2.get(url).catch(() => {
		store.dispatch(setAllCasaPermissions('error'));
	});

	if (response?.data) {
		user.type === 'agencyuser' &&
			store.dispatch(setInitialCasaPermissions(response.data));
		store.dispatch(setAllCasaPermissions(response.data));

		return response.data;
	} else {
		store.dispatch(setAllCasaPermissions('error'));
		return [];
	}
};

export const getCasaUserPermissions = async (casaId, childId, casaUserId) => {
	const url = `/casa/${casaId}/permission?childId=${childId}&casaUserId=${casaUserId}`;

	const response = await apiV2.get(url).catch(() => {
		store.dispatch(setAllCasaUserPermissions('error'));
	});

	if (response?.data) {
		store.dispatch(setInitialCasaPermissions(response.data));
		store.dispatch(setAllCasaUserPermissions(response.data));
		return response.data;
	} else {
		store.dispatch(setAllCasaUserPermissions('error'));
		return [];
	}
};
