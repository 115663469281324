import { experiments } from 'helpers';
import { AccountGroup } from 'mdi-material-ui';
import {
	Apartment,
	FolderShared,
	SettingsApplications,
	SupervisorAccount,
	Help,
	Home,
	SupervisedUserCircle,
	ExitToApp,
	Lock,
	HomeWork,
	Event,
} from '@material-ui/icons';
import { LifeBook, SocialWorker } from 'helpers/icons';

const navOptions = {
	lifebooks: {
		name: 'LifeBooks',
		icon: LifeBook,
		path: '/lifebooks/',
	},
	children: {
		name: 'Children',
		icon: SupervisorAccount,
		path: '/children/',
	},
	agency: {
		name: 'Agency',
		icon: Apartment,
		path: '/agencies/',
	},
	administrators: {
		name: 'Admins',
		icon: AccountGroup,
		path: '/admins/',
	},
	managers: {
		name: 'Managers',
		icon: SupervisedUserCircle,
		path: '/managers/',
	},
	casaAgency: {
		name: 'Casa',
		icon: HomeWork,
		path: '/casaAgency/',
	},
	casa: {
		name: 'Casa',
		icon: HomeWork,
		path: '/casa/',
	},
	manage: {
		name: 'Manage',
		icon: HomeWork,
		path: '/casa/',
	},
	casaUsers: {
		name: 'Users',
		icon: HomeWork,
		path: '/casaUsers/',
	},
	connections: {
		name: 'Connections',
		icon: HomeWork,
		path: '/casaConnections/',
	},
	contributors: {
		name: 'Contributors',
		icon: Home,
		path: '/contributors/',
	},
	homes: {
		name: 'Homes',
		icon: Home,
		path: '/homes/',
	},
	resourceParents: {
		name: 'Parents',
		icon: Home,
		path: '/resource-parents/',
	},
	socialWorkers: {
		name: 'Social Workers',
		icon: SocialWorker,
		path: '/socialworkers/',
	},
	account: {
		name: 'Account',
		icon: SettingsApplications,
		path: '/account/',
	},
	archive: experiments.archive(true) && {
		name: 'Archive',
		icon: FolderShared,
		path: '/archive/',
	},
	vault: {
		name: 'Vault',
		icon: Lock,
		path: '/vault/',
	},
	support: {
		name: 'Support',
		icon: Help,
		path: '/support/',
	},
	calendar: {
		name: 'Calendar',
		icon: Event,
		path: '/calendar/',
	},
	billing: {
		name: 'Billing',
		Icon: Event,
		path: '/billing/',
	},
	activities: {
		name: 'Activities',
		icon: Event,
		path: '/agencyactivities/',
	},
	logout: {
		name: 'Logout',
		icon: ExitToApp,
		path: '/logout/',
	},
};

export default navOptions;
