import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import * as redux from 'redux/selectors';
import { getNspTemplates, listExportRequest } from 'services';
import { useCreateDispatches, useBlockAddPost } from 'helpers/hooks';
import useFetchPosts from './utils/useFetchPosts';
import { AddVaultPostButton, NoPost } from 'components';
import PostMapper from './components/PostMapper';
import ExportRequest from '../ExportRequest/ExportRequest';
import AddPostButton from './components/AddPostButton';
import clsx from 'clsx';
import styles from './timeline.module.css';
import VaultReason from './components/VaultReason';

export default function Timeline() {
	const { setFeedType } = useCreateDispatches();
	const feedView = useSelector(redux.getPostsFeedView);
	const isGrid = feedView === 'grid';
	const reduxFeedType = useSelector(redux.getPostsFeedType);
	const { feedType } = useParams();
	const { lazyLoadHandler, isLoading } = useFetchPosts();
	const exportRequests = useSelector((state) => state.lifebooks.exportRequests);
	const blockAddPostAccess = useBlockAddPost();
	const hasPosts = useSelector(redux.getHasPosts);
	const root = useSelector(redux.getPostsRoot);

	useEffect(() => {
		if (feedType !== reduxFeedType) {
			setFeedType(feedType);
		}
		if (feedType === 'exports') {
			listExportRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedType]);

	useEffect(() => {
		getNspTemplates();
	}, []);

	const hasAddPost = hasPosts && !blockAddPostAccess;

	if (feedType === 'exports') {
		const hasExports = exportRequests.length > 0;
		return (
			<>
				{isLoading && hasExports ? (
					''
				) : !isLoading && !hasExports ? (
					<NoPost />
				) : (
					<ExportRequest />
				)}
			</>
		);
	}

	return (
		<div className={styles.timelineRoot}>
			<VaultReason />
			<div
				className={clsx(
					styles.timelineContent,
					hasAddPost && styles.timelineWithAddPost,
					isGrid && hasPosts && styles.timelineContentGrid
				)}
				onScroll={lazyLoadHandler}>
				{isLoading && !hasPosts ? (
					''
				) : !isLoading && !hasPosts ? (
					<NoPost />
				) : (
					<PostMapper />
				)}
			</div>
			{root === 'vault' ? <AddVaultPostButton /> : <AddPostButton />}
		</div>
	);
}
