import { getRightContentLessThanMin } from 'redux/selectors';
import { Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styles from '../editPost.module.css';

export function ServiceAvatar(props) {
	const { alt, image, children } = props;
	const lessThanMin = useSelector(getRightContentLessThanMin);

	let altLetters = alt
		.split(/\s/)
		.reduce((acc, cur) => (acc += cur.slice(0, 1)), '');

	const avatarRender = image ? null : children ? children : altLetters;

	return (
		<Avatar
			src={image}
			alt={alt}
			className={[
				styles.serviceOptionsAvatar,
				lessThanMin && styles.minAvatar,
				props.isActive && styles.serviceOptionsActiveIcon,
			].join(' ')}
			{...props.componentProps}>
			{avatarRender}
		</Avatar>
	);
}

export default ServiceAvatar;
