import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { apiV2 } from '../../axios';
import { useSelector } from 'react-redux';
import store from 'redux/store';
import { setCases, setConnectionsModal } from 'redux/index';

const FamilyConnectRedirect = () => {
	const children = useSelector((state) => state.lifebooks.children);

	const history = useHistory();
	const childId = localStorage.getItem('childId');

	const fetchCases = async () => {
		const response = await apiV2.get('/cok/case').catch((err) => {
			console.log('err', err);
		});

		return response;
	};

	const filterCases = (response) => {
		if (children.length > 0) {
			const resData = response?.data || [];
			const idsToFilter = children.map((child) => child.cokCaseId);
			const cleanIds = idsToFilter.filter((id) => id !== undefined);
			const newData = resData.filter(({ id }) => !cleanIds.includes(id));
			return newData;
		}
		return [];
	};

	useEffect(() => {
		const token = new URLSearchParams(window.location.hash).get(
			'#access_token'
		);
		localStorage.setItem('cok_token', token);

		fetchCases()
			.then((response) => filterCases(response))
			.then((newData) => store.dispatch(setCases(newData)))
			.finally(() => {
				if (children.length > 0) {
					store.dispatch(setConnectionsModal(true));
					history.push(`/lifebooks/manage/${childId}`);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children]);

	return <></>;
};

export default FamilyConnectRedirect;
