import { useHistory } from 'react-router-dom';
import { getCurrentChild } from 'services';
import useSortMenuOptions from '../../Lifebooks/utils/useSortMenuOptions';
import useFilterMenuOptions from '../../Lifebooks/utils/useFilterMenuOptions';
import { UserList, SlideContainer } from 'components';

export default function CalendarListContainer() {
	const { push } = useHistory();

	const { menuItems: filterMenuItems, filteredList } = useFilterMenuOptions();

	const sortMenuItems = useSortMenuOptions();

	const selectChild = async (e, child) => {
		e.stopPropagation();
		await getCurrentChild(child.id);
		push(`/calendar/${child.id}`);
	};

	return (
		<SlideContainer>
			<UserList
				list={filteredList}
				label='Children'
				pathParam='childId'
				handleSelect={selectChild}
				sortMenuItems={sortMenuItems}
				filterMenuItems={filterMenuItems}
			/>
		</SlideContainer>
	);
}
