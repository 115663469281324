import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLifebooksNsps, getRightContentLessThanMin } from 'redux/selectors';
import { serviceIconPack } from 'helpers';
import { getNspTemplates } from 'services';
import useFetchPosts from '../../Lifebooks/utils/useFetchPost';
import ServiceAvatar from './ServiceAvatar';
import styles from '../editPost.module.css';

export default function ServiceOptions({
	handleChange,
	activeServiceCategoryIndex,
	setActiveServiceCategoryIndex,
}) {
	const nsps = useSelector(getLifebooksNsps);
	const { state } = useFetchPosts();
	const lessThanMin = useSelector(getRightContentLessThanMin);

	useEffect(() => {
		let getTemplates = async () => {
			await getNspTemplates();
		};
		getTemplates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state.nsp) {
			const serviceIndex = nsps.findIndex((val) => val.id === state.nsp?.id);
			setActiveServiceCategoryIndex(serviceIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nsps, state]);

	const onHandleChange = (name, value) => {
		return handleChange({
			target: { name, value },
		});
	};

	const selectNsp = (val, index) => {
		setActiveServiceCategoryIndex(index);
		const valuesToUpdate = ['title', 'description', 'category'];
		valuesToUpdate.forEach((name) =>
			onHandleChange(name, val[name]?.id || val[name])
		);
		onHandleChange('nspId', val.id);
		onHandleChange('sgpId', null);
	};

	useEffect(() => {
		return () => {
			onHandleChange('nsp', null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const serviceMapper = (nsp, index) => {
		const { servicesCategory } = nsp;
		const ServiceIcon = serviceIconPack[servicesCategory];
		const isActive = activeServiceCategoryIndex === index;

		return (
			<div
				key={nsp.id}
				className={[
					styles.serviceOptionsTile,
					lessThanMin && styles.tileMin,
				].join(' ')}
				onClick={() => selectNsp(nsp, index)}>
				{!!ServiceIcon && (
					<ServiceAvatar isActive={isActive} alt={servicesCategory}>
						<ServiceIcon style={{ width: '40px', height: '40px' }} />
					</ServiceAvatar>
				)}
				<div
					className={[
						styles.serviceOptionsText,
						isActive && styles.activeText,
					].join(' ')}>
					{servicesCategory}
				</div>
			</div>
		);
	};

	return (
		<div className={styles.serviceOptionsTileContainer}>
			{nsps.map(serviceMapper)}
		</div>
	);
}
