import { DatePicker, PrimaryButton } from 'components';
import { validDate } from 'helpers';
import styles from '../headers.module.css';

export default function DateRangePicker({
	dateRange,
	setDateRange,
	onComplete,
}) {
	const checkComplete = (start, end) => {
		if (onComplete && validDate(start) && validDate(end) && start < end) {
			onComplete();
		}
	};

	return (
		<div className={styles.dateContainer}>
			<DatePicker
				className={styles.datePicker}
				label='Start Date'
				value={dateRange[0]}
				name='Start Date'
				maxDate={validDate(dateRange[1]) ? new Date(dateRange[1]) : null}
				maxDateMessage='Start Date cannot be after End Date'
				onChange={(e) => {
					setDateRange([e, dateRange[1]]);
					checkComplete(e, dateRange[1]);
				}}
			/>
			<DatePicker
				className={styles.datePicker}
				label='End Date'
				value={dateRange[1]}
				name='End Date'
				minDate={validDate(dateRange[0]) ? new Date(dateRange[0]) : null}
				maxDateMessage='Start Date cannot be after End Date'
				onChange={(e) => {
					setDateRange([dateRange[0], e]);
					checkComplete(dateRange[0], e);
				}}
			/>
			<div className={styles.clearButtonContainer}>
				<PrimaryButton
					onClick={() => {
						setDateRange([null, null]);
						onComplete();
					}}>
					Clear Dates
				</PrimaryButton>
			</div>
		</div>
	);
}
