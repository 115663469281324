export const handleChildValidation = (reqBody, childId) => {
	if (reqBody.dob === 'error') {
		return { isValid: false, message: 'Date of birth is required' };
	}
	if (childId && reqBody.firstname === '') {
		return { isValid: false, message: 'First name is required' };
	}

	if (childId && reqBody.lastname === '') {
		return { isValid: false, message: 'Last name is required' };
	}

	if (!childId && !reqBody.firstname) {
		return { isValid: false, message: 'First name is required' };
	}

	if (!childId && !reqBody.lastname) {
		return { isValid: false, message: 'Last name is required' };
	}

	return { isValid: true };
};
