import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MentalHealth = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 96.171 105.942'
			{...props}>
			<g
				id='Group_211'
				data-name='Group 211'
				transform='translate(-1568.204 -764.878)'>
				<g
					id='heart_1_'
					data-name='heart (1)'
					transform='translate(1544.594 764.878)'>
					<path
						id='Path_241'
						data-name='Path 241'
						d='M219.6,133.422a10.05,10.05,0,0,0-7.558,3.434l-2.335,2.667-2.335-2.667a10.042,10.042,0,0,0-17.6,6.611c0,6.5,6.705,14.116,19.939,22.658,13.233-8.543,19.939-16.161,19.939-22.658A10.056,10.056,0,0,0,219.6,133.422Z'
						transform='translate(-131.775 -105.815)'
					/>
					<path
						id='Path_242'
						data-name='Path 242'
						d='M119.781,41.931A41.938,41.938,0,0,0,37.942,29.026l-2.02,12.5L23.61,61.946v6.191h9.8l4.172,22.792H60.952L64.5,105.942H111.68l-5.842-32.786a41.827,41.827,0,0,0,13.944-31.225ZM98.16,51.568c-5.542,6.615-12.929,11.62-20.231,16.083-7.3-4.463-14.69-9.468-20.231-16.083-3.925-4.684-5.915-9.366-5.915-13.915a16.249,16.249,0,0,1,26.146-12.89,16.249,16.249,0,0,1,26.146,12.89c0,4.549-1.99,9.231-5.915,13.915Z'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default MentalHealth;
