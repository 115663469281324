const routeContentStyles = theme => ({
  container: {
    flex: '1',
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  content: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white'
  }
})


module.exports ={
  routeContentStyles
}