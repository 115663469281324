import ContributorSelectorList from './ContributorSelectorList';

export default function ContributorChildrenList({
	selectorListState,
	disabled,
}) {
	return (
		<ContributorSelectorList
			label='Assign Children'
			textFieldLabel='Child'
			disabled={disabled}
			{...selectorListState}
		/>
	);
}
