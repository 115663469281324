export const modalStyle = {
	content: {
		height: '100%',
		width: '100%',
		top: 0,
		left: 0,
		zIndex: 5000,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	overlay: {
		height: '100%',
		with: '100%',
		zIndex: 5000,
	},
};

export const childImageStyles = {
	personIcon: {
		fontSize: '6vw',
		color: '#00A0E8',
	},
	personIconSm: {
		fontSize: '9vw',
		color: '#00A0E8',
	},
};
