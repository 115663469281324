import Switcher from './components/Switcher';
import styles from './headers.module.css';

export default function DraftsHeader() {
	return (
		<div className={styles.headerRoot}>
			<Switcher />
		</div>
	);
}
