import { Switch, Route } from 'react-router-dom';
import AgencyActivities from './components/AgencyActivities';

export default function AgencyActivitiesFeed(_props) {
	return (
		<Switch>
			<Route path={'/agencyactivities'}>
				<AgencyActivities />
			</Route>
		</Switch>
	);
}
