import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { excludeType } from 'helpers';
import { Sort, FilterList } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { OptionMenu, SelectMenu, DashboardToggle } from 'components';
import ListLabel from './ListLabel';
import styles from '../userList.module.css';

const ListSort = (props) => {
	const {
		addHover,
		addToggle,
		label,
		sortMenuItems,
		filterMenuItems,
		isMd,
		addMenuItems,
	} = props;

	const showDashboardToggle = () => {
		return (
			(excludeType(['child']) && isMd && !label && <DashboardToggle />) || null
		);
	};

	return (
		<>
			<div className={styles.listSortContainer}>
				<div className={styles.listSort}>
					<div className={styles.listSortBy}>
						{showDashboardToggle()}
						{sortMenuItems?.length > 0 && label === 'Children' && (
							<OptionMenu menuItems={sortMenuItems} square>
								<Sort className={styles.listSortOptionIcon} />
								<Typography className={styles.listSortOptionLabel}>
									Sort
								</Typography>
							</OptionMenu>
						)}
						{filterMenuItems?.length > 0 && label === 'Children' && (
							<div style={{ paddingLeft: '20px' }}>
								<SelectMenu menuItems={filterMenuItems} square>
									<FilterList className={styles.listSortOptionIcon} />
									<Typography className={styles.listSortOptionLabel}>
										Filter
									</Typography>
								</SelectMenu>
							</div>
						)}
					</div>
				</div>
				{label ? (
					<ListLabel
						addToggle={addToggle}
						addHover={addHover}
						label={label}
						menuItems={addMenuItems}
						isMd={isMd}
					/>
				) : null}
			</div>
		</>
	);
};

const mapStateToProps = ({ mediaQuery: { isMd, isSm } }) => {
	return { isSm, isMd };
};

export default connect(mapStateToProps)(withRouter(ListSort));
