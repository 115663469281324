import store from '../redux/store';
import { apiV2 } from '../axios';
import { setLoader } from '../redux';

export const getLocationAndUpload = async (file, id) => {
	//check if a file needs to be uploaded
	let reqBody = {};
	if (id) {
		reqBody.userId = id;
	}
	let contentType = 'image/jpeg';
	reqBody.contentType = contentType;
	return await apiV2.post(`/file`, reqBody).then(async (res) => {
		return await imageUpload(res.data, file).then(() => {
			return res.data.location;
		});
	});
};

export const imageUpload = async ({ url, location }, file) => {
	let result = await apiV2.put(url, file).catch((error) => {
		store.dispatch(setLoader(false));
		return { error: true, response: error.response };
	});
	let response = { data: result.data, location };
	return response;
};

export const getPhotoUrl = (location, _type, quality) => {
	let qualityQuery = quality ? `&quality=${quality}` : '';
	return apiV2.get(`/file?location=${location}${qualityQuery}`).catch((err) => {
		return { error: true, message: err };
	});
};

export const getDisplayPhotofromArray = async (photos, heicSetCB) => {
	if (!!photos?.[0]?.url) {
		try {
			new URL(photos[0].url);
			return { url: photos[0].url, index: 0 };
		} catch (e) {
			return getPhotoUrl(photos[0].location).then((photoRes) => {
				return Promise.resolve({ url: photoRes.data.url, index: 0 });
			});
		}
	} else {
		const nonHeicIndex = photos.findIndex((val = {}) => {
			return !val.heic;
		});
		if (nonHeicIndex >= 0) {
			heicSetCB && heicSetCB(false);
			return Promise.resolve({
				url: URL.createObjectURL(photos[nonHeicIndex].file),
				index: nonHeicIndex,
			});
		} else {
			heicSetCB && heicSetCB(true);
			return Promise.resolve({ url: photos[0].file });
		}
	}
};

export const getAcceptedPhotoArray = (photos, acceptedExt) => {
	let acceptedFiles = [];
	let rejected = 0;
	photos.forEach((val) => {
		let verified = verifyFileType(val, acceptedExt);
		if (verified) {
			if (verified === 'heic') {
				acceptedFiles.push({ file: val, heic: true });
			} else {
				acceptedFiles.push({ file: val });
			}
		} else {
			rejected++;
		}
	});

	return Promise.resolve({ acceptedFiles, rejected });
};

export const verifyFileType = (file, acceptedExt) => {
	if (file) {
		const fileExt = file?.name
			.substr(file.name.lastIndexOf('.') + 1, file.name.length)
			.toLowerCase();

		if (acceptedExt.indexOf(fileExt) === -1) {
			return false;
		} else {
			return fileExt;
		}
	}
};

export const getDisplayPostImage = async (photo, onLoad, onError) => {
	var img = new Image();
	img.src = photo?.url || '';

	img.onload = onLoad;
	img.onerror = onError;
	return img;
};
