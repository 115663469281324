import React from 'react';
import { makeStyles, InputAdornment } from '@material-ui/core';
import { SecondaryButton, TextField } from '../..';
import clsx from 'clsx';

const styles = (theme) => ({
	staticRoot: {
		width: '100%',
		paddingBottom: '8px',
	},
	staticBottom: {
		borderBottom: `1px solid #E8ECEE`,
	},

	staticSection: {
		width: '100%',
		height: '56px',
		padding: '8px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		justifyContent: 'center',
	},
	staticSectionEdit: {
		height: 'auto',
	},
	staticLabel: {
		fontWeight: 'bold',
	},
	staticValue: {},
	staticFirst: {
		marginTop: '8px',
		borderTop: `1px solid #E8ECEE`,
		padding: '16px 0 8px 0',
	},
	staticLast: {
		marginBottom: '8px',
		borderBottom: `1px solid #E8ECEE`,
		padding: '8px 0 16px 0',
	},
	staticEditButton: {
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	staticFloatRight: {
		float: 'right',
	},
});

const useStyles = makeStyles(styles);

function StaticInfo(props) {
	const classes = useStyles();
	const { infoArray, hideBottom } = props;

	const infoMapper = (val) => {
		const {
			first,
			label,
			value,
			edit,
			key,
			name,
			allowEdit,
			setEdit,
			component,
		} = val || {};
		const containerClass = clsx(
			classes.staticSection,
			first ? classes.staticFirst : null,
			edit ? classes.staticSectionEdit : null
		);
		return (
			component ||
			(val ? (
				<div className={containerClass} key={label}>
					<div className={classes.staticLabel}>{label}</div>
					{name && !edit ? (
						<input
							readOnly
							name={name || ''}
							label={label}
							value={value}
							style={{ height: '0', width: '0', padding: '0', border: '0' }}
						/>
					) : null}
					{!edit ? (
						<div className={classes.staticValue}>{value}</div>
					) : (
						<>
							{allowEdit ? (
								<TextField
									key={key || ''}
									placeholder={label}
									value={value}
									onChange={edit}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<SecondaryButton
													onClick={() => {
														setEdit(false);
													}}>
													CANCEL
												</SecondaryButton>
											</InputAdornment>
										),
									}}
								/>
							) : (
								<div className={classes.staticEditable}>
									{value ? `${value}  ` : ''}
									<span
										className={clsx(
											classes.staticEditButton,
											value ? classes.staticFloatRight : null
										)}
										onClick={() => {
											setEdit(true);
										}}>
										EDIT
									</span>
								</div>
							)}
						</>
					)}
				</div>
			) : null)
		);
	};
	return (
		<div
			className={clsx(
				classes.staticRoot,
				hideBottom ? null : classes.staticBottom
			)}>
			{infoArray.map(infoMapper)}
		</div>
	);
}

export default StaticInfo;
