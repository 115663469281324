import theme from '../../material/styles/muiTheme';

export const getMediaQueryState = ({ mediaQuery }) => mediaQuery;

export const getHeight = ({ mediaQuery }) => mediaQuery.height;

export const getWidth = ({ mediaQuery }) => mediaQuery.width;

export const getPortrait = ({ mediaQuery }) => mediaQuery.portrait;

export const getIsLg = ({ mediaQuery }) => mediaQuery.isLg;

export const getIsMd = ({ mediaQuery }) => mediaQuery.isMd;

export const getIsSm = ({ mediaQuery }) => mediaQuery.isSm;

export const getRightContentWidth = ({ mediaQuery }) =>
	mediaQuery.rightContentWidth;

export const getRightContentLessThanMin = ({ mediaQuery }) => {
	const { contentMax } = theme.width || {};
	const themeWidth = Number(contentMax?.replace('px', ''));
	return mediaQuery.rightContentWidth < themeWidth;
};
