import { MenuItem } from '@material-ui/core';
import { TextFieldRow } from 'components';

export const ethnicityMap = {
	americanIndian: 'American Indian or Alaska Native',
	asian: 'Asian',
	black: 'Black or African American',
	white: 'White',
	hispanic: 'Hispanic or Latino',
	nativeHawaiian: 'Native Hawaiian and Other Pacific Islander',
	notSpecified: 'Not Specified',
};

export default function ChildEthnicity({ value, onChange, disabled }) {
	const ethnicityObjectMap = () => {
		let array = [];
		for (let [key, value] of Object.entries(ethnicityMap)) {
			array.push({ value: key, label: value });
		}
		return array;
	};

	const ethnicityMapMap = ethnicityObjectMap().map((val) => {
		return (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		);
	});

	return (
		<TextFieldRow
			value={value}
			label='Ethnicity'
			name='ethnicity'
			onChange={onChange}
			disabled={disabled}
			select>
			{ethnicityMapMap}
		</TextFieldRow>
	);
}
