export const getContributorState = ({ contributor }) => contributor;

export const getContributorListIds = ({ contributor }) =>
	contributor.contributorList;

export const getContributorList = ({ contributor }) => {
	const hash = contributor.contributorHash;
	return contributor.contributorList?.map?.((id) => hash[id]);
};

export const getContributorContributorHash = ({ contributor }) =>
	contributor.contributorHash;

export const getContributorContributor = ({ contributor }) =>
	contributor.contributor;

export const getContributorContributorChildIds = ({ contributor }) =>
	contributor.contributor?.childIds;

export const getContributorChildren = ({ contributor }) =>
	contributor.contributor?.children;

export const getContributorContributorLoaded = ({ contributor }) =>
	contributor.contributorLoaded;

export const getContributorContributorPending = ({ contributor }) =>
	contributor.contributorPending;
