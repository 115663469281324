import { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import * as service from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { PrimaryButton, SecondaryButton } from 'components';
import VaultReasonDropdown from './VaultReasonDropdown';
import styles from '../timeline.module.css';
import { modalStyle } from '../timelineStyles';

const VaultReason = () => {
	const modalOpen = useSelector((state) => state.posts.vaultReason);
	const vaultPost = useSelector((state) => state.posts.vaultPost);
	const child = useSelector((state) => state.lifebooks.child);

	const [reason, setReason] = useState('Other');

	const {
		setLoader,
		removePost,
		setChildPendCount,
		setVaultReason,
		setVaultPost,
	} = useCreateDispatches();

	const moveToVault = () => {
		const id = vaultPost.id;
		const childId = child.id;
		const childPending = child.pendingPostCount;

		if (id) {
			setLoader(true);
			removePost(id);
			service
				.moveToVault(id, reason)
				.then(({ data }) => {
					data && removePost({ id });
					service.getCurrentChild(childId, { fetch: true });
					if (vaultPost.status !== 'approved') {
						setChildPendCount({ childId, count: childPending - 1 });
					}
				})
				.finally(() => {
					setVaultReason(false);
					setVaultPost({});
					setLoader(false);
				});
		}
	};

	const handleCancel = () => {
		setVaultPost({});
		setVaultReason(false);
	};

	const changeVaultReason = (e) => {
		setReason(e?.target?.value);
	};

	return (
		<div style={{ zIndex: 5000 }}>
			<Modal
				ariaHideApp={false}
				style={modalStyle}
				isOpen={modalOpen}
				transparent={false}>
				<div className={styles.modalContainer}>
					<div className={styles.textContainer}>
						Please provide a reason for moving this post to the vault
					</div>
					<div className={styles.reasonContainer}>
						<VaultReasonDropdown onChange={changeVaultReason} />
					</div>
					<div className={styles.buttonContainer}>
						<PrimaryButton onClick={() => moveToVault()}>Submit</PrimaryButton>
						<SecondaryButton onClick={() => handleCancel()}>
							Close
						</SecondaryButton>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default VaultReason;
