import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCreateDispatches } from 'helpers/hooks';
import { emailTypeCheck } from 'helpers';
import { getUserStateId } from 'redux/selectors';
import { sendSupportMessage } from 'services';

export default function useSupportFormCallBacks(
	getState,
	setSubmitted,
	getRecaptchaValue
) {
	const userId = useSelector(getUserStateId);
	const { setSnackbar, setLoader } = useCreateDispatches();
	const { push } = useHistory();

	const handleValidate = (state) => {
		const { subject, message, email } = state;
		let validationError = '';
		if (!message || !email || !subject) {
			validationError = 'Please include an email address, subject and message';
		} else if (!emailTypeCheck(email)) {
			validationError = 'Invalid email format';
		} else if (!userId && !getRecaptchaValue()) {
			validationError = 'Please validate that you are human';
		}
		if (!validationError) {
			return true;
		} else {
			setSnackbar(
				{ variant: 'error', message: validationError },
				{ loggedIn: false }
			);
			return false;
		}
	};

	const onSubmit = () => {
		const state = getState();
		if (handleValidate(state)) {
			const requestBody = { ...state };
			setLoader(true);
			sendSupportMessage(requestBody)
				.then((res) => {
					setSnackbar(
						{ variant: 'success', message: 'Thank you.' },
						{ loggedIn: false }
					);
				})
				.catch((err) => {
					setSnackbar(
						{
							variant: 'error',
							message: err.response?.data.message,
						},
						{ log: true, metaData: err.response, loggedIn: false }
					);
				})
				.finally(() => {
					setLoader(false);
					setSubmitted(false);
				});
		}
	};

	const onCancel = () => {
		push(userId ? '/lifebooks/' : '/login/');
	};

	return { onSubmit, onCancel };
}
