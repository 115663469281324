/* global google */
import axios from 'axios';
import { loadGoogle } from '../index';

var geocoder;

const initialize = () => {
	geocoder = new google.maps.Geocoder();
};

export const formatAddressBody = async (result, input) => {
	const { address_components, geometry } = result;
	const lat = geometry.location.lat();
	const lng = geometry.location.lng();
	const parsedAddress = parseGoogleAddress(address_components);
	const {
		street_number,
		route,
		locality,
		administrative_area_level_1,
		postal_code,
		country,
	} = parsedAddress;
	if (!street_number) {
		return { error: 'NOT_FOUND' };
	}
	const addressBody = {
		street: `${street_number} ${route}`,
		street2: input.street2,
		city: locality,
		state: administrative_area_level_1,
		postal: postal_code,
		country,
		lat,
		lng,
		tzid: await getTimezone({ lat, lng }),
	};

	return addressBody;
};

export const getTimezone = ({ lat, lng }) => {
	const location = `${lat}, ${lng}`;
	const targetDate = new Date(); // Current date/time of user computer
	const timestamp =
		targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;
	const apiCall = `https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_KEY}`;
	return axios
		.get(apiCall)
		.then((response) => {
			return response.data.timeZoneId;
		})
		.catch((err) => {
			console.log('Timezone error', err);
			return 'America/Los_Angeles';
		});
};

export const parseGoogleAddress = (address_components) => {
	const addressHash = {};
	address_components.forEach((val, i) => {
		val.types.forEach((type) => {
			addressHash[type] = val.short_name;
		});
	});
	return addressHash;
};

export const getGeocode = async (input) => {
	const { street, city, state, zip } = input;
	const errorMsg = 'Address could not be validated. Please try again';

	try {
		loadGoogle(initialize);
		const address = `${street} ${city ? city : ''}, ${state ? state : ''} ${
			zip ? zip : ''
		}`;
		let result = new Promise((resolve, reject) => {
			geocoder.geocode({ address: address }, async (results, status) => {
				if (status === 'OK' && results?.[0]) {
					const addressBody = await formatAddressBody(results[0], input);
					if (addressBody.error) {
						reject(new Error(addressBody.error));
					} else {
						resolve(addressBody);
					}
				} else {
					reject(new Error(errorMsg));
				}
			});
		});
		return result;
	} catch (_err) {
		throw new Error(errorMsg);
	}
};

export const parseAddress = (addressObject) => {
	if (addressObject?.street) {
		const { street, street2, city, state, postal, country } = addressObject;
		const fullStreet = street + (street2 ? `, ${street2}` : '');
		return `${fullStreet}, ${city}, ${state} ${postal}, ${country}`;
	} else return '';
};
