import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { setSnackbar, setMobileList } from 'redux/index';
import * as services from 'services';
import useOnInvite from './utils/useOnInvite';
import { MenuItem } from '@material-ui/core';
import InviteAssignment from './components/InviteAssignment';
import InviteNoBranch from './components/InviteNoBranch';
import InviteForm from './components/InviteForm';
import * as Comp from 'components';
import style from './sendInvite.module.css';

function SendInvites(props) {
	const {
		initialManager,
		initialBranch,
		onClose,
		type,
		managerList,
		getList,
		branchList: branchListProp,
		setMobileList,
	} = props;
	const [inviteArray, setInviteArray] = useState(['', '', '']);
	const [manager, setManager] = useState(initialManager || '');
	const [branch, setBranch] = useState(initialBranch || '');
	const [branchList, setBranchList] = useState([]);

	const typeProps = {
		mentor: {
			header: 'Mentors',
			buttonLabel: 'Mentor',
			role: 'mentor',
		},
		socialWorker: {
			header: 'Social Workers',
			buttonLabel: 'Social Worker',
			role: 'social-worker',
		},
		manager: {
			header: 'Managers',
			buttonLabel: 'Manager',
			role: 'manager',
		},
		admin: {
			header: 'Administrators',
			buttonLabel: 'Admin',
			role: 'admin',
		},
	};

	const getApplicableLists = useCallback(async () => {
		if (type === 'manager') {
			if (!branchListProp) {
				let branchResponse = await services.getBranchList();
				if (!branchResponse.error) {
					setBranchList(branchResponse.data);
				}
			} else {
				setBranchList(branchListProp);
			}
		}
	}, [branchListProp, type]);

	useEffect(() => {
		getApplicableLists();
	}, [getApplicableLists]);

	const managerMap =
		managerList &&
		Boolean(managerList.length) &&
		managerList.map((val, i) => {
			return (
				<MenuItem key={val.id} value={val.id}>
					{val.firstname ? `${val.firstname} ${val.lastname}` : val.email}
				</MenuItem>
			);
		});

	const selectManager = (e) => {
		const { value } = e.target;
		let index = managerList.findIndex((val) => {
			return val.id === e.target.value;
		});
		setManager(value);
		setBranch(managerList[index].branchId);
	};

	const branchMap =
		branchList &&
		Boolean(branchList.length) &&
		branchList.map((val) => {
			return (
				<MenuItem key={val.id} value={val.id}>
					{val.name}
				</MenuItem>
			);
		});

	const selectBranch = (e) => {
		setBranch(e.target.value);
	};

	const selectClose = () => {
		setMobileList(true);
		onClose();
	};

	const onInvite = useOnInvite(typeProps[type], getList, selectClose);

	const buttonContainer = [
		<Comp.SecondaryButton key={'send-sw-invite'} onClick={selectClose}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton
			key={'invite-sw'}
			onClick={() => onInvite(inviteArray, { branch, manager })}>
			Invite
		</Comp.PrimaryButton>,
	];

	return (
		<div className={style.container}>
			<Comp.PageLayout
				title='Invite'
				buttonArray={buttonContainer}
				navBack={selectClose}>
				<div className={style.content}>
					{!branchMap && type === 'manager' ? (
						<InviteNoBranch />
					) : (
						<>
							{['socialWorker', 'mentor'].includes(type) && managerMap ? (
								<InviteAssignment
									label='Manager'
									value={manager}
									onSelect={selectManager}
									listMap={managerMap}
								/>
							) : null}
							{type === 'manager' ? (
								<InviteAssignment
									label='Branch'
									value={branch}
									onSelect={selectBranch}
									listMap={branchMap}
								/>
							) : null}
							<InviteForm
								type={type}
								header={typeProps[type].header}
								buttonLabel={typeProps[type].buttonLabel}
								inviteArray={inviteArray}
								setInviteArray={setInviteArray}
							/>
						</>
					)}
				</div>
			</Comp.PageLayout>
		</div>
	);
}

export default connect(null, { setSnackbar, setMobileList })(SendInvites);
