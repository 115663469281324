import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux'

const PrivateRoute = (props) => {
  const { component: Component, user, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem('token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login/', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = ({user}) => {
  return { user }
}

export default connect(mapStateToProps)(PrivateRoute);
