export const getTitle = (user) => {
	if (user.firstname) {
		return `${user.firstname} ${user.lastname}`;
	}

	if (user.email) {
		return user.email;
	}

	return 'User';
};

export const showStaticText = (user, selectedUser) => {
	if (user.id === selectedUser.id) {
		return true;
	}

	if (selectedUser.name?.includes('@')) {
		return true;
	}

	return false;
};

export const splitCasaPermissions = (i, c) => {
	let addPermissions = [];
	let removePermissions = [];

	if (c.basic_read_profile !== i.basic_read_profile) {
		c.basic_read_profile
			? addPermissions.push('basic_read_profile')
			: removePermissions.push('basic_read_profile');
	}

	if (c.basic_write_profile !== i.basic_write_profile) {
		c.basic_write_profile
			? addPermissions.push('basic_write_profile')
			: removePermissions.push('basic_write_profile');
	}

	if (c.mgmt_read_profile !== i.mgmt_read_profile) {
		c.mgmt_read_profile
			? addPermissions.push('mgmt_read_profile')
			: removePermissions.push('mgmt_read_profile');
	}

	if (c.mgmt_write_profile !== i.mgmt_write_profile) {
		c.mgmt_write_profile
			? addPermissions.push('mgmt_write_profile')
			: removePermissions.push('mgmt_write_profile');
	}

	if (c.export_lifebook !== i.export_lifebook) {
		c.export_lifebook
			? addPermissions.push('export_lifebook')
			: removePermissions.push('export_lifebook');
	}

	if (c.mgmt_lifebook !== i.mgmt_lifebook) {
		c.mgmt_lifebook
			? addPermissions.push('mgmt_lifebook')
			: removePermissions.push('mgmt_lifebook');
	}

	if (c.mgmt_contributor !== i.mgmt_contributor) {
		c.mgmt_contributor
			? addPermissions.push('mgmt_contributor')
			: removePermissions.push('mgmt_contributor');
	}

	return { addPermissions, removePermissions };
};

export const getNameTile = (child) => {
	if (child.firstname) {
		return `${child.firstname} ${child.lastname}`;
	}

	if (child.name) {
		return child.name;
	}

	return child.email;
};

export const casaPermissionsToObject = (permissions) => {
	const casaPermissions = {
		basic_read_profile: false,
		basic_write_profile: false,
		mgmt_read_profile: false,
		mgmt_write_profile: false,
		export_lifebook: false,
		mgmt_contributor: false,
		mgmt_lifebook: false,
	};

	if (permissions.includes('basic_read_profile')) {
		casaPermissions.basic_read_profile = true;
	}

	if (permissions.includes('basic_write_profile')) {
		casaPermissions.basic_write_profile = true;
	}

	if (permissions.includes('mgmt_read_profile')) {
		casaPermissions.mgmt_read_profile = true;
	}

	if (permissions.includes('mgmt_write_profile')) {
		casaPermissions.mgmt_write_profile = true;
	}

	if (permissions.includes('export_lifebook')) {
		casaPermissions.export_lifebook = true;
	}

	if (permissions.includes('mgmt_lifebook')) {
		casaPermissions.mgmt_lifebook = true;
	}

	if (permissions.includes('mgmt_contributor')) {
		casaPermissions.mgmt_contributor = true;
	}

	return casaPermissions;
};

export const createAllPermissions = {
	basic_write_profile: true,
	basic_read_profile: true,
	mgmt_read_profile: true,
	mgmt_write_profile: true,
	mgmt_lifebook: true,
	mgmt_contributor: true,
	export_lifebook: true,
};
