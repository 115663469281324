import { useState } from 'react';
import ProfileEmailField from './ProfileEmailField';
import ProfileEmailStatic from './ProfileEmailStatic';

export default function ProfileEmail({ email }) {
	const [edit, setEdit] = useState(false);

	return edit ? (
		<ProfileEmailField email={email} setEdit={setEdit} />
	) : (
		<ProfileEmailStatic email={email} setEdit={setEdit} />
	);
}
