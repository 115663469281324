import { useState } from 'react';
import { useSelector } from 'react-redux';
import MappedChild from './MappedChild';
import CasesButtons from './CasesButtons';
import styles from '../familyConnect.module.css';

const InnerModal = () => {
	const cases = useSelector((state) => state.familyConnections.cases);
	const { isMd } = useSelector(({ mediaQuery }) => mediaQuery);

	const [selectedChild, setSelectedChild] = useState(null);

	const handleOnClick = (e) => {
		selectedChild === e ? setSelectedChild(null) : setSelectedChild(e);
	};

	const children =
		cases.length > 0 ? (
			cases.map((e) => {
				const name = `${e.person?.firstName} ${e.person?.lastName}`;

				return (
					<MappedChild
						key={e.id}
						name={name}
						id={e.id}
						picture={e.person?.picture}
						selectedId={selectedChild}
						onClick={(e) => handleOnClick(e)}
					/>
				);
			})
		) : (
			<div className={styles.noCaseText}>There are no cases for this user</div>
		);

	return (
		<div className={isMd ? styles.innerModalSm : styles.innerModal}>
			<div className={styles.modalHeader}>
				<p className={isMd ? styles.headerTextSm : styles.headerText}>
					Family Connections
				</p>
			</div>
			<div className={styles.allChildren}>{children}</div>
			<CasesButtons id={selectedChild} />
		</div>
	);
};

export default InnerModal;
