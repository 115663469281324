import PostImage from './PostImage';
import PostTileNoImage from './PostTileNoImage';
import styles from '../timeline.module.css';

const PostTile = (props) => {
	const hasPhotos = props.post?.photos?.length >= 1;

	return (
		<div className={styles.postTileRoot}>
			{hasPhotos ? (
				<PostImage {...props} carouselDisabled />
			) : (
				<PostTileNoImage {...props} />
			)}
		</div>
	);
};

export default PostTile;
