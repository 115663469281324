import { useSelector } from 'react-redux';
import { getUserState } from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';
import useGetCallbacks from './useGetCallbacks';
import useGetMenuPermissions from './useGetMenuPermissions';
import ChangeRequestButton from '../components/ChangeRequestButton';

export default function useGetMenuOptions(post) {
	const { id: userId, type: userType } = useSelector(getUserState);

	const checkDateDayOld = (date) => {
		const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
		return new Date(date).getTime() > oneDay;
	};

	const { status, createdAt, createdBy } = post;
	const isOwnPost = createdBy?.id === userId;
	const isDayOld = checkDateDayOld(createdAt);

	const childDeleteLabel = () => {
		if (userType === 'child' && post.variant !== 'vault') {
			if (isOwnPost && isDayOld) {
				return 'Delete';
			} else {
				return 'Remove';
			}
		} else {
			return 'Delete';
		}
	};

	const {
		onEditPost,
		onApprovePost,
		onDeletePost,
		onMoveServicePost,
		onMoveFromVault,
	} = useGetCallbacks(post);

	const menuPermissions = useGetMenuPermissions(post);
	const changeRequested = status === 'change_requested';

	const { setModal, setVaultReason, setVaultPost } = useCreateDispatches();

	const withCloseModal = (callback) => {
		return () => {
			setModal(false);
			callback();
		};
	};

	const vaultMove = () => {
		setModal(false);
		setVaultPost(post);
		setVaultReason(true);
	};

	return [
		menuPermissions.edit && {
			value: 'edit',
			label: 'Edit',
			onClick: withCloseModal(onEditPost),
		},
		menuPermissions.changeRequest && {
			value: 'changeRequest',
			disabled: changeRequested,
			label: (
				<ChangeRequestButton post={post} disabled={changeRequested}>
					Request Change
				</ChangeRequestButton>
			),
			onClick: () => void 0,
		},
		menuPermissions.approve && {
			value: 'approve',
			label: 'Approve',
			onClick: withCloseModal(onApprovePost),
		},
		menuPermissions.delete && {
			value: 'delete',
			label: childDeleteLabel(),
			onClick: withCloseModal(onDeletePost),
		},
		menuPermissions.vault && {
			value: 'vault',
			label: 'Move to Vault',
			onClick: vaultMove,
		},
		menuPermissions.moveFromVault && {
			value: 'return',
			label: 'Move To Timeline',
			onClick: withCloseModal(onMoveFromVault),
		},
		menuPermissions.move && {
			value: 'move',
			label: post.displayNSPInTimeline
				? 'Remove from Timeline'
				: 'View in Timeline',
			onClick: withCloseModal(onMoveServicePost),
		},
	];
}
