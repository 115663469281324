import SectionLayout from './SectionLayout';
import ServiceSwitcherMobile from './ServiceSwitcherMobile';
import styles from '../headers.module.css';

export default function PendingHeaderFilter() {
	const options = [
		{ label: 'All', path: 'pending' },
		{ label: 'Timeline', path: 'pendingTL' },
		{ label: 'Services', path: 'pendingNsp' },
	];

	return (
		<SectionLayout label={`Filter:`} className={styles.sectionLayoutContainer}>
			<ServiceSwitcherMobile options={options} />
		</SectionLayout>
	);
}
