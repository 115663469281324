import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserStateId } from 'redux/selectors';
import * as service from 'services';
import * as hook from 'helpers/hooks';
import { errors } from 'helpers';
import { PrimaryButton, SecondaryButton, DeleteButton } from 'components';

export const useGetManagerButtons = (getBody, resetListChanges) => {
	const history = useHistory();
	const { setLoader, setSnackbar, setSnackbarError } =
		hook.useCreateDispatches();
	const { managerId } = useParams();
	const userId = useSelector(getUserStateId);
	const isOwnUser = managerId === userId;
	const onPasswordConfirm = hook.usePasswordConfirm();
	const roleSelectRedirect = hook.useRoleSelectRedirect();

	const errorHandler =
		(error) =>
		({ response }) => {
			setSnackbarError({ response, ...error }, { log: true });
		};

	function CancelButton() {
		const onClick = () => history.goBack();
		return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
	}

	function ArchiveButton() {
		const onDelete = async () => {
			setLoader(true);
			service
				.archiveAgencyUser(managerId)
				.then(async (res) => {
					setSnackbar({
						variant: 'success',
						message: 'Manager removed successfully',
					});
					await service.getAgencyUsers();
					await service.setChildrenList();
					history.push('/managers/');
				})
				.catch(errorHandler(errors.USER_ARCHIVE('Manager')))
				.finally(() => {
					setLoader(false);
				});
		};

		return (
			!isOwnUser && (
				<DeleteButton onClick={() => onPasswordConfirm(onDelete)}>
					Delete
				</DeleteButton>
			)
		);
	}

	function SaveButton() {
		const addToManager = async (user) => {
			return await service.updateAgencyUser({
				id: user.id,
				managerAgencyUserId: managerId,
			});
		};

		const removeFromManager = async (user) => {
			return await service.updateAgencyUser({
				id: user.id,
				managerAgencyUserId: '',
			});
		};

		const onClick = async () => {
			setLoader(true);
			const { reqBody, socialWorkerChanges } = getBody();

			const promiseArray = [service.updateAgencyUser(reqBody)];

			socialWorkerChanges.add?.forEach((val) => {
				promiseArray.push(addToManager(val));
			});

			socialWorkerChanges.remove?.forEach((val) => {
				promiseArray.push(removeFromManager(val));
			});

			Promise.all(promiseArray)
				.then(async () => {
					await service.getAgencyUsers();
					if (reqBody.role) {
						roleSelectRedirect(reqBody.role, reqBody.id);
					} else {
						resetListChanges();
					}
				})
				.catch(errorHandler(errors.USER_SAVE('Manager')))
				.finally(() => {
					setLoader(false);
				});
		};

		return <PrimaryButton onClick={onClick}>Save Changes</PrimaryButton>;
	}

	return [
		<CancelButton key='cancel' />,
		<ArchiveButton key='archive' />,
		<SaveButton key='save' />,
	];
};
