/* global google */
import { useState, useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { loadGoogle } from 'index';
import styles from '../child.module.css';

const ChildSchoolList = (props) => {
	const [schoolList, setSchoolList] = useState([]);

	let map;
	let service;

	useEffect(() => {
		loadGoogle(initialize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialize = () => {
		let query = (props.input && props.input.split(' ').join('+')) || '';
		map = new google.maps.Map(document.getElementById('map'), {
			zoom: 10,
			disableDefaultUI: true,
		});
		service = new google.maps.places.PlacesService(map);
		const request = {
			type: ['school'],
			region: 'US',
			query,
		};
		service.textSearch(request, getResults);
	};

	const getResults = (res) => {
		setSchoolList(res);
	};

	const menuItemMapper = (val) => {
		const { schoolSelect, clearAnchor } = props;

		const onClick = () => {
			schoolSelect(val);
			clearAnchor();
		};

		return (
			<MenuItem
				className={styles.schoolListMenuItem}
				key={val.place_id}
				value={val.place_id}
				onClick={onClick}>
				<div className={styles.schoolName}>{val.name}</div>
				<div>{val.formatted_address}</div>
			</MenuItem>
		);
	};

	let schoolListMap = schoolList.length
		? schoolList.map(menuItemMapper)
		: 'loading';

	return (
		<>
			<div className={styles.schoolListMap} id='map' />
			<Menu
				anchorEl={props.anchorEl}
				keepMounted
				open={Boolean(props.anchorEl)}
				onClose={props.clearAnchor}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{
					style: {
						width: 'calc((100vw - 590px) * 0.9)',
						maxWidth: '600px',
						minWidth: '480px',
					},
				}}>
				{schoolListMap}
			</Menu>
		</>
	);
};

export default ChildSchoolList;
