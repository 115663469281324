import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { logoutUser } from 'services';

function AutoLogout(props) {
	const { location } = props;

	useEffect(() => {
		const compareTime = () => {
			const activeTime = localStorage.getItem('active');
			const currentTime = new Date().getTime();
			if (currentTime > Number(activeTime)) {
				logoutUser();
			}
		};
		compareTime();
	}, [location]);

	return null;
}

export default withRouter(AutoLogout);
