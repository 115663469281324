import * as muiIcons from '@material-ui/icons';
import * as mdiIcons from 'mdi-material-ui';

const formatIconName = (iconName = '') => {
	return iconName
		?.split('-')
		.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
		.join('');
};

const getLibraryName = (iconSet) => {
	switch (iconSet) {
		case 'mdi':
			return mdiIcons;
		case 'mi':
		default:
			return muiIcons;
	}
};

export function getIcon(categoryConfig = {}, nsp) {
	const NoIcon = () => null;

	if (typeof categoryConfig === 'string') return NoIcon;

	const name = categoryConfig && formatIconName(categoryConfig.iconName);
	const library = categoryConfig && getLibraryName(categoryConfig.iconSet);

	const Icon = library[name] || NoIcon;

	return Icon;
}
