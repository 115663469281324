import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/index';
import { useCreateDispatches } from 'helpers/hooks';
import { useActivityMapper, useGetActivity } from '../utils';
import styles from '../activity.module.css';

export default function Activities() {
	const { setSnackbar } = useCreateDispatches();
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));

	const { childId } = useParams();
	const [activity, setActivity] = useState(null);
	const [lastPostActivityId, setlastPostActivityId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const getActivity = useGetActivity({
		setActivity,
		setlastPostActivityId,
	});

	const populateActivityFeed = useCallback(async () => {
		dispatchLoader(true);
		setIsLoading(true);
		await getActivity({ childId }).finally(() => {
			setIsLoading(false);
			dispatchLoader(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	useEffect(() => {
		populateActivityFeed();
	}, [populateActivityFeed]);

	const handleScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (
			activity &&
			activity.length &&
			lastPostActivityId &&
			!isLoading &&
			scrollTop >= scrollHeight - clientHeight - 5
		) {
			lazyLoadActivity();
		}
	};

	const lazyLoadActivity = async () => {
		if (lastPostActivityId) {
			dispatchLoader(true);
			setIsLoading(true);
			await getActivity({ childId, lastPostActivityId })
				.then((result) => {
					setActivity([...activity, ...result.data.postActivities]);
					setlastPostActivityId(result.data.lastPostActivityId || null);
					setIsLoading(false);
					dispatchLoader(false);
				})
				.catch((err) => {
					setIsLoading(false);
					dispatchLoader(false);
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					);
				});
		}
	};

	const activityMapper = useActivityMapper(lastPostActivityId);

	const activityMap =
		activity && activity.length && activity.map(activityMapper);

	return (
		<div className={styles.activityFeedRoot} onScroll={handleScroll}>
			{activity ? (
				activity.length ? (
					activityMap
				) : (
					<div className={styles.noPostRoot}>
						<img src='/assets/ella-elephant.svg' alt='Ella Elephant' />
						<div className={styles.noPostTitle}>{'No Activities Found'}</div>
					</div>
				)
			) : null}
		</div>
	);
}
