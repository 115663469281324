import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EllaLogo(props) {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='71.219'
			height='42.477'
			viewBox='0 0 71.219 42.477'
			{...props}>
			<defs>
				<clipPath id='clip-path'>
					<rect
						id='Rectangle_435'
						data-name='Rectangle 435'
						width='19.157'
						height='14.854'
					/>
				</clipPath>
			</defs>
			<g id='Ella-logo-icon' transform='translate(-33.6 -226.306)'>
				<path
					id='Path_1828'
					data-name='Path 1828'
					d='M55.557,302.719c.29,0,.581.007.871.03a29.348,29.348,0,0,0-.089,3.7v.052l.007.052a18.178,18.178,0,0,0,2.5,7.922,5.817,5.817,0,0,1-2.278-1.5c-.045-.03-.216-.328-.186.082.335,4.385,4.237,7.445,8.473,8.384a12.658,12.658,0,0,0,2.948.29,1.3,1.3,0,0,0,.365.6,6.863,6.863,0,0,0,2.182,1.042,20.709,20.709,0,0,0,5.361.8c1.407.156,2.36,2.182,2.852,3.3.439.99.8,2,1.221,2.993a5.2,5.2,0,0,0,1.511,2.256H41.351a7.832,7.832,0,0,1-7.751-7.751,7.75,7.75,0,0,1,7.751-7.751,7.558,7.558,0,0,1,2.025.268,12.433,12.433,0,0,1,12.181-14.779m20.475-2.509a.845.845,0,0,1-.045-.186,5.205,5.205,0,0,0-3.053-4.088,6.451,6.451,0,0,0-6.225.6c-2.628,1.9-2.368,5.137-1.258,7.84.1.253.395.893.7.975.372.1,1.2-.342,1.5-.514.73-.409,1.444-.9,2.144-1.362.916-.6,1.832-1.184,2.792-1.713a20.837,20.837,0,0,1,3.44-1.549'
					transform='translate(0 -63.95)'
					fill='#00a0e8'
					fillRule='evenodd'
				/>
				<path
					id='Path_1829'
					data-name='Path 1829'
					d='M374.628,235.7c.789-.134,1.757.045,1.727-.64-.931-11.384-22.828-14.273-22.024,7.393.894,7.1,2.971,8.957,7.58,12.226-1.824.544-5.711-2.122-7.14-3.418-.127-.119-.194-.112-.112.06,4.988,6.478,10.319,5.227,12.285,5.167.454.38-1.5.722-1.161,1.042.186.171,2.181,1.4,6.82,1.534,3.79.335,4.6,7.855,6.224,8.786,2.859,1.452,4.624.893,6.895.3.343-.171-.38-3.574,1.31-3.529,1.05-.06,1.392,1.534,1.057,3.447a19.217,19.217,0,0,0,6.455-.015c3.142-2.725,6.426-8.324,6.292-12.657.037-.38.551,1.69.73,1.616.074-.03.089-.283.082-.424-.134-5.495-2.621-9.821-5.77-11.451,1.511,11.414-10.245,18.592-16.76,10.93,6.6,5.726,15.7-.127,15.554-9.53v-.03h0v-.156c0-.29-.015-.573-.037-.856h0l-.03-.372h0v-.134a11.318,11.318,0,0,0-3.06-6.56,12.8,12.8,0,0,0-5.629-3.4h-.007a12.971,12.971,0,0,0-8.778,1.534c-5.607.417-9.441,3.857-12.077,5.346-1.742.975-2.963,1.109-3.842-1.028-4.788-11.652,11.243-14.2,12.747-4.981.037.194.365-.164.67-.2m-6.135,14.824c1.1-2.487,2.747-1.556,3.343-.395a8.683,8.683,0,0,0-3.343.395'
					transform='translate(-296.831)'
					fill='#00a0e8'
					fillRule='evenodd'
				/>
				<g
					id='Group_994'
					data-name='Group 994'
					transform='translate(82.309 245.128)'
					opacity='0.2'>
					<g id='Group_993' data-name='Group 993' transform='translate(0)'>
						<g id='Group_992' data-name='Group 992' clipPath='url(#clip-path)'>
							<path
								id='Path_1830'
								data-name='Path 1830'
								d='M687.8,490.123Zm0,.008c6.515,7.661,18.264.484,16.753-10.93a8.532,8.532,0,0,1,2.4,1.906c.186,13.908-14.817,16.1-19.15,9.024'
								transform='translate(-687.8 -479.193)'
								fill='#00a0e8'
								fillRule='evenodd'
							/>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
}
