import { connect } from 'react-redux';
import { getIcon } from 'helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
	MenuItem,
	FormControl,
	ListItemIcon,
	TextField,
	Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import styles from '../editPost.module.css';

export function CategorySelect({
	className,
	children,
	handleChange,
	none,
	style,
	value,
	required,
	categories,
	disabled,
	...props
}) {
	const useStyles = makeStyles(() => ({
		categorySelectMenuItem: {
			display: 'flex',
			alignItems: 'center',
		},
	}));

	const variant = props.variant
		? { variant: props.variant, label: 'Category' }
		: {};

	const classes = useStyles();

	const categoryMap =
		categories &&
		Boolean(categories.length) &&
		categories.map((val, i, arr) => {
			const Icon = getIcon(val);
			const isParent = val.id === arr[i + 1]?.parentId;
			const parentClass = isParent ? styles.parentMenuItem : null;

			return [
				isParent && <Divider className={styles.categorySelectDivider} />,
				<MenuItem
					className={clsx(classes.categorySelectMenuItem, parentClass)}
					value={val.id}
					key={val.id}>
					{<ListItemIcon>{Icon ? <Icon /> : null}</ListItemIcon>}
					{val.label}
				</MenuItem>,
			];
		});
	return categories && Boolean(categories.length) ? (
		<FormControl className={className ? className : styles.select}>
			<TextField
				select
				value={value || ''}
				className={styles.select}
				InputProps={{ classes: { input: styles.inputProps } }}
				onChange={handleChange}
				disabled={disabled}
				required={required}
				name='category'
				{...variant}>
				{children}
				<MenuItem value=''>None</MenuItem>
				{categoryMap}
			</TextField>
		</FormControl>
	) : null;
}

const mapStateToProps = ({ lifebooks }) => {
	return {
		categories: lifebooks.categories,
		categoriesHash: lifebooks.categoriesHash,
	};
};

export default connect(mapStateToProps)(CategorySelect);
