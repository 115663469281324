export const currentDate = new Date();
export const currentTime = new Date().getTime();
export const endDate = new Date(currentTime + 3 * 60 * 60 * 1000);

export const getDateRange = (e) => {
	const month = e.getMonth();
	const year = e.getFullYear();
	const startDate = new Date(year, month, 1);
	const endDate = new Date(year, month + 1, 0);
	return { start: startDate.toISOString(), end: endDate.toISOString() };
};
