import { useEffect, useState } from 'react';
import { useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';

export default function useActiveNavOption() {
	const [activeMatch, setActiveMatch] = useState('Timeline');
	const { path } = useRouteMatch();
	const { feedType } = useParams();
	const { search } = useLocation();
	const user = useSelector((state) => state.user.user);
	const child = useSelector(getLifebooksChild);
	const isPrivatized = child?.privatizePosts;

	useEffect(() => {
		let getActive = () => {
			switch (true) {
				case path.includes('/videos'):
					return 'Tutorials';
				case path.includes('/manual'):
					return 'Manual';
				case path.includes('/contact'):
					return 'Contact Us';
				case path.includes('/calendarActivity'):
				case path.includes('/vaultActivity'):
					return 'Activity';
				case path.includes('/calendar'):
					return 'Calendar';
				case path.includes('/activity') ||
					/(a|profileA|exportA)ctivity/.test(feedType):
					return 'Activity';
				case path.includes('/drafts') || feedType === 'drafts':
				case path.includes('/nspDrafts') || feedType === 'nspDrafts':
					return 'Drafts';
				case path.includes('/vault/timeline'):
					return 'Posts';
				case path.includes('/pending') ||
					['pending', 'pendingNsp', 'pendingTL'].includes(feedType):
					return 'Pending';
				case feedType === 'exports':
					return 'Export Requests';
				case path.includes('/archive/:feedType'):
					return 'LifeBook';
				case path.includes('/lifebooks/:feedType'):
					return 'LifeBook';
				case path.includes('/manage'):
				case path.includes('/child') || feedType === 'child':
					return 'Profile';
				case path.includes('/files') || feedType === 'files':
					return 'Files';
				case user.type === 'child' && path.includes('/lifebooks'):
					return 'Lifebook';
				default:
					return isPrivatized ? 'Services' : 'Lifebook';
			}
		};
		setActiveMatch(getActive());
	}, [search, path, feedType, isPrivatized, user]);

	return (label) => {
		return activeMatch === label;
	};
}
