import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPostNotes } from 'redux/reducers';
import { getNotes } from 'services';
import PostNoteList from './components/PostNoteList';
import AddPostNote from './components/AddPostNote';
import clsx from 'clsx';
import styles from './postNote.module.css';

export default function PostNote({ post, collapsible }) {
	const { id: postId, variant } = post;
	const user = useSelector((state) => state.user.user);
	const postNotes = useSelector((state) => state.posts.postNotes);
	const [addNote, setAddNote] = useState(false);
	const [collapseNotes, setCollapseNotes] = useState(true);

	const dispatch = useDispatch();

	const show =
		variant !== 'vault' && user.type === 'agencyuser' && user.role !== 'mentor';

	const AddNoteButton = () => (
		<div
			onClick={onAddNote}
			className={clsx(styles.postNoteListAddButton, styles.postNoteListButton)}>
			Add a Note
		</div>
	);

	const fetchNotes = useCallback(async () => {
		postId && show && (await getNotes(postId));
	}, [postId, show]);

	useEffect(() => {
		fetchNotes();

		return () => {
			dispatch(setPostNotes([]));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchNotes, postId]);

	const onAddNote = async () => {
		setAddNote(true);
	};

	const onSubmit = () => {
		setAddNote(false);
		fetchNotes();
	};

	return show ? (
		<div className={styles.postNoteListRoot}>
			{postId && (
				<>
					{postNotes.length > 0 ? (
						<>
							<div className={styles.postNoteListHeader}>
								{collapsible ? (
									<span
										className={clsx(styles.postNoteListButton)}
										onClick={() => setCollapseNotes(!collapseNotes)}>
										{collapseNotes ? 'Show Notes' : 'Hide Notes'}
									</span>
								) : (
									'Notes'
								)}
								{!addNote && <AddNoteButton />}
							</div>
							{addNote && (
								<AddPostNote
									postId={postId}
									onCancel={() => setAddNote(false)}
									onSubmitCallback={onSubmit}
								/>
							)}
							{(!collapseNotes || !collapsible) && (
								<PostNoteList
									postNoteList={postNotes}
									collapseNotes={!collapseNotes || !collapsible}
								/>
							)}
						</>
					) : addNote ? (
						<AddPostNote
							postId={postId}
							onCancel={() => setAddNote(false)}
							onSubmitCallback={onSubmit}
						/>
					) : (
						<AddNoteButton />
					)}
				</>
			)}
		</div>
	) : null;
}
