import { useEffect } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState } from 'redux/selectors';
import { Welcome, Feed, ManageChild } from 'components';
import { useCreateDispatches } from 'helpers/hooks';
import useSnapshotChildListfetch from './utils/useSnapshotChildListfetch';
import SnapshotChildListContainer from './components/SnapshotChildListContainer';
import styles from './snapshots.module.css';

export default function Snapshots(_props) {
	const { setRoot, setChild } = useCreateDispatches();
	const { childList, sortMenuItems } = useSnapshotChildListfetch();
	const { childId, feedType } = useParams();

	// TODO: set child in redux so that NoPost component renders properly

	const user = useSelector(getUserState);

	useEffect(() => {
		setRoot('archive');
		return () => setChild({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.archiveContainer}>
			<SnapshotChildListContainer
				childList={childList}
				sortMenuItems={sortMenuItems}
			/>
			<div className={styles.archiveFeed}>
				<div className={styles.archiveContent}>
					<Switch>
						<Route key={childId + 'child'} path='/archive/child/:childId'>
							<div className={styles.archiveProfile}>
								<ManageChild />
							</div>
						</Route>
						<Route key={childId + feedType} path='/archive/:feedType/:childId'>
							<Feed />
						</Route>
						<Route path='/archive/'>
							<Welcome user={user} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
}
