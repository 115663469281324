import { withRouter } from 'react-router-dom';
import { logoutUser } from 'services';
import { SecondaryButton } from 'components';

const LogoutButton = ({ history }) => {
	const onLogout = () => {
		logoutUser(history);
	};
	return (
		<>
			<SecondaryButton onClick={onLogout}>Log Out</SecondaryButton>
		</>
	);
};

export default withRouter(LogoutButton);
