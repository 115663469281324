import moment from 'moment';

export const eventValidation = (childId, startDate, endDate, title) => {
	const validStartDate = moment(startDate).isValid();
	const validEndDate = moment(endDate).isValid();

	if (!childId) {
		return {
			isValid: false,
			reason: 'Sorry please refresh and try again',
		};
	}

	if (!startDate) {
		return {
			isValid: false,
			reason: 'Start Date is required',
		};
	}

	if (!endDate) {
		return {
			isValid: false,
			reason: 'End Date is required',
		};
	}

	if (!validStartDate) {
		return {
			isValid: false,
			reason: 'Not a valid Start Date',
		};
	}

	if (!validEndDate) {
		return {
			isValid: false,
			reason: 'Not a valid End Date',
		};
	}

	const startTime = new Date(startDate).getTime();
	const endTime = new Date(endDate).getTime();

	if (startTime > endTime) {
		return {
			isValid: false,
			reason: 'End Date must be after Start Date',
		};
	}

	if (!title) {
		return {
			isValid: false,
			reason: 'Title is required',
		};
	}

	return { isValid: true };
};
