import clsx from 'clsx';
import { Avatar } from '@material-ui/core';

export function RoundAvatar(props) {
	const { alt, image, children } = props;

	let altLetters = alt
		.split(/\s/)
		.reduce((acc, cur) => (acc += cur.slice(0, 1)), '');

	const avatarRender = image ? null : children ? children : altLetters;

	return (
		<Avatar
			src={image}
			alt={alt}
			className={clsx(props.className ? props.className : null)}
			{...props.componentProps}>
			{avatarRender}
		</Avatar>
	);
}

export default RoundAvatar;
