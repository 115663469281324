import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { getIcon } from 'helpers';

const postCategoryChipStyles = (theme) => ({
	postCategoryChipRoot: {
		fontFamily: theme.font.default,
		fontSize: '16px',
		margin: '8px 0 24px',
		'& > div > span': {
			display: 'flex',
			alignItems: 'center',
		},
	},
	postCategoryChipIcon: {
		width: '16px',
		height: '16px',
		color: theme.palette.primary.main,
		marginRight: '8px',
	},
});

const useStyles = makeStyles(postCategoryChipStyles);

const PostCategoryChip = ({ category }) => {
	const classes = useStyles();
	const IconComponent = getIcon(category);

	return (
		<div className={classes.postCategoryChipRoot}>
			<Chip
				label={
					<>
						<IconComponent className={classes.postCategoryChipIcon} />
						<span className={classes.postCategoryChipLabel}>
							{category.label?.toUpperCase?.()}
						</span>
					</>
				}
			/>
		</div>
	);
};

export default PostCategoryChip;
