export const createCasaPermissionObject = (reqKey, value) => {
	let permissions = {};

	if (reqKey === 'readProfile') {
		value
			? (permissions = { basic_read_profile: true })
			: (permissions = {
					basic_read_profile: false,
					basic_write_profile: false,
					mgmt_read_profile: false,
					mgmt_write_profile: false,
			  });
	}

	if (reqKey === 'exportLifeBook') {
		permissions = { export_lifebook: value };
	}

	if (reqKey === 'writeProfile') {
		value
			? (permissions = { basic_write_profile: true, basic_read_profile: true })
			: (permissions = {
					basic_write_profile: false,
					mgmt_write_profile: false,
					mgmt_read_profile: false,
			  });
	}

	if (reqKey === 'mgmtReadProfile') {
		value
			? (permissions = {
					mgmt_read_profile: true,
					basic_read_profile: true,
			  })
			: (permissions = { mgmt_read_profile: false, mgmt_write_profile: false });
	}

	if (reqKey === 'mgmtWriteProfile') {
		value
			? (permissions = {
					basic_write_profile: true,
					basic_read_profile: true,
					mgmt_read_profile: true,
					mgmt_write_profile: true,
			  })
			: (permissions = {
					mgmt_write_profile: false,
					mgmt_read_profile: false,
			  });
	}

	if (reqKey === 'mgmtLifeBook') {
		permissions = { mgmt_lifebook: value };
	}

	if (reqKey === 'mgmtContributor') {
		permissions = { mgmt_contributor: value };
	}

	return permissions;
};
