import { useSelector } from 'react-redux';
import { usePasswordConfirm } from 'helpers';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Icon } from '@material-ui/core';
import styles from './childAudit.module.css';

export default function AuditorListItem({ auditor, handleRevoke, date }) {
	const { email } = auditor;
	const { isSm } = useSelector(({ mediaQuery }) => mediaQuery);
	const onConfirm = usePasswordConfirm();

	return (
		<div className={styles.auditorListItemRoot}>
			<div className={styles.auditorListItemInfo}>
				<div className={styles.auditorItem}>{email}</div>
				<div className={styles.auditorItem}>{`valid through ${date}`}</div>
			</div>
			{handleRevoke && (
				<div
					className={styles.auditorListRevokeButton}
					onClick={() =>
						onConfirm(
							handleRevoke,
							`To revoke auditor access for ${email}, please confirm your password`
						)
					}>
					{!isSm ? (
						'Revoke Access'
					) : (
						<Icon>
							<DeleteForever />
						</Icon>
					)}
				</div>
			)}
		</div>
	);
}
