import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { verifyEmail, logoutUser, getOwnUser } from 'services';
import { decodeToken } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { PageLayout, PrimaryButton } from 'components';

import styles from './verify.module.css';

export function EmailVerify() {
	const { search } = useLocation();
	const { push } = useHistory();
	const { setLoader } = useCreateDispatches();
	const { token } = queryString.parse(search || '');
	const { userId } = decodeToken(token);
	const authToken = localStorage.getItem('token');
	const { userId: authUserId } = decodeToken(authToken);

	const [message, setMessage] = useState('');

	const verifyToken = useCallback(async () => {
		setLoader(true);
		await verifyEmail(userId, token)
			.then(async () => {
				setMessage('Success you email has been changed');
				if (authUserId) {
					await getOwnUser('fetchUpdate');
				}
			})
			.catch(() => {
				setMessage('Sorry, this link is either expired or invalid.');
			})
			.finally(() => {
				setLoader(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, userId, setLoader]);

	useEffect(() => {
		if (token && userId) {
			verifyToken();
		} else {
			setMessage('Sorry, this link is either expired or invalid.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<PageLayout title='Email Verification'>
			<div className={styles.verificationFailedRoot}>
				{message}
				<div className={styles.verificationFailedContainer}>
					<PrimaryButton onClick={() => logoutUser()}>
						RETURN TO LOGIN
					</PrimaryButton>
					{authUserId && (
						<PrimaryButton onClick={() => push('/account/')}>
							RETURN TO PROFILE
						</PrimaryButton>
					)}
				</div>
			</div>
		</PageLayout>
	);
}

export default EmailVerify;
