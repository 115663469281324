import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChild, getLifebooksChildrenSort } from 'redux/selectors';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { experiments } from 'helpers';
import useManageChildAdminPermissions from '../../utils/useManageChildAdminPermissions';
import * as ChildComp from '.';
import { SectionHeader } from 'components';
import clsx from 'clsx';
import styles from './ChildAdmin.module.css';

export function ChildAdmin() {
	const permissions = useManageChildAdminPermissions();
	const child = useSelector(getLifebooksChild);
	const user = useSelector((state) => state.user.user.type);

	const { childId } = useParams();
	const childSort = useSelector(getLifebooksChildrenSort);

	const [expanded, setExpanded] = useState(false);

	const buttonArray = childId
		? [
				permissions.exitCare && {
					label: 'Exiting Care',
					info: 'Use this option if a child has exited care or is no longer managed by your agency. ',
					button: (
						<ChildComp.ExitCareButton childId={childId} childSort={childSort} />
					),
				},
				permissions.deleteChild && {
					label: 'Delete Child',
					info: 'This option will permanently delete this child from the Ella system.',
					warn: true,
					button: (
						<ChildComp.DeleteChildButton
							child={child}
							childId={childId}
							childSort={childSort}
						/>
					),
				},
		  ]
		: [];

	const hasItems = () => {
		return childId && Object.values(permissions).some((val) => !!val);
	};

	const buttonMapper = (val) => {
		if (val) {
			const { label, info, button } = val;
			return (
				<div
					className={clsx(
						styles.childAdminItemWrapper,
						val.warn ? styles.childAdminWarn : ''
					)}
					key={label}>
					<div>
						<div className={styles.childAdminBoldLabel}>{label}</div>
						<div>{info}</div>
					</div>
					<div className={styles.childAdminButtonContainer}>{button}</div>
				</div>
			);
		} else return null;
	};

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	return hasItems() ? (
		<div className={styles.childAdminRoot}>
			<div className={styles.childAdminContent}>
				<SectionHeader hideTop>
					<div className={styles.basicHeader}>
						<div className={styles.headerText}>MANAGEMENT OPTIONS</div>
						{expanded ? (
							<ExpandLess onClick={() => handleOpenClose()} />
						) : (
							<ExpandMore onClick={() => handleOpenClose()} />
						)}
					</div>
				</SectionHeader>
				{expanded && (
					<>
						{permissions.exportPdf && <ChildComp.ExportPdf />}
						{experiments.familyConnect(true) && user === 'agencyuser' && (
							<ChildComp.ConnectChildButton childId={childId}>
								Connect Child
							</ChildComp.ConnectChildButton>
						)}
						{buttonArray.map(buttonMapper)}
					</>
				)}
			</div>
		</div>
	) : null;
}

export default ChildAdmin;
