const defaultPostState = {
	postList: [],
	category: null,
	dateRange: [null, null],
	pendingFilter: null,
	root: 'lifebooks',
	feedType: 'timeline',
	feedView: 'list',
	vaultReason: false,
	vaultPost: {},
	postNotes: [],
};

const CLEAR_STORE = 'CLEAR_STORE';
const SET_POST_LIST = 'SET_POST_LIST';
const ADD_POST_LIST = 'ADD_POST_LIST';
const UPDATE_POST = 'UPDATE_POST';
const REMOVE_POST = 'REMOVE_POST';
const CLEAR_POST_LIST = 'CLEAR_POST_LIST';
const SET_CATEGORY = 'SET_CATEGORY';
const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
const SET_DATE_RANGE = 'SET_DATE_RANGE';
const CLEAR_DATE_RANGE = 'CLEAR_DATE_RANGE';
const SET_PENDING_FILTER = 'SET_PENDING_FILTER';
const SET_ROOT = 'SET_ROOT';
const SET_FEED_TYPE = 'SET_FEED_TYPE';
const SET_FEED_VIEW = 'SET_FEED_VIEW';
const SET_VAULT_REASON = 'SET_VAULT_REASON';
const SET_VAULT_POST = 'SET_VAULT_POST';
const SET_POST_NOTES = 'SET_POST_NOTES';

export default function postReducer(state = { ...defaultPostState }, action) {
	switch (action.type) {
		case CLEAR_STORE:
			return { ...defaultPostState };
		case SET_POST_LIST: {
			return {
				...state,
				postList: action.payload,
			};
		}
		case ADD_POST_LIST: {
			return {
				...state,
				postList: [...state.postList, ...action.payload],
			};
		}
		case UPDATE_POST: {
			let updatedPosts = [...state.postList];
			//If index is available, use it to find the post, else find the post by id
			const index =
				action.index ||
				state.postList.findIndex((post) => {
					return post.id === action.payload?.id;
				});
			updatedPosts[index] = action.payload;
			return {
				...state,
				postList: updatedPosts,
			};
		}
		case REMOVE_POST: {
			const { index, id, postDraftId } = action.payload || {};
			let updatedPosts = [...state.postList];
			if (index >= 0) {
				updatedPosts.splice(index, 1);
			} else if (!!id) {
				updatedPosts = updatedPosts.filter((val) => val.id !== id);
			} else if (!!postDraftId) {
				updatedPosts = updatedPosts.filter(
					(val) => val.postDraftId !== postDraftId
				);
			}
			return {
				...state,
				postList: updatedPosts,
			};
		}
		case CLEAR_POST_LIST: {
			return {
				...state,
				postList: [],
			};
		}
		case SET_CATEGORY: {
			return {
				...state,
				category: action.payload,
			};
		}
		case CLEAR_CATEGORY: {
			return {
				...state,
				category: null,
			};
		}
		case SET_DATE_RANGE: {
			return {
				...state,
				dateRange: action.payload || [null, null],
			};
		}
		case CLEAR_DATE_RANGE: {
			return {
				...state,
				dateRange: [null, null],
			};
		}
		case SET_PENDING_FILTER: {
			return {
				...state,
				pendingFilter: action.payload,
			};
		}
		case SET_ROOT: {
			return {
				...state,
				root: action.payload,
			};
		}
		case SET_FEED_TYPE: {
			return {
				...state,
				feedType: action.payload,
			};
		}
		case SET_FEED_VIEW: {
			return {
				...state,
				feedView: action.payload,
			};
		}
		case SET_VAULT_REASON: {
			return {
				...state,
				vaultReason: action.payload,
			};
		}
		case SET_VAULT_POST: {
			return {
				...state,
				vaultPost: action.payload,
			};
		}
		case SET_POST_NOTES: {
			return {
				...state,
				postNotes: action.payload,
			};
		}
		default:
			return state;
	}
}

export function setPostList(postList) {
	return {
		type: SET_POST_LIST,
		payload: postList,
	};
}

export function addpostList(postList) {
	return {
		type: ADD_POST_LIST,
		payload: postList,
	};
}

export function updatePost(post, index) {
	return {
		type: UPDATE_POST,
		payload: post,
		index,
	};
}

export function removePost(params) {
	return {
		type: REMOVE_POST,
		payload: params,
	};
}

export function clearPostList(param) {
	return {
		type: CLEAR_POST_LIST,
	};
}

export function setCategory(category) {
	return {
		type: SET_CATEGORY,
		payload: category,
	};
}

export function clearCategory() {
	return {
		type: CLEAR_CATEGORY,
	};
}

export function setDateRange(date) {
	return {
		type: SET_DATE_RANGE,
		payload: date,
	};
}

export function clearDateRange() {
	return {
		type: CLEAR_DATE_RANGE,
	};
}

export function setPendingFilter(date) {
	return {
		type: SET_PENDING_FILTER,
		payload: date,
	};
}

export function setRoot(root) {
	return {
		type: SET_ROOT,
		payload: root,
	};
}

export function setFeedType(feedType) {
	return {
		type: SET_FEED_TYPE,
		payload: feedType,
	};
}

export function setFeedView(feedView) {
	return {
		type: SET_FEED_VIEW,
		payload: feedView,
	};
}

export function setVaultReason(showVault) {
	return {
		type: SET_VAULT_REASON,
		payload: showVault,
	};
}

export function setVaultPost(post) {
	return {
		type: SET_VAULT_POST,
		payload: post,
	};
}

export function setPostNotes(notes) {
	return {
		type: SET_POST_NOTES,
		payload: notes,
	};
}
