import styles from '../selectorList.module.css';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { SectionHeader } from 'components';

const Header = (props) => {
	if (props.expandable) {
		return (
			<SectionHeader hideTop>
				<div className={styles.headerExpandable}>
					<div className={styles.labelExpandable}>
						{(props.label || '').toUpperCase()}
					</div>
					{props.isOpen ? (
						<ExpandLess onClick={() => props.handleOpenClose()} />
					) : (
						<ExpandMore onClick={() => props.handleOpenClose()} />
					)}
				</div>
			</SectionHeader>
		);
	}

	return (
		<SectionHeader hideTop>
			<div className={styles.label}>{(props.label || '').toUpperCase()}</div>
		</SectionHeader>
	);
};

export default Header;
