import { useCheckPostPermissions } from 'helpers/hooks';
import useEditPostCallbacks from '../utils/useEditPostCallbacks';
import { PrimaryButton, SecondaryButton } from 'components';
import EditPostDraftButtons from './EditPostDraftButtons';
import styles from '../editPost.module.css';

export default function EditPostButtonContainer({ postState }) {
	const { postDraftId, status } = postState;
	const { allowShowVaultOption } = useCheckPostPermissions(postState);
	const showVault = allowShowVaultOption();
	const getPostState = () => postState;
	const { onCancel, useOnSubmit } = useEditPostCallbacks(getPostState);
	const onSubmit = useOnSubmit();

	return (
		<div className={styles.editPostFooterRoot}>
			<div className={styles.editPostFooterContent}>
				<SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
				<EditPostDraftButtons {...{ postDraftId, getPostState }} />
				{showVault && (
					<SecondaryButton onClick={(e) => onSubmit(e, 'vault')}>
						VAULT
					</SecondaryButton>
				)}
				<PrimaryButton onClick={(e) => onSubmit(e, 'post')}>
					{status === 'approved' || status === 'pending' ? 'Save' : 'Create'}
				</PrimaryButton>
			</div>
		</div>
	);
}
