import { useState, useEffect } from 'react';
import RelationshipTile from './RelationshipTile';
import { MenuItem } from '@material-ui/core';
import { TextFieldRow, TextField } from 'components';
import { makeStyles } from '@material-ui/core/styles';

export const styles = (theme) => ({
	selectorListRoot: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		margin: '16px 0 16px 0',
		maxWidth: theme.width.contentMax,
		minWidth: theme.width.contentMin,
	},
	selectorListStandard: {
		width: '95%',
	},
	selectorListFull: {
		width: '100%',
	},
	selectorListTextField: {
		margin: '16px 0',
	},
	selectorListSubheader: {
		fontWeight: '500',
		borderTop: `1px solid ${theme.palette.disabled.main}`,
		backgroundColor: 'white',
	},
	selectorListTileList: {
		width: '100%',
	},
	selectorListButtonContainer: {
		position: 'absolute',
		right: '16px',
		[theme.breakpoints.down('sm')]: {
			right: '8px',
		},
	},
	selectorListActiveTile: {
		borderLeft: `8px solid ${theme.palette.secondary.main}`,
	},
});

const useStyles = makeStyles(styles);

const RelationshipType = (props) => {
	const classes = useStyles();
	const { selected, label, textFieldLabel, menuList, handleMenuSelect } = props;

	const [selectedObject, setSelectedObject] = useState({});
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		const isInList = menuList.some((relation) => relation.label === selected);

		if (isInList) {
			let result = menuList.filter((val) => {
				return val.label === selected;
			});
			setSelectedObject(result[0]);
			setSelectedValue(selected);
		} else if (selected) {
			let result = { label: selected, value: selected };
			setSelectedValue('Other');
			setSelectedObject(result);
		} else {
			setSelectedValue('');
			setSelectedObject({});
		}
	}, [selected, menuList]);

	const tile = Boolean(selectedObject) ? (
		<>
			{selectedObject.label === 'Other' ? (
				<div className={classes.selectorListTextInput}>
					<TextFieldRow
						label={'Relation Type'}
						key={'contrib-relation-type'}
						className={classes.inputField}
						variant='outlined'
						value={props.customValue}
						onChange={props.changeCustomValue}
						required={true}
					/>
				</div>
			) : (
				<RelationshipTile
					value={selectedObject}
					activeClass={classes.activeTile}>
					{selectedObject.label}
				</RelationshipTile>
			)}
		</>
	) : null;

	const menuSelect = (e, value) => {
		handleMenuSelect(e, value);
	};
	const id = label?.toLowerCase().replace(/ /g, '-') + '-single-selector-';

	return (
		<div className={classes.selectorListRoot} id={id + 'root'}>
			{menuList.length > 0 ? (
				<TextField
					select
					disabled={props.disabled}
					id={id + 'textField'}
					SelectProps={{ multiple: false }}
					className={classes.selectorListTextField}
					value={selectedValue || ''}
					variant='outlined'
					required={true}
					label={textFieldLabel || ''}
					onChange={menuSelect}>
					{menuList.map((val, i) => (
						<MenuItem key={`${val.id}-${i}`} value={val.value}>
							{val.label}
						</MenuItem>
					))}
				</TextField>
			) : null}
			<div className={classes.tileList}>{tile}</div>
		</div>
	);
};

export default RelationshipType;
