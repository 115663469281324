import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as selector from 'redux/selectors';
import { useFormInput } from 'helpers/inputFormatting';
import { useQuery, useSelectorListState } from 'helpers/hooks';
import { getButtonArray } from '../utils/getButtons';
import useManageRPPermissions from '../utils/useManageRPPermissions';
import { PageLayout } from 'components';
import RPBasicInfo from './RPBasicInfo';
import RPHouseList from './RPHouseList';

export default function ManageRP() {
	const houseList = useSelector(selector.getHomesHouseList);
	const houseHash = useSelector(selector.getLifebooksHousesHash);
	const rpHash = useSelector(selector.getHomesRPHash);
	const rp = useSelector((state) => state.homes.resourceParent);

	const [houseId, setHouseId] = useState(null);
	const [status, setStatus] = useState(null);
	const [housesLoaded, setHousesLoaded] = useState(false);

	const { rpId } = useParams();
	const pageTitle = rpId
		? `${rp.firstname} ${rp.lastname}`
		: 'Add Resource Parent';
	const permissions = useManageRPPermissions();
	const canEdit = (permission) => permission === 'view';
	const { houseId: houseIdQuery } = useQuery();
	const getHousefromHash = (id) => id && houseHash[id] && [houseHash[id]];

	const firstname = useFormInput('First Name', '', {
		required: true,
		disabled: canEdit(permissions.firstname),
	});
	const lastname = useFormInput('Last Name', '', {
		required: true,
		disabled: canEdit(permissions.lastname),
	});
	const email = useFormInput('Email', '', {
		required: true,
		type: 'email',
		disabled: canEdit(permissions.lastname),
	});

	useEffect(() => {
		const setRp = async () => {
			const resourceParent = rpId ? rpHash[rpId] : {};
			firstname.onChange({ value: resourceParent.firstname });
			lastname.onChange({ value: resourceParent.lastname });
			email.onChange({ value: resourceParent.email });
			setStatus(resourceParent.status);
			setHouseId(resourceParent.houseId);
			setHousesLoaded(true);
		};

		setRp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rpId]);

	const houseSelectorState = useSelectorListState(
		houseList,
		getHousefromHash(houseIdQuery) || getHousefromHash(houseId) || [],
		{
			single: true, // single select
			loaded: housesLoaded,
		}
	);

	const getBody = () => {
		const reqBody = {
			firstname: firstname.value,
			lastname: lastname.value,
			email: email.value,
		};
		const { changes } = houseSelectorState || {};
		if (changes) {
			const { add = [], remove = [] } = changes || {};
			if (add.length > 0) {
				reqBody.houseId = add[0].id;
			} else if (remove.length > 0) {
				reqBody.houseId = '';
			}
		}
		return { reqBody };
	};

	const hasEdits = Object.values(permissions).some((val) => val === 'edit');

	const buttonArray = hasEdits
		? getButtonArray(getBody, houseSelectorState.resetChanges)
		: [];

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<RPBasicInfo inputs={[firstname, lastname, email]} status={status} />
			<RPHouseList selectorListState={houseSelectorState} />
		</PageLayout>
	);
}
