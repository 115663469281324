const Avatar = (props) => {
	const largeStyle = {
		backgroundColor: '#00A0E8',
		borderRadius: '50%',
		width: '75px',
		height: '75px',
		fontSize: '30px',
		marginRight: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	const smallStyle = {
		backgroundColor: '#00A0E8',
		borderRadius: '50%',
		width: '40px',
		height: '40px',
		marginRight: '10px',
		fontSize: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	let altLetters = props.alt
		? props.alt.split(/\s/).reduce((acc, cur) => (acc += cur.slice(0, 1)), '')
		: null;

	let avatarSize = props.size === 'small' ? smallStyle : largeStyle;

	if (props.image) {
		return (
			<div style={avatarSize}>
				<img
					style={{
						width: '100%',
						height: '100%',
						borderRadius: '50%',
						objectFit: 'cover',
					}}
					alt={'round-avatar'}
					src={props.image}></img>
			</div>
		);
	}

	return (
		<div style={avatarSize}>
			<div
				style={{
					display: 'flex',
					color: 'white',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%',
				}}>
				{altLetters}
			</div>
		</div>
	);
};

export default Avatar;
