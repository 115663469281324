import {
	validateEmail,
	validatePassword,
	validateChildId,
} from './utils/validate';

export const handleLoginValidation = (username, password) => {
	const isEmailValid = validateEmail(username);
	const isPasswordValid = validatePassword(password);

	if (!username || !password) {
		return {
			isValid: false,
			message: 'Please fill out all fields before submitting',
		};
	}

	if (!isEmailValid) {
		return { isValid: false, message: 'Invalid email address' };
	}

	if (!isPasswordValid) {
		return { isValid: false, message: "Password doesn't meet required length" };
	}

	return { isValid: true };
};

export const handleResetValidation = (email) => {
	const isEmailValid = validateEmail(email);

	if (!email) {
		return { isValid: false, message: 'Email field is required' };
	}

	if (!isEmailValid) {
		return { isValid: false, message: 'Invalid email address' };
	}

	return { isValid: true };
};

export const handleOwnerCodeValidation = (code, id, email) => {
	const isEmailValid = validateEmail(email);
	const isChildIdValid = validateChildId(id);

	if (!code || !id || !email) {
		return { isValid: false, message: 'All fields are required' };
	}

	if (!isChildIdValid) {
		return { isValid: false, message: 'Not a valid Child ID' };
	}

	if (!isEmailValid) {
		return { isValid: false, message: 'Email is invalid' };
	}

	return { isValid: true };
};
