import { useSelector } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import styles from '../userList.module.css';
import { getOverLayMobileList, getMediaQueryState } from 'redux/selectors';

export default function SlideContainer({ children, className }) {
	const mobileList = useSelector(getOverLayMobileList);
	const { isMd } = useSelector(getMediaQueryState);

	return (
		<Slide
			className={clsx(styles.slideContainerRoot, className)}
			direction='right'
			in={(isMd && mobileList) || !isMd}>
			<div>{children}</div>
		</Slide>
	);
}
