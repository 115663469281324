import { getNameTile } from '../../utils';
import CasaAdminTile from './CasaAdminTile';
import { SectionHeader } from 'components';
import styles from '../CasaAgency.module.css';

const CasaUserList = (props) => {
	const tileMapper = (val, index) => {
		if (!val) {
			return null;
		}
		const label = getNameTile(val);

		return (
			<div key={`${val.id}${index}`}>
				<CasaAdminTile
					image={val.photoUrl}
					value={val}
					activeClass={styles.selectorListActiveTile}>
					{label}
				</CasaAdminTile>
			</div>
		);
	};

	const filteredList =
		props.tileList !== 'error'
			? props.tileList.filter((e) => e.status === 'active')
			: [];

	const tileMap = filteredList.map(tileMapper);

	return (
		<>
			<SectionHeader>Casa Admins</SectionHeader>
			<div className={styles.selectorListTileList}>{tileMap}</div>
		</>
	);
};

export default CasaUserList;
