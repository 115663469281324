const defaultVaultState = {
	videoPosition: 0,
	videoPlay: false,
};

const SET_VIDEO_POSITION = 'SET_VIDEO_POSITION';
const SET_VIDEO_PLAY = 'SET_VIDEO_PLAY';

export default function videoReducer(state = { ...defaultVaultState }, action) {
	switch (action.type) {
		case SET_VIDEO_POSITION: {
			return {
				...state,
				videoPosition: action.payload,
			};
		}
		case SET_VIDEO_PLAY: {
			return {
				...state,
				videoPlay: action.payload,
			};
		}

		default:
			return state;
	}
}

export function setVideoPosition(position) {
	return {
		type: SET_VIDEO_POSITION,
		payload: position,
	};
}

export function setVideoPlay(play) {
	return {
		type: SET_VIDEO_PLAY,
		payload: play,
	};
}
