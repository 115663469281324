const mediaQueryState = {
	height: 0,
	width: 0,
	portrait: false,
	isLg: false,
	isMd: false,
	isSm: false,
	isSmd: false,
	rightContentWidth: null,
};

const SET_HEIGHT = 'SET_HEIGHT';
const SET_WIDTH = 'SET_WIDTH';
const SET_PORTRAIT = 'SET_PORTRAIT';
const SET_LG = 'SET_LG';
const SET_MD = 'SET_MD';
const SET_SM = 'SET_SM';
const SET_SMD = 'SET_SMD';
const SET_RIGHT_CONTENT_WIDTH = 'SET_RIGHT_CONTENT_WIDTH';

export default function mediaQueryReducer(state = mediaQueryState, action) {
	switch (action.type) {
		case SET_HEIGHT:
			return { ...state, height: action.payload };
		case SET_WIDTH:
			return { ...state, width: action.payload };
		case SET_PORTRAIT:
			return { ...state, portrait: action.payload };
		case SET_LG:
			return { ...state, isLg: action.payload };
		case SET_SMD:
			return { ...state, isSmd: action.payload };
		case SET_MD:
			return { ...state, isMd: action.payload };
		case SET_SM:
			return { ...state, isSm: action.payload };
		case SET_RIGHT_CONTENT_WIDTH:
			return { ...state, rightContentWidth: action.payload };
		default:
			return state;
	}
}

export function setHeight(height) {
	return {
		type: SET_HEIGHT,
		payload: height,
	};
}

export function setWidth(width) {
	return {
		type: SET_WIDTH,
		payload: width,
	};
}

export function setPortrait(bool) {
	return {
		type: SET_PORTRAIT,
		payload: bool,
	};
}

export function setLg(bool) {
	return {
		type: SET_LG,
		payload: bool,
	};
}

export function setSmd(bool) {
	return {
		type: SET_SMD,
		payload: bool,
	};
}

export function setMd(bool) {
	return {
		type: SET_MD,
		payload: bool,
	};
}

export function setSm(bool) {
	return {
		type: SET_SM,
		payload: bool,
	};
}

export function setRightContentWidth(width) {
	return {
		type: SET_RIGHT_CONTENT_WIDTH,
		payload: width,
	};
}

export const breakpoints = {
	xs: 0,
	sm: 660,
	md: 960,
	smd: 1040,
	lg: 1280,
	xl: 1920,
};
