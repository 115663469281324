import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCategory, clearCategory } from 'redux/index';
import { getPostsCategory, getIsLg } from 'redux/selectors';
import { serviceIconPack } from 'helpers';
import { OptionMenu, Icon } from 'components';
import SectionLayout from './SectionLayout';
import styles from '../headers.module.css';

export default function ServiceCategorySelect() {
	const dispatch = useDispatch();
	const category = useSelector(getPostsCategory);
	const nsps = useSelector((state) => state.lifebooks.nsps);
	const isMd = useSelector(getIsLg);
	const dispatchCategory = (val) => dispatch(setCategory(val));
	const { pathname } = useLocation();
	const { push } = useHistory();

	const onCategorySelect = async (value) => {
		let query;
		setCategory(value.servicesCategory);
		if (value.servicesCategory) {
			dispatchCategory(value.servicesCategory);
			query = `nspId=${value.id}`;
		} else {
			dispatch(clearCategory());
			query = '';
		}
		push(`${pathname}?` + query);
	};

	const categoryItems = () => {
		let itemList = [
			{
				value: '',
				label: 'All Categories',
				onClick: () => {
					onCategorySelect('');
				},
			},
		];
		// eslint-disable-next-line array-callback-return
		nsps.map((nsp) => {
			const item = {
				value: nsp.id,
				label: nsp.servicesCategory,
				onClick: () => {
					onCategorySelect(nsp);
				},
			};
			itemList.push(item);
		});
		return itemList;
	};

	const categoryLabel = category || 'ALL';
	const ServiceIcon = serviceIconPack[category];

	return (
		<SectionLayout label='CATEGORY:'>
			<OptionMenu optionButton menuItems={categoryItems()}>
				<div className={styles.categoryButtonContent}>
					{!isMd && ServiceIcon ? (
						<Icon>
							<ServiceIcon className={styles.categoryIcon} />
						</Icon>
					) : null}
					<div>{categoryLabel}</div>
				</div>
			</OptionMenu>
		</SectionLayout>
	);
}
