import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserStateId } from 'redux/selectors';
import { requestEmailChange } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { TextFieldRow } from 'components';
import { InputAdornment } from '@material-ui/core';
import styles from '../account.module.css';

export default function ProfileEmailField({ email, setEdit }) {
	const [newEmail, setNewEmail] = useState(email.value);
	const id = useSelector(getUserStateId);
	const { setSnackbar, setLoader } = useCreateDispatches();

	const onChange = ({ target: { value } }) => {
		setNewEmail(value);
	};

	const onSubmit = async () => {
		setLoader(true);
		await requestEmailChange(id, newEmail).then(() => {
			setLoader(false);
			setSnackbar({
				variant: 'success',
				message:
					'Request sent successfully. Please check your email for verification.',
			});
		});
	};

	return (
		<TextFieldRow
			label={email.label}
			value={newEmail}
			onChange={onChange}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<span
							className={styles.profileEmail}
							onClick={() => setEdit(false)}>
							CANCEL
						</span>
						<span className={styles.profileEmail} onClick={onSubmit}>
							SUBMIT
						</span>
					</InputAdornment>
				),
			}}
		/>
	);
}
