import { SelectorList } from 'components';

export default function ManagerBranchList({ selectorListState }) {
	const linkTo = '/agencies/manage/';

	return (
		<SelectorList
			label='Branches'
			textFieldLabel='Branch'
			linkTo={linkTo}
			fromList='branch'
			single
			disableRemove
			{...selectorListState}
		/>
	);
}
