import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from 'redux/index';
import { Icon } from '@material-ui/core';
import { ViewCarousel } from '@material-ui/icons';
import { LazyCarousel, ImageUnavailable, TransparentButton } from 'components';
import clsx from 'clsx';
import styles from '../addImage.module.css';

export function AddImagePreview(props) {
	const dispatch = useDispatch();
	const { modal } = useSelector(({ overLay }) => overLay);
	const {
		imageDisplay,
		onDrop,
		onDragOver,
		onDragLeave,
		disable,
		single,
		heic,
		photos,
		initialSlide,
		removeFile,
	} = props;

	const viewPhotos = () => {
		dispatch(
			setModal(
				<LazyCarousel
					photos={photos}
					initialSlide={initialSlide}
					removeFile={removeFile}
				/>
			)
		);
	};

	useEffect(() => {
		if (modal) {
			dispatch(
				setModal(
					<LazyCarousel
						photos={photos}
						initialSlide={initialSlide}
						removeFile={removeFile}
					/>
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photos]);

	return (
		<div
			className={clsx(styles.addImageRoot)}
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}>
			{heic ? (
				<ImageUnavailable image={imageDisplay} />
			) : (
				<div className={styles.addImagePreview}>
					<img
						className={styles.addImagePreviewImg}
						src={imageDisplay}
						alt='preview'
					/>
					{!disable && !single ? (
						<div>
							{photos?.length &&
								typeof initialSlide === 'number' &&
								initialSlide >= 0 && (
									<TransparentButton
										onClick={viewPhotos}
										className={styles.addImagePreviewIcon}
										contrast>
										<Icon>
											<ViewCarousel />
										</Icon>
									</TransparentButton>
								)}
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}

export default AddImagePreview;
