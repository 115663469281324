import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { PrimaryButton, SecondaryButton, TextArea } from 'components';
import { requestPostChange } from 'services';
import styles from '../timeline.module.css';

export default function ChangeRequestForm({ postId, post }) {
	const { setLoader, setSnackbar, setModal, updatePost } =
		useCreateDispatches();
	const changeRequestMessage = useFormInput('Requested Changes', '', {
		required: true,
	});

	const handleValidate = (cb) => {
		if (changeRequestMessage.value) {
			cb();
		} else {
			setSnackbar(
				{
					variant: 'error',
					message: 'Context for requested changes is required.',
				},
				{ log: true }
			);
		}
	};

	const handleSubmit = () => {
		setLoader(true);
		requestPostChange(post, changeRequestMessage.value)
			.then((res) => {
				updatePost({
					...post,
					changeRequested: true,
					changeRequestMessage: changeRequestMessage.value,
				});
				setLoader(false);
				setModal(false);
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
				setLoader(false);
			});
	};

	const handleCancel = () => {
		setModal(false);
	};

	return (
		<div className={styles.changeRequestFormContainer}>
			<div className={styles.changeRequestFormButtonContainer}>
				<SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
				<PrimaryButton onClick={() => handleValidate(handleSubmit)}>
					Submit
				</PrimaryButton>
			</div>
			<TextArea
				className={styles.changeRequestFormField}
				{...changeRequestMessage}
				minRows={10}
				maxRows={10}
				name='change-request'
			/>
		</div>
	);
}
