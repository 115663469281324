import { withRouter, Switch, Route, useLocation } from 'react-router-dom';
import { AccessRoute } from 'routing/AccessRoute';
import { useMobileListCheck } from 'helpers';
import { Welcome } from 'components';
import RPListContainer from './components/RPListContainer';
import ManageRP from './components/ManageRp';
import styles from './resourceParents.module.css';

function ResourceParents(props) {
	const { pathname } = useLocation();
	const {
		match: {
			params: { rpId },
		},
	} = props;

	useMobileListCheck(pathname === '/resource-parents/');

	return (
		<div className={styles.rpRouteContainer}>
			<RPListContainer />
			<div className={styles.rpRouteContent}>
				<Switch>
					<AccessRoute
						key={`manageRP-${rpId}`}
						path='/resource-parents/manage/:rpId?'
						authorized={{
							agencyuser: { role: ['admin', 'manager', 'social-worker'] },
						}}
						fallBackRoute='/resource-parents/'
						component={ManageRP}
						privileged={true}
					/>
					<Route path='/' component={Welcome} />
				</Switch>
			</div>
		</div>
	);
}

export default withRouter(ResourceParents);
