import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import InnerModal from './components/InnerModal';
import { modalStyle } from './FamilyConnectStyles';

const FamilyConnectCases = () => {
	const modalOpen = useSelector((state) => state.familyConnections.modal);

	return (
		<div style={{ zIndex: 5000 }}>
			<Modal
				ariaHideApp={false}
				style={modalStyle}
				isOpen={modalOpen}
				transparent={true}>
				<InnerModal />
			</Modal>
		</div>
	);
};

export default FamilyConnectCases;
