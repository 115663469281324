import { TextFieldRow, SectionHeader } from 'components';
import styles from '../branches.module.css';

export default function BranchBasicInfo({ inputs }) {
	const inputMap = inputs?.map((val) => {
		return <TextFieldRow key={val.label} variant='outlined' {...val} />;
	});

	return (
		<>
			<SectionHeader>BRANCH INFORMATION</SectionHeader>
			<div className={styles.branchInfoContainer}>{inputMap}</div>
		</>
	);
}
