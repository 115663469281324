import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MaterialField, InputAdornment } from '@material-ui/core';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const PasswordField = (props) => {
	const [passwordMask, setPasswordMask] = useState(true);
	const useStyles = makeStyles((theme) => ({
		textField: {
			margin: '8px 0 8px 0',
			borderRadius: '12px !important',
			fontWeight: 'normal',
			color: 'black',
			width: '100%',
			...props.style,
		},
		textFieldInput: {
			height: '24px',
		},
		passwordFieldEye: {
			cursor: 'pointer',
		},
	}));

	const classes = useStyles();

	const Eye = passwordMask ? VisibilityOutlined : VisibilityOffOutlined;
	return (
		<React.Fragment>
			<MaterialField
				variant='outlined'
				id={`password-input-field-${props.name}`}
				type={passwordMask ? 'password' : ''}
				className={clsx(
					classes.textField,
					props.className ? props.className : ''
				)}
				InputProps={{
					classes: { input: classes.textFieldInput },
					endAdornment: (
						<InputAdornment position='end'>
							<Eye
								className={classes.passwordFieldEye}
								onClick={() => setPasswordMask(!passwordMask)}
							/>
						</InputAdornment>
					),
				}}
				{...props}
			/>
		</React.Fragment>
	);
};

export default PasswordField;
