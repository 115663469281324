export const removeRelation = (arr, newArr, id) => {
	const relationIndex = arr.findIndex((relation) => relation.childId === id);

	if (relationIndex !== -1) {
		newArr.splice(relationIndex);
		return newArr;
	} else {
		return arr;
	}
};

export const removeChild = (optionsList, assignedList, index) => {
	let menu = [...optionsList];
	let tile = [...assignedList];
	let changed = tile.splice(index, 1)[0];
	menu.push(changed);
	return { menu, tile, changed };
};

export const addChild = (optionsList, assignedList, index) => {
	let menu = [...optionsList];
	let tile = [...assignedList];
	let changed = menu.splice(index, 1)[0];
	tile.push(changed);
	return { menu, tile, changed };
};

export const getLabel = (val) => {
	if (val.firstname) {
		return `${val.firstname} ${val.lastname}`;
	}

	if (val.name) {
		return val.name;
	}

	return val.email.split('@')[0];
};

export const relationship = (
	relationships,
	child,
	selected,
	index,
	isCustom
) => {
	const obj = {
		childId: child.id,
		id: child.relation ? child.relation?.id : null,
		relationship: selected,
		label: isCustom ? 'Other' : selected,
	};

	if (index !== -1) {
		relationships[index] = obj;
	} else {
		relationships.push(obj);
	}

	return relationships;
};
