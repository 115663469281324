import { getLocationAndUpload } from 'services';
import { formatPhone } from 'helpers';

export function filterChildBasic(basicValues, initialChild) {
	const checkValue = (key, value) => {
		if (!initialChild[key] && !value) return { check: false };
		switch (key) {
			case 'mobile':
				const formattedNewValue = '+1' + formatPhone(value);
				return {
					check: initialChild[key] !== formattedNewValue,
					value: formattedNewValue,
				};
			default:
				return { check: initialChild[key] !== value, value: value };
		}
	};

	return Object.keys(basicValues).reduce((acc, cur) => {
		const basicValue = basicValues[cur];
		const { check, value } = checkValue(cur, basicValue);
		if (check) {
			acc[cur] = value;
		}
		return acc;
	}, {});
}

export function createAgencyUserIds(assigned = []) {
	return assigned.map(({ id }) => id);
}

export function getChildHome(assigned = []) {
	return assigned[0]?.id || '';
}

export async function getImageLocation(file, childId) {
	if (!file) return '';
	return await getLocationAndUpload(file, childId);
}
