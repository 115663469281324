import sort from 'fast-sort';
import _ from 'lodash';
import { store, setChildSort, setSnackbar } from '../redux';
import * as services from '../services';

const user = store?.getState()?.user?.user?.id;

export const listSortPersonByName = (array, sortBy, thirdOption) => {
	let sortArray = array?.slice() || [];
	const secondOption = sortBy === 'firstname' ? 'lastname' : 'firstname';
	let result = sort(sortArray).asc([
		(user) => user[sortBy],
		(user) => user[secondOption],
		(user) => user[thirdOption],
	]);
	return result;
};

export const listSortByValues = (array, ...keys) => {
	let sortArray = array?.slice() || [];
	const sortFunctions = keys.map((key) => (value) => value[key]);
	return sort(sortArray).asc(sortFunctions);
};

export const listSortBySchool = (childArray, schoolsHash) => {
	let sortArray = childArray?.slice() || [];
	let result = sort(sortArray).asc((user) => schoolsHash[user.schoolId]);
	return result;
};

export const listSortByHouse = (childArray, housesHash) => {
	if (!housesHash) {
		store.dispatch(setChildSort('lastname'));
		user &&
			store.dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Could not sort list by House',
				})
			);
		return listSortPersonByName(childArray, 'lastname');
	}
	let sortArray = childArray?.slice() || [];
	let result = sort(sortArray).asc([
		(child) => {
			if (child.house && housesHash && !housesHash[child.house.id]) {
				child.house = {};
			}
			return housesHash[child.house?.id]
				? housesHash[child.house?.id].lastname
				: null;
		},
		(child) => {
			if (child.house && housesHash && !housesHash[child.house.id]) {
				child.house = {};
			}
			return housesHash[child.house?.id]
				? housesHash[child.house?.id].firstname
				: null;
		},
		(child) => {
			if (child.house && housesHash && !housesHash[child.house?.id]) {
				child.house = {};
			}
			return housesHash[child.house?.id]
				? housesHash[child.house?.id].id
				: null;
		},
		(child) => child.lastname,
		(child) => child.firstname,
	]);
	return result;
};

export const listSortBySW = async (childArray, sWHash) => {
	if (!sWHash) {
		let sWListResult = await services.getAgencyUsers('social-worker');
		if (!sWListResult.error) {
			sWHash = hashList(sWListResult);
		}
	}
	let result = sort([...childArray]).asc([
		(child) =>
			sWHash[child.agencyUserId] && sWHash[child.agencyUserId].lastname,
		(child) =>
			sWHash[child.agencyUserId] && sWHash[child.agencyUserId].firstname,
		(child) => sWHash[child.agencyUserId] && sWHash[child.agencyUserId].email,
		(child) => child.lastname,
		(child) => child.firstname,
	]);
	return result;
};

export const listSortByBranch = async (childArray, branchHash) => {
	const agencyHash = store.getState().user.agencyHash;
	if (
		!branchHash ||
		(typeof branchHash === 'object' && _.isEmpty(branchHash))
	) {
		let branchListResult = await services.getBranchList();
		if (!branchListResult.error) {
			branchHash = hashList(branchListResult.data);
		}
	}
	let result = sort([...childArray]).asc([
		(child) =>
			agencyHash[child.agencyUserId] &&
			branchHash[agencyHash[child.agencyUserId].branchId] &&
			branchHash[agencyHash[child.agencyUserId].branchId].name,
	]);
	return result;
};

export const listSortAgencyUserByBranch = async (array, hash) => {};

export const listSortOrganizationByName = (array) => {
	let sortArray = array?.slice() || [];
	return sortArray.sort((a, b) => {
		return a.name < b.name ? -1 : 1;
	});
};

export const listSortByDistance = (array, userPosition) => {
	let sortArray = array?.slice() || [];

	return sortArray.sort((a, b) => {
		return getDistance(a, userPosition) < getDistance(b, userPosition) ? 1 : -1;
	});
};

export const getDistance = (target, userPosition) => {
	const { acos, sin, cos, PI } = Math;
	const radians = (degrees) => {
		return degrees * (PI / 180);
	};
	const { lat, lng } = target.address;
	const { lat: userLat, lng: userLng } = userPosition;
	return (
		3956 *
		acos(
			sin(radians(userLat)) * sin(radians(lat)) +
				cos(radians(userLat)) *
					cos(radians(lat)) *
					cos(radians(lng) - radians(userLng))
		)
	);
};

export const hashList = (array) => {
	if (array.length > 0) {
		let hashMap = {};
		array &&
			array.forEach((val) => {
				hashMap[val.id] = val;
			});
		return hashMap;
	} else {
		return [];
	}
};
