import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setMobileDashboard } from 'redux/reducers';
import { logoutUser } from 'services';
import { getRoleOptions } from '../utils/getRoleOptions';
import DashboardNavItem from './DashboardNavItem';
import DashboardNavParent from './DashboardNavParent';
import styles from '../dashboard.module.css';

function DashboardNav(props) {
	const {
		user,
		history,
		location: { pathname },
		setMobileDashboard,
	} = props;

	const initialPath = pathname.split('/', 2).join('/') + '/';
	const [activePath, setActivePath] = useState(initialPath);

	useEffect(() => {
		setActivePath(pathname.split('/', 2).join('/') + '/');
	}, [pathname]);

	const navigateToPath = (path) => {
		//	setActivePath(path);
		setMobileDashboard(false);
		history.push(path);
	};

	const roleOptions = getRoleOptions(user);
	let navList = roleOptions();

	const initialOpen = navList?.findIndex((val) => {
		const found = val?.subList?.find?.(({ path }) => {
			return path === activePath;
		});
		return found;
	});
	// For determining which parent nav item should be open
	const [openParent, setOpenParent] = useState(initialOpen);

	let navMap =
		navList &&
		Boolean(navList.length) &&
		navList.map((navItem, i) => {
			const { path, subList } = navItem;
			const active = path === activePath;

			const onClick = () => {
				setOpenParent(i);
				if (path === '/logout/') {
					logoutUser(history);
				} else {
					navigateToPath(path);
				}
			};

			return subList && subList.length > 0 ? (
				<DashboardNavParent
					key={path}
					{...navItem}
					activePath={activePath}
					navigateToPath={navigateToPath}
					open={openParent === i}
					setOpen={() =>
						openParent === i ? setOpenParent(-1) : setOpenParent(i)
					}
				/>
			) : (
				<DashboardNavItem
					key={path}
					{...navItem}
					active={active}
					onClick={onClick}
				/>
			);
		});

	return <div className={styles.dashboardNavContainer}>{navMap}</div>;
}

const mapStateToProps = ({ user, overLay: { notificationsCount } }) => {
	return { user: user.user, notificationsCount };
};

export default withRouter(
	connect(mapStateToProps, { setMobileDashboard })(DashboardNav)
);
