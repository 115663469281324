import { useState, useRef } from 'react';

export default function useManageChildSchoolMap() {
	const anchorRef = useRef();

	const [anchorEl, setAnchorEl] = useState(null);

	const toggleMap = () => {
		setAnchorEl(anchorRef.current);
	};

	const clearAnchor = () => {
		setAnchorEl(null);
	};

	const enterSearch = (e) => {
		if (e.keyCode === 13) {
			toggleMap();
		}
	};

	return { anchorRef, anchorEl, toggleMap, clearAnchor, enterSearch };
}
