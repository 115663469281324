import { useDispatch } from 'react-redux';
import { apiV2 } from '../axios';
import {
	setContributorList,
	setContributorHash,
	setContributorLoaded,
	setListLoader,
} from 'redux/index';
import {
	getUser,
	createUser,
	updateUser,
	getRelationsList,
	reinviteUser,
} from 'services';
import { listSortPersonByName } from 'helpers';

export const useGetContributorList = () => {
	const dispatch = useDispatch();

	return async () => {
		dispatch(setListLoader(true));
		let { data } = (await getContributorList()) || [];
		const rawList = listSortPersonByName(data, 'lastname');
		const promiseArray = rawList.map((contributor) =>
			getContributorChildrenInfo(contributor.id)
		);
		const childInfoArray = await Promise.all(promiseArray);
		const reduced = rawList.reduce((acc, contributor, i) => {
			acc[contributor.id] = { ...contributor, ...childInfoArray[i] };
			return acc;
		}, {});
		dispatch(setContributorList(rawList.map((val) => val.id)));
		dispatch(setContributorLoaded(true));
		dispatch(setContributorHash(reduced));
		dispatch(setListLoader(false));
	};
};

export const getContributorList = async () => {
	return await apiV2.get('/contributor');
};

export const getContributor = async (id) => {
	return await getUser(id);
};

export const addContributor = async (contributor) => {
	return await createUser({
		...contributor,
		type: 'contributor',
		role: 'contributor',
	});
};

export const updateContributor = async (contributor, contributorId) => {
	const reqBody = { ...contributor };
	if (reqBody.email) {
		delete reqBody.email;
	}
	return await updateUser(reqBody, contributorId);
};

export const getContributorChildren = async (id) => {
	return await getRelationsList(id, 'contributor');
};

const getIdsFromList = (arr) => arr.map((val) => val.id);

export const getContributorChildIds = async (id) => {
	return await getContributorChildren(id).then(({ data }) => {
		return (data && getIdsFromList(data)) || [];
	});
};

export const getContributorChildrenInfo = async (id) => {
	return await getContributorChildren(id).then(({ data }) => {
		const childIds = (data && getIdsFromList(data)) || [];
		return { children: data, childIds };
	});
};

export const reinviteContributor = async (id) => {
	return await reinviteUser(id);
};

export const updateRelationship = async (id, relation) => {
	const res = await apiV2.put(`/relation/${id}/relationship`, {
		relationship: relation,
	});
	return res;
};

export const getRelationTypes = async () => {
	const relationTypes = [
		{ label: 'Teacher', value: 'Teacher', id: 0 },
		{ label: 'Coach', value: 'Coach', id: 1 },
		{ label: 'Therapist', value: 'Therapist', id: 2 },
		{ label: 'Counselor', value: 'Counselor', id: 3 },
		{ label: 'Friend', value: 'Friend', id: 4 },
		{ label: 'Grandmother', value: 'Grandmother', id: 5 },
		{ label: 'Grandfather', value: 'Grandfather', id: 6 },
		{ label: 'Mother', value: 'Mother', id: 7 },
		{ label: 'Father', value: 'Father', id: 8 },
		{ label: 'Stepmother', value: 'Stepmother', id: 9 },
		{ label: 'Sister', value: 'Sister', id: 10 },
		{ label: 'Stepfather', value: 'Stepfather', id: 11 },
		{ label: 'Brother', value: 'Brother', id: 12 },
		{ label: 'Uncle', value: 'Uncle', id: 13 },
		{ label: 'Aunt', value: 'Aunt', id: 14 },
		{ label: 'Cousin', value: 'Cousin', id: 15 },
		{ label: 'Family Friend', value: 'Family Friend', id: 16 },
	];

	const orderedRelations = relationTypes.sort((a, b) =>
		a.label.localeCompare(b.label)
	);
	const newObject = { label: 'Other', value: 'Other', key: 17 };
	orderedRelations.push(newObject);
	return orderedRelations;
};
