import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { PageLayout, LifebookNav } from 'components';
import * as Child from '.';
import { ResendExitCare } from './components/ChildAdmin';

export default function ManageChild() {
	const { childId } = useParams();
	const user = useSelector((state) => state.user.user);
	const child = useSelector((state) => state.lifebooks.child);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector((state) => state.casaUserPermissions);

	const {
		pageTitle,
		state: basicState,
		values: basicValues,
		initialize: basicInitialize,
	} = Child.useManageChildBasicState();

	const imageState = Child.useManageChildImageState();
	const swState = Child.useManageChildSWState();
	const homeState = Child.useManageChildHomeState();
	const cswState = Child.useManageChildCSWState();
	const schoolState = Child.useManageChildSchoolState();
	const codeState = Child.useManageChildCodeState();
	const siblingsState = Child.useManageChildSiblingsState();

	const { ownershipCodeVerified } = codeState;
	const location = useLocation();
	const isArchive = location.pathname?.includes('archive');

	let permissions;

	if (user.type === 'casauser') {
		if (user.role === 'admin') {
			permissions = casaPermissions;
		} else {
			permissions = casaUserPermissions;
		}
	}

	const getBody = () => {
		return {
			basicValues,
			basicInitialize,
			imageState,
			swState,
			siblingsState,
			homeState,
			cswState,
			codeState,
			schoolState,
		};
	};

	let writeProfile = true;

	if (user.type === 'casauser' && child.agencyId) {
		if (!permissions?.basic_write_profile && !permissions?.mgmt_write_profile) {
			writeProfile = false;
		}
	}
	const seeContributor = user.type === 'agencyuser' || user.type === 'casauser';
	const buttonArray = Child.useManageChildButtons({ getBody });

	return (
		<>
			<PageLayout
				title={pageTitle}
				buttonArray={writeProfile ? buttonArray : null}
				footer={childId && <LifebookNav />}
				align='left'>
				<Child.ChildImage {...{ ...imageState, ownershipCodeVerified }} />
				<Child.ChildCode {...codeState} />
				<Child.ChildBasicInfo
					state={basicState}
					school={schoolState}
					ownershipCodeVerified={ownershipCodeVerified}
				/>
				{!ownershipCodeVerified && (
					<>
						<Child.ChildSiblings selectorListState={siblingsState} />
						{user.type !== 'casauser' && (
							<>
								<Child.ChildCSW state={cswState} />
								<Child.ChildHome selectorListState={homeState} />
								<Child.ChildSocialWorker selectorListState={swState} />
								<Child.ChildCasa />
							</>
						)}
						{seeContributor && childId && <Child.ChildContributor />}
						<Child.ChildSchool
							key={`managecChildSchool-${childId}`}
							{...schoolState}
						/>
					</>
				)}
				{user.type !== 'casauser' && !isArchive && <Child.ChildAudit />}
				<Child.ChildAdmin />
				{user.role === 'admin' && isArchive && <ResendExitCare />}
			</PageLayout>
		</>
	);
}
