import {
	validatePassword,
	validatePhoneNumber,
	validateEmail,
} from './utils/validate';

export const handleInviteValidation = (infoState) => {
	const { firstname, lastname, newPassword, newPwConfirm, policyAccepted } =
		infoState;

	const validPassword = validatePassword(newPassword.value);

	if (!firstname.value) {
		return 'First name is a required field';
	}

	if (!lastname.value) {
		return 'Last name is a required field';
	}

	if (!newPassword.value) {
		return 'Password is a required field';
	}

	if (validPassword && !validPassword) {
		return 'Passwords must be at least 8 characters.';
	}

	if (newPassword.value !== newPwConfirm.value) {
		return 'Passwords do not match';
	}

	if (!policyAccepted) {
		return 'Please review and accept the Privacy Policy and Terms of Service';
	}

	if (infoState?.mobile?.value) {
		const phoneValid = validatePhoneNumber(infoState?.mobile?.value);

		if (!phoneValid) {
			return 'Phone number is not valid';
		}
	}

	return;
};

export const handleAuditInviteValidation = (name, email) => {
	const emailValid = validateEmail(email);

	if (!name) {
		return { isValid: false, message: 'Name is a required field' };
	}

	if (!email) {
		return { isValid: false, message: 'Email is a required field' };
	}

	if (!emailValid) {
		return { isValid: false, message: 'Not a valid Email' };
	}

	return { isValid: true };
};
