import VaultActivityItem from '../components/VaultActivityItem';

export const capitalizeFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const handleDescription = (val) => {
	let fileType = null;

	if (
		val.label === 'Birth Certificate' ||
		val.label === 'Social Security' ||
		val.label === 'ID Card'
	) {
		fileType = val.label;
	}

	if (val.activity === 'created') {
		if (fileType) {
			return `${fileType} Added`;
		} else {
			return 'Vault File Added';
		}
	}

	if (val.activity === 'edited') {
		if (val.accessDateChanged && val.labelChanged) {
			return `${fileType || 'Vault File'} Access Date & Label Changed`;
		}

		if (val.accessDateChanged && !val.labelChanged) {
			return `${fileType || 'Vault File'} Access Date Changed`;
		}

		if (!val.accessDateChanged && val.labelChanged) {
			return `${fileType || 'Vault File'} Label Changed`;
		}

		return `${fileType || 'Vault File'} Edited`;
	}

	if (val.activity === 'deleted') {
		if (fileType) {
			return `${fileType} Deleted`;
		} else {
			return 'Vault File Deleted';
		}
	}

	return 'Vault File Activity';
};

export function useVaultActivityMapper(lastPostActivityId) {
	return (val, index, arr) => {
		const description = handleDescription(val);

		return (
			<VaultActivityItem
				key={val.id}
				activity={{
					...val,
					index,
					description,
					only: arr.length === 1 && index === 0,
					last: !lastPostActivityId && index === arr.length - 1,
				}}
			/>
		);
	};
}
