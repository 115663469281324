import { useState } from 'react';
import { passwordRequest } from 'services';
import { useFormInput, errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { handleResetValidation } from 'helpers/validation/loginValidation';
import { TextField, PrimaryButton } from 'components';
import styles from '../login.module.css';

export default function PasswordRequest() {
	const { setSnackbarError } = useCreateDispatches();
	const [submitted, setSubmitted] = useState(false);

	let email = useFormInput('Email', '', { required: true });

	const onSubmit = async () => {
		const validation = handleResetValidation(email.value);

		if (!validation?.isValid) {
			setSnackbarError(
				{
					message: validation.message,
					location: 'Password Request Validation',
				},
				{ loggedIn: false }
			);
		} else {
			await passwordRequest(email.value)
				.then(() => {
					setSubmitted(true);
				})
				.catch(({ response }) => {
					setSnackbarError(
						{ response, ...errors.PASSWORD_REQUEST },
						{ loggedIn: false }
					);
				});
		}
	};

	email.onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};

	return (
		<div className={styles.loginRoot}>
			<div className={styles.loginContent}>
				{!submitted ? (
					<>
						<div className={styles.loginTitle}>Password Reset Request</div>
						<div className={styles.loginInfo}>
							Enter the email associated with your account.
						</div>
						<div className={styles.loginForm}>
							<TextField className={styles.loginInput} {...email} />
						</div>
					</>
				) : (
					<div className={styles.loginInfo}>
						Your request has been submitted. If the account exists, a password
						recovery email will be sent to the associated email address.
					</div>
				)}
			</div>
			<div className={styles.loginButtonContainer}>
				{!submitted ? (
					<PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
				) : null}
			</div>
		</div>
	);
}
