import { useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyManagerUsers } from 'redux/selectors';
import * as services from 'services';
import { useMobileListCheck } from 'helpers';
import { SocialWorkerListContainer, ManageSocialWorker } from './components';
import { SendInvites, Welcome } from 'components';
import styles from './socialWorkers.module.css';

function SocialWorkers(props) {
	const {
		match: {
			params: { swId },
		},
	} = props;
	const managerList = useSelector(getAgencyManagerUsers);
	const [invite, setInvite] = useState(false);

	useMobileListCheck(!(swId || invite));

	const getList = async () => {
		return await services.getAgencyUsers();
	};

	useEffect(() => {
		getList();
	}, []);

	return (
		<div className={styles.swRoot}>
			<SocialWorkerListContainer setInvite={setInvite} />
			<div className={styles.swRouteContent}>
				<Switch>
					<Route path='/socialworkers/manage/:swId?'>
						<ManageSocialWorker key={swId} />
					</Route>
					<Route path='/socialworkers/:swId' component={ManageSocialWorker} />
					<Route path='/'>
						<Welcome />
					</Route>
				</Switch>
				{invite ? (
					<div className={styles.swRouteContainer}>
						<SendInvites
							onClose={() => setInvite(false)}
							type={invite.type}
							initialValue={invite.initialValue}
							managerList={managerList}
							getList={getList}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default withRouter(SocialWorkers);
