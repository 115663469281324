import { useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getMediaQueryState } from 'redux/selectors';
import { logoutUser } from 'services';
import EllaLogo from 'images/Ella-logo-only.svg';
import WelcomeLink from './WelcomeLink';
import styles from './welcomeStyles';

export function Welcome(props) {
	const { isMd } = useSelector(getMediaQueryState);

	const { push } = useHistory();
	const location = useLocation();

	const { user } = props;

	const welcomeName = user.firstname ? user.firstname : '';

	const handleClick = (linkto) => {
		if (linkto === 'lifebook') {
			push({ pathname: `/lifebooks/` });
		} else if (linkto === 'profile') {
			push({ pathname: `/account/` });
		} else if (linkto === 'support') {
			push({ pathname: `/support/contact` });
		} else if (linkto === 'logout') {
			logoutUser();
		}
	};

	return (
		<div style={styles.welcomeRoot}>
			<div style={!isMd ? styles.container : styles.smallContainer}>
				<img style={styles.welcomeLogo} src={EllaLogo} alt='Ella Elephant' />
				<div
					style={
						!isMd ? styles.welcomeTitle : styles.smallTitle
					}>{`Welcome back, ${welcomeName}!`}</div>
				<div style={!isMd ? styles.welcomeSubject : styles.smallSubject}>
					Keeping Track Of What Matters.
				</div>
				<div style={styles.linkContainer}>
					{location.pathname !== '/lifebooks/' && (
						<WelcomeLink
							name={'LifeBook'}
							icon={'lifeBook'}
							onClick={() => handleClick('lifebook')}
						/>
					)}
					{location.pathname !== '/account/' && (
						<WelcomeLink
							name={'User Profile'}
							icon={'profile'}
							onClick={() => handleClick('profile')}
						/>
					)}
					{location.pathname !== '/support/' && (
						<WelcomeLink
							name={'Support'}
							icon={'support'}
							onClick={() => handleClick('support')}
						/>
					)}
					<WelcomeLink
						name={'Logout'}
						icon={'logout'}
						onClick={() => handleClick('logout')}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ({ user: { user } }) => {
	return { user };
};

export default connect(mapStateToProps)(Welcome);
