import { TextFieldRow, SectionHeader, EmailTextFieldRow } from 'components';
import styles from '../resourceParents.module.css';

export default function RPBasicInfo({ inputs, status }) {
	const inputMap = inputs?.map((val) => {
		if (!val.value) {
			val.value = '';
		}
		return val.label === 'Email' ? (
			<EmailTextFieldRow key={val.label} {...val} status={status} />
		) : (
			<TextFieldRow key={val.label} variant='outlined' {...val} />
		);
	});

	return (
		<>
			<SectionHeader>RESOURCE PARENT INFORMATION</SectionHeader>
			<div className={styles.rpBasicInfoRoot}>{inputMap}</div>
		</>
	);
}
