import { useLocation } from 'react-router-dom';
import * as services from 'services';
import * as Comp from 'components';
import styles from '../CasaUser.module.css';

const Connected = (props) => {
	const { state } = useLocation();

	const onSubmit = () => {
		services.approveCasaConnection(state.connectId);
		props.onCancel();
	};

	const onReject = () => {
		services.rejectCasaConnection(state.connectId);
		props.onCancel();
	};

	let buttonArray =
		state.status === 'pending'
			? [
					<Comp.SecondaryButton key='account-cancel' onClick={props.onCancel}>
						Cancel
					</Comp.SecondaryButton>,
					<Comp.PrimaryButton key='account-save-casa-reject' onClick={onReject}>
						Reject Connection
					</Comp.PrimaryButton>,
					<Comp.PrimaryButton key='account-casa-save' onClick={onSubmit}>
						Approve Connection
					</Comp.PrimaryButton>,
			  ]
			: [
					<Comp.SecondaryButton key='account-cancel' onClick={props.onCancel}>
						Cancel
					</Comp.SecondaryButton>,
			  ];

	return (
		<Comp.PageLayout
			title={'Connected Agency'}
			align='left'
			buttonArray={buttonArray}>
			<Comp.SectionHeader>Casa Name</Comp.SectionHeader>
			<div className={styles.casaUserCenteredFlex}>
				<Comp.StaticTextRow
					label={'Casa Name'}
					value={state.name}
					variant='outlined'
				/>
				<Comp.StaticTextRow
					label={'Phone Number'}
					value={state.office}
					variant='outlined'
				/>
			</div>
			<Comp.SectionHeader>Address</Comp.SectionHeader>
			<div className={styles.casaUserCenteredFlex}>
				<Comp.StaticTextRow
					label={'Street'}
					variant='outlined'
					value={state.address?.street}
				/>
				<Comp.StaticTextRow
					label={'Street 2'}
					variant='outlined'
					value={state.address?.street2}
				/>
				<Comp.StaticTextRow
					label={'City'}
					variant='outlined'
					value={state.address?.city}
				/>
				<Comp.StaticTextRow
					label={'State'}
					variant='outlined'
					value={state.address?.state}
				/>
				<Comp.StaticTextRow
					label={'Postal'}
					variant='outlined'
					value={state.address?.postal}
				/>
			</div>
		</Comp.PageLayout>
	);
};

export default Connected;
