import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	getLifebooksChild,
	getPostsRoot,
	getUserStateType,
} from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';
import { NavBack, RoundAvatar } from 'components';
import ViewSwitcher from './components/ViewSwitcher';
import styles from './headers.module.css';

export default function MobileHeader(props) {
	const params = useParams();
	const { AvatarIcon, onNavBack } = props;
	const child = useSelector(getLifebooksChild);
	const root = useSelector(getPostsRoot);
	const userType = useSelector(getUserStateType);
	const location = useLocation();
	const { push, goBack } = useHistory();
	const { setMobileDashboard } = useCreateDispatches();
	const { firstname, lastname, name } = child || {};
	const fullName = firstname && lastname ? `${firstname} ${lastname}` : name;
	const defaultOnClick = () => push('/lifebooks/');
	const isEditPost = Object.keys(params).includes('postId');
	const isChild = userType === 'child';

	const getNavBackOnClick = () => {
		switch (true) {
			case location?.pathname?.includes('calendar'):
				return goBack;
			case location?.pathname?.includes('support'):
				return () => setMobileDashboard(true);
			case ['readonly'].includes(root):
				return null;
			case isChild && isEditPost:
				return goBack;
			case isChild && !isEditPost:
				return () => setMobileDashboard(true);
			case !!onNavBack:
				return onNavBack;
			default:
				return defaultOnClick;
		}
	};

	const checkLocation = () => {
		if (location?.pathname?.includes('timeline')) {
			return true;
		}
		if (location?.pathname?.includes('services')) {
			return true;
		}
	};

	const onClick = getNavBackOnClick();

	return (
		<>
			<div className={styles.mobileHeaderContainer}>
				<NavBack onClick={onClick} className={styles.navBack} />
				{typeof child === 'object' && fullName ? (
					<>
						<RoundAvatar
							alt={fullName}
							identity={child}
							image={child.photoUrl}
							AvatarIcon={AvatarIcon}
							className={styles.mobileHeaderAvatar}
						/>
						<div className={styles.mobileHeaderName}>{fullName}</div>
					</>
				) : typeof child === 'string' ? (
					<div className={styles.mobileHeaderName}>{child}</div>
				) : null}
				<div className={styles.viewSwitchContainer}>
					{checkLocation() && <ViewSwitcher />}
				</div>
			</div>
		</>
	);
}
