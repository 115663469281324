import { formatDateRender } from 'helpers';
import styles from '../exportRequest.module.css';

const ExportRequestFor = (props) => {
	const requestedDate = formatDateRender(props?.request?.requestedAt);
	const requestFor = props?.request?.receiverName;
	const relation = props?.request?.receiverRelation;

	return (
		<div className={styles.exportRequesterFor}>
			<div className={styles.requestFor}>
				<div className={styles.headerText}>Request For:</div>
				<div className={styles.text}>{requestFor}</div>
			</div>
			<div className={styles.requestRelation}>
				<div className={styles.headerText}>Relation:</div>
				<div className={styles.text}>{relation}</div>
			</div>
			<div className={styles.requestDate}>
				<div className={styles.headerText}>Date Submitted:</div>
				<div className={styles.text}>{requestedDate}</div>
			</div>
		</div>
	);
};

export default ExportRequestFor;
