import { useHistory } from 'react-router-dom';

export function useRoleSelectRedirect() {
  const { push } = useHistory();
  const roleRoutes = {
    admin: 'admins',
    manager: 'managers',
    'social-worker': 'socialworkers'
  }

  return (role, id) => push(`/${roleRoutes[role]}/manage/${id}`)
}