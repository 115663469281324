import { withRouter, Redirect, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getMediaQueryState } from 'redux/selectors';
import { Typography } from '@material-ui/core';
import { FormSectionHeader, NavBack } from 'components';
import styles from '../child.module.css';

export function ExitedCare(props) {
	const history = useHistory();
	const { isMd } = useSelector(getMediaQueryState);

	const {
		location: { search },
	} = props;

	const { success } = queryString.parse(search) || {};

	const handleBack = () => {
		history.push('/lifebooks');
	};

	return success === 'true' ? (
		<div className={styles.exitedCareContainer}>
			{isMd && (
				<div className={styles.goBack}>
					<NavBack onClick={handleBack} />
				</div>
			)}
			<div className={styles.exitedCareContent}>
				<FormSectionHeader hideTop>Exiting Care Successful</FormSectionHeader>
				<Typography className={styles.exitedCareSection}>
					Please check your email for further instructions on handling the child
					account. An email has also been sent to the state/county social worker
					listed on file for this child.
				</Typography>
				<Typography className={styles.exitedCareSection}>
					Further records of this child can be found in the archive section.
					Please allow a few moments for the archive to compile.{' '}
				</Typography>
			</div>
			<img src='/assets/ella-elephant.svg' alt='Ella Elephant' />
		</div>
	) : (
		<Redirect to='/children/' />
	);
}

export default withRouter(ExitedCare);
