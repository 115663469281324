import { useHistory } from 'react-router-dom';
import { useCreateDispatches } from 'helpers/hooks';
import { handleInviteValidation } from 'helpers/validation/inviteValidation';

export default function useAcceptInviteHandlers() {
	const { push } = useHistory();
	const { setLoader, setSnackbar } = useCreateDispatches();

	const handleCancel = () => {
		push('/login/');
	};

	const handleValidate = async (infoState) => {
		const validationError = handleInviteValidation(infoState);

		if (!validationError) {
			return true;
		} else {
			setLoader(false);
			setSnackbar(
				{ variant: 'error', message: validationError },
				{ loggedIn: false }
			);
			return false;
		}
	};

	return {
		handleCancel,
		handleValidate,
	};
}
