import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPostsFeedType } from 'redux/selectors';
import { apiV2 } from '../../../axios';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {
	MenuItem,
	FormControl,
	TextField,
	Divider,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import styles from '../editPost.module.css';

const SuggestedPost = (props) => {
	const childId = useSelector((state) => state.lifebooks.child?.id);
	const feedType = useSelector(getPostsFeedType);
	const [sgp, setSgp] = useState();
	const [sgpValue, setSgpValue] = useState('');

	useEffect(() => {
		getSuggestedPosts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSuggestedPosts = async () => {
		await apiV2
			.get(`/sgp?childId=${childId}`)
			.then((response) => {
				setSgp(response.data);
			})
			.catch((err) => {
				console.log('get sgp error', err);
			});
	};

	const dismissSuggestedPost = async (e, id) => {
		e.stopPropagation();
		const reqBody = { childId: childId, sgpId: id };
		await apiV2.post('/sgp/dismiss', reqBody);
		await getSuggestedPosts();
	};

	const handleSgp = (e) => {
		setSgpValue(e.target.value);
		props.handleChange(e);
	};

	const sgpMap =
		sgp &&
		sgp.length > 0 &&
		sgp.map((val) => {
			return [
				<Divider key={val.id} className={styles.categorySelectDivider} />,
				<MenuItem
					className={styles.categorySelectMenuItem}
					value={val}
					key={val.id}>
					{val.title}
					{val.id !== sgpValue.id && (
						<InputAdornment position={'end'}>
							<IconButton onClick={(e) => dismissSuggestedPost(e, val.id)}>
								<DeleteForever />
							</IconButton>
						</InputAdornment>
					)}
				</MenuItem>,
			];
		});

	return feedType === 'timeline' ? (
		<div className={styles.formContainer}>
			<FormControl className={styles.select}>
				<TextField
					select
					name='sgp'
					value={sgpValue}
					className={styles.select}
					InputProps={{ classes: { input: styles.inputProps } }}
					onChange={(e) => handleSgp(e)}
					variant={'outlined'}
					label={'Suggested Posts'}>
					<MenuItem value=''>None</MenuItem>
					{sgpMap}
				</TextField>
			</FormControl>
		</div>
	) : null;
};

export default SuggestedPost;
