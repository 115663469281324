export const getAddress  = ({ address }) => address;

export const getAddressStreet  = ({ address }) => address.street;

export const getAddressStreet2  = ({ address }) => address.street2;

export const getAddressCity  = ({ address }) => address.city;

export const getAddressState  = ({ address }) => address.state;

export const getAddressPostal  = ({ address }) => address.postal;

export const getAddressCountry  = ({ address }) => address.country;