import { useSelector } from 'react-redux';
import { getUserState, getUserAgency } from 'redux/selectors';
import { TextField } from 'components';
import styles from '../support.module.css';

export default function SupportFormTextFields({ state, setState }) {
	const user = useSelector(getUserState);
	const agency = useSelector(getUserAgency);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({
			[name]: value,
		});
	};

	const textFields = [
		{
			label: 'Email',
			name: 'email',
			required: true,
			disabled: Boolean(user?.email),
		},
		{
			label: 'First Name',
			name: 'firstname',
			disabled: Boolean(user?.firstname),
		},
		{ label: 'Last Name', name: 'lastname', disabled: Boolean(user?.lastname) },
		{
			label: 'Organization',
			name: 'organization',
			disabled: Boolean(agency?.name),
		},
		{ label: 'Subject', name: 'subject', required: true },
	];

	const fieldMapper = (arr) =>
		arr.map((val) => {
			return (
				<TextField
					key={val.name}
					className={styles.supportFormField}
					{...val}
					value={state[val.name]}
					onChange={handleChange}
				/>
			);
		});

	return <>{fieldMapper(textFields)}</>;
}
