import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { includeType } from 'helpers/checkPermission';
import { getPostsRoot, getHeight } from 'redux/selectors';
import { Tooltip } from '@material-ui/core';
import { PostNoteListContainer, TextArea } from 'components';
import styles from '../editPost.module.css';

const EditPostNote = ({ note, variant, handleChange }) => {
	const { postId } = useParams();
	const height = useSelector(getHeight);
	const root = useSelector(getPostsRoot);
	const isLifebook = ['lifebooks'].includes(root);

	return (
		<>
			{isLifebook && includeType(['agencyuser']) && !postId && (
				<Tooltip
					title={'Notes can only be viewed by your agency.'}
					placement='top-start'
					disableHoverListener={true}>
					<TextArea
						className={[styles.addPostInputField, styles.inputBody].join(' ')}
						value={note}
						label='Note'
						onChange={handleChange}
						minRows={5}
						maxRows={(height / 30) * 0.3}
						name='note'
					/>
				</Tooltip>
			)}
			{isLifebook && <PostNoteListContainer post={{ id: postId, variant }} />}
		</>
	);
};

export default EditPostNote;
