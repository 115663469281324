import { MenuItem } from '@material-ui/core';
import { TextFieldRow } from 'components';

export const genderMenuItems = {
	male: 'Male',
	'non-binary': 'Non-Binary',
	female: 'Female',
	notSpecified: 'Not Specified',
};

export default function ChildGender({ value, onChange, disabled }) {
	const genderObjectMap = () => {
		let array = [];
		for (let [key, value] of Object.entries(genderMenuItems)) {
			array.push({ value: key, label: value });
		}
		return array;
	};

	const genderMenuItemsMap = genderObjectMap().map((val) => {
		return (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		);
	});

	return (
		<TextFieldRow
			value={value}
			label='Gender'
			name='gender'
			onChange={onChange}
			disabled={disabled}
			select>
			{genderMenuItemsMap}
		</TextFieldRow>
	);
}
