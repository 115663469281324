import { useEffect, useCallback, useState } from 'react';
import { setAgency, getBranch } from 'services';
import { useCheckUserPermissions, useCreateDispatches } from 'helpers/hooks';

export default function useRetrieveAgency({ user, agency, token }) {
	const [branchName, setBranchName] = useState('');
	const { setSnackbar } = useCreateDispatches();

	const { includeUsers } = useCheckUserPermissions();

	const retrieveBranch = useCallback(async () => {
		const response = await getBranch(user.branchId, token);
		if (!response.error) {
			setBranchName(response.data.name);
		} else {
			setSnackbar({
				variant: 'error',
				message:
					response.response?.data?.message || 'Could not retrieve branch',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const retrieveAgency = useCallback(async () => {
		setAgency(token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (user.branchId) {
			retrieveBranch();
		}
		if (includeUsers('agencyuser') && user.agencyId && !agency) {
			retrieveAgency();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return { branchName };
}
