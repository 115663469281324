import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getWidth } from 'redux/selectors';
import { getFooter, getHeader } from './utils/helpers';
import { useGetContainerWidth } from 'helpers/hooks';
import LayeredButtonContainer from './components/LayeredButtonContainer';
import clsx from 'clsx';
import styles from './pageLayout.module.css';

export default function PageLayout({
	children,
	header,
	title,
	footer,
	buttonArray,
	align = 'center',
}) {
	const {
		ref: contentRef,
		setContainerWidth,
		getIsContained,
	} = useGetContainerWidth();
	const width = useSelector(getWidth);

	const hasBoth = !!footer && !!buttonArray;
	const footerComponent = getFooter(footer, buttonArray);
	const headerComponent = getHeader(header, title);
	const alignmentClassName = `${align}PageLayoutAlign`;

	useEffect(() => {
		setContainerWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width, contentRef.current]);

	return (
		<div className={styles.pageLayoutRoot} ref={contentRef}>
			<div className={clsx(styles.pageLayoutHeader)}>{headerComponent}</div>
			<div
				className={clsx(
					styles.pageLayoutChildren,
					styles[alignmentClassName],
					getIsContained() ? styles.mobilePageLayoutAlign : ''
				)}>
				{children}
			</div>
			{footerComponent ? (
				<div
					className={clsx(
						styles.pageLayoutFooter,
						hasBoth && styles.pageLayoutFooterAndButtons
					)}>
					{buttonArray && footer ? (
						<LayeredButtonContainer buttonArray={buttonArray} />
					) : null}
					{footerComponent}
				</div>
			) : null}
		</div>
	);
}
