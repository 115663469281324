import DefaultFooter from '../components/DefaultFooter';
import DefaultHeader from '../components/DefaultHeader';

export const getFooter = (footer, buttonArray) => {
	switch (true) {
		case !!footer:
			return footer;
		case buttonArray && buttonArray.length > 0:
			return <DefaultFooter buttonArray={buttonArray} />;
		default:
			return null;
	}
};

export const getHeader = (header, title) => {
	switch (true) {
		case !!header:
			return header;
		default:
			return <DefaultHeader title={title} />;
	}
};
