import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDateRange } from 'redux/index';
import { getPostsDateRange } from 'redux/selectors';
import { formatDateRender, validDate } from 'helpers';
import { OptionMenu } from 'components';
import SectionLayout from './SectionLayout';
import DateRangePicker from './DateRangePicker';
import styles from '../headers.module.css';

export default function DateRange() {
	const dispatch = useDispatch();
	const dispatchDateRange = (val) => dispatch(setDateRange(val));
	const dateRange = useSelector(getPostsDateRange);
	const { childId } = useParams();

	const { pathname, search } = useLocation();
	const { push } = useHistory();

	const convertDate = (date) => {
		return new Date(date?.setHours(0, 0, 0, 0)).getTime();
	};

	const getTomorrow = (date) => {
		return date.setDate(date.getDate() + 1);
	};

	const datePickerItems = [
		{
			component: (onComplete) => (
				<DateRangePicker
					dateRange={dateRange}
					setDateRange={dispatchDateRange}
					key='date-range-picker'
					onComplete={onComplete}
				/>
			),
		},
	];

	let dateRangeLabel =
		validDate(dateRange?.[0]) && validDate(dateRange?.[1])
			? `${formatDateRender(dateRange[0], 'MMDDYY')}-${formatDateRender(
					dateRange[1],
					'MMDDYY'
			  )}`
			: 'ALL';

	useEffect(() => {
		let parseSearch = queryString.parse(search);
		const start = dateRange[0];
		const end = dateRange[1];
		if (parseSearch.startDate !== start || parseSearch.endDate !== end) {
			if (validDate(start) && validDate(end) && start < end) {
				parseSearch.startDate = convertDate(new Date(start));
				parseSearch.endDate = getTomorrow(new Date(end));
			} else {
				delete parseSearch.startDate;
				delete parseSearch.endDate;
			}
			push(`${pathname}?` + queryString.stringify(parseSearch));
		}
	}, [dateRange, childId, search, push, pathname]);

	return (
		<SectionLayout label='DATE:'>
			<OptionMenu
				menuItems={datePickerItems}
				paperStyles={{
					width: '250px',
					top: '100px !important',
				}}
				optionButton>
				<div className={styles.dateButtonContent}>
					<div>{dateRangeLabel}</div>
				</div>
			</OptionMenu>
		</SectionLayout>
	);
}
