import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getRelationsList } from 'services';
import { listSortPersonByName, errors } from 'helpers';
import { useSelectorListState, useCreateDispatches } from 'helpers/hooks';
import useManageChildPermissions from './useManageChildPermissions';

export default function useManageChildSiblingsState() {
	const { childId } = useParams();
	const permissions = useManageChildPermissions();
	const [loaded, setLoaded] = useState(false);
	const [assignedSiblings, setAssignedSiblings] = useState([]);
	const childrenList = useSelector((state) => state.lifebooks.children);
	const { setSnackbarError, setLoader } = useCreateDispatches();

	const renderList = childId && !!permissions['sw'];

	useEffect(() => {
		const setSiblings = async () => {
			setLoader(true);
			setLoaded(false);
			if (childId && renderList) {
				try {
					const { data } = await getRelationsList(childId, 'sibling');
					setAssignedSiblings(listSortPersonByName(data));
				} catch (err) {
					setSnackbarError(
						{ response: err.response, ...errors.MANAGE_CHILD_SW_REL },
						{ log: true }
					);
				}
			}
			setLoaded(true);
			setLoader(false);
		};

		setSiblings();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId]);

	return useSelectorListState(childrenList, assignedSiblings, {
		loaded,
	});
}
