import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getBranchHash } from 'redux/selectors';
import {
	useCheckUserPermissions,
	usePrevious,
	useCreateDispatches,
} from 'helpers/hooks';
import useMappedChildList from './useMappedChildList';

export default function useFilterMenuOptions() {
	const { excludeUsers } = useCheckUserPermissions();
	const updatePending = useSelector((state) => state.lifebooks.updatePending);
	const childList = useMappedChildList();
	const [filteredList, setFilteredList] = useState([...childList]);
	const [branchFilterList, setBranchFilterList] = useState([]);
	const [checked, setChecked] = useState({});
	const branchHash = useSelector(getBranchHash);
	const prevChildList = usePrevious(childList);
	const { updatePendCount } = useCreateDispatches();

	useEffect(() => {
		updateList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	// NOTE: This is used to update the list whenever the original list is updated.
	useEffect(() => {
		if (
			prevChildList &&
			childList?.length > 0 &&
			prevChildList?.length !== childList.length
		) {
			updateList();
		} else if (prevChildList?.length !== 0 && childList?.length === 0) {
			setFilteredList([]);
		} else if (updatePending) {
			updateList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childList, prevChildList]);

	const addBranch = (branchId) => {
		setChecked({ ...checked, [branchId]: true });
		setBranchFilterList([...branchFilterList, branchId]);
	};

	const removeBranch = (branchId) => {
		setChecked({ ...checked, [branchId]: false });
		const newList = branchFilterList.filter((val) => val !== branchId);
		setBranchFilterList(newList);
	};

	const newList = [];
	const bcis = childList.map((child) => child.branches);
	bcis.forEach((branch) => branch.forEach((b) => newList.push(b.id)));
	const branchChildIds = [...new Set(newList)];

	const updateList = () => {
		const checkedBranches = Object.keys(checked).filter((val) => checked[val]);

		if (checkedBranches.length === 0) {
			setFilteredList([...childList]);
			if (updatePending) {
				updatePendCount();
			}
		} else {
			let filteredList = [];
			checkedBranches.forEach((branchId) =>
				childList.filter((child) =>
					child.branches?.forEach((branch) => {
						if (branch.id === branchId) {
							filteredList.push(child);
						}
					})
				)
			);
			const removeCopyList = [...new Set(filteredList)];
			setFilteredList(removeCopyList);
		}
	};

	if (excludeUsers('admin', 'manager')) return { filteredList: childList };

	const branchMenuItems = branchChildIds.map((branchId) => {
		const isChecked = checked[branchId];
		return {
			value: branchId,
			label: branchHash[branchId]?.name,
			checked: isChecked,
			onClick: () => {
				if (isChecked) {
					removeBranch(branchId);
				} else {
					addBranch(branchId);
				}
			},
		};
	});

	branchMenuItems.unshift({
		label: 'Branches',
		value: 'branches',
		isParent: true,
	});

	return { menuItems: branchMenuItems, filteredList };
}
