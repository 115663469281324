import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Dental = (props) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			viewBox="0 0 88.485 103.18"
			{...props}>
			<path
				id='teeth'
				d='M97.625,33.4a46.947,46.947,0,0,1-6.852,24.135,28.213,28.213,0,0,0-4.03,12.446c-1.258,13.655-7.118,24.521-17.412,32.308a4.329,4.329,0,0,1-2.612.887,4.264,4.264,0,0,1-2.056-.524,4.2,4.2,0,0,1-2.241-4.03c.4-6.546.468-19.088-3.547-29.624a5.86,5.86,0,0,0-9.036-2.588A6,6,0,0,0,47.929,69C43.9,79.538,43.988,92.08,44.383,98.626a4.2,4.2,0,0,1-2.241,4.03,4.3,4.3,0,0,1-4.667-.363C27.18,94.507,21.352,83.641,20.063,69.985a28.213,28.213,0,0,0-4.071-12.446A46.947,46.947,0,0,1,9.14,33.4C9.1,14.679,21.264,0,36.789,0a23.756,23.756,0,0,1,9.746,2.088,16.767,16.767,0,0,0,13.647,0A23.852,23.852,0,0,1,69.944,0C85.461,0,97.625,14.679,97.625,33.4Z'
				transform='translate(-9.14)'
			/>
		</SvgIcon>
	);
};

export default Dental;
