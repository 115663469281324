import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setLoader, store } from 'redux/index';
import * as services from 'services';
import { usePasswordConfirm } from 'helpers';
import { DeleteButton } from 'components';
import styles from '../casa.module.css';

const RemoveChildButton = (props) => {
	const user = useSelector((state) => state.user.user);
	const onConfirm = usePasswordConfirm();
	const location = useLocation();
	const history = useHistory();

	const onDeleteChild = async () => {
		store.dispatch(setLoader(true));
		if (user.type === 'agencyuser') {
			await services.addChildToCasa('', location.state?.child?.id, 'remove');
		} else {
			await services.addChildToCasaUser(
				props.userId,
				[props.child.id],
				'remove'
			);
		}
		await services.setChildrenList();
		store.dispatch(setLoader(false));
		history.goBack();
	};

	const buttonText = user.type === 'agencyuser' ? 'CASA' : 'CASA Worker';

	return (
		<div className={styles.casaRemoveButton}>
			<div>
				<div className={styles.casaButtonLabel}>Remove Connection</div>
				<div>
					{`This option will remove the connection from this child to its ${buttonText}`}
				</div>
			</div>
			<div className={styles.casaRemoveButtonContainer}>
				<DeleteButton onClick={() => onConfirm(onDeleteChild)}>
					Remove Connection
				</DeleteButton>
			</div>
		</div>
	);
};

export default RemoveChildButton;
