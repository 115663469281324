import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import { useCreateDispatches, usePasswordConfirm } from 'helpers/hooks';
import * as service from 'services';
import useGetPostFunction from './useGetPostFunction';

export default function useGetCallbacks(post) {
	const { id, postDraftId, childId: postChildId } = post;
	const user = useSelector((state) => state.user.user);
	const checkDateDayOld = (date) => {
		const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
		return new Date(date).getTime() > oneDay;
	};
	const isOwnPost = post.createdBy?.id === user.id;
	const isDayOld = checkDateDayOld(post.createdAt);
	const { childId: paramsChildId } = useParams();
	const childId = paramsChildId || postChildId;
	const root = useSelector(getPostsRoot);
	const childPending = useSelector(
		(state) => state.lifebooks.child.pendingPostCount
	);
	const { push } = useHistory();
	const onPasswordConfirm = usePasswordConfirm();
	const fetchPostsFunction = useGetPostFunction();

	const { setLoader, setSnackbar, removePost, setChildPendCount, setPostList } =
		useCreateDispatches();

	const onEditPost = () => {
		push({
			pathname: `/${root}/post/${childId}/${id || postDraftId}`,
			state: post,
		});
	};

	const onApprovePost = async () => {
		if (id) {
			setLoader(false);
			const approveResponse = await service.approvePost(post);
			if (!approveResponse.error) {
				removePost({ id });
				service.getCurrentChild(childId, { fetch: true });
				setChildPendCount({ childId, count: childPending - 1 });
				setLoader(false);
			}
		}
	};

	const handleDeleteDraft = async () => {
		service
			.deleteDraft(postDraftId)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Draft deleted successfully',
				});
				removePost({ id, postDraftId });
				service.getCurrentChild(childId, { fetch: true });
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const deletePost = async () => {
		let message;
		if (user.type === 'child') {
			if (isOwnPost && isDayOld) {
				message = 'Post deleted succesfully';
			} else {
				message = 'Post removed';
			}
		} else {
			message = 'Post deleted successfully';
		}

		service
			.archivePost(id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: `${message}`,
				});
				removePost({ id });
				service.getCurrentChild(childId, { fetch: true });
				if (post.status !== 'approved') {
					setChildPendCount({ childId, count: childPending - 1 });
				}
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const onDeletePost = () => {
		if (postDraftId) {
			handleDeleteDraft();
		} else {
			onPasswordConfirm(deletePost, {
				message:
					'Are you sure you want to delete this post? Please confirm your password to proceed.',
			});
		}
	};

	const onMoveToVault = () => {
		if (id) {
			setLoader(true);
			removePost(id);
			service
				.moveToVault(id)
				.then(({ data }) => {
					data && removePost({ id });
					service.getCurrentChild(childId, { fetch: true });
					if (post.status !== 'approved') {
						setChildPendCount({ childId, count: childPending - 1 });
					}
				})
				.finally(() => {
					setLoader(false);
				});
		}
	};

	const onMoveFromVault = () => {
		onPasswordConfirm(moveFromVault, {
			message: 'Are you sure you want to move this post to the Timeline?',
		});
	};

	const moveFromVault = () => {
		if (id) {
			setLoader(true);
			removePost(id);
			service
				.moveFromVault(id)
				.then(({ data }) => {
					data && removePost({ id });
					service.getCurrentChild(childId, { fetch: true });
				})
				.finally(() => {
					setSnackbar({
						variant: 'success',
						message: 'Successfully restored post',
					});
					setLoader(false);
				});
		}
	};

	const onMoveServicePost = async () => {
		setLoader(true);
		const moved = await service.moveServicePost(id, !post.displayNSPInTimeline);

		if (moved) {
			const data = await fetchPostsFunction(childId, {});
			if (data) {
				setPostList(data.data?.posts);
			}
		}
		setLoader(false);
	};

	return {
		onEditPost,
		onApprovePost,
		onDeletePost,
		onMoveToVault,
		onMoveServicePost,
		onMoveFromVault,
	};
}
