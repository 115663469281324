import { useSelector } from 'react-redux';
import { getPostsFeedType } from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';
import useFetchPost from '../../Lifebooks/utils/useFetchPost';
import HelpOutline from '@material-ui/icons/HelpOutline';
import {
	RadioGroup,
	Radio,
	FormControl,
	FormControlLabel,
	Tooltip,
} from '@material-ui/core';
import styles from '../editPost.module.css';

export default function SwitcherRadios({ handleChange }) {
	const { setFeedType } = useCreateDispatches();
	const feedType = useSelector(getPostsFeedType);

	const onChange = ({ target }) => {
		setFeedType(target?.value);
		if (target?.value) {
			handleChange({ target: { name: 'nspId', value: null } });
		}
	};

	const { state } = useFetchPost();

	let postType;
	if (feedType === 'services' || state.nsp) {
		postType = 'services';
	} else {
		postType = 'timeline';
	}

	return (
		<FormControl className={styles.switcherRadiosRoot}>
			<div
				id='demo-radio-buttons-group-label'
				className={styles.switcherRadiosLabel}>
				Is this a service post?
				<Tooltip
					title='Service posts help record important information about the services your child
							receives while in care.'>
					<HelpOutline className={styles.switcherRadiosHelpIcon} />
				</Tooltip>
			</div>
			<RadioGroup
				className={styles.switcherRadiosGroup}
				aria-labelledby='demo-radio-buttons-group-label'
				value={postType}
				onChange={onChange}
				name='radio-buttons-group'>
				<FormControlLabel value='services' control={<Radio />} label='Yes' />
				<FormControlLabel value='timeline' control={<Radio />} label='No' />
			</RadioGroup>
		</FormControl>
	);
}
