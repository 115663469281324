import { SquareIconButton } from 'components';
import useActiveNavOption from './useActiveNavOption';
import styles from '../lifebookNav.module.css';

export default function useIconMapper() {
	const isActiveNavOption = useActiveNavOption();

	return (val, i, arr) => {
		const { label, onClick, icon, indicator } = val || {};
		const standardColors = arr?.length > 0;
		const activeClass = standardColors
			? styles.lifebookNavActive
			: styles.lifebookNavIconColor;
		const inactiveIconClass = standardColors
			? styles.lifebookNavIconColor
			: styles.lifebookNavActive;
		const iconClass = isActiveNavOption(label)
			? activeClass
			: inactiveIconClass;
		const lastIconClass =
			arr.length - 1 === i ? styles.lifebookNavIconLast : null;
		return val ? (
			<SquareIconButton
				key={label}
				className={[styles.lifebookNavIcon, iconClass, lastIconClass].join(' ')}
				onClick={onClick}
				vert>
				<div className={styles.lifebookNavIconContainer}>
					{icon}
					{indicator ? indicator : null}
				</div>
				<div className={styles.lifebookNavIconLabel}>{label}</div>
			</SquareIconButton>
		) : null;
	};
}
