import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/index';
import * as services from 'services';
import { filesAcceptedExtensions } from 'helpers';
import VaultHeader from './VaultHeader';
import VaultFileOptions from './VaultFileOptions';
import styles from '../vault.module.css';

const VaultFile = (props) => {
	const child = useSelector((state) => state.lifebooks.child);

	const [file, setFile] = useState();

	const [accessDate, setAccessDate] = useState(new Date());
	const [open, setOpen] = useState(false);

	const fileInputRef = useRef();
	const dispatch = useDispatch();

	const getAccessDate = () => {
		if (props?.file?.accessDate) {
			setAccessDate(props.file.accessDate);
		} else if (child.dob) {
			const date = child.dob;
			const newDate = new Date(date).setFullYear(
				new Date(date).getFullYear() + 18
			);
			const finalDate = new Date(newDate);
			setAccessDate(finalDate);
		} else {
			const date = new Date();
			setAccessDate(date);
		}
	};

	useEffect(() => {
		getAccessDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child, props.file]);

	const compatibilityString = filesAcceptedExtensions.reduce(
		(acc, cur, i, arr) => {
			return i === arr.length - 1 ? acc + ` and ${cur}` : acc + `${cur}, `;
		},
		`${filesAcceptedExtensions[0]}, `
	);

	const handleAddFile = (e) => {
		const file = e?.target?.files[0];
		const verified = services.getAcceptedVaultFile(file, compatibilityString);
		if (verified) {
			setFile(file);
		} else {
			dispatch(
				setSnackbar({
					variant: 'error',
					message: `Acceptable file formats are ${compatibilityString}`,
				})
			);
		}
	};

	const handleFileDownload = () => {
		if (props?.file?.url) {
			window.location = props?.file?.url;
		} else {
			dispatch(
				setSnackbar(
					{ variant: 'error', message: 'No File To Download' },
					{ log: true }
				)
			);
		}
	};

	const handleFileDelete = () => {
		if (props?.file?.url) {
			dispatch(setLoader(true));
			services
				.deleteVaultFile(props?.file?.id)
				.then(() => {
					services.getChildVaultFiles(child.id);
				})
				.catch((err) => {
					dispatch(setLoader(false));
					dispatch(
						setSnackbar(
							{ variant: 'error', message: err.response?.data.message },
							{ log: true }
						)
					);
				})
				.finally(() => {
					dispatch(setLoader(false));
				});
		} else {
			dispatch(
				setSnackbar(
					{ variant: 'error', message: 'No File To Delete' },
					{ log: true }
				)
			);
		}
	};

	const handleDateChange = (e) => {
		setAccessDate(e);
	};

	const handleDeleteOriginal = () => {
		if (props?.file?.label === props.label) {
			services.deleteVaultFile(props?.file?.id);
			return;
		} else {
			return;
		}
	};

	const handleUploadVault = () => {
		services
			.uploadVaultFile(child.id, file, props.label, accessDate)
			.then(() => {
				dispatch(
					setSnackbar({
						variant: 'success',
						message: 'Files uploaded successfully',
					})
				);
				services.getChildVaultFiles(child.id);
			})
			.catch((err) => {
				dispatch(
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					)
				);
			})
			.finally(() => {
				dispatch(setLoader(false));
			});
	};

	const handleSubmit = () => {
		dispatch(setLoader(true));
		if (file) {
			handleDeleteOriginal();
			handleUploadVault();
		} else if (
			props?.file?.accessDate &&
			accessDate !== props?.file?.accessDate
		) {
			services.editVaultFile(props?.file?.id, accessDate);
			dispatch(setLoader(false));
		} else {
			dispatch(setLoader(false));
			dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Edit file or access date to submit',
				})
			);
		}
	};

	return (
		<div className={styles.fullVaultFileItem}>
			<VaultHeader
				open={open}
				setOpen={setOpen}
				label={props.label}
				file={props.file}
			/>
			{open && (
				<VaultFileOptions
					showIcons={props.showIcons}
					file={props.file}
					newFile={file}
					fileInputRef={fileInputRef}
					accessDate={accessDate}
					handleAddFile={handleAddFile}
					handleFileDownload={handleFileDownload}
					handleFileDelete={handleFileDelete}
					handleDateChange={handleDateChange}
					handleSubmit={handleSubmit}
				/>
			)}
		</div>
	);
};

export default VaultFile;
