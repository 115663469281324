export const modalStyle = {
	content: {
		height: '100%',
		width: '100%',
		top: 0,
		left: 0,
		zIndex: 1000,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	overlay: {
		height: '100%',
		with: '100%',
		zIndex: 1000,
	},
};
