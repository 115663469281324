import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { defaultContributor } from 'redux/reducers';
import { getContributorContributorHash } from 'redux/selectors';
import { useCreateDispatches } from 'helpers/hooks';

export default function useSetContributor({ setPageTitle, setLoaded }) {
	const { contributorId } = useParams();
	const contributorHash = useSelector(getContributorContributorHash);
	const { setContributor } = useCreateDispatches();

	useEffect(() => {
		const contributor = (contributorId && contributorHash?.[contributorId]) || {
			...defaultContributor,
		};
		setContributor(contributor || { ...defaultContributor });
		setLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contributorId, contributorHash]);
}
