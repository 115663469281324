import { Checkbox, FormControlLabel } from '@material-ui/core';
import { mixpanelTrack } from 'helpers';
import styles from '../invite.module.css';

const PrivacyPolicy = ({ policyAccepted, setPolicyAccepted }) => {
	return (
		<FormControlLabel
			className={styles.privacyPolicyCheckbox}
			control={
				<Checkbox
					checked={policyAccepted}
					onChange={() => {
						setPolicyAccepted(!policyAccepted);
					}}
				/>
			}
			label={
				<p>
					I have read and agree to the{' '}
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://ella.care/privacy'
						onClick={() => mixpanelTrack('policy.click')}>
						Privacy Policy
					</a>{' '}
					and{' '}
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://ella.care/terms'
						onClick={() => mixpanelTrack('terms.click')}>
						Terms of Service
					</a>{' '}
					for Ella
				</p>
			}
		/>
	);
};

export default PrivacyPolicy;
