import { ExpandMore, ExpandLess, Check } from '@material-ui/icons';
import { SectionHeader } from 'components';
import { Tooltip } from '@material-ui/core';
import styles from '../vault.module.css';

const VaultHeader = (props) => {
	const hasFile = props.newFile || props?.file?.url;

	return (
		<SectionHeader>
			<div className={styles.vaultHeader}>
				<div className={styles.vaultHeaderLabel}>
					{hasFile && (
						<Tooltip title={'File has been uploaded'} placement='top-start'>
							<Check className={styles.checkbox} />
						</Tooltip>
					)}
					{props.label}
				</div>
				{!props.open ? (
					<ExpandMore
						onClick={() => props.setOpen(true)}
						className={styles.vaultExpandIcon}
					/>
				) : (
					<ExpandLess onClick={() => props.setOpen(false)} />
				)}
			</div>
		</SectionHeader>
	);
};

export default VaultHeader;
