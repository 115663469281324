import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChildren, getContributorChildren } from 'redux/selectors';
import { useSelectorListState } from 'helpers/hooks';
import useSetContributor from '../utils/useSetContributor';
import { useButtonArray } from '../utils/getButtons';
import ContributorBasicInfo from './ContributorBasicInfo';
import ContributorChildrenList from './ContributorChildrenList';
import { PageLayout } from 'components';

const ManageContributor = () => {
	const user = useSelector((state) => state.user.user);
	const childrenList = useSelector(getLifebooksChildren);
	const contributorChildren = useSelector(getContributorChildren);
	const casaChildren = useSelector((state) => state.casa.contributorChildren);
	const contributorHash = useSelector(
		(state) => state.contributor.contributorHash
	);
	const { contributorId } = useParams();
	const contributor = contributorHash[contributorId];

	const title = contributorId
		? `${contributor.firstname} ${contributor.lastname}`
		: 'Add Contributor';

	const [loaded, setLoaded] = useState(false);
	const [pageTitle, setPageTitle] = useState(title);

	const handleDisabled = () => {
		if (user.role === 'social-worker' && !user.privileged) {
			return true;
		} else {
			return false;
		}
	};

	const disabled = handleDisabled();

	useEffect(() => {
		setPageTitle(title);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contributorHash]);

	useSetContributor({ setLoaded });

	const childrenSelectorState = useSelectorListState(
		user.type === 'casauser' ? casaChildren : childrenList,
		contributorChildren,
		{ loaded }
	);

	const getChildIds = () => {
		const { changes: { add, remove } = {} } = childrenSelectorState || {};

		const childIdsToAdd = add.map(({ id }) => id);
		const childIdsToRemove = remove.map(({ id }) => id);

		return { childIdsToAdd, childIdsToRemove };
	};

	const buttonArray = useButtonArray(getChildIds);

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<ContributorBasicInfo key={contributorId} disabled={disabled} />
			<ContributorChildrenList
				disabled={disabled}
				key={contributorId + 1}
				selectorListState={childrenSelectorState}
			/>
		</PageLayout>
	);
};

export default ManageContributor;
