import { setHeader, getUser } from '../../services';
import { decodeToken } from '../../helpers';

export const checkExpiry = (exp) => {
	if (!exp) return false;
	const timeNow = Math.floor(Date.now() / 1000);
	// returns true if token is expired
	return timeNow > exp;
};

export const getChildFromToken = async (token) => {
	const { userId, type, exp } = (token && decodeToken(token)) || {};
	if (!userId || type !== 'child') return;
	if (checkExpiry(exp)) return;
	setHeader({ data: { token } });

	return await getUser(userId);
};

export const getChildFromAuditToken = async (token) => {
	const { childId, exp } = (token && decodeToken(token)) || {};
	if (!childId) return;
	if (checkExpiry(exp)) return;
	setHeader({ data: { token } });

	return await getUser(childId);
};

export const getFromToken = async (token, tokenType) => {
	switch (tokenType) {
		case 'auditoraccess':
			return await getChildFromAuditToken(token);
		case 'connect':
		default:
			return await getChildFromToken(token);
	}
};
