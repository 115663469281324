import axios from 'axios';
import { apiV2 } from '../axios';
import { store, setSnackbar, setVaultFiles } from '../redux';
import { postQueryBuilder } from '../helpers';
import { updateUser, setChildrenList } from './';

export const postChildVault = async (body) => {
	delete body.relations;
	return await apiV2.post('/vault/post/', body).catch((err) => {
		const { data } = err.response;
		store.dispatch(setSnackbar({ variant: 'error', message: data.message }));
		return { error: true, response: data };
	});
};

export const editVaultPost = async (body) => {
	delete body.relations;
	return await apiV2.put(`/vault/post/${body.postId}`, body).catch((err) => {
		const { data } = err.response;
		store.dispatch(setSnackbar({ variant: 'error', message: data.message }));
		return { error: true, response: data };
	});
};

export const getChildVault = async (childId, query) => {
	return await apiV2.get(
		`/vault/post?childId=${childId}${postQueryBuilder(query)}`
	);
};

export const getChildVaultFiles = async (childId) => {
	const vaultFiles = await apiV2.get(`/vault/docs?childId=${childId}`);
	store.dispatch(setVaultFiles(vaultFiles.data));
	return vaultFiles;
};

export const uploadVaultFile = async (userId, file, label, accessDate) => {
	const { name, type: contentType } = file;
	const nameSplit = name.split('.');
	const extension = nameSplit[nameSplit.length - 1];
	const awsUrlResponse = await getVaultFileUploadUrl({
		userId,
		extension,
		contentType,
	});
	if (awsUrlResponse.status === 200) {
		const { url, filename } = awsUrlResponse.data;
		return await uploadVaultFileToAws(url, file, contentType).then(async () => {
			return await confirmVaultFile(
				userId,
				filename,
				label || name,
				accessDate
			);
		});
	}
};

export const getVaultFileUploadUrl = async (params) => {
	return apiV2.post('/vault/docs', params);
};

export const deleteVaultFile = async (docId) => {
	return await apiV2.delete(`/vault/docs/${docId}`);
};

export const uploadVaultFileToAws = async (url, file, contentType) => {
	// NOTE: uses axios instance because this is an aws url
	return await axios.put(url, file, {
		headers: { 'Content-Type': contentType },
	});
};

export const confirmVaultFile = async (userId, filename, label, accessDate) => {
	return await apiV2.post('/vault/docs/confirm', {
		userId,
		filename,
		label,
		accessDate,
	});
};

const verifyFileType = (file, acceptedExt) => {
	if (file) {
		const fileExt = file?.name
			.substr(file.name.lastIndexOf('.') + 1, file.name.length)
			.toLowerCase();

		if (acceptedExt.indexOf(fileExt) === -1) {
			return false;
		} else {
			return fileExt;
		}
	}
};

export const getAcceptedVaultArray = (files, acceptedExt) => {
	let acceptedFiles = [];
	let rejected = 0;

	for (var i = 0; i < files.length; i++) {
		const file = files.item(i);
		let verified = verifyFileType(file, acceptedExt);
		if (verified) {
			acceptedFiles.push(file);
		} else {
			rejected++;
		}
	}

	return Promise.resolve({ acceptedFiles, rejected });
};

export const getAcceptedVaultFile = (file, acceptedExt) => {
	let verified = verifyFileType(file, acceptedExt);

	if (verified) {
		return true;
	} else {
		return false;
	}
};

export const editVaultFile = (docId, accessDate, label) => {
	const reqBody = {};
	if (accessDate) {
		reqBody.accessDate = accessDate;
	}
	if (label) {
		reqBody.label = label;
	}

	return apiV2.put(`/vault/docs/${docId}`, reqBody);
};

export const editVaultAccessDate = async (childId, vaultAccessDate) => {
	return await updateUser({ vaultAccessDate }, childId).then(async (res) => {
		await setChildrenList();
		return res;
	});
};
