import { useParams } from 'react-router-dom';
import { useSelectorListState } from 'helpers/hooks';
import useManageSocialWorkerState from '../utils/useManageSocialWorkerState';
import { useGetSocialWorkerButtons } from '../utils/useGetSocialWorkerButtons';
import { PageLayout, RoleSelector } from 'components';
import * as SwComp from './index';

export default function ManageSocialWorker() {
	const { swId } = useParams();

	const {
		pageTitle,
		childList,
		assignedChildren,
		managerList,
		assignedManager,
		role,
		setRole,
		loaded,
	} = useManageSocialWorkerState();

	const managerListState = useSelectorListState(managerList, assignedManager, {
		single: true,
		loaded,
	});

	const childListState = useSelectorListState(childList, assignedChildren, {
		loaded,
	});

	const resetListChanges = () => {
		managerListState.resetChanges();
		childListState.resetChanges();
	};

	const getUpdatedChildIds = () => {
		const { add, remove } = childListState?.changes || {};
		if (!(add.length > 0) && !(remove.length > 0)) return;

		const newList = [...assignedChildren];
		const filteredList = newList.filter((val) => {
			return remove.findIndex((removeVal) => {
				return removeVal.id === val.id;
			});
		});

		return add
			.reduce(
				(acc, cur) => {
					acc.push(cur);
					return acc;
				},
				[...filteredList]
			)
			.map((val) => val.id);
	};

	const getBody = () => {
		const reqBody = { id: swId };
		if (role !== 'social-worker') {
			reqBody.role = role;
		}
		const { add, remove } = managerListState?.changes || {};
		const updatedChildIds = getUpdatedChildIds();

		if (updatedChildIds) {
			reqBody.childIds = updatedChildIds;
		}

		if (add.length > 0) {
			reqBody.managerAgencyUserId = add[0].id;
		} else if (remove.length > 0) {
			reqBody.managerAgencyUserId = '';
		}

		return reqBody;
	};

	const buttonArray = useGetSocialWorkerButtons(getBody, resetListChanges);

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<SwComp.SocialWorkerBasicInfo />
			<SwComp.SocialWorkerBranch />
			<RoleSelector key={swId} role={role} onChange={setRole} />
			<SwComp.SocialWorkerManagerList selectorListState={managerListState} />
			<SwComp.SocialWorkerChildList selectorListState={childListState} />
			<SwComp.EnableSwPrivilege />
		</PageLayout>
	);
}
