import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from 'redux/index';
import { setResourceParent } from 'redux/reducers';
import { getHomesRPList } from 'redux/selectors';
import { UserList, SlideContainer } from 'components';

function RPListContainer() {
	const history = useHistory();
	const resourceParentList = useSelector(getHomesRPList);

	const houseListMap = resourceParentList?.map((val) => {
		return { ...val, name: `${val.firstname} ${val.lastname}` };
	});

	const onSelectRP = (e, { id }) => {
		const rp = resourceParentList.filter((parent) => parent.id === id);
		store.dispatch(setResourceParent(rp));
		history.push(`/resource-parents/manage/${id}`);
	};

	const onAddRP = () => {
		history.push('/resource-parents/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={houseListMap}
				label='Parents'
				pathParam='rpId'
				handleSelect={onSelectRP}
				addToggle={onAddRP}
				addLabel='Add a Parent'
			/>
		</SlideContainer>
	);
}

export default RPListContainer;
