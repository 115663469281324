import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from 'redux/index';
import { getHomesHouseList } from 'redux/selectors';
import { setHouse } from 'redux/reducers';
import { UserList, SlideContainer } from 'components';

function HomesListContainer() {
	const history = useHistory();
	const houseList = useSelector(getHomesHouseList);

	const houseListMap = houseList?.map((val) => {
		return { ...val, displayName: `${val.name}` };
	});

	const selectHome = async (e, { id }) => {
		const house = await houseList.filter((house) => house.id === id);
		await store.dispatch(setHouse(house));
		history.push(`/homes/manage/${id}`);
	};

	const addHouseToggle = () => {
		history.push('/homes/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={houseListMap}
				label='Households'
				pathParam={'houseId'}
				handleSelect={selectHome}
				addToggle={addHouseToggle}
				addLabel='Add a Household'
			/>
		</SlideContainer>
	);
}

export default HomesListContainer;
