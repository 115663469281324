import useGetMenuOptions from '../utils/useGetMenuOptions';
import { MoreHoriz } from '@material-ui/icons';
import { OptionMenu } from 'components';
import styles from '../timeline.module.css';

export default function PostMenu({ post }) {
	let menuItems = useGetMenuOptions(post);

	return (
		<div className={styles.postMenuRoot}>
			{menuItems?.some((val) => val) ? (
				<OptionMenu menuItems={menuItems} Icon={MoreHoriz} />
			) : null}
		</div>
	);
}
