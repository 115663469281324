import { getIcon } from 'helpers';
import { EllaLogo } from 'helpers/icons';
import { useCreateDispatches } from 'helpers/hooks';
import Post from './Post';
import { RoundAvatar } from 'components';
import styles from '../timeline.module.css';

export default function PostTileNoImage(props) {
	const { setModal } = useCreateDispatches();

	const category = props.post.category;
	const Icon = category?.iconName ? getIcon(category) : EllaLogo;

	const onClick = () => {
		setModal(
			<div className={styles.postTileNoImageModalRoot}>
				<Post {...props} />
			</div>
		);
	};

	return (
		<div className={styles.postTileNoImageRoot} onClick={onClick}>
			<RoundAvatar
				className={styles.postTileNoImageAvatar}
				alt={category?.id || ''}>
				<Icon className={styles.postTileNoImageIcon} />
			</RoundAvatar>
		</div>
	);
}
