import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { listSortPersonByName } from 'helpers';
import Close from '@material-ui/icons/Close';
import { SecondaryButton, TransparentButton } from 'components';
import AddField from './components/AddField';
import Header from './components/Header';
import Tile from './components/Tile';
import styles from './selectorList.module.css';
import clsx from 'clsx';

const SelectorList = (props = {}) => {
	const {
		children,
		assignedList,
		setAssignedList,
		label,
		textFieldLabel,
		optionsList,
		setOptionsList,
		changes,
		handleChangeAdd,
		handleChangeRemove,
		disable,
		addOnly,
		full,
		linkTo,
		disableRemove,
		single,
		expandable,
		sort = 'lastname',
		fromList,
	} = props;

	const [expandOpen, setExpandOpen] = useState(expandable ? false : true);

	const history = useHistory();
	const { isSm } = useSelector(({ mediaQuery }) => mediaQuery);
	const handleMenuSelectMulti = (e) => {
		const { value } = e.target;
		if (value > -1 && value !== 'function') {
			let menu = [...optionsList];
			let tile = [...assignedList];
			let changed = menu.splice(value, 1)[0];
			tile.push(changed);
			if (handleChangeAdd) {
				handleChangeAdd(changed);
			}
			setAssignedList(listSortPersonByName(tile, sort));
			setOptionsList(menu);
		}
	};

	const handleMenuSelectSingle = (e) => {
		const { value } = e.target;
		let menu = [...optionsList];
		let changed = menu.splice(value, 1)[0];
		menu.push(assignedList[0]);
		let tile = [changed];
		if (handleChangeAdd) {
			handleChangeAdd(changed);
		}
		setAssignedList(tile);
		setOptionsList(listSortPersonByName(menu, sort));
	};

	const headerOpen = () => {
		setExpandOpen(!expandOpen);
	};

	const handleRemove = (index, e) => {
		e.stopPropagation();
		let menu = [...optionsList];
		let tile = [...assignedList];
		let changed = tile.splice(index, 1)[0];
		menu.push(changed);

		if (handleChangeRemove) {
			handleChangeRemove(changed);
		}
		setAssignedList(tile);
		setOptionsList(listSortPersonByName(menu, sort));
	};

	const handleMenuSelect = single
		? handleMenuSelectSingle
		: handleMenuSelectMulti;

	const tileMapper = (val, index) => {
		if (!val) {
			return null;
		}
		const label = val.firstname
			? `${val.firstname} ${val.lastname}`
			: val.name
			? val.name
			: val.email.split('@')[0];

		const isNewChange = changes && changes[val.id];

		const RemoveButtonType = isSm ? TransparentButton : SecondaryButton;
		return (
			<div key={`${val.id}${index}`}>
				<Tile
					image={val.photoUrl}
					value={val}
					matchActiveParam={isNewChange ? val.id : ''}
					select={linkTo ? () => history.push(`${linkTo}${val.id}`) : null}>
					{label}
					{!disable && (!addOnly || changes[val.id]) ? (
						<div className={styles.selectorListButtonContainer}>
							{!(disableRemove && !isNewChange) && (
								<RemoveButtonType onClick={(e) => handleRemove(index, e)}>
									{isSm ? <Close /> : 'Remove'}
								</RemoveButtonType>
							)}
						</div>
					) : null}
				</Tile>
			</div>
		);
	};

	const tileMap = () => {
		if (label === 'Siblings' && disable && assignedList?.length === 0) {
			return `No ${label} Data`;
		} else if (!!assignedList && Boolean(assignedList.length)) {
			return assignedList.map(tileMapper);
		}
		return null;
	};

	const id = label?.toLowerCase().replace(/ /g, '-') + '-selector-list-';

	return (
		<div
			className={clsx(
				styles.selectorListRoot,
				full ? styles.selectorListFull : styles.selectorListStandard
			)}
			id={id + 'root'}>
			<Header
				label={label}
				expandable={expandable}
				handleOpenClose={() => headerOpen()}
				isOpen={expandOpen}
			/>
			{expandOpen && (
				<>
					{!disable ? (
						<AddField
							{...{
								id,
								children,
								label,
								textFieldLabel,
								handleMenuSelect,
								optionsList,
								single,
								fromList,
							}}
						/>
					) : null}
					<div className={styles.selectorListTileList}>{tileMap()}</div>
				</>
			)}
		</div>
	);
};

export default SelectorList;
