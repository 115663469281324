import { useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { useCheckUserPermissions } from 'helpers/hooks';

export default function useManageChildAdminPermissions() {
	const { pathname } = useLocation();
	const { childId } = useParams();
	const child = useSelector(getLifebooksChild);
	const user = useSelector((state) => state.user.user);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);

	let permissions;

	if (user.type === 'casauser') {
		if (user.role === 'admin') {
			permissions = casaPermissions;
		} else {
			permissions = casaUserPermissions;
		}
	}

	const isArchiveView = pathname?.includes('/archive/');

	const { includeUsers } = useCheckUserPermissions();

	let perms = isArchiveView
		? []
		: {
				exportPdf:
					includeUsers('manager', 'admin', 'social-worker', 'casauser') &&
					childId,
				exitCare: includeUsers('manager', 'admin', 'privileged', 'casauser'),
				deleteChild:
					includeUsers('manager', 'admin', 'casauser') &&
					child &&
					!child.archived,
		  };

	if (user.type === 'casauser') {
		if (child.agencyId) {
			return {
				exportPdf: permissions?.export_lifebook ? true : false,
				exitCare: false,
				deleteChild: false,
			};
		} else {
			if (user.role === 'mentor') {
				return {
					exportPdf: true,
					exitCare: user.privileged ? true : false,
					deleteChild: user.privileged ? true : false,
				};
			} else {
				return {
					exportPdf: true,
					exitCare: true,
					deleteChild: true,
				};
			}
		}
	} else {
		return perms;
	}
}
