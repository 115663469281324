export default function getDefaultPostState() {
  return {
    id: null,
    title: '',
    body: '',
    photo: '',
    category: '',
    posted: new Date(),
    variant: 'basic',
    file: false,
    edit: false,
    newFile: false,
    errors: false,
    approved: false,
    change: {},
    photoLoading: true,
    photos: [],
    nsp: false,
    nspDescription: null,
    servicesCategory: null,
    metadata: {},
    postDraftId: null,
    postNotes: null,
    relations: [],
    note: '',
  };
}