const defaultHomeState = {
	houseList: [],
	houseHash: {},
	home: {},
	resourceParent: {},
	resourceParentList: [],
	resourceParentHash: {},
};

const CLEAR_STORE = 'CLEAR_STORE';
const SET_HOUSE_LIST = 'SET_HOUSE_LIST';
const SET_HOUSE = 'SET_HOUSE';
const SET_RESOURCE_PARENT = 'SET_RESOURCE_PARENT';
const SET_RESOURCE_PARENT_LIST = 'SET_RESOURCE_PARENT_LIST';
const ADD_RESOURCE_PARENT_LIST = 'ADD_RESOURCE_PARENT_LIST';

const hashReduceCallback = (acc, cur) => {
	return { ...acc, [cur.id]: cur };
};

export default function homesReducer(state = { ...defaultHomeState }, action) {
	switch (action.type) {
		case CLEAR_STORE:
			return { ...defaultHomeState };
		case SET_HOUSE_LIST: {
			return {
				...state,
				houseList: action.payload.map(({ id }) => id),
				houseHash: action.payload.reduce(hashReduceCallback, {}),
			};
		}
		case SET_HOUSE: {
			let home = {};

			if (action.payload[0]) {
				home = action.payload[0];
			} else {
				home = action.payload;
			}

			return {
				...state,
				home: home,
			};
		}
		case SET_RESOURCE_PARENT: {
			let resourceParent = {};

			if (action.payload[0]) {
				resourceParent = action.payload[0];
			} else {
				resourceParent = action.payload;
			}

			return {
				...state,
				resourceParent: resourceParent,
			};
		}
		case SET_RESOURCE_PARENT_LIST: {
			return {
				...state,
				resourceParentList: action.payload.map(({ id }) => id),
				resourceParentHash: action.payload.reduce(hashReduceCallback, {}),
			};
		}
		case ADD_RESOURCE_PARENT_LIST: {
			return {
				...state,
				resourceParentList: [
					...state.resourceParentList,
					...action.payload.map(({ id }) => id),
				],
				resourceParentHash: {
					...state.resourceParentHash,
					...action.payload.reduce(hashReduceCallback, {}),
				},
			};
		}
		default:
			return state;
	}
}

export function setHouseList(houseList) {
	return {
		type: SET_HOUSE_LIST,
		payload: houseList,
	};
}

export function setHouse(house) {
	return {
		type: SET_HOUSE,
		payload: house,
	};
}

export function setResourceParent(rp) {
	return {
		type: SET_RESOURCE_PARENT,
		payload: rp,
	};
}

export function setResourceParentList(parentList) {
	return {
		type: SET_RESOURCE_PARENT_LIST,
		payload: parentList,
	};
}

export function addResourceParentList(parentList) {
	return {
		type: ADD_RESOURCE_PARENT_LIST,
		payload: parentList,
	};
}
