import useUserTypeOptions from './utils/useUserTypeOptions';
import useIconMapper from './utils/useIconMapper';
import styles from './lifebookNav.module.css';

export function LifebookNav(props) {
	const { root } = props;
	const iconMapper = useIconMapper();
	const navOptions = useUserTypeOptions(root);
	const iconMap = navOptions.map(iconMapper);

	return (
		<div className={styles.lifebookNavRoot}>
			<div className={styles.lifebookNavList}>{iconMap}</div>
		</div>
	);
}

export default LifebookNav;
