import { useEffect } from 'react';
import * as Router from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMobileList } from 'redux/index';
import { excludeType } from 'helpers';
import { SupportForm, SupportManual, SupportVideo } from './components';
import styles from './support.module.css';

const Support = () => {
	const { supportId } = Router.useParams();
	const { push } = Router.useHistory();
	const dispatch = useDispatch();
	const { user } = useSelector(({ user }) => user);
	const { email } = user;

	useEffect(() => {
		if (!supportId) {
			if (excludeType(['child'])) {
				push('/support/contact/');
			} else {
				push('/support/videos/');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supportId]);

	const onMobileNavBack = () => {
		dispatch(setMobileList(true));
	};

	return (
		<div className={styles.supportRoot}>
			<Router.Switch>
				<Router.Route path='/support/contact'>
					<SupportForm email={email} navBack={onMobileNavBack} />
				</Router.Route>
				<Router.Route path='/support/manual'>
					<SupportManual navBack={onMobileNavBack} />
				</Router.Route>
				<Router.Route path='/support/videos'>
					<SupportVideo navBack={onMobileNavBack} />
				</Router.Route>
			</Router.Switch>
		</div>
	);
};

export default Router.withRouter(Support);
