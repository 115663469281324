import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'helpers';

const defaultState = { file: '', photo: '', newFile: false, photos: null };

export default function useManageAccountImage() {
	const user = useSelector((state) => state.user.user);

	const [state, setState] = useSetState({ ...defaultState });

	const deletePhoto = () => {
		setState({ ...defaultState, newFile: true });
	};

	const { photo, id } = user;

	useEffect(() => {
		if (id && photo) {
			setState({ photo });
		} else {
			deletePhoto();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, photo, id]);

	return { ...state, setState, deletePhoto };
}
