import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader, store, setAllCasaPermissions } from 'redux/index';
import * as services from 'services';
import { casaData } from '../../utils';
import CasaUserBasicInfo from '../../Components/CasaUserBasicInfo';
import AssignedChildren from '../../Components/AssignedChildren';
import CasaPermissions from './CasaPermissions';
import CasaSelector from 'components/Casa/Components/CasaSelector';
import * as Comp from 'components';
import styles from '../CasaAgency.module.css';

const ManageCasaUserAgency = () => {
	const children = useSelector((state) => state.lifebooks.children);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const permissions = casaData(casaPermissions);

	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));

	const [child, setChild] = useState('');
	const [childList, setChildList] = useState([]);
	const [assignedChildren, setAssignedChildren] = useState([]);

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		loadChildren();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state?.id]);

	const loadChildren = async () => {
		dispatchLoader(true);
		const assigned = await services.getCasaRelation(location?.state?.id);
		setAssignedChildren(assigned?.data || []);
		const assignedIdArray = assigned?.data?.map((child) => child.id) || [];
		const list = await children.filter(
			(child) => assignedIdArray.indexOf(child.id) === -1
		);
		setChildList(list);
		dispatchLoader(false);
	};

	const onCancel = () => {
		history.goBack();
	};

	const setPermissions = async () => {
		let keys = [];

		permissions?.forEach((p) => {
			if (p.value === true) {
				keys.push(p.key);
			}
		});

		await services.setChildCasaPermissions(
			keys,
			null,
			child,
			location?.state?.id
		);
	};

	const assignChildToUser = async () => {
		await services.addChildToCasaUser(location?.state?.id, [child], 'add');
	};

	const onSubmit = async () => {
		dispatchLoader(true);
		await assignChildToUser();
		await setPermissions();
		setChild('');
		await loadChildren();
		dispatchLoader(false);
	};

	const handleSelectChild = (e) => {
		store.dispatch(setAllCasaPermissions([]));
		setChild(e.target.value);
	};

	let buttonArray = [
		<Comp.SecondaryButton key='account-cancel' onClick={onCancel}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-save-casa-user' onClick={onSubmit}>
			Save
		</Comp.PrimaryButton>,
	];

	const title = location.state?.firstname
		? `${location?.state?.firstname} ${location?.state?.lastname}`
		: location.state?.email;

	return (
		<Comp.PageLayout buttonArray={buttonArray} title={title} align='left'>
			<CasaUserBasicInfo />
			<div className={styles.manageCasaWidth}>
				<Comp.StaticTextRow value={location.state?.role} label={'Role'} />
			</div>
			{assignedChildren?.length > 0 && (
				<div className={styles.manageCasaWidth}>
					<Comp.SectionHeader>Assigned Children</Comp.SectionHeader>
					<div className={styles.manageCasaCenteredFlex}>
						<AssignedChildren
							assignedChildren={assignedChildren}
							user={location?.state}
						/>
					</div>
				</div>
			)}
			<div className={styles.manageCasaCenteredFlex}>
				<CasaSelector
					label='Children'
					textFieldLabel='Assign Child'
					menuList={childList || []}
					handleMenuSelect={(e) => handleSelectChild(e)}
					selected={child}
					handleRemove={() => setChild('')}
				/>
			</div>

			{child && <CasaPermissions permissions={permissions || []} />}
		</Comp.PageLayout>
	);
};

export default ManageCasaUserAgency;
