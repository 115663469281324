import ActivityHeaderFilter from './components/ActivityHeaderFilter';
import styles from './headers.module.css';

export default function ActivityHeader() {
	return (
		<div className={styles.headerRoot}>
			<ActivityHeaderFilter />
		</div>
	);
}
