const styles = {
	welcomeRoot: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#DEEFFB',
	},
	welcomeLogo: {
		width: '30%',
		height: 'auto',
		marginBottom: '-10px',
	},
	welcomeTitle: {
		marginBottom: '1vw',
		fontSize: '2vw',
	},
	smallTitle: {
		fontSize: '4vw',
		marginBottom: '1vw',
	},
	welcomeSubject: {
		color: '#00A0E8',
		fontSize: '1vw',
		lineHeight: '14px',
		fontWeight: '700',
	},
	smallSubject: {
		color: '#00A0E8',
		fontSize: '2vw',
		lineHeight: '14px',
		fontWeight: '700',
	},
	container: {
		backgroundColor: 'white',
		width: '38vw',
		height: '26vw',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: 25,
	},
	smallContainer: {
		backgroundColor: 'white',
		width: '90%',
		height: '50vw',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: 25,
	},
	linkContainer: {
		width: '90%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		marginTop: '1vw',
		marginBottom: '1vw',
	},
};

export default styles;
