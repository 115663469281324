const defaultUserState = { user: {}, tos: false, branches: [] };

const CLEAR_STORE = 'CLEAR_STORE';
const SET_USER = 'SET_USER';
const SET_AGENCY = 'SET_AGENCY';
const SET_AGENCY_USER = 'SET_AGENCY_USER';
const SET_AGENCY_PENDING = 'SET_AGENCY_PENDING';
const SET_AGENCY_FULFILLED = 'SET_AGENCY_FULFILLED';
const SET_AGENCY_USERS = 'SET_AGENCY_USERS';
const SET_AGENCY_HASH = 'SET_AGENCY_HASH';
const SET_AGENCY_HASH_PENDING = 'SET_AGENCY_HASH_PENDING';
const SET_AGENCY_HASH_FULFILLED = 'SET_AGENCY_HASH_FULFILLED';
const SET_AGENCY_LOADED = 'SET_AGENCY_LOADED';
const SET_BRANCH_LIST = 'SET_BRANCH_LIST';
const SET_BRANCH_HASH = 'SET_BRANCH_HASH';
const SET_BRANCHES = 'SET_BRANCHES';

export default function userReducer(state = defaultUserState, action) {
	switch (action.type) {
		case CLEAR_STORE:
			return { ...defaultUserState };
		case SET_USER:
			return { ...state, user: action.payload };
		case SET_AGENCY_PENDING:
			return { ...state, agency: {} };
		case SET_AGENCY_FULFILLED:
			return { ...state, agency: action.payload };
		case SET_AGENCY:
			return { ...state, agency: action.payload };

		case SET_AGENCY_USER: {
			const newList = { ...state.agencyUsers };
			const newHash = { ...state.agencyHash };
			newHash[action.payload?.id] = action.payload;
			const index = newList.findIndex((val) => val.id === action.payload?.id);
			if (index >= 0) {
				newList[index] = action.payload;
			}
			return {
				...state,
				agencyUsers: newList,
				agencyHash: newHash,
			};
		}
		case SET_AGENCY_USERS:
			return { ...state, agencyUsers: action.payload };
		case SET_AGENCY_HASH_PENDING:
			return { ...state, agencyHash: {} };
		case SET_AGENCY_HASH_FULFILLED:
			return { ...state, agencyHash: action.payload };
		case SET_AGENCY_HASH:
			return { ...state, agencyHash: action.payload };
		case SET_AGENCY_LOADED:
			return { ...state, agencyLoaded: action.payload };
		case SET_BRANCH_LIST:
			return { ...state, branchList: action.payload };
		case SET_BRANCH_HASH:
			return { ...state, branchHash: action.payload };
		case SET_BRANCHES:
			return { ...state, branches: action.payload };
		default:
			return state;
	}
}

export function setUser(user) {
	return {
		type: SET_USER,
		payload: user,
	};
}

export function setAgency(agency) {
	return {
		type: SET_AGENCY,
		payload: agency,
	};
}

export function setAgencyUser(user) {
	return {
		type: SET_AGENCY_USER,
		payload: user,
	};
}

export function setAgencyUsers(agency) {
	return {
		type: SET_AGENCY_USERS,
		payload: agency,
	};
}

export function setAgencyHash(hash) {
	return {
		type: SET_AGENCY_HASH,
		payload: hash,
	};
}

export function setAgencyLoaded(bool) {
	return {
		type: SET_AGENCY_LOADED,
		payload: bool,
	};
}

export function setBranchList(list) {
	return {
		type: SET_BRANCH_LIST,
		payload: list,
	};
}

export function setBranchHash(hash) {
	return {
		type: SET_BRANCH_HASH,
		payload: hash,
	};
}

export function setBranches(list) {
	return {
		type: SET_BRANCHES,
		payload: list,
	};
}
