import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/index';
import * as services from 'services';
import * as helper from 'helpers';
import { handleCasaValidation } from 'helpers/validation/casaValidation';
import { casaInputFields } from '../utils';
import addressMapper from 'components/UtilityComponents/AddressForm/utils/addressMapper';
import * as Comp from 'components';
import ErrorScreen from '../Components/ErrorScreen';
import styles from './CasaUser.module.css';

const ManageCasa = () => {
	const casa = useSelector((state) => state.casa.casa);

	const [name, setName] = useState(casa.name);
	const [phone, setPhone] = useState(helper.handlePhoneInput(casa.office));
	const [address, setAddress] = useState(casa.address);

	const history = useHistory();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));

	helper.useMobileListCheck(pathname === '/casa/');

	const onCancel = () => {
		history.goBack();
	};

	const onSubmit = async () => {
		try {
			dispatchLoader(true);
			const checkGeo = await helper.getGeocode(address);
			setAddress(checkGeo);
			const validation = handleCasaValidation(name, phone);

			if (!validation?.isValid) {
				dispatch(
					setSnackbar({
						variant: 'error',
						message: validation?.message,
					})
				);
			} else {
				services.editCasa(casa.id, {
					name: name,
					phone: phone,
					address: checkGeo,
				});
			}
		} catch {
			dispatch(
				setSnackbar({
					variant: 'error',
					message:
						'Invalid address, please check the address and submit again.',
				})
			);
		}
		dispatchLoader(false);
	};

	const onChange = (e) => {
		setAddress({ ...address, [e.target.name]: e.target.value });
	};

	let buttonArray = casa
		? [
				<Comp.SecondaryButton key='account-cancel' onClick={onCancel}>
					Cancel
				</Comp.SecondaryButton>,
				<Comp.PrimaryButton key='account-save-manage-casa' onClick={onSubmit}>
					Save
				</Comp.PrimaryButton>,
		  ]
		: [
				<Comp.SecondaryButton key='account-cancel' onClick={onCancel}>
					Cancel
				</Comp.SecondaryButton>,
		  ];

	const stateValue =
		helper.stateMap[address?.state] ||
		helper.stateMap[address?.state?.value] ||
		'';

	const inputFields = casaInputFields(address, stateValue);

	const inputMap = addressMapper(inputFields, onChange);

	if (casa === 'error') {
		return <ErrorScreen />;
	}

	return (
		<Comp.PageLayout
			title={'Manage Casa'}
			align='left'
			buttonArray={buttonArray}>
			<Comp.SectionHeader>Casa Name</Comp.SectionHeader>
			<div className={styles.casaUserCenteredFlex}>
				<Comp.TextField
					variant='outlined'
					value={name}
					label={'Name'}
					required={true}
					onChange={(e) => setName(e.target.value)}
				/>
				<Comp.TextField
					variant='outlined'
					value={phone}
					label='Phone'
					onChange={(e) => setPhone(e.target.value)}
				/>
			</div>
			<Comp.SectionHeader>Address</Comp.SectionHeader>
			<div className={styles.casaAddressContainer}>
				<div className={styles.casaAddressForm}>{inputMap}</div>
			</div>
		</Comp.PageLayout>
	);
};

export default ManageCasa;
