import { useDispatch } from 'react-redux';
import { setModal } from 'redux/index';
import ChangeRequestForm from './ChangeRequestForm';
import Post from './Post';
import styles from '../timeline.module.css';

export default function ChangeRequestButton({ children, post, disabled }) {
	const dispatch = useDispatch();

	const handleClick = () => {
		if (disabled) return;
		dispatch(
			setModal(
				<div className={styles.changeRequestModalRoot}>
					<ChangeRequestForm postId={post.id} post={post} />
					<Post
						post={post}
						disabled
						index={0}
						className={{
							content: styles.changeRequesPostContent,
							container: styles.changeRequesPostContainer,
						}}
					/>
				</div>
			)
		);
	};

	return (
		<div
			className={disabled ? styles.changeRequestDisabled : ''}
			onClick={handleClick}>
			{children}
		</div>
	);
}
