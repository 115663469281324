import { mixpanelSetUser } from '../helpers';
import { apiV2 } from '../axios';
import {
	store,
	setUser,
	setAgency as setReduxAgency,
	clearStore,
	setLoader,
} from '../redux';
import { getUserFromToken, updateUser } from './';
import history from '../routing/history';
import { checkIsInThePast } from '../helpers';

const fetchUser = () => {
	let user;

	return async (arg) => {
		// possible arg uses are login, fetchUpdate, logout

		if (arg === 'logout') {
			// update user after logout
			user = false;
			return user;
		} else if (
			(!user && localStorage.getItem('token')) ||
			arg === 'login' || // gets user after login
			arg === 'fetchUpdate' // gets the updated owner from the API
		) {
			return getOwner().then(async (response) => {
				user = response.data;
				mixpanelSetUser(user);
				if (user.vaultAccessDate) {
					user.vaultAccess = checkIsInThePast(new Date(user.vaultAccessDate));
				}
				store.dispatch(setUser(user));
				return user;
			});
		} else if (user) {
			return user;
		} else {
			return { status: 'error', message: 'Problem getting user info' };
		}
	};
};

let getOwnUser = fetchUser();

const loginEmailUser = async (reqBody) => {
	if (reqBody.uuid) {
		delete reqBody.uuid;
	}
	let response = await apiV2
		.post('/session/authenticate', reqBody)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return { error: true, message: err };
		});
	return response;
};

const logoutUser = (query) => {
	const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';
	// clear redux
	store.dispatch(setUser({}));
	store.dispatch(setReduxAgency({}));
	store.dispatch(clearStore());
	store.dispatch(setLoader(false));
	getOwnUser('logout');
	// clear session/token;
	localStorage.removeItem('active');
	localStorage.removeItem('token');
	localStorage.removeItem('cok_token');
	localStorage.removeItem('session');
	localStorage.removeItem('originalUser');
	localStorage.removeItem('childId');
	localStorage.removeItem('eroa'); //exit read only attempts
	// redirect to login
	return history.push(`/login/${queryString}`);
};

const getTokenFromResponse = (response) => {
	const { token } = response.data || {};
	return token;
};

const getTokenHeaders = (token) => ({
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	},
});

const setHeader = async (loginResponse) => {
	const token = getTokenFromResponse(loginResponse);
	if (!token) return;
	await localStorage.setItem('token', token);

	// for v2 `Bearer ${token}`
	let headers = getTokenHeaders(token);
	return headers;
};

const getOwner = async (userId) => {
	if (!userId) {
		const user = (await getUserFromToken()) || {};
		userId = user.userId;
	}

	return await apiV2.get(`/user/${userId}`);
};

const getPolicy = async (body) => {
	return await apiV2.get('/policy', body);
};

const passwordRequest = async (email) => {
	return await apiV2.post('/user/forgot', { email });
};

const passwordReset = async (newPassword, token) => {
	const user = await getUserFromToken(token);
	const requestBody = { newPassword };
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await updateUser(requestBody, user.userId, config);
};

const validJwt = (token) => {
	try {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		let decode = window.atob(base64);
		let payload = decode
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('');
		return JSON.parse(decodeURIComponent(payload));
	} catch {
		return false;
	}
};

const verifyOwnership = async (reqBody) => {
	return await apiV2.post('/child/ownership', reqBody);
};

export {
	getOwnUser,
	getOwner,
	loginEmailUser,
	logoutUser,
	getTokenHeaders,
	setHeader,
	getPolicy,
	passwordRequest,
	passwordReset,
	verifyOwnership,
	validJwt,
};
