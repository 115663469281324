import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyUser } from 'redux/selectors';
import { reinviteAgencyUser } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { errors } from 'helpers';
import { StaticTextRow, EmailStaticTextRow, SectionHeader } from 'components';
import styles from '../socialWorkers.module.css';

export default function SocialWorkerBasicInfo() {
	const { swId } = useParams();
	const { firstname, lastname, email, status, branch } = useSelector(
		getAgencyUser(swId)
	);
	const { setLoader, setSnackbar, setSnackbarError } = useCreateDispatches();
	const branchName = branch?.name ? branch.name : null;

	const onResendInvite = async () => {
		setLoader(true);
		await reinviteAgencyUser(swId)
			.then((res) => {
				setSnackbar({
					variant: 'success',
					message: 'Invite successfully re-sent.',
				});
			})
			.catch((err) => {
				setSnackbarError(
					{ response: err.response, ...errors.USER_REINVITE('SW') },
					{ log: true }
				);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const inputs = [
		{ label: 'First Name', value: firstname },
		{ label: 'Last Name', value: lastname },
		{ label: 'Branch', value: branchName },
	];
	const inputMap = inputs?.map((val) => {
		return val.value ? (
			<StaticTextRow key={val.label} variant='outlined' {...val} />
		) : null;
	});

	return (
		<>
			<SectionHeader>Social-Worker Information</SectionHeader>
			<div className={styles.socialWorkerBasicInfoRoot}>
				{inputMap}
				<EmailStaticTextRow
					value={email}
					status={status}
					onResend={onResendInvite}
				/>
			</div>
		</>
	);
}
