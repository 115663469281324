import { withRouter } from 'react-router-dom';
import styles from '../dashboard.module.css';

function OnboardBanner() {
	return (
		<div className={styles.onboardBannerRoot}>
			<div
				className={
					styles.onboardBannerContent
				}>{`You are currently in onboarding mode. To turn off onboarding go to your account settings`}</div>
		</div>
	);
}

export default withRouter(OnboardBanner);
