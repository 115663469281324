import { useHistory } from 'react-router-dom';
import { updateUser, getCasaUsers } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { PasswordConfirm, PrimaryButton } from 'components';
import styles from '../CasaUser.module.css';

const CasaExtendedPermissions = (props) => {
	const { push } = useHistory();

	const { setModal, setLoader, setSnackbar } = useCreateDispatches();

	const onSubmit = async () => {
		await updateUser({ privileged: !props.user?.privileged }, props.user?.id)
			.then((res) => {
				setSnackbar({
					variant: 'success',
					message: 'Permissions successfully changed.',
				});
				getCasaUsers();
				push({ pathname: null, state: res.data });
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const onConfirm = (onSubmit) => {
		setModal(
			<PasswordConfirm
				show={true}
				showToggle={(val) => setModal(val)}
				onSubmit={onSubmit}
			/>
		);
	};

	return (
		<div className={styles.ePermissionsRoot}>
			<div className={styles.ePermissionsContainer}>
				<div>
					<div className={styles.casaBoldLabel}>Enable Extended Access</div>
					<div>
						Enabling this option will give this user access to management
						options.
					</div>
				</div>
				<div className={styles.casaButtonWrap}>
					<PrimaryButton onClick={() => onConfirm(onSubmit)}>
						{props.user?.privileged ? 'Disable' : 'Enable'}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

export default CasaExtendedPermissions;
