import { SelectorList } from 'components';

export default function BranchManagerList({ selectorListState }) {
	const linkTo = '/agencies/manage/';
	return (
		<SelectorList
			label='MANAGERS'
			textFieldLabel='Manager'
			linkTo={linkTo}
			{...selectorListState}
		/>
	);
}
