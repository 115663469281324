import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencySWUsers } from 'redux/selectors';
import { UserList, SlideContainer } from 'components';

export default function SocialWorkerListContainer({ setInvite }) {
	const { push } = useHistory();
	const swList = useSelector(getAgencySWUsers);

	const swListMap = swList?.map((val) => {
		const { firstname, lastname, email, privileged } = val;
		const hasName = firstname && lastname;
		const name = hasName ? `${val.firstname} ${val.lastname}` : email;
		return { ...val, name };
	});

	const selectSocialWorker = (e, sw) => {
		e.stopPropagation();
		setInvite(false);
		push({ pathname: `/socialworkers/manage/${sw.id}`, state: sw });
	};

	const onAddSocialWorker = () => {
		setInvite({ type: 'socialWorker' });
	};

	return (
		<SlideContainer>
			<UserList
				list={swListMap}
				label='Social Workers'
				pathParam='swId'
				handleSelect={selectSocialWorker}
				addToggle={onAddSocialWorker}
				addLabel='Invite a Social Worker'
			/>
		</SlideContainer>
	);
}
