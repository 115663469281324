import { useParams } from 'react-router-dom';
import PendingHeaderFilter from './components/PendingHeaderFilter';
import DefaultHeader from 'components/UtilityComponents/PageLayout/components/DefaultHeader';
import styles from './headers.module.css';

export default function PendingHeader() {
	const { feedType } = useParams();

	return (
		<div className={styles.headerRoot}>
			{feedType !== 'exports' && <PendingHeaderFilter />}
			{feedType === 'exports' && <DefaultHeader title='Export Requests' />}
		</div>
	);
}
