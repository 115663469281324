import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import * as services from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { EmailTextFieldRow, SectionHeader, StaticTextRow } from 'components';
import styles from '../casa.module.css';

const CasaUserBasicInfo = () => {
	const location = useLocation();

	const [email, setEmail] = useState(location.state?.email);

	const { setSnackbar, setLoader, setCasaInput } = useCreateDispatches();

	useEffect(() => {
		setEmail(location.state?.email);
	}, [location.state]);

	const onChange = ({ target: { name, value } } = {}) => {
		setEmail(value);
		setCasaInput({ [name]: value });
	};

	const onResend = () => {
		setLoader(true);
		services
			.reinviteAgencyUser(location.state?.id)
			.then(() => {
				setLoader(false);
				setSnackbar({
					variant: 'success',
					message: `Invitation resent`,
				});
			})
			.catch((err) => {
				setLoader(false);
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			});
	};

	return (
		<>
			<SectionHeader>Casa User Information</SectionHeader>
			<div className={styles.casaCenteredFlex}>
				<EmailTextFieldRow
					key={'casa-input-email'}
					variant='outlined'
					value={email}
					name={'email'}
					label={'Email'}
					onChange={onChange}
					status={location.state?.status}
					onResend={onResend}
				/>
				<StaticTextRow
					key={'casa-input-firstname'}
					variant='outlined'
					value={location.state?.firstname}
					name={'firstname'}
					label={'First Name'}
					onChange={onChange}
				/>
				<StaticTextRow
					key={'casa-input-lastname'}
					variant='outlined'
					value={location.state?.lastname}
					name={'lastname'}
					label={'Last Name'}
					onChange={onChange}
				/>
			</div>
		</>
	);
};

export default CasaUserBasicInfo;
