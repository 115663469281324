import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPostsRoot } from 'redux/selectors';
import useFetchPost from '../Lifebooks/utils/useFetchPost';
import { AddImages, InfoTag, PageLayout } from 'components';
import * as PostComp from './components';
import styles from './editPost.module.css';

const EditPost = ({ fetchNspCount }) => {
	const { postId } = useParams();
	const root = useSelector(getPostsRoot);
	const isVault = root === 'vault';

	const { state, setState, handlers } = useFetchPost();
	const { handleChange } = handlers;
	const { photoLoading, nspDescription, relations } = state;

	return (
		<PageLayout
			header={<PostComp.EditPostHeader postId={postId} />}
			footer={
				<PostComp.EditPostButtonContainer
					{...{ postState: state, fetchNspCount, root }}
				/>
			}>
			<Prompt
				message={(location) => {
					if (window?.location?.pathname?.includes('/vault')) {
						if (!state.id) {
							if (
								state.category !== '' ||
								state.title !== '' ||
								state.body !== '' ||
								state.photos?.length > 0
							) {
								if (
									location.pathname.includes('/pending') ||
									location.pathname.includes('/vault')
								) {
									return true;
								} else {
									return `Any information added will be lost do you wish to continue?`;
								}
							}
						}
					}
					return;
				}}
			/>
			<div className={styles.editPostContainer}>
				<div className={styles.addPostContent}>
					{!isVault && (
						<>
							<PostComp.ChangeRequestEdit {...state} />
							<PostComp.EditPostServiceSelector handleChange={handleChange} />
						</>
					)}
					{Boolean(nspDescription) ? <InfoTag>{nspDescription}</InfoTag> : null}
					<AddImages
						setState={setState}
						deletePhoto={handlers.deletePhoto}
						loading={photoLoading}
						photos={state.photos}
					/>
					<form className={styles.addPostForm}>
						{!isVault && <PostComp.SuggestedPost handleChange={handleChange} />}
						<PostComp.EditPostBasicInfo handlers={handlers} post={state} />
						{!postId && ['lifebooks'].includes(root) && (
							<PostComp.TagSibling
								relations={relations}
								setRelations={(relations) => {
									setState({ relations });
								}}
							/>
						)}
						{!isVault && (
							<PostComp.EditPostNote
								{...{ ...state, handleChange: handlers.handleChange }}
							/>
						)}
					</form>
				</div>
			</div>
		</PageLayout>
	);
};

export default EditPost;
