import React, { useState, useEffect } from 'react';
import { getDisplayPhotofromArray, getAcceptedPhotoArray } from 'services';
import { imageAcceptedExtensions } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import {
	AddImageDropzone,
	AddImagePreview,
	AddImageLoading,
} from './components';
import { SecondaryButton } from 'components';
import styles from './addImage.module.css';

const fileCountLimit = 15;

export function AddImages(props) {
	const { setState, photos = [], single, file, disable } = props;
	const [dragOver, setDragOver] = useState(false);
	const [imageDisplay, setImageDisplay] = useState(null);
	const [heicDisplay, setHeicDisplay] = useState(false);
	const [initialSlide, setInitialSlide] = useState(null);
	const [loading, setLoading] = useState(true);
	const fileInputRef = React.createRef();
	const { setSnackbar } = useCreateDispatches();

	useEffect(() => {
		let getAsyncDisplay = async (photos) => {
			if (!imageDisplay && photos?.length) {
				let photo = await getDisplayPhotofromArray(photos, setHeicDisplay);
				setImageDisplay(photo.url || null);
				setInitialSlide(photo.index);
				setLoading(false);
			} else if (imageDisplay && !photos?.length) {
				setInitialSlide(null);
				setImageDisplay(null);
			} else {
				setLoading(false);
			}
		};
		getAsyncDisplay(photos);
	}, [imageDisplay, photos]);

	useEffect(() => {
		let getAsyncDisplay = async (photos) => {
			let photo = await getDisplayPhotofromArray(photos, setHeicDisplay);
			setImageDisplay(photo.url || null);
			setInitialSlide(photo.index);
			setLoading(false);
		};
		if (file) {
			setLoading(true);
			getAsyncDisplay([file]);
		}
	}, [file]);

	const openFileInput = () => {
		fileInputRef.current.click();
	};

	const onDrop = (e) => {
		const files = Array.from(e.dataTransfer.files);
		const filesToVerify = single ? [files[0]] : files;
		e.preventDefault();
		setDragOver(false);
		verifyFiles(filesToVerify);
	};

	const onDragOver = (e) => {
		e.preventDefault();
		setDragOver(true);
	};

	const onDragLeave = () => {
		setDragOver(false);
	};

	const inputFile = (e) => {
		const { files } = e.target;
		if (files?.length) {
			if (files.length + photos.length <= fileCountLimit) {
				verifyFiles(Array.from(files));
			} else {
				setSnackbar({
					variant: 'error',
					message: `Upload is limited to a maximum of ${fileCountLimit} files.`,
				});
			}
		}
		e.target.value = null;
	};

	const verifyFiles = async (files) => {
		let compatibilityString = imageAcceptedExtensions.reduce(
			(acc, cur, i, arr) => {
				return i === arr.length - 1 ? acc + ` and ${cur}` : acc + `${cur}, `;
			},
			''
		);

		let { acceptedFiles, rejected } = await getAcceptedPhotoArray(
			files,
			imageAcceptedExtensions
		);

		if (single) {
			const acceptedFile = acceptedFiles[0];
			setState({
				file: acceptedFile,
				photos: acceptedFile ? [acceptedFile] : [],
				newFile: true,
			});
		} else {
			setState({ photos: [...photos, ...acceptedFiles], newFile: true });
		}

		if (rejected) {
			setSnackbar({
				variant: 'error',
				message: `"Some files may have been rejected. Acceptable file formats are ${compatibilityString}`,
			});
		}
	};

	const deleteFile = () => {
		setState({ photos: [''] });
		setImageDisplay(null);
		props.deletePhoto();
	};

	const removeFile = (index) => {
		const newPhotosArray = [...photos];
		newPhotosArray.splice(index, 1);
		setState({ photos: newPhotosArray });
	};

	return (
		<>
			<div className={styles.addFileContainer}>
				{loading ? (
					<AddImageLoading />
				) : !imageDisplay ? (
					<AddImageDropzone
						dragOver={dragOver}
						openFileInput={openFileInput}
						onDrop={onDrop}
						onDragOver={onDragOver}
						onDragLeave={onDragLeave}
					/>
				) : (
					<>
						<AddImagePreview
							imageDisplay={imageDisplay}
							photos={photos}
							onDrop={onDrop}
							onDragOver={onDragOver}
							onDragLeave={onDragLeave}
							multiple={Boolean(photos?.length)}
							heic={heicDisplay}
							initialSlide={initialSlide}
							removeFile={removeFile}
							disable={!!disable}
							single={single}
						/>
					</>
				)}
				<input
					type='file'
					ref={fileInputRef}
					onChange={inputFile}
					accept='image/gif,image/jpeg,image/heic,image/png'
					multiple='multiple'
					style={{ display: 'none' }}
				/>
			</div>
			{imageDisplay && !disable ? (
				<div className={styles.addImagePreviewButtonContainer}>
					<SecondaryButton onClick={openFileInput}>
						{single ? 'Update' : 'Add More'}
					</SecondaryButton>
					{single && (
						<SecondaryButton onClick={deleteFile}>{'Remove'}</SecondaryButton>
					)}
				</div>
			) : null}
		</>
	);
}

export default AddImages;
