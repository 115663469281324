const defaultState = {
	childSort: 'house',
	children: [],
	childrenHash: {},
	childrenLoaded: false,
	child: {},
	schools: [],
	schoolsHash: null,
	categories: [],
	categoriesHash: {},
	houses: [],
	housesHash: {},
	housesLoaded: false,
	nsps: [],
	nspsHash: {},
	collapse: true,
	exportRequests: [],
	updatePending: false,
};

const lifebooksState = { ...defaultState };

const CLEAR_STORE = 'CLEAR_STORE';
const SET_CHILD_SORT = 'SET_CHILD_SORT';
const SET_CHILDREN = 'SET_CHILDREN';
const SET_CHILD_PEND_COUNT = 'SET_CHILD_PEND_COUNT';
const SET_CHILDREN_PENDING = 'SET_CHILDREN_PENDING';
const SET_CHILDREN_FULFILLED = 'SET_CHILDREN_FULFILLED';
const SET_CHILDREN_HASH = 'SET_CHILDREN_HASH';
const SET_CHILDREN_LOADED = 'SET_CHILDREN_LOADED';
const SET_CHILD_PENDING = 'SET_CHILD_PENDING';
const SET_CHILD_FULFILLED = 'SET_CHILD_FULFILLED';
const SET_CHILD = 'SET_CHILD';
const SET_SCHOOLS = 'SET_SCHOOLS';
const SET_SCHOOLS_HASH = 'SET_SCHOOLS_HASH';
const SET_CATEGORIES = 'SET_CATEGORIES';
const SET_CATEGORIES_HASH = 'SET_CATEGORIES_HASH';
const SET_HOUSES = 'SET_HOUSES';
const SET_HOUSES_HASH_PENDING = 'SET_HOUSES_HASH_PENDING';
const SET_HOUSES_HASH_FULFILLED = 'SET_HOUSES_HASH_FULFILLED';
const SET_HOUSES_HASH = 'SET_HOUSES_HASH';
const SET_HOUSES_LOADED = 'SET_HOUSES_LOADED';
const SET_NSPS = 'SET_NSPS';
const SET_NSPS_HASH = 'SET_NSP_HASH';
const SET_COLLAPSE = 'SET_COLLAPSE';
const SET_COLLAPSE_ALL = 'SET_COLLAPSE_ALL';
const SET_EXPORT_REQUESTS = 'SET_EXPORT_REQUESTS';
const UPDATE_PEND_COUNT = 'UPDATE_PEND_COUNT';

export default function userReducer(state = lifebooksState, action) {
	switch (action.type) {
		case CLEAR_STORE:
			return { ...defaultState };
		case SET_CHILD_SORT:
			return { ...state, childSort: action.payload };
		case SET_CHILDREN_PENDING:
			return { ...state, children: [] };
		case SET_CHILDREN_FULFILLED:
			return { ...state, children: action.payload };
		case SET_CHILDREN:
			return { ...state, children: action.payload };
		case SET_CHILDREN_HASH:
			return { ...state, childrenHash: action.payload };
		case SET_CHILDREN_LOADED:
			return { ...state, childrenLoaded: action.payload };
		case SET_CHILD_PEND_COUNT: {
			const childIndex = state.children?.findIndex(
				(val) => val.id === action.payload?.childId
			);
			let newList = [...state.children];
			newList[childIndex].pendingPostCount = action.payload?.count;
			return { ...state, children: newList, updatePending: true };
		}
		case SET_CHILD_PENDING:
			return { ...state, child: {} };
		case UPDATE_PEND_COUNT:
			return { ...state, updatePending: false };
		case SET_CHILD_FULFILLED:
			return { ...state, child: action.payload.data };
		case SET_CHILD:
			return { ...state, child: action.payload };
		case SET_SCHOOLS:
			return { ...state, schools: action.payload };
		case SET_SCHOOLS_HASH:
			return { ...state, schoolsHash: action.payload };
		case SET_NSPS:
			return { ...state, nsps: action.payload };
		case SET_NSPS_HASH:
			return { ...state, nspsHash: action.payload };
		case SET_CATEGORIES:
			return { ...state, categories: action.payload };
		case SET_CATEGORIES_HASH:
			return { ...state, categoriesHash: action.payload };
		case SET_HOUSES:
			return { ...state, houses: action.payload };
		case SET_HOUSES_HASH_PENDING:
			return { ...state, housesHash: {} };
		case SET_HOUSES_HASH_FULFILLED:
			return { ...state, housesHash: action.payload };
		case SET_HOUSES_HASH:
			return { ...state, housesHash: action.payload };
		case SET_HOUSES_LOADED:
			return { ...state, housesLoaded: action.payload };
		case SET_COLLAPSE:
			const { collapse, houseId } = action.payload;
			return {
				...state,
				housesHash: {
					...state.housesHash,
					[houseId]: {
						...state.housesHash[houseId],
						collapse,
					},
				},
			};
		case SET_COLLAPSE_ALL:
			let newHash = { ...state.housesHash };
			for (let value in newHash) {
				newHash[value].collapse = action.payload;
			}
			return {
				...state,
				housesHash: newHash,
				collapse: action.payload,
			};
		case SET_EXPORT_REQUESTS:
			return {
				...state,
				exportRequests: action.payload,
			};
		default:
			return state;
	}
}

export function clearStore() {
	return {
		type: CLEAR_STORE,
	};
}

export function setChildSort(sort) {
	return {
		type: SET_CHILD_SORT,
		payload: sort,
	};
}

export function setChildren(children) {
	return {
		type: SET_CHILDREN,
		payload: children,
	};
}

export function setChildrenHash(children) {
	return {
		type: SET_CHILDREN_HASH,
		payload: children,
	};
}

export function setChildrenLoaded(bool) {
	return {
		type: SET_CHILDREN_LOADED,
		payload: bool,
	};
}

export function setChild(child) {
	return {
		type: SET_CHILD,
		payload: child,
	};
}

export function setChildPendCount(payload) {
	return {
		type: SET_CHILD_PEND_COUNT,
		payload,
	};
}

export function updatePendCount() {
	return {
		type: UPDATE_PEND_COUNT,
	};
}

export function setSchools(schools) {
	return {
		type: SET_SCHOOLS,
		payload: schools,
	};
}

export function setSchoolsHash(hashMap) {
	return {
		type: SET_SCHOOLS_HASH,
		payload: hashMap,
	};
}

export function setNsps(nsps) {
	return {
		type: SET_NSPS,
		payload: nsps,
	};
}

export function setNspsHash(hashMap) {
	return {
		type: SET_NSPS_HASH,
		payload: hashMap,
	};
}

export function setCategories(categories) {
	return {
		type: SET_CATEGORIES,
		payload: categories,
	};
}

export function setCategoriesHash(categoriesHash) {
	return {
		type: SET_CATEGORIES_HASH,
		payload: categoriesHash,
	};
}

export function setHouses(houses) {
	return {
		type: SET_HOUSES,
		payload: houses,
	};
}

export function setHousesHash(housesHash) {
	return {
		type: SET_HOUSES_HASH,
		payload: housesHash,
	};
}

export function setHousesLoaded(bool) {
	return {
		type: SET_HOUSES_LOADED,
		payload: bool,
	};
}

export function setCollapse(collapse) {
	return {
		type: SET_COLLAPSE,
		payload: collapse,
	};
}

export function setCollapseAll(bool) {
	return {
		type: SET_COLLAPSE_ALL,
		payload: bool,
	};
}

export function setExportRequest(exportRequests) {
	return {
		type: SET_EXPORT_REQUESTS,
		payload: exportRequests,
	};
}
