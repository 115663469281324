const defaultState = {
	modalArray: [],
	slideOut: false,
	fullScreen: false,
	listLoader: false,
	loader: false,
	bannerButtons: [],
	mobileDashboard: false,
	mobileList: false,
	notificationsCount: null,
};

const overLaysState = { ...defaultState };

const CLEAR_STORE = 'CLEAR_STORE';
const SET_MODAL = 'SET_MODAL';
const SET_SLIDE_OUT = 'SET_SLIDE_OUT';
const SET_FULL_SCREEN = 'SET_FULL_SCREEN';
const SET_LOADER = 'SET_LOADER';
const SET_LIST_LOADER = 'SET_LIST_LOADER';
const SET_BANNER_BUTTONS = 'SET_BANNER_BUTTONS';
const SET_MOBILE_DASHBOARD = 'FuseDispatchSET_MOBILE_DASHBOARD';
const SET_MOBILE_LIST = 'SET_MOBILE_LIST';
const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
const SET_NOTIFICATION_COUNT_FULFILLED = 'SET_NOTIFICATION_COUNT_FULFILLED';

export default function overLaysReducer(state = overLaysState, action) {
	switch (action.type) {
		case SET_MODAL: {
			let modalArray = [...state.modalArray];
			if (!action.payload) {
				if (modalArray.length > 0) {
					modalArray.shift();
				}
			} else {
				modalArray.unshift(action.payload);
			}

			return { ...state, modalArray };
		}
		case SET_SLIDE_OUT:
			return { ...state, slideOut: action.payload };
		case SET_FULL_SCREEN:
			return { ...state, fullScreen: action.payload };
		case SET_LIST_LOADER:
			return { ...state, listLoader: action.payload };
		case SET_LOADER:
			return { ...state, loader: action.payload };
		case SET_BANNER_BUTTONS:
			return { ...state, bannerButtons: action.payload };
		case SET_MOBILE_DASHBOARD:
			return { ...state, mobileDashboard: action.payload };
		case SET_MOBILE_LIST:
			return { ...state, mobileList: action.payload };
		case SET_NOTIFICATION_COUNT:
		case SET_NOTIFICATION_COUNT_FULFILLED:
			return { ...state, notificationsCount: action.payload };
		case CLEAR_STORE:
			return defaultState;
		default:
			return state;
	}
}

export function setModal(child) {
	return {
		type: SET_MODAL,
		payload: child,
	};
}

export function setSlideOut(bool) {
	return {
		type: SET_SLIDE_OUT,
		payload: bool,
	};
}

export function setFullScreen(bool) {
	return {
		type: SET_FULL_SCREEN,
		payload: bool,
	};
}

export function setListLoader(bool) {
	return {
		type: SET_LIST_LOADER,
		payload: bool,
	};
}

export function setLoader(bool) {
	return {
		type: SET_LOADER,
		payload: bool,
	};
}

export function setBannerButtons(buttons) {
	return {
		type: SET_BANNER_BUTTONS,
		payload: buttons,
	};
}

export function setMobileDashboard(bool) {
	return {
		type: SET_MOBILE_DASHBOARD,
		payload: bool,
	};
}

export function setMobileList(bool) {
	return {
		type: SET_MOBILE_LIST,
		payload: bool,
	};
}

export function setNotificationCount(count) {
	return {
		type: SET_NOTIFICATION_COUNT,
		payload: count,
	};
}
