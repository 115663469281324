import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import useCallbacks from './useCallbacks';
import useManageChildPermissions from './useManageChildPermissions';
import * as util from './utils';
import { SecondaryButton, PrimaryButton } from 'components';

export default function useManageChildButtons({ getBody } = {}) {
	const { childId } = useParams();
	const child = useSelector(getLifebooksChild);
	const permissions = useManageChildPermissions();
	const { restart, submitRequest, onCancel } = useCallbacks();

	const hasSubmit = Object.values(permissions)?.some?.((val) => val === 'edit');

	const onSubmit = async () => {
		const {
			basicValues = {},
			basicInitialize,
			imageState: { file, newFile } = {},
			siblingsState: { changes: siblingChanges = {} } = {},
			swState: { changes: swChanges = {}, assignedList: swAssigned = [] } = {},
			homeState: { assignedList: homeAssigned = [] } = {},
			codeState: {
				ownershipCodeVerified,
				textFieldArray: [ownershipCode, ownershipCodeId] = [],
			},

			schoolState: { schoolState },
			cswState: { email: cswEmail } = {},
		} = getBody();

		if (ownershipCodeVerified) {
			const id = ownershipCodeId?.value;
			const code = ownershipCode?.value;
			await restart(id, code);
		} else {
			const basicInfo = util.filterChildBasic(basicValues, child);
			let reqBody = {
				...basicInfo,
			};

			if (basicValues.dob === '' || !basicValues.dob) {
				reqBody.dob = 'error';
			}

			if (basicInfo?.gender === 'notSpecified') {
				reqBody.gender = '';
			}

			if (!childId && newFile) {
				reqBody.file = file?.file;
			}

			if (childId) {
				reqBody.id = childId;
				if (newFile) {
					if (file?.file) {
						reqBody.photo = await util.getImageLocation(file.file, childId);
					} else {
						reqBody.photo = '';
					}
				}
			}

			const { add: swAdd = [], remove: swRemove = [] } = swChanges;

			if (swAdd.length > 0 || swRemove.length > 0) {
				reqBody.agencyUserIds = util.createAgencyUserIds(swAssigned);
			}

			const { add: siblingAdd = [], remove: siblingRemove = [] } =
				siblingChanges;

			if (siblingAdd.length > 0) {
				reqBody.siblingAdd = siblingAdd.map((sibling) => sibling.id);
			}

			if (siblingRemove.length > 0) {
				reqBody.siblingRemove = siblingRemove.map(
					(sibling) => sibling.relation?.id
				);
			}

			if (child?.house?.id !== homeAssigned?.[0]?.id) {
				reqBody.houseId = util.getChildHome(homeAssigned);
			}

			if (schoolState?.id) {
				reqBody.schoolId = schoolState.id;
			}

			if (cswEmail?.value) {
				reqBody.cswEmail = cswEmail.value;
			} else if (cswEmail?.value === '') {
				reqBody.cswEmail = '';
			}

			const addAgency = !childId && swAdd ? reqBody.agencyUserIds : null;

			await submitRequest(reqBody, basicInitialize, addAgency);
		}
	};

	return hasSubmit
		? [
				<SecondaryButton key='manageChildCancel' onClick={onCancel}>
					Cancel
				</SecondaryButton>,
				<PrimaryButton key='manageChildSave' onClick={onSubmit}>
					Save
				</PrimaryButton>,
		  ]
		: null;
}
