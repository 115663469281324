import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePasswordConfirm } from 'helpers';
import { CloudDownload, DeleteForever, CloudUpload } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { PrimaryButton, DatePicker } from 'components';
import styles from '../vault.module.css';

const VaultFileOptions = (props) => {
	const user = useSelector((state) => state.user.user);

	const fileInputRef = useRef(null);
	const onPasswordConfirm = usePasswordConfirm();

	const handleClick = () => {
		fileInputRef.current.click();
	};

	const today = new Date();

	const hasFile = props.newFile || props?.file?.url;
	const hasAccess =
		props?.file?.accessDate && new Date(props?.file?.accessDate) < today
			? true
			: false;

	if (user.type === 'child' && !hasAccess) {
		return <>No Access or File</>;
	}

	if (user.type === 'child' && hasAccess && hasFile) {
		return (
			<div className={styles.vaultChildDownload}>
				<Tooltip title={'Download file'} placement='top-start'>
					<CloudDownload
						className={styles.downloadIconChild}
						onClick={props.handleFileDownload}
					/>
				</Tooltip>
				<>{props.file?.label}</>
			</div>
		);
	}

	return (
		<div className={styles.vaultFileItemContainer}>
			<div className={styles.updateAddContainer}>
				{hasFile?.name && (
					<Tooltip title={'File is ready for submission'} placement='top-start'>
						<CloudUpload className={styles.infoIcon} />
					</Tooltip>
				)}

				<PrimaryButton onClick={handleClick}>
					{props?.file?.url ? 'Update' : 'Add File'}
				</PrimaryButton>
				<input
					ref={fileInputRef}
					type='file'
					className={styles.fileInput}
					onChange={(e) => props.handleAddFile(e)}
				/>
			</div>
			<div className={styles.vaultDateContainer}>
				<DatePicker
					onChange={(e) => props.handleDateChange(e)}
					value={props.accessDate}
					required
					disableFuture={false}
					label={'Access Date'}
				/>
			</div>
			{props.file.url && (
				<div className={styles.vaultIconContainer}>
					<Tooltip title={'Download file'} placement='top-start'>
						<CloudDownload
							className={
								props?.file?.url
									? styles.downloadIconActive
									: styles.downloadIconInactive
							}
							onClick={props.handleFileDownload}
						/>
					</Tooltip>
					<Tooltip title={'Delete File'} placement='top-start'>
						<DeleteForever
							className={
								props?.file?.url
									? styles.downloadIconActive
									: styles.downloadIconInactive
							}
							onClick={() => onPasswordConfirm(props.handleFileDelete)}
						/>
					</Tooltip>
				</div>
			)}

			<div className={styles.submitButtonContainer}>
				<PrimaryButton onClick={props.handleSubmit}>Submit</PrimaryButton>
			</div>
		</div>
	);
};

export default VaultFileOptions;
