import { useState } from 'react';
import useManageChildPermissions from '../utils/useManageChildPermissions';
import { SectionHeader, StaticTextRow, EmailTextFieldRow } from 'components';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import styles from '../child.module.css';
import { reinviteCsw } from 'services';
import { useCreateDispatches } from 'helpers/hooks';

export default function ChildCSW({ state }) {
	const { csw, email } = state;
	const { csw: permission } = useManageChildPermissions();
	const canEdit = permission === 'edit';
	const canView = permission === 'view';

	const [expanded, setExpanded] = useState(false);

	const reinvite = email?.value !== csw?.email ? false : true;

	const { setLoader, setSnackbar, setSnackbarError } = useCreateDispatches();

	const onResendInvite = async () => {
		setLoader(true);
		await reinviteCsw(csw?.id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Invite successfully re-sent.',
				});
			})
			.catch((err) => {
				setSnackbarError({ response: err.response }, { log: true });
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	if (canEdit) {
		return (
			<div className={styles.childCswRoot}>
				<SectionHeader>
					<div className={styles.basicHeader}>
						<div className={styles.headerText}>STATE/COUNTY SOCIAL WORKER </div>
						{expanded ? (
							<ExpandLess onClick={() => handleOpenClose()} />
						) : (
							<ExpandMore onClick={() => handleOpenClose()} />
						)}
					</div>
				</SectionHeader>
				{expanded && (
					<EmailTextFieldRow
						{...email}
						editable={true}
						reinvite={reinvite}
						status={csw?.status}
						onResend={() => onResendInvite()}
					/>
				)}
			</div>
		);
	}

	return canView && csw?.email ? (
		<div className={styles.childCswRoot}>
			<SectionHeader>
				<div className={styles.basicHeader}>
					<div className={styles.headerText}>STATE/COUNTY SOCIAL WORKER </div>
					{expanded ? (
						<ExpandLess onClick={() => handleOpenClose()} />
					) : (
						<ExpandMore onClick={() => handleOpenClose()} />
					)}
				</div>
			</SectionHeader>
			{expanded && (
				<StaticTextRow label='State/County SW'>
					{csw.email} {`(verified)`}
				</StaticTextRow>
			)}
		</div>
	) : null;
}
