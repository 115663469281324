import { useDispatch } from 'react-redux';
import * as reduxActions from '../../redux/reducers';

let dispatches = {};

export function useCreateDispatches(a) {
	const dispatch = useDispatch();
  if (Object.keys(dispatches).length === 0) {
	dispatches = Object.keys(reduxActions).reduce((acc, key) => {
		const action = reduxActions[key];
		acc[key] = (...args) => {
			return dispatch(action(...args));
		}
		return acc;
	}, {});
}
	return dispatches;
}
