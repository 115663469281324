import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	setHeight,
	setWidth,
	setPortrait,
	breakpoints,
	setLg,
	setMd,
	setSm,
	setSmd,
} from 'redux/index';

const MediaQuery = (props) => {
	useEffect(() => {
		listenResize();
		window.addEventListener('resize', listenResize);
		return () => window.removeEventListener('resize', listenResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const listenResize = () => {
		const {
			setWidth,
			setHeight,
			setPortrait,
			setLg,
			setSmd,
			setMd,
			setSm,
			mediaQuery: { portrait, width, height, isLg, isMd, isSm, isSmd },
		} = props;

		if (window.innerWidth !== width) {
			setWidth(window.innerWidth);
		}
		if (window.innerHeight !== height) {
			setHeight(window.innerHeight);
		}
		if (window.innerHeight > window.innerWidth && !portrait) {
			setPortrait(true);
		} else if (window.innerHeight < window.innerWidth && portrait) {
			setPortrait(false);
		}
		if (window.innerWidth < breakpoints.lg && !isLg) {
			setLg(true);
		}
		if (window.innerWidth > breakpoints.lg && isLg) {
			setLg(false);
		}
		if (window.innerWidth < breakpoints.smd && !isSmd) {
			setSmd(true);
		}
		if (window.innerWidth > breakpoints.smd && isSmd) {
			setSmd(false);
		}

		if (window.innerWidth < breakpoints.md && !isMd) {
			setMd(true);
		}
		if (window.innerWidth > breakpoints.md && isMd) {
			setMd(false);
		}
		if (window.innerWidth < breakpoints.sm && !isSm) {
			setSm(true);
		}
		if (window.innerWidth > breakpoints.sm && isSm) {
			setSm(false);
		}
	};

	return null;
};

const mapStateToProps = (mediaQuery) => {
	return mediaQuery;
};

const mapDispatchToProps = {
	setHeight,
	setWidth,
	setPortrait,
	setLg,
	setSmd,
	setMd,
	setSm,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery);
