import useManageChildPermissions from '../utils/useManageChildPermissions';
import { SelectorList } from 'components';

export default function ChildSiblings({ selectorListState }) {
	const { siblings: permission } = useManageChildPermissions();
	const canRender = !!permission;
	const canEdit = permission === 'edit';

	return canRender ? (
		<SelectorList
			label='Siblings'
			textFieldLabel='Siblings'
			fromList='child-siblings'
			disable={!canEdit}
			full
			expandable={true}
			{...selectorListState}
		/>
	) : null;
}
