import { InputAdornment } from '@material-ui/core';
import { StaticTextRow, TextFieldRow } from 'components';
import styles from './form.module.css';

const EmailTextFieldRow = ({
	status,
	editable,
	reinvite,
	onResend,
	...rest
}) => {
	if (reinvite === undefined) {
		reinvite = true;
	}

	const showResend =
		// - has a resend callback
		// - value has not been changed
		onResend && status === 'invited' && reinvite;

	return status === 'active' && !editable ? (
		<StaticTextRow {...{ ...rest, label: rest.label + ' (verified)' }} />
	) : (
		<TextFieldRow
			{...rest}
			InputProps={{
				endAdornment: showResend ? (
					<InputAdornment position='end'>
						<span className={styles.emailInputResendButton} onClick={onResend}>
							Resend Invite
						</span>
					</InputAdornment>
				) : null,
			}}
		/>
	);
};

export default EmailTextFieldRow;
