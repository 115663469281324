import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const styles = (theme) => ({
	infoTagContainer: {
		marginTop: '16px',
		width: '100%',
		height: 'auto',
		color: theme.palette.secondary.font,
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: '24px',
	},
	infoTagContent: {
		marginLeft: '8px',
	},
});

const useStyles = makeStyles(styles);
export function InfoTag(props) {
	const classes = useStyles();
	const { children, className } = props;
	return (
		<div className={clsx(classes.infoTagContainer, className ? className : '')}>
			<InfoOutlined />
			<div className={classes.infoTagContent}>{children}</div>
		</div>
	);
}

export default InfoTag;
