import { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { useCheckUserPermissions } from 'helpers/hooks';
import ExportPdfButton from './ExportPdfButton';
import ExportPdfForm from './ExportPdfForm';
import styles from '../ChildAdmin.module.css';

export default function ExportPdf() {
	const child = useSelector(getLifebooksChild);
	const { childId } = useParams();
	const [open, setOpen] = useState(false);
	const openProps = { open, setOpen };
	const { includeUsers } = useCheckUserPermissions();

	const info = child.privatizePosts
		? "Use this option to request a copy of this child's LifeBook. "
		: "Use this option to export a copy of this child's LifeBook. ";

	const isAllowed =
		childId && includeUsers('manager', 'admin', 'social-worker', 'casauser');

	return isAllowed ? (
		<>
			<div className={styles.childAdminItemWrapper}>
				<div>
					<div className={styles.childAdminBoldLabel}>Export Lifebook</div>
					<div>{info}</div>
				</div>
				<div className={styles.childAdminButtonContainer}>
					{!open && <ExportPdfButton {...openProps} />}
				</div>
			</div>
			{open && <ExportPdfForm {...openProps} />}
		</>
	) : null;
}
