import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/reducers/loaderReducer';
import { useCreateDispatches } from 'helpers/hooks';
import { getAgencyActivity } from 'services/activity-services';
import { useGetAgencyActivity } from '../utils/useGetAgencyActivity';
import PageLayout from 'components/UtilityComponents/PageLayout/PageLayout';
import MobileHeader from 'components/Headers/MobileHeader';
import styles from '../activity.module.css';
import { useAgencyActivityMapper } from '../utils/useAgencyActivityMapper';

export default function AgencyActivities() {
	const { setSnackbar } = useCreateDispatches();
	const dispatch = useDispatch();
	const dispatchLoader = (val) => dispatch(setLoader(val));
	const agencyId = useSelector((state) => state.user.agency?.id);
	const isMd = useSelector((state) => state.mediaQuery.isMd);

	const [activity, setActivity] = useState(null);
	const [lastPostActivityId, setlastPostActivityId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const getActivity = useGetAgencyActivity({
		setActivity,
		setlastPostActivityId,
	});

	const populateActivityFeed = useCallback(async () => {
		dispatchLoader(true);
		setIsLoading(true);
		await getActivity(agencyId).finally(() => {
			setIsLoading(false);
			dispatchLoader(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agencyId]);

	useEffect(() => {
		if (agencyId) {
			populateActivityFeed();
		}
	}, [populateActivityFeed]);

	const handleScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (
			activity &&
			activity.length &&
			lastPostActivityId &&
			!isLoading &&
			scrollTop >= scrollHeight - clientHeight - 5
		) {
			lazyLoadActivity();
		}
	};

	const lazyLoadActivity = async () => {
		if (lastPostActivityId) {
			dispatchLoader(true);
			setIsLoading(true);
			await getAgencyActivity({ agencyId, lastPostActivityId })
				.then((result) => {
					setActivity([...activity, ...result.data.agencyActivities]);
					setlastPostActivityId(result.data.lastPostActivityId || null);
					setIsLoading(false);
					dispatchLoader(false);
				})
				.catch((err) => {
					setIsLoading(false);
					dispatchLoader(false);
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					);
				});
		}
	};

	const activityMapper = useAgencyActivityMapper(lastPostActivityId);

	const activityMap =
		activity && activity.length && activity.map(activityMapper);

	return (
		<PageLayout
			header={isMd ? <MobileHeader /> : null}
			title={`Agency Activity`}>
			<div className={styles.activityFeedRoot} onScroll={handleScroll}>
				{activity ? (
					activity.length ? (
						activityMap
					) : (
						<div className={styles.noPostRoot}>
							<img src='/assets/ella-elephant.svg' alt='Ella Elephant' />
							<div className={styles.noPostTitle}>{'No Activities Found'}</div>
						</div>
					)
				) : null}
			</div>
		</PageLayout>
	);
}
