import { withRouter, Link } from 'react-router-dom';
import styles from '../pageLayout.module.css';

function InvalidToken({ error }) {
	const message =
		typeof error === 'string'
			? error
			: 'Sorry, this link is either expired or invalid.';

	return (
		<div className={styles.inviteContainer}>
			<div className={styles.errorContent}>
				{message}
				<Link to='/login/'>RETURN TO LOGIN</Link>
			</div>
		</div>
	);
}

export default withRouter(InvalidToken);
