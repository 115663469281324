import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { getIsMd, getIsSm } from 'redux/selectors';
import { setVideoPosition } from 'redux/reducers';
import { videoData } from '../utils/supportItems';
import { PageLayout, LifebookNav } from 'components';
import DefaultHeader from 'components/UtilityComponents/PageLayout/components/DefaultHeader';
import MobileHeader from 'components/Headers/MobileHeader';
import SupportNav from './SupportNav';
import styles from '../support.module.css';

const SupportVideo = (props) => {
	const user = useSelector((state) => state.user.user);
	const position = useSelector((state) => state.video.videoPosition);
	const isMd = useSelector(getIsMd);
	const isSm = useSelector(getIsSm);

	const videoType = videoData(user.type, user.role);

	const [playing, setPlaying] = useState(false);
	const [thumb, setThumb] = useState(true);
	const [url, setUrl] = useState(
		`https://ella-support-videos.s3.us-west-2.amazonaws.com/en/${videoType.id}.mp4`
	);

	const playerRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		setUrl(
			`https://ella-support-videos.s3.us-west-2.amazonaws.com/en/${videoType.id}.mp4`
		);
		return () => {
			dispatch(setVideoPosition(0));
			setThumb(true);
		};
	}, []);

	useEffect(() => {
		playerRef.current.seekTo(position, 'seconds');
	}, [position]);

	return (
		<>
			<PageLayout
				header={isMd ? <MobileHeader /> : <DefaultHeader title={'Tutorials'} />}
				navBack={props.navBack}
				footer={<LifebookNav />}>
				<div className={isSm ? styles.videoWrapperSmall : styles.videoWrapper}>
					{!isSm && <SupportNav />}
					<div
						className={
							isSm
								? styles.supportVideoContentSmall
								: styles.supportVideoContent
						}>
						{thumb && (
							<div className={styles.videoThumb}>
								<img
									src='/assets/video-thumbnail.png'
									style={{ width: '95%', height: '95%' }}
								/>
							</div>
						)}
						<ReactPlayer
							ref={playerRef}
							key={'en'}
							width='95%'
							height='95%'
							controls={true}
							onSeek={() => setPlaying(true)}
							onPlay={() => {
								setPlaying(true);
								setThumb(false);
							}}
							onPause={() => setPlaying(false)}
							playing={playing}
							url={url}
							config={{ file: { attributes: { controlsList: 'nodownload' } } }}
						/>
					</div>
					{isSm && <SupportNav />}
				</div>
			</PageLayout>
		</>
	);
};

export default SupportVideo;
