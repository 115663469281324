import { useSelector } from 'react-redux';
import useManageChildPermissions from '../utils/useManageChildPermissions';
import { SelectorList } from 'components';

export default function ChildSocialWorker({ selectorListState }) {
	const swList = useSelector((state) => state.user.agencyUsers);
	const linkTo = '/socialworkers/manage/';

	const { sw: permission } = useManageChildPermissions();
	const canRender = !!permission;
	const canEdit = permission === 'edit';

	if (selectorListState?.optionsList?.length === 0) {
		selectorListState.optionsList = swList;
	}

	const filteredList = selectorListState?.optionsList?.filter(
		(sw) => sw?.status === 'active'
	);

	if (selectorListState) {
		selectorListState.optionsList = filteredList;
	}

	return canRender ? (
		<SelectorList
			label='Social Workers'
			textFieldLabel='Social Workers'
			linkTo={linkTo}
			fromList='social-worker'
			disable={!canEdit}
			expandable={true}
			full
			{...selectorListState}
		/>
	) : null;
}
