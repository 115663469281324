import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './App.scss';
import themeV2 from './material/styles/muiTheme';
import { Loader, Modal } from './components';
import { MediaQuery, mixpanelTrack } from './helpers';
import { useAppInitialize } from './helpers/hooks';
import EventModal from 'components/Calendar/components/EventModal';
import FamilyConnectCases from './components/FamilyConnect/FamilyConnectCases';
import { getMediaQueryState, getOverLayState } from './redux/selectors';
import { Snackbar } from './components';
import { AppRouter } from './routing';

export default function App() {
	const { loading } = useAppInitialize();
	const { pathname } = useLocation();
	const { isMd, height } = useSelector(getMediaQueryState);
	const { modalArray, loader } = useSelector(getOverLayState);

	useEffect(() => {
		mixpanelTrack('navigate', { pathname });
	}, [pathname]);

	return (
		<div className='App' style={isMd ? { height } : {}}>
			<MediaQuery />
			<CssBaseline />
			<ThemeProvider theme={themeV2}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<AppRouter loading={loading} />
					{modalArray && modalArray.length > 0 ? <Modal /> : null}
					<FamilyConnectCases />
					<EventModal />
					{loader ? <Loader /> : null}
					<Snackbar />
				</LocalizationProvider>
			</ThemeProvider>
		</div>
	);
}
