import { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MaterialField } from '@material-ui/core';
import clsx from 'clsx';

const TextField = ({ style, className, required, autoFocus, ...props }) => {
	const useStyles = makeStyles(() => ({
		textField: {
			margin: '8px 0 8px 0',
			borderRadius: '12px !important',
			fontWeight: 'normal',
			color: 'black',
			width: '100%',
			...style,
		},
		textFieldInput: {
			height: '24px',
		},
	}));
	const classes = useStyles();

	const inputRef = useRef();

	useEffect(() => {
		if (autoFocus) {
			inputRef.current.focus();
		}
	}, [autoFocus, inputRef]);

	return (
		<>
			<MaterialField
				variant={props.variant || 'outlined'}
				inputRef={inputRef}
				required={required}
				InputProps={{ classes: { input: classes.textFieldInput } }}
				className={clsx(classes.textField, className ? className : '')}
				{...props}
			/>
		</>
	);
};

export default TextField;
