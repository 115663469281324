import { TextFieldRow } from 'components';
import styles from '../account.module.css';

export default function useArrayMappers(onSubmit) {
	const textFieldMap = (array) => {
		return array.map((val) => {
			if (val) {
				const { value, input, type, submit, name } = val;

				return (
					<TextFieldRow
						key={val.input.label}
						className={styles.arrayMapperProfileField}
						type={type}
						variant='outlined'
						{...input}
						name={name || value}
						onKeyDown={
							submit && onSubmit
								? (e) => {
										if (e.keyCode === 13) {
											onSubmit();
										}
								  }
								: null
						}
					/>
				);
			} else {
				return null;
			}
		});
	};

	return { textFieldMap };
}
