import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserAgency } from 'redux/selectors';
import { useFormInput } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useManageAgencyState() {
	const { clearAddress, setAddress } = useCreateDispatches();
	const agency = useSelector(getUserAgency);
	const name = useFormInput('Agency Name', '', { required: true });
	const office = useFormInput('Phone', '', { type: 'phone' });

	useState(false);

	useEffect(() => {
		// clear data first
		clearAddress();

		const setAgency = async () => {
			const { name: dataName, office: dataOffice, address } = agency || {};
			name.onChange({ value: dataName });
			office.onChange({ value: dataOffice });

			setAddress(address || {});
		};
		setAgency();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agency?.id]);

	return { name, office, agency };
}
