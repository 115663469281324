import { useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import useManageChildPermissions from '../utils/useManageChildPermissions';
import useManageChildSchoolMap from '../utils/useManageChildSchoolMap';
import { InputAdornment } from '@material-ui/core';
import * as Comp from 'components';
import ChildSchoolList from './ChildSchoolList';
import styles from '../child.module.css';

export default function ChildSchool({
	schoolState,
	schoolName,
	clearSchoolData,
	handleSchoolSelect,
	infoArray,
}) {
	const permissions = useManageChildPermissions();

	const { anchorRef, anchorEl, toggleMap, clearAnchor, enterSearch } =
		useManageChildSchoolMap();

	const permission = permissions.school;

	const [expanded, setExpanded] = useState(false);

	const handleOpenClose = () => {
		setExpanded(!expanded);
	};

	return permission === 'edit' ? (
		<div className={styles.childSchoolContainer}>
			<Comp.SectionHeader>
				<div className={styles.basicHeader}>
					<div className={styles.headerText}>SCHOOL</div>
					{expanded ? (
						<ExpandLess onClick={() => handleOpenClose()} />
					) : (
						<ExpandMore onClick={() => handleOpenClose()} />
					)}
				</div>
			</Comp.SectionHeader>

			{expanded && (
				<div className={styles.childSchoolContent} ref={anchorRef}>
					<Comp.TextField
						id='school-input'
						className={styles.childSchoolTextField}
						variant='outlined'
						{...schoolName}
						onKeyDown={(e) => enterSearch(e)}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<>
										<Comp.PrimaryButton
											id='school-search-button'
											onClick={toggleMap}>
											Search
										</Comp.PrimaryButton>
										{schoolName.value ? (
											<Comp.SecondaryButton onClick={clearSchoolData}>
												Clear School
											</Comp.SecondaryButton>
										) : null}
									</>
								</InputAdornment>
							),
						}}
					/>
					{anchorEl ? (
						<ChildSchoolList
							input={schoolName.value}
							schoolSelect={handleSchoolSelect}
							anchorEl={anchorEl}
							clearAnchor={clearAnchor}
						/>
					) : null}

					<div className={styles.childSchoolForm}>
						{schoolState.id
							? infoArray.map((val, i) => {
									return (
										<Comp.StaticTextRow key={`school-row-${i}`} {...val} />
									);
							  })
							: null}
					</div>
				</div>
			)}
		</div>
	) : null;
}
