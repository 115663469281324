import { useRef } from 'react';
import theme from '../../material/styles/muiTheme';
import { useCreateDispatches } from './useCreateDispatches';

export function useGetContainerWidth() {
	const ref = useRef(null);
	const { current } = ref;

	const { contentMax } = theme.width || {};
	const themeWidth = Number(contentMax?.replace('px', ''));
	const { setRightContentWidth } = useCreateDispatches();

	const setContainerWidth = () => {
		setRightContentWidth(current?.offsetWidth);
	};

	const getContainerWidth = () => {
		return current?.offsetWidth;
	};

	const getIsContained = () => {
		return current?.offsetWidth <= themeWidth;
	};

	return { ref, getContainerWidth, getIsContained, setContainerWidth };
}
