import { store } from '../redux';

export const excludeRole = (arr) => {
	let { role, privileged } = store.getState().user.user;
	let comparisonValue = privileged ? 'privileged' : role;

	return !arr.includes(comparisonValue);
};

export const excludeType = (arr) => {
	let { type } = store.getState().user.user;
	return !arr.includes(type);
};

export const excludePrivatize = () => {
	return !store.getState().user.user.privatizePosts;
};

export const excludeActiveChild = () => {
	const { type, status } = store.getState().user.user || {};
	return Boolean(type === 'child' && status !== 'active');
};

export const excludeArchivedChild = () => {
	return !store.getState().user.user.exitedCare;
};

export const includeRole = (arr) => {
	let { role, privileged, type } = store.getState().user.user;
	if (type === 'agencyuser') {
		let comparisonValue = privileged
			? arr.includes('social-worker')
				? 'social-worker'
				: 'privileged'
			: role;
		return arr.includes(comparisonValue);
	}
	if (type === 'casauser') {
		let comparisonValue = privileged
			? arr.includes('casaadmin')
				? 'admin'
				: 'mentor'
			: role;
		return arr.includes(comparisonValue);
	}
};

export const includeType = (arr) => {
	let { type } = store.getState().user.user;
	return arr.includes(type);
};

export const includePrivatize = () => {
	let { user } = store.getState().user;
	return user.privatizePosts;
};

export const includeActiveChild = () => {
	const { type, status } = store.getState().user.user || {};
	return Boolean(type === 'child' && status === 'active');
};

export const includeArchivedChild = () => {
	return store.getState().user.user.exitedCare;
};
