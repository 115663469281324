import * as services from 'services';
import { emailTypeCheck } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useOnInvite({ role }, getList, selectClose) {
	const { setSnackbar } = useCreateDispatches();

	const inviteList = (inviteArray, { manager, branch }) => {
		let errors = [];
		let invitedCount = 0;
		return new Promise((resolve, reject) => {
			let promiseArray = [];
			let inviteFunction = async (email) => {
				if (email) {
					let reqBody = {
						email,
						role,
					};
					if (['socialWorker', 'mentor'].includes(role)) {
						reqBody.managerAgencyUserId = manager;
						reqBody.branchId = branch;
					}
					if (role === 'manager') {
						reqBody.branchId = branch;
					}
					return await services.inviteAgencyUser(reqBody).catch((err) => {
						// TODO ADD BETTER ERROR MESSAGING //
						setSnackbar({ variant: 'error', message: 'Error inviting user' });
						errors.push(err.message);
					});
				}
			};
			const invited = {};
			inviteArray.forEach((val) => {
				if (!invited[val]) promiseArray.push(inviteFunction(val));
				invited[val] = true;
				invitedCount += 1;
			});

			Promise.all(promiseArray)
				.then(() => {
					if (errors.length) {
						let errorMessage = '';
						if (errors.length < invitedCount) {
							if (errors.length === 1) return errors[0];
							let errorListString = errors.reduce((acc, cur) => {
								return (acc += `, ${cur}`);
							});
							errorMessage = `Invite(s) could not be sent to ${errorListString.substring(
								2,
								errorListString.length
							)}`;
						} else {
							errorMessage = 'Invites could not be sent';
						}

						reject(errorMessage);
					} else {
						resolve('Invites sent successfully');
					}
				})
				.catch((err) => {
					reject('Invites could not be sent');
				});
		});
	};

	const handleValidate = (inviteArray, { branch, manager }) => {
		let validationError = '';
		let inputCount = 0;

		if (!inviteArray || !Boolean(inviteArray.length)) {
			validationError = 'No emails entered';
		} else {
			inviteArray.forEach((val) => {
				if (val) {
					inputCount += 1;
					if (!emailTypeCheck(val)) {
						validationError = `Invalid email format (${val})`;
					}
				}
			});
		}

		if (!inputCount) {
			validationError = 'No emails entered';
		}

		if (role === 'manager' && !branch) {
			validationError = 'Please select or create a branch';
		}

		if (['social-worker', 'mentor'].includes(role) && !manager) {
			validationError = 'Please select or create a manager';
		}

		if (!validationError) {
			return true;
		} else {
			setSnackbar({ variant: 'error', message: validationError });
			return false;
		}
	};

	const onInvite = (inviteArray, params) => {
		if (handleValidate(inviteArray, params)) {
			inviteList(inviteArray, params)
				.then((message) => {
					setSnackbar({
						variant: 'success',
						message,
					});
					if (getList) {
						getList();
					}
					selectClose();
				})
				.catch((message) => {
					setSnackbar({
						variant: 'error',
						message,
					});
				});
		}
	};

	return onInvite;
}
