import { useSelector } from 'react-redux';
import { getOverLayListLoader } from 'redux/selectors';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from '../userList.module.css';

export default function UserListEmpty() {
	const listLoader = useSelector(getOverLayListLoader);
	return listLoader ? (
		<LinearProgress />
	) : (
		<div className={styles.userListEmptyRoot}>No Records Found</div>
	);
}
