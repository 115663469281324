export const casaData = (permissions, casaPermissions) => {
	if (!casaPermissions) {
		casaPermissions = permissions;
	}

	return [
		{
			value: casaPermissions.basic_read_profile,
			label: 'View Basic Profile',
			reqKey: 'readProfile',
			key: 'basic_read_profile',
			tooltip: "Ability to view child's profile only",
			show: permissions.basic_read_profile,
		},

		{
			value: casaPermissions.basic_write_profile,
			label: 'Edit Basic Profile',
			reqKey: 'writeProfile',
			key: 'basic_write_profile',
			tooltip:
				"Ability to view child's profile and editing access of child's basic profile information only",
			show: permissions.basic_write_profile,
		},
		{
			value: casaPermissions.mgmt_read_profile,
			label: 'View Full Profile',
			reqKey: 'mgmtReadProfile',
			key: 'mgmt_read_profile',
			tooltip: 'Ability to view basic and management profile information',
			show: permissions.mgmt_read_profile,
		},
		{
			value: casaPermissions.mgmt_write_profile,
			label: 'Edit Full Profile',
			reqKey: 'mgmtWriteProfile',
			key: 'mgmt_write_profile',
			tooltip: 'Ability to edit basic and management profile information',
			show: permissions.mgmt_write_profile,
		},
		{
			value: casaPermissions.export_lifebook,
			label: 'Export LifeBook',
			reqKey: 'exportLifeBook',
			key: 'export_lifebook',
			tooltip: "Ability to export child's LifeBook to approved individuals",
			show: permissions.export_lifebook,
		},
		{
			value: casaPermissions.mgmt_lifebook,
			label: 'Management Lifebook',
			reqKey: 'mgmtLifeBook',
			key: 'mgmt_lifebook',
			tooltip:
				'Ability to moderate posts such as approving posts.  Auto approve posts created by this user',
			show: permissions.mgmt_lifebook,
		},
		{
			value: casaPermissions.mgmt_contributor,
			label: 'Management Contributor',
			reqKey: 'mgmtContributor',
			key: 'mgmt_contributor',
			tooltip: 'Allows contributor to be invited by this user',
			show: permissions.mgmt_contributor,
		},
	];
};

export const casaRoleData = [
	{ value: 'mentor', label: 'Mentor' },
	{ value: 'admin', label: 'Admin' },
];

export const casaInputFields = (address, stateValue) => {
	const fields = [
		{
			label: 'Street',
			name: 'street',
			value: address?.street,
			required: true,
		},
		{ label: 'Street 2', name: 'street2', value: address?.street2 },
		{ label: 'City', name: 'city', value: address?.city, required: true },
		{
			label: 'State',
			name: 'state',
			value: stateValue,
			type: 'select',
			required: true,
		},
		{ label: 'Zip', name: 'postal', value: address?.postal, type: 'submit' },
	];

	return fields;
};
