import { SelectorList } from 'components';

export default function HouseRPList({ selectorListState, disable }) {
	const linkTo = '/resource-parents/manage/';
	return (
		<SelectorList
			label='RESOURCE PARENTS IN HOME'
			textFieldLabel='Resource Parent'
			linkTo={linkTo}
			disable={disable}
			{...selectorListState}
		/>
	);
}
