import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLifebooksChild } from 'redux/selectors';
import { useFormInput } from 'helpers';

export default function useManageChildCSWState() {
	const { csw } = useSelector(getLifebooksChild);
	const { email: cswEmail = '' } = csw || {};
	const email = useFormInput('Email', cswEmail);

	useEffect(() => {
		email.onChange({ value: cswEmail || '' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cswEmail]);

	return { csw, email };
}
