import { useSelector } from 'react-redux';
import { Person } from '@material-ui/icons';
import styles from '../familyConnect.module.css';
import { childImageStyles } from '../FamilyConnectStyles';

const ChildImage = (props) => {
	const { isMd } = useSelector(({ mediaQuery }) => mediaQuery);

	return (
		<div
			className={
				isMd ? styles.childImageContainerSm : styles.childImageContainer
			}>
			{props.picture ? (
				<img
					className={styles.innerImage}
					alt='family connect'
					src={props.picture}
				/>
			) : (
				<Person
					style={
						isMd ? childImageStyles.personIconSm : childImageStyles.personIcon
					}
				/>
			)}
		</div>
	);
};

export default ChildImage;
