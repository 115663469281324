import { useHistory, useParams } from 'react-router-dom';
import {
	addChild,
	updateChild,
	restartChild,
	setSiblingRelation,
	deleteSiblingRelation,
} from 'services';
import { errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { handleChildValidation } from 'helpers/validation/childValidation';

export default function useCallbacks() {
	const { push } = useHistory();
	const { childId } = useParams();

	const { setLoader, setSnackbar, setSnackbarError, setMobileList } =
		useCreateDispatches();

	const errorHandler =
		(error) =>
		({ response }) => {
			setLoader(false);
			setSnackbarError({ response, ...error }, { log: true });
		};

	const restart = async (id, code) => {
		setLoader(true);
		await restartChild(id, code)
			.then(() => {
				push(`/lifebooks/manage/${id}`);
			})
			.catch(errorHandler(errors.MANAGE_CHILD_RESTART))
			.finally(() => {
				setLoader(false);
			});
	};

	const submitRequest = async (reqBody, basicInitialize, addAgency) => {
		setLoader(true);
		let successMessage = `Child successfully ${childId ? 'updated' : 'added'}.`;

		const requestFunction = childId ? updateChild : addChild;

		const validation = handleChildValidation(reqBody, childId);

		if (!validation.isValid) {
			setSnackbar(
				{ variant: 'error', message: validation.message },
				{ log: true }
			);
			setLoader(false);
			return;
		}

		await requestFunction(reqBody)
			.then(async (res) => {
				const agencyIds = reqBody?.agencyUserIds;

				if (reqBody?.siblingAdd) {
					reqBody?.siblingAdd?.forEach((sibling) =>
						setSiblingRelation(sibling, childId)
					);
				}

				if (reqBody?.siblingRemove) {
					reqBody?.siblingRemove?.forEach((sibling) =>
						deleteSiblingRelation(sibling)
					);
				}

				setSnackbar({ variant: 'success', message: successMessage });

				const { id } = res.data || {};

				if (!childId) {
					if (addAgency) {
						const reqBody = { id: id, agencyUserIds: agencyIds };
						updateChild(reqBody);
					}
					push(`/lifebooks/manage/${id}`);
				} else {
					// NOTE: refreshes the current child
					basicInitialize?.(id);
				}
			})
			.catch(errorHandler(errors.USER_SAVE('Child')))
			.finally(() => {
				setLoader(false);
			});
	};

	const onCancel = () => {
		setMobileList(true);
		push('/lifebooks/');
	};

	return { restart, submitRequest, onCancel };
}
