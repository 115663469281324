import { useState, useEffect } from 'react';
import * as Router from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCasaUser } from 'redux/reducers';
import { useMobileListCheck } from 'helpers';
import SendCasaUserInvite from './components/SendCasaUserInvite';
import ManageCasaUser from './components/ManageCasaUser';
import ManageCasaChild from '../Components/ManageCasaChild';
import ErrorScreen from '../Components/ErrorScreen';
import { SlideContainer, UserList, Welcome } from 'components';
import styles from './CasaUser.module.css';

const CasaUsers = () => {
	const casaUsers = useSelector((state) => state.casa.casaUsers);
	const selectedUser = useSelector((state) => state.casa.selectedCasaUser);

	const [invite, setInvite] = useState(false);

	const { push } = Router.useHistory();
	const { pathname } = Router.useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!pathname.includes('manage') && !pathname.includes('child')) {
			dispatch(setCasaUser({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	useMobileListCheck(pathname === '/casaUsers' || pathname === '/casaUsers/');

	let casaUserList = casaUsers === 'error' ? [] : casaUsers;

	const casaUsersListMap = casaUserList?.map((val) => {
		const { firstname, lastname } = val || {};
		const hasName = !!firstname && !!lastname;
		return (
			(hasName && { ...val, name: `${firstname} ${lastname}` }) || {
				...val,
				name: val.email,
			}
		);
	});

	const selectCasaUser = (e, user) => {
		dispatch(setCasaUser(user));
		e.stopPropagation();
		setInvite(false);
		push({ pathname: `/casaUsers/manage/${user.id}`, state: user });
	};

	return (
		<div className={styles.casaWrapper}>
			{!invite && (
				<SlideContainer>
					<UserList
						list={casaUsersListMap || []}
						addToggle={() => setInvite({ type: 'casaUser' })}
						handleSelect={selectCasaUser}
						label='Casa Users'
						paramId={selectedUser.id}
						addLabel='Add Casa User'
					/>
				</SlideContainer>
			)}
			{casaUsers === 'error' ? (
				<ErrorScreen />
			) : (
				<div className={styles.casaUserContent}>
					<Router.Switch>
						<Router.Route path='/casaUsers/manage/:casaUserId?'>
							<ManageCasaUser />
						</Router.Route>
						<Router.Route path='/casaUsers/child/:childId'>
							<ManageCasaChild />
						</Router.Route>
						<Router.Route path='/'>
							<Welcome />
						</Router.Route>
					</Router.Switch>
					{invite ? (
						<div className={styles.casaUserInvite}>
							<SendCasaUserInvite onCancel={() => setInvite(false)} />
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default Router.withRouter(CasaUsers);
