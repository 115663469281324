import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import styles from '../timeline.module.css';

export default function ImageSelection({ nextImg, prevImg, index, maxIndex }) {
	return (
		<div className={styles.imageSelectionRoot}>
			<div onClick={prevImg}>
				<ArrowLeft className={styles.imageSelectionArrows} />
			</div>
			<div>{` ${index + 1}/${maxIndex + 1} `}</div>
			<div onClick={nextImg}>
				<ArrowRight className={styles.imageSelectionArrows} />
			</div>
		</div>
	);
}
