import { CloudUpload } from '@material-ui/icons';
import { Icon, PrimaryButton } from 'components';
import clsx from 'clsx';
import style from '../addImage.module.css';

export function AddImageDropzone(props) {
	const { dragOver, openFileInput, onDrop, onDragOver, onDragLeave } = props;

	const iconStyle = {
		fontSize: '72px',
		color: '#D2D2D2',
		borderBottom: '16px',
	};

	return (
		<div
			className={clsx(
				style.addImageRoot,
				dragOver ? style.addImageDropzoneActive : null
			)}
			onClick={openFileInput}
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}>
			<div className={style.addImageDropzoneSection}>
				<Icon className={style.addImageDropzoneButton} iconStyle={iconStyle}>
					<CloudUpload />
				</Icon>
				<p>Drag and drop your files</p>
			</div>
			<div className={style.addImageDropzoneDivider} />
			<div className={style.addImageDropzoneSection}>
				<PrimaryButton
					className={style.addImageDropzoneButton}
					style={{ marginTop: '16px' }}>
					Browse
				</PrimaryButton>
				<p>Or browse your files</p>
			</div>
		</div>
	);
}

export default AddImageDropzone;
