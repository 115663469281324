import { useEffect, useState } from 'react';
import { experiments } from 'helpers';
import Checkbox from '@material-ui/core/Checkbox';
import { FormSectionHeader, FormToggleSwitch } from 'components';
import styles from '../account.module.css';

export default function NotificationToggles({ notifications }) {
	const [all, setAll] = useState(notifications.every(({ value }) => value));
	const [check, setCheck] = useState(null);

	const handleAllToggle = () => {
		const valueToSet = !all;
		notifications.forEach(({ toggle }) => {
			toggle(valueToSet);
		});
		setAll(valueToSet);
	};

	useEffect(() => {
		if (notifications.every(({ value }) => value)) {
			setAll(true);
		} else {
			setAll(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [check]);

	const handleSingleToggle = (value, toggle, index) => {
		toggle(!value);
		setCheck(`${index}: ${value}`);
	};

	const toggleMapper = ({ value, toggle, label }, i) => {
		return (
			<FormToggleSwitch
				key={`${i}-${value}`}
				switchState={value}
				setSwitch={() => handleSingleToggle(value, toggle, i)}>
				{label}
			</FormToggleSwitch>
		);
	};

	return experiments.notificationToggle(true) ? (
		<div className={styles.notificationsContainer}>
			<FormSectionHeader>
				<div className={styles.notificationsAll}>
					<div>Notifications</div>
					<div className={styles.notificationsCheck}>
						All
						<Checkbox checked={all} onChange={handleAllToggle} />
					</div>
				</div>
			</FormSectionHeader>
			{notifications.map(toggleMapper)}
		</div>
	) : null;
}
