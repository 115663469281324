import { useSelector, useDispatch } from 'react-redux';
import store from 'redux/store';
import { setConnectionsModal, setSnackbar } from 'redux/reducers';
import * as services from 'services';
import { PrimaryButton, SecondaryButton } from 'components';
import styles from '../familyConnect.module.css';

const CasesButtons = (props) => {
	const { isMd } = useSelector(({ mediaQuery }) => mediaQuery);
	const childId = localStorage.getItem('childId');
	const dispatch = useDispatch();

	const handleCancel = () => {
		store.dispatch(setConnectionsModal(false));
	};

	const handleConnect = () => {
		const body = { cokCaseId: props.id };
		services.cokConnect(body, childId).then(async () => {
			await services.getCurrentChild(childId);
			store.dispatch(setConnectionsModal(false));
			dispatch(setSnackbar({ variant: 'success', message: 'Success' }));
		});
	};

	return (
		<div className={styles.casesButtonContainer}>
			<div
				className={
					isMd ? styles.casesInnerContainerSmall : styles.casesInnerContainer
				}>
				<SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
				<PrimaryButton onClick={handleConnect}>Connect</PrimaryButton>
			</div>
		</div>
	);
};

export default CasesButtons;
