import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	getLifebooksChild,
	getUserState,
	getPostsRoot
} from '../../redux/selectors';
import { useCheckUserPermissions } from './';

export function useBlockAddPost() {
  const child = useSelector(getLifebooksChild);
	const user = useSelector(getUserState);
	const root = useSelector(getPostsRoot);
	const { childId } = useParams();
  const { includeUsers, excludeUsers } = useCheckUserPermissions();
  const [blockAddPostAccess, setBlockAddPostAccess] = useState(false);

  useEffect(() => {
		if (
			!user.id ||
			(includeUsers('child') && excludeUsers('activeChild')) ||
			includeUsers('archivedChild') || root === 'archive'
		) {
			setBlockAddPostAccess(true);
		} else {
			setBlockAddPostAccess(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child, user, childId]);

  return blockAddPostAccess;
}