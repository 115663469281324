import { Avatar } from 'components';
import styles from '../CasaAgency.module.css';

function CasaAdminTile(props) {
	const { value, children } = props;

	const alt = (typeof children === 'string' ? children : children?.[0]) || '';
	const avatar = <Avatar image={value?.photoUrl} alt={alt} size='small' />;

	return children || children?.[0] ? (
		<>
			<div className={styles.selectListTile}>
				<div>{avatar}</div>
				{children} ({value.email})
			</div>
		</>
	) : null;
}

export default CasaAdminTile;
