import { apiV2 } from '../axios';
import { setPostNotes } from 'redux/reducers';
import store from '../redux/store';

export const addNote = async (reqBody) => {
	return await apiV2.post('/post/note', reqBody);
};

export const getNotes = async (postId) => {
	const resData = await apiV2.get(`/post/note?postId=${postId}`);
	store.dispatch(setPostNotes(resData.data));
	return;
};

export const deleteNote = async (id) => {
	return await apiV2.delete(`/post/note/${id}`);
};
