import React from 'react';
import Avatar from '../../Icons/Avatar';
import clsx from 'clsx';
import styles from '../selectorList.module.css';

const Tile = ({ value, select, children, matchActiveParam, noAvatar }) => {
	const selectable = !!select;

	const onSelect = (e) => {
		selectable && select(e, value);
	};

	const activeClass = matchActiveParam === value?.id ? styles.tileActive : '';
	const alt = (typeof children === 'string' ? children : children?.[0]) || '';
	const avatar = <Avatar image={value?.photoUrl} alt={alt} size='small' />;

	return children || children?.[0] ? (
		<React.Fragment>
			<div
				className={clsx(
					styles.tileContent,
					` ${selectable ? 'selectable' : ''} ${activeClass}`
				)}
				onClick={onSelect}>
				{!noAvatar ? (
					<div>{avatar}</div>
				) : (
					<div className={styles.selectListTileNoAvatar} />
				)}
				<div className={styles.selectListTileName}>{children}</div>
			</div>
		</React.Fragment>
	) : null;
};

export default Tile;
