import { useSelector } from 'react-redux';

export const useCreateMetadata = () => {
	const user = useSelector((state) => state.user.user);

	return (reqBody) => {
		const { metadata } = reqBody;
		let reqMetadata = { ...metadata } || {};
		reqMetadata.lastUpdated = new Date();
		reqMetadata.lastUpdatedBy = user.id;
		reqMetadata.lastUpdatedName = `${user.firstname} ${user.lastname?.substring(
			0,
			1
		)}`;

		if (!metadata || (metadata && !metadata.created)) {
			reqMetadata = {
				...reqMetadata,
				created: new Date(),
				createdBy: user.id,
				createdName: `${user.firstname} ${user.lastname?.substring(0, 1)}`,
			};
		}

		for (let key in reqMetadata) {
			if (!reqMetadata[key]) {
				delete reqMetadata[key];
			}
		}
		return reqMetadata;
	};
};
