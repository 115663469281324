import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setChild, store } from 'redux/index';
import { getUserState, getPostsRoot } from 'redux/selectors';
import useSortMenuOptions from '../utils/useSortMenuOptions';
import useFilterMenuOptions from '../utils/useFilterMenuOptions';
import { UserList, SlideContainer } from 'components';

export default function ChildListContainer(props) {
	const { push } = useHistory();
	const user = useSelector(getUserState);
	const root = useSelector(getPostsRoot);
	const { menuItems: filterMenuItems, filteredList } = useFilterMenuOptions();

	const { feedType } = useParams();

	const sortMenuItems = useSortMenuOptions();

	const getPath = (child, user) => {
		if (feedType === 'manage') return 'manage';
		if (child.privatizePosts && user.id !== child.id) {
			if (user.role === 'contributor') {
				return 'pending';
			} else {
				return 'services';
			}
		} else {
			return 'timeline';
		}
	};

	const getPushTo = (child) => {
		if (root === 'lifebooks') {
			const path = getPath(child, user);
			return `/lifebooks/${path}/${child.id}`;
		} else {
			return `/${root}/manage/${child.id}`;
		}
	};

	const selectChild = (e, child) => {
		e.stopPropagation();
		push(getPushTo(child));
	};

	const onAddChild = () => {
		store.dispatch(setChild({}));
		push('/lifebooks/manage');
	};

	return (
		<SlideContainer>
			<UserList
				list={filteredList}
				label='Children'
				pathParam='childId'
				handleSelect={props.selectChild || selectChild}
				addToggle={root === 'lifebooks' && onAddChild}
				addLabel='Add a Child'
				sortMenuItems={sortMenuItems}
				filterMenuItems={filterMenuItems}
				toggle={'remove'}
			/>
		</SlideContainer>
	);
}
