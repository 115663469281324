import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as selector from 'redux/selectors';
import { listSortPersonByName, errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useManageManagerState() {
	const [loaded, setLoaded] = useState(false);
	const [role, setRole] = useState('manager');
	const socialWorkerList = useSelector(selector.getAgencySWUsers);
	const [assignedSocialWorkerList, setAssignedSocialWorkersList] = useState([]);
	const [assignedBranch, setAssignedBranch] = useState([]);
	const { managerId } = useParams();

	const { setLoader, setSnackbarError } = useCreateDispatches();

	const branchHash = useSelector(selector.getBranchHash);
	const manager = useSelector(selector.getAgencyUser(managerId));
	const { firstname, lastname, email } = manager;
	const branchList = useSelector(selector.getBranchList);
	const [pageTitle, setPageTitle] = useState('Add Manager');

	const getAssignedSWs = () => {
		return socialWorkerList.filter(
			(val) => val.managerAgencyUserId === managerId
		);
	};

	const getData = async (refreshedAssignedList) => {
		setLoader(true);
		setLoaded(false);
		setRole('manager');
		try {
			setAssignedSocialWorkersList(
				listSortPersonByName(refreshedAssignedList || getAssignedSWs())
			);

			const branch = branchHash[manager?.branch?.id];
			setAssignedBranch(branch ? [branch] : []);
		} catch ({ response }) {
			setSnackbarError(
				{ response, ...errors.MANAGER_BASIC_STATE },
				{ log: true }
			);
		}
		setLoaded(true);
		setLoader(false);
	};

	useEffect(() => {
		const title =
			firstname && lastname
				? `${manager.firstname} ${manager.lastname}`
				: email;
		setPageTitle(title);
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [managerId]);

	const refresh = (refreshedAssignedList) => {
		getData(refreshedAssignedList);
	};

	return {
		pageTitle,
		socialWorkerList,
		assignedSocialWorkerList,
		branchList,
		assignedBranch,
		role,
		setRole,
		refresh,
		loaded,
	};
}
