import { useSelector } from 'react-redux';
import { getContributorContributor } from 'redux/selectors';
import { reinviteContributor } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import {
	EmailTextFieldRow,
	TextFieldRow,
	SectionHeader,
	EmailStaticTextRow,
} from 'components';
import styles from '../contributor.module.css';

export default function ContributorBasicInfo(props) {
	const contributor = useSelector(getContributorContributor);
	const { setContributorInput, setLoader, setSnackbar } = useCreateDispatches();

	const inputs = [
		{ key: 'firstname', label: 'First Name' },
		{ key: 'lastname', label: 'Last Name' },
		{ key: 'email', label: 'Email' },
	];

	const onChange = ({ target: { name, value } } = {}) => {
		setContributorInput({ [name]: value });
	};

	const onResendInvite = () => {
		setLoader(true);
		reinviteContributor(contributor.id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Reinvite Successfull',
				});
				setLoader(false);
			})
			.catch(() => setLoader(false));
	};

	const inputMap = inputs.map(({ key, label }) => {
		if (props.disabled && key === 'email') {
			return (
				<EmailStaticTextRow
					key={`contrib-input-${key}`}
					variant='outlined'
					value={contributor[key]}
					name={key}
					label={label}
					status={contributor?.status}
					required={true}
				/>
			);
		} else {
			return key === 'email' ? (
				<EmailTextFieldRow
					key={`contrib-input-${key}`}
					variant='outlined'
					value={contributor[key]}
					name={key}
					label={label}
					onChange={onChange}
					status={contributor?.status}
					onResend={onResendInvite}
					required={true}
					disabled={props.disabled}
				/>
			) : (
				<TextFieldRow
					key={`contrib-input-${key}`}
					variant='outlined'
					value={contributor[key]}
					name={key}
					label={label}
					onChange={onChange}
					required={true}
					disabled={props.disabled}
				/>
			);
		}
	});

	return (
		<>
			<SectionHeader>CONTRIBUTOR INFORMATION</SectionHeader>
			<div className={styles.contributorRoot}>{inputMap}</div>
		</>
	);
}
