import { withRouter, Switch, Route, useLocation } from 'react-router-dom';
import { AccessRoute } from 'routing/AccessRoute';
import { useMobileListCheck } from 'helpers';
import HomesListContainer from './components/HomesListContainer';
import { ManageHouse, Welcome } from 'components';
import styles from './homes.module.css';

function Home(props) {
	const { pathname } = useLocation();

	const {
		match: {
			params: { houseId },
		},
	} = props;

	useMobileListCheck(pathname === '/homes/');

	return (
		<div className={styles.homesContainer}>
			<HomesListContainer />
			<div className={styles.homesContent}>
				<Switch>
					<AccessRoute
						key={`manageHome-${houseId}`}
						path='/homes/manage/:houseId?'
						authorized={{ agencyuser: true }}
						fallBackRoute='/homes/'
						component={ManageHouse}
					/>
					<Route path='/' component={Welcome} />
				</Switch>
			</div>
		</div>
	);
}

export default withRouter(Home);
