const defaultAddressState = {
	street: '',
	street2: '',
	city: '',
	state: '',
	postal: '',
	country: '',
};

const CLEAR_STORE = 'CLEAR_STORE';
const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
const SET_ADDRESS = 'SET_ADDRESS';

export default function addressReducer(state = defaultAddressState, action) {
	switch (action.type) {
		case CLEAR_STORE:
		case CLEAR_ADDRESS:
			return { ...defaultAddressState };
		case SET_ADDRESS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

export function clearAddress() {
	return {
		type: CLEAR_ADDRESS,
	};
}

export function setAddress(address) {
	return {
		type: SET_ADDRESS,
		payload: address,
	};
}
