import { SelectorList } from 'components';

export default function SocialWorkerChildList({ selectorListState }) {
	const linkTo = '/lifebooks/manage/';
	return (
		<SelectorList
			label='CHILDREN'
			textFieldLabel='Child'
			linkTo={linkTo}
			fromList='children'
			{...selectorListState}
		/>
	);
}
