import { useEffect, useState } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { getAgencyManagerUsers, getBranchList } from 'redux/selectors';
import * as services from 'services';
import { useMobileListCheck } from 'helpers';
import { SendInvites, Welcome } from 'components';
import ManagerListContainer from './components/ManagerListContainer';
import ManageManager from './components/ManageManager';
import styles from './managers.module.css';

function Managers(props) {
	const {
		match: {
			params: { managerId },
		},
	} = props;

	const managerList = useSelector(getAgencyManagerUsers);
	const branchList = useSelector(getBranchList);
	const [invite, setInvite] = useState(false);

	useMobileListCheck(!(managerId || invite));

	const getList = async () => {
		await services.getAgencyUsers();
		await services.getBranchList();
	};

	useEffect(() => {
		getList();
	}, []);

	return (
		<div className={styles.managerFull}>
			<ManagerListContainer setInvite={setInvite} />
			<div className={styles.managerRouteContent}>
				<Switch>
					<Route path='/managers/manage/:managerId?'>
						<ManageManager setInvite={setInvite} key={managerId} />
					</Route>
					<Route path='/' component={Welcome} />
				</Switch>
				{invite ? (
					<div className={styles.managerRouteContainer}>
						<SendInvites
							onClose={() => setInvite(false)}
							type={invite.type}
							initialManager={
								invite.initialValue ? invite.initialValue.id : null
							}
							initialBranch={
								invite.initialValue ? invite.initialValue.branchId : null
							}
							managerList={managerList}
							branchList={branchList}
							getList={getList}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

const mapStateToProps = ({ user, overLay }) => {
	return { ...user, mobileList: overLay.mobileList };
};

export default connect(mapStateToProps)(withRouter(Managers));
