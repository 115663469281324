import { apiV2 } from '../axios';
import {
	store,
	setSnackbar,
	setHouseList as setReduxHouseList,
	addResourceParentList as addReduxRPList,
	setResourceParentList,
	setHousesHash,
} from '../redux';
import { getUser, createUser, updateUser } from './';
import { hashList } from '../helpers';

const dispatchSnackbar = (val) => store.dispatch(setSnackbar(val));
const dispatchAddRP = (val) => store.dispatch(addReduxRPList(val));
const dispatchSetRP = (val) => store.dispatch(setResourceParentList(val));

const onErrorCallback = (err) => {
	err?.response &&
		dispatchSnackbar({ variant: 'error', message: err.response });
	throw err;
};

export const getResourceParentList = async (houseId) => {
	return await apiV2.get(`/user?houseId=${houseId}`);
};

export const getResourceParent = async (rpId) => {
	return await getUser(rpId);
};

export const addResourceParent = async (parent) => {
	const user = {
		type: 'contributor',
		role: 'resource-parent',
		...parent,
	};
	return await createUser(user);
};

export const updateResourceParent = async (parent, rpId) => {
	return await updateUser(parent, rpId);
};

export const getHouseList = async () => {
	const response = await apiV2.get(`/house`).catch(onErrorCallback);
	if (response.data) {
		store.dispatch(setHousesHash(hashList(response.data)));
	}
	return response;
};

export const setFetchedHouseList = async () => {
	return await getHouseList()
		.then(async ({ data = [] }) => {
			const formattedHouseList = [];
			try {
				dispatchSetRP([]);
				const promiseArray = data.map((house) =>
					getResourceParentList(house.id).then(({ data: parentData = [] }) => {
						formattedHouseList.push({
							...house,
							resourceParents: parentData,
						});
						dispatchAddRP(parentData);
					})
				);
				await Promise.all(promiseArray);
			} catch (err) {
				onErrorCallback(err);
			}
			formattedHouseList?.length > 0 &&
				store.dispatch(setReduxHouseList(formattedHouseList));
			return data;
		})
		.catch(onErrorCallback);
};

export const addHouse = async (body) => {
	return await apiV2.post('/house', body);
};

export const editHouse = async (body, id) => {
	return await apiV2.put(`/house/${id}`, body);
};

export const getHouse = async (houseId) => {
	return await apiV2.get(`/house/${houseId}`);
};
