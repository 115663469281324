import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const TextArea = React.forwardRef((props, ref) => {
	const useStyles = makeStyles(() => ({
		textField: {
			position: 'relative',
			overflow: 'auto',
			maxHeight: '200px',
		},
	}));

	const classes = useStyles();

	return (
		<>
			<TextField
				ref={ref}
				variant='outlined'
				multiline={true}
				className={classes.textField}
				{...props}
			/>
		</>
	);
});

export default TextArea;
