export const getHomestate = ({ homes }) => {
	return homes;
};

export const getHomesHouseList = ({ homes }) => {
	const { houseList, houseHash } = homes;
	return houseList.map((id) => houseHash[id]);
};

export const getHomesHouseHash = ({ homes }) => {
	return homes.houseHash;
};

export const getHomesRPList = ({ homes }) => {
	const { resourceParentList, resourceParentHash } = homes;
	return resourceParentList.map((id) => resourceParentHash[id]);
};

export const getHomesRPHash = ({ homes }) => {
	return homes.resourceParentHash;
};
