import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

export default function RoundButton({
	style,
	iconStyle,
	buttonClass,
	iconClass,
	...props
}) {
	const { Icon } = props;
	const { height, width, iconSize } = style || {};
	const useStyles = makeStyles((theme) => {
		const roundButton = {
			minWidth: '0',
			height: '60px',
			width: '60px',
			padding: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			borderRadius: '50%',
			backgroundColor: '#00A0E8',
			cursor: 'pointer',
			zIndex: '50',
			boxShadow:
				'0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.12)',
			'&:hover': {
				backgroundColor: theme.palette.secondary.hover,
			},
			...style,
		};

		return {
			root: {
				justifyContent: 'center',
				padding: '0',
			},
			roundButton,
			icon: {
				//change size of icon symbol based on iconSize style prop
				color: 'white',
				height: iconSize
					? iconSize
					: height
					? `${+height.substring(0, height.length - 1)}px`
					: '30px',
				width: iconSize
					? iconSize
					: width
					? `${+width.substring(0, width.length - 1)}px`
					: '30px',
				...iconStyle,
			},
		};
	});

	const tooltipStyles = makeStyles((theme) => ({
		tooltip: {
			borderRadius: '2',
			fontSize: '12px',
		},
	}));
	const classes = useStyles();
	const tooltipClasses = tooltipStyles();

	return (
		<React.Fragment>
			<Tooltip classes={tooltipClasses} title={props.hoverText}>
				<IconButton
					variant='contained'
					className={`${classes.roundButton} ${buttonClass}`}
					onClick={props.onClick}>
					<Icon className={clsx(classes.icon, iconClass)} />
					{props.children}
				</IconButton>
			</Tooltip>
		</React.Fragment>
	);
}
