import { useSelector } from 'react-redux';
import { SecondaryButton } from 'components';
import styles from './ChildAdmin.module.css';

const ConnectChildButton = (props) => {
	const child = useSelector((state) => state.lifebooks.child);

	const url = 'login.connectourkids.org/authorize';
	const responseType = 'token';
	const clientId = process.env.REACT_APP_FAMILY_CONNECT_CLIENT_ID;
	const redirectUri = process.env.REACT_APP_FAMILY_CONNECT_REDIRECT;
	const audience = process.env.REACT_APP_FAMILY_CONNECT_AUDIENCE;

	const scope = 'openid profile email read:scope';
	const cokLink = `https://${url}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&audience=${audience}`;

	const handleOnClick = () => {
		localStorage.setItem('childId', props.childId);
	};

	return (
		<div className={styles.childAdminItemWrapper}>
			<div>
				<div className={styles.childAdminBoldLabel}>Family Connections</div>
				<div>Login to Connect Our Kids and connect childs lifebook</div>
			</div>
			<div className={styles.childAdminButtonContainer}>
				<SecondaryButton
					onClick={handleOnClick}
					href={cokLink}
					disabled={child.cokCaseId ? true : false}>
					{child.cokCaseId ? 'Connected' : 'Connect Child'}
				</SecondaryButton>
			</div>
		</div>
	);
};

export default ConnectChildButton;
