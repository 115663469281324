import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPostsFeedType } from 'redux/selectors';
import useFetchPost from '../../Lifebooks/utils/useFetchPost';
import { SwitcherRadios, ServiceOptions } from './';
import styles from '../editPost.module.css';

export default function EditPostServiceSelector({ handleChange }) {
	const feedType = useSelector(getPostsFeedType);
	const { state } = useFetchPost();

	const [activeServiceCategoryIndex, setActiveServiceCategoryIndex] =
		useState(null);

	return (
		<div className={styles.editPostServiceSelectorRoot}>
			<div className={styles.editPostServiceSelectorContent}>
				{!state.id && <SwitcherRadios handleChange={handleChange} />}
				{feedType === 'services' || state.nsp ? (
					<ServiceOptions
						{...{
							handleChange,
							activeServiceCategoryIndex,
							setActiveServiceCategoryIndex,
						}}
					/>
				) : null}
			</div>
		</div>
	);
}
