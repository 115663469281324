import { approveExportRequest, denyExportRequest } from 'services';
import { PrimaryButton, SecondaryButton } from 'components';
import styles from '../exportRequest.module.css';

export default function ExportButtons(props) {
	const exportRequestId = props.request?.id;

	return (
		<>
			<div className={styles.buttonContainer}>
				<div className={styles.button}>
					<PrimaryButton onClick={() => approveExportRequest(exportRequestId)}>
						Approve
					</PrimaryButton>
				</div>
				<SecondaryButton onClick={() => denyExportRequest(exportRequestId)}>
					Deny
				</SecondaryButton>
			</div>
		</>
	);
}
