import { apiV2 } from '../axios';
import { store, setCalendar, setSnackbar } from '../redux';
import { getDateRange } from 'helpers';

export const getChildCalendar = async (childId, dateRange) => {
	const response = await apiV2
		.get(
			`/event/?childId=${childId}&start=${dateRange.start}&end=${dateRange.end}`
		)
		.catch(() => {
			store.dispatch(setCalendar('error'));
			return;
		});

	response?.data
		? store.dispatch(setCalendar(response.data))
		: store.dispatch(setCalendar('error'));

	return response;
};

export const addCalendarEvent = async (event) => {
	const resData = await apiV2.post('/event', event);

	if (resData.data) {
		const dateRange = getDateRange(new Date(event.start));
		getChildCalendar(event.childId, dateRange);
	} else {
		store.dispatch(
			setSnackbar({
				variant: 'error',
				message: 'There was a problem adding this calendar event',
			})
		);
	}
	return resData;
};

export const editCalendarEvent = async (event) => {
	const resData = await apiV2.put(`/event/${event.id}`, event);

	if (resData.data) {
		const dateRange = getDateRange(new Date(event.start));
		await getChildCalendar(event.childId, dateRange);
	} else {
		store.dispatch(
			setSnackbar({
				variant: 'error',
				message: 'There was a problem adding this calendar event',
			})
		);
	}
	return resData;
};

export const deleteCalendarEvent = async (eventId) => {
	await apiV2.delete(`/event/${eventId}`).catch(() => {
		store.dispatch(
			setSnackbar({ variant: 'error', message: 'Sorry there was an error.' })
		);
		return { error: true, response: '' };
	});

	store.dispatch(
		setSnackbar({
			variant: 'success',
			message: 'Success, calendar event deleted',
		})
	);

	return { error: false, response: '' };
};
