import { useHistory, useParams } from 'react-router-dom';
import { store } from 'redux/index';
import { setHouse } from 'redux/reducers';
import * as service from 'services';
import { errors } from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';
import { useAddressGeocode } from 'components/UtilityComponents/AddressForm/AddressForm';
import { handleHomeValidation } from 'helpers/validation/homesValidation';
import { PrimaryButton, SecondaryButton } from 'components';

function CancelButton() {
	const history = useHistory();
	const onClick = () => history.goBack();

	return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
}

function SaveButton({ getBody, resetListChanges }) {
	const { setLoader, setSnackbarError, setSnackbar } = useCreateDispatches();
	const history = useHistory();
	const { houseId } = useParams();
	const getGeocode = useAddressGeocode();

	const isEdit = !!houseId;

	const getText = () => {
		return !isEdit ? 'Save Home' : 'Save Changes';
	};

	const errorHandler =
		(error) =>
		({ response }) => {
			setSnackbarError({ response, ...error }, { log: true });
		};

	const addToHouse = async (user, houseId) => {
		return await service.updateUser({ houseId }, user.id);
	};

	const removeFromHouse = async (user) => {
		return await service.updateUser({ houseId: '' }, user.id);
	};

	const houseRequest = async (reqBody, geoCodeRes) => {
		const reqFunction = isEdit ? service.editHouse : service.addHouse;
		return await reqFunction(
			{
				...reqBody,
				address: { ...geoCodeRes, state: geoCodeRes.state.value },
			},
			houseId
		);
	};

	const onClick = async () => {
		setLoader(true);
		await getGeocode()
			.then(async (geoCodeRes) => {
				const { reqBody, houseId, childChanges, resourceParentChanges } =
					getBody();

				const validation = handleHomeValidation(reqBody.name);

				if (validation?.isValid) {
					const promiseArray = [houseRequest(reqBody, geoCodeRes)];

					[...childChanges.add, ...resourceParentChanges.add].forEach((val) =>
						promiseArray.push(addToHouse(val, houseId))
					);
					[...childChanges.remove, ...resourceParentChanges.remove].forEach(
						(val) => promiseArray.push(removeFromHouse(val))
					);

					Promise.all(promiseArray)
						.then(async (values) => {
							await service.setFetchedHouseList();
							const houseData = values[0]?.data || {};
							store.dispatch(setHouse(houseData));
							const { id } = houseData;
							await service.setChildrenList();
							if (!isEdit && id) {
								history.push(`/homes/manage/${id}`);
							} else {
								resetListChanges();
								return houseData;
							}
						})
						.catch(errorHandler());
					setSnackbar({
						variant: 'success',
						message: 'Success',
					});
				} else {
					setSnackbar({
						variant: 'error',
						message: validation?.message,
					});
				}
			})
			.catch(errorHandler(errors.GEOCODE('House')))
			.finally(() => {
				setLoader(false);
			});
	};

	return <PrimaryButton onClick={onClick}>{getText()}</PrimaryButton>;
}

export const getButtonArray = (getBody, resetListChanges) => {
	return [
		<CancelButton key='cancel' />,
		<SaveButton {...{ getBody, resetListChanges }} key='save' />,
	];
};
