import clsx from 'clsx';
import styles from './icons.module.css';

export function IndicatorCount(props) {
	const { className, children } = props;
	return (
		<div
			className={clsx(styles.indicatorCountRoot, className ? className : null)}>
			<div className={styles.indicatorCountNumber}>
				{typeof children === 'number' || typeof children === 'string'
					? children
					: ''}
			</div>
		</div>
	);
}

export default IndicatorCount;
