export const defaultContributor = {
	firstname: '',
	lastname: '',
	email: '',
	childIds: [],
	children: [],
	relationships: [],
};

const contributorState = {
	contributorList: [],
	contributorHash: {},
	contributor: { ...defaultContributor },
	contributorLoaded: false,
	contributorPending: false,
};

const CLEAR_STORE = 'CLEAR_STORE';
const CLEAR_CONTRIBUTOR_LIST = 'CLEAR_CONTRIBUTOR_LIST';
const SET_CONTRIBUTOR_LIST = 'SET_CONTRIBUTOR_LIST';
const ADD_CONTRIBUTOR_LIST = 'ADD_CONTRIBUTOR_LIST';
const SET_CONTRIBUTOR_HASH = 'SET_CONTRIBUTOR_HASH';
const CLEAR_CONTRIBUTOR = 'CLEAR_CONTRIBUTOR';
const SET_CONTRIBUTOR = 'SET_CONTRIBUTOR';
const SET_CONTRIBUTOR_LOADED = 'SET_CONTRIBUTOR_LOADED';
const SET_CONTRIBUTOR_PENDING = 'SET_CONTRIBUTOR_PENDING';
const SET_CONTRIBUTOR_RELATIONSHIPS = 'SET_CONTRIBUTOR_RELATIONSHIPS';

const SET_CONTRIBUTOR_INPUT = 'SET_CONTRIBUTOR_INPUT';

export default function contributorReducer(
	state = { ...contributorState },
	action
) {
	switch (action.type) {
		case CLEAR_STORE:
			return { ...contributorState };
		case CLEAR_CONTRIBUTOR_LIST:
			return { ...state, contributorList: [] };
		case CLEAR_CONTRIBUTOR:
			return {
				...state,
				contributor: { ...defaultContributor },
			};
		case SET_CONTRIBUTOR_LIST: {
			return {
				...state,
				contributorList: action.payload,
			};
		}
		case ADD_CONTRIBUTOR_LIST: {
			return {
				...state,
				contributorList: [...state.contributorList, ...action.payload],
			};
		}
		case SET_CONTRIBUTOR_HASH: {
			return {
				...state,
				contributorHash: action.payload,
			};
		}
		case SET_CONTRIBUTOR: {
			const contributor = action.payload;

			return {
				...state,
				contributor: {
					...state.contributor,
					...action.payload,
					relationships:
						contributor.children?.length > 0
							? contributor.children?.map((child) => {
									return {
										id: child.relation?.id,
										relationship: child.relation?.relationship,
										childId: child.id,
										label: child.relation?.relationship,
									};
							  })
							: [],
				},
			};
		}
		case SET_CONTRIBUTOR_LOADED: {
			return {
				...state,
				contributorLoaded: action.payload,
			};
		}
		case SET_CONTRIBUTOR_RELATIONSHIPS: {
			return {
				...state,
				contributor: { ...state.contributor, relationships: action.payload },
			};
		}
		case SET_CONTRIBUTOR_PENDING: {
			return {
				...state,
				contributorPending: action.payload,
			};
		}
		case SET_CONTRIBUTOR_INPUT: {
			return {
				...state,
				contributor: {
					...state.contributor,
					...action.payload,
				},
			};
		}
		default:
			return state;
	}
}

export function clearContributorList() {
	return {
		type: CLEAR_CONTRIBUTOR_LIST,
	};
}

export function clearContributor() {
	return {
		type: CLEAR_CONTRIBUTOR,
	};
}

export function setContributorList(contributorList) {
	return {
		type: SET_CONTRIBUTOR_LIST,
		payload: contributorList,
	};
}

export function addContributorList(contributorList) {
	return {
		type: ADD_CONTRIBUTOR_LIST,
		payload: contributorList,
	};
}

export function setContributorHash(contributorHash) {
	return {
		type: SET_CONTRIBUTOR_HASH,
		payload: contributorHash,
	};
}

export function setContributor(contributor) {
	return {
		type: SET_CONTRIBUTOR,
		payload: contributor,
	};
}

export function setContributorLoaded(bool) {
	return {
		type: SET_CONTRIBUTOR_LOADED,
		payload: bool,
	};
}

export function setContributorPending(bool) {
	return {
		type: SET_CONTRIBUTOR_PENDING,
		payload: bool,
	};
}

export function setContributorInput(input) {
	return {
		type: SET_CONTRIBUTOR_INPUT,
		payload: input,
	};
}

export function setContributorRelationships(relationships) {
	return {
		type: SET_CONTRIBUTOR_RELATIONSHIPS,
		payload: relationships,
	};
}
