import { useSelector } from 'react-redux';
import { getPostsPosts, getPostsFeedView, getPostsRoot } from 'redux/selectors';
import PostTile from './PostTile';
import Post from './Post';

export default function PostMapper() {
	const postList = useSelector(getPostsPosts);
	const feedView = useSelector(getPostsFeedView);
	const root = useSelector(getPostsRoot);

	const PostComponent = feedView === 'grid' ? PostTile : Post;

	const disabledRoot = ['archive'].includes(root);

	return (
		<>
			{postList.map((post, i) => {
				return (
					<PostComponent
						key={`post-map-${i}`}
						post={post}
						index={i}
						disabled={post.disabled || disabledRoot}
					/>
				);
			})}
		</>
	);
}
