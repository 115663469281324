import { SecondaryButton } from 'components';
import clsx from 'clsx';
import styles from './form.module.css';

export function FormSectionHeader(props) {
	const { children, onClick, buttonLabel, hideTop, hideBottom, className } =
		props;
	const top = hideTop ? null : styles.top;
	const bottom = hideBottom ? null : styles.bottom;

	return (
		<div className={clsx(styles.formSectionHeader, top, bottom, className)}>
			{children}
			{onClick ? (
				<SecondaryButton
					style={{ position: 'absolute', right: '0' }}
					onClick={onClick}>
					{buttonLabel}
				</SecondaryButton>
			) : null}
		</div>
	);
}

export default FormSectionHeader;
