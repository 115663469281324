import { useParams } from 'react-router-dom';
import useManageAdminState from '../utils/useManageAdminState';
import { useGetAdminButtons } from '../utils/useGetAdminButtons';
import AdminBasicInfo from './AdminBasicInfo';
import { PageLayout, RoleSelector } from 'components';

export default function ManageAdmin() {
	const { adminId } = useParams();
	const { pageTitle, role, setRole, disableRoleMessage } =
		useManageAdminState();

	const getBody = () => {
		const reqBody = { id: adminId };
		if (role !== 'admin') {
			reqBody.role = role;
		}

		return reqBody;
	};

	const buttonArray = useGetAdminButtons(getBody);

	return (
		<PageLayout title={pageTitle} buttonArray={buttonArray} align='left'>
			<AdminBasicInfo />
			{/*	<AdminBranch id={adminId} /> */}
			<RoleSelector
				key={adminId}
				role={role}
				onChange={setRole}
				disableMessage={disableRoleMessage}
			/>
		</PageLayout>
	);
}
