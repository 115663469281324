import { useSelector } from 'react-redux';
import { useGetAgencyButtons } from '../utils/useGetAgencyButtons';
import useManageAgencyState from '../utils/useManageAgencyState';
import AgencyBasicInfo from './AgencyBasicInfo';
import { PageLayout, AddressForm, SectionHeader } from 'components';

export default function ManageAgency() {
	const agencyId = useSelector((state) => state.user?.agency?.id);

	const { name, office } = useManageAgencyState();

	const getBody = () => {
		const reqBody = { name: name.value, id: agencyId, office: office?.value };
		return {
			reqBody,
		};
	};

	const buttonArray = useGetAgencyButtons(getBody);

	return (
		<PageLayout title={'Edit Agency'} buttonArray={buttonArray} align='left'>
			<AgencyBasicInfo inputs={[name, office]} />
			<SectionHeader>ADDRESS</SectionHeader>
			<AddressForm key={agencyId} />
		</PageLayout>
	);
}
