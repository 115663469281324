import Moment from 'react-moment';
import { PostCategoryChip } from 'components';
import clsx from 'clsx';
import styles from './calendarActivity.module.css';

export default function CalendarActivityItem(props) {
	const { activity } = props;

	const activityDate = activity?.logged || activity?.requestedAt;

	return (
		<div
			className={[
				styles.activityRoot,
				activity.last && styles.activityLast,
			].join(' ')}>
			<div
				className={clsx(
					styles.activityLine,
					activity.index === 0
						? activity.only
							? styles.activityOnlyLine
							: styles.activityFirstLine
						: activity.last
						? styles.activityLastLine
						: null
				)}
			/>
			<div className={styles.activityCircle} />
			<div className={styles.activityContent}>
				{activityDate ? (
					<div className={styles.activityDate}>
						<Moment format='MMMM DD, YYYY'>{activityDate}</Moment>
					</div>
				) : null}
				<div className={styles.activityLabel}>
					{activity.description}
					<div className={styles.activityCategory}>
						<>
							{activity.category?.id && (
								<PostCategoryChip
									className={styles.activityIcon}
									category={activity.category}
								/>
							)}
						</>
					</div>
				</div>
			</div>
		</div>
	);
}
