import AccountChild from 'mdi-material-ui/AccountChild';
import HeartCircle from 'mdi-material-ui/HeartCircle'
import MultiplicationBox from 'mdi-material-ui/MultiplicationBox';
import PineTree from 'mdi-material-ui/PineTree';
import Halloween from 'mdi-material-ui/Halloween';
import Turkey from 'mdi-material-ui/Turkey';
import EggEaster from 'mdi-material-ui/EggEaster';
import PalmTree from 'mdi-material-ui/PalmTree';

import Pets from '@material-ui/icons/Pets';
import Brush from '@material-ui/icons/Brush';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import Cake from '@material-ui/icons/Cake';
import Flare from '@material-ui/icons/Flare';
import DirectionsBus from '@material-ui/icons/DirectionsBus';
import People from '@material-ui/icons/People';
import Fastfood from '@material-ui/icons/Fastfood';
import SportsEsports from '@material-ui/icons/SportsEsports';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import LocalMovies from '@material-ui/icons/LocalMovies';
import MusicNote from '@material-ui/icons/MusicNote';
import Loyalty from '@material-ui/icons/Loyalty';
import School from '@material-ui/icons/School';
import Event from '@material-ui/icons/Event';
import Sports from '@material-ui/icons/Sports';
import Accessibility from '@material-ui/icons/Accessibility';
import ChildCare from '@material-ui/icons/ChildCare';
import House from '@material-ui/icons/House';
import LocalFlorist from '@material-ui/icons/LocalFlorist';
import GolfCourse from '@material-ui/icons/GolfCourse';

export const iconPack = {
	animals: Pets,
	arts: Brush,
	awards: EmojiEvents,
	birthdays: Cake,
	faith: Flare,
	family: AccountChild,
	field_trips: DirectionsBus,
	friends: People,
	food: Fastfood,
	games: SportsEsports,
	interests: InsertEmoticon,
	travel: FlightTakeoff,
	vacation: PalmTree,
	movies: LocalMovies,
	music: MusicNote,
	other: HeartCircle,
	personal: Loyalty,
	school: School,
	special_events: Event,
	sports: Sports,
	relatives: Accessibility,
	siblings: ChildCare,
	current_home: House,
	holidays: MultiplicationBox,
	mothers_day: LocalFlorist,
	fathers_day: GolfCourse,
	christmas: PineTree,
	halloween: Halloween,
	thanksgiving: Turkey,
	easter: EggEaster,
};
