import { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as services from 'services';
import { includeType, useMobileListCheck } from 'helpers';
import { useCreateDispatches, useGetContainerWidth } from 'helpers/hooks';
import { ChildListContainer, LifebooksRoutes } from 'components';
import styles from './lifebooks.module.css';

export function Lifebooks(props) {
	const {
		fullScreen,
		user,
		history: { push },
		mediaQuery: { height, width, mobile },
		match: {
			params: { childId, postId },
		},
	} = props;

	const { setChild, setRoot } = useCreateDispatches();

	const { ref: rightContentRef, setContainerWidth } = useGetContainerWidth();

	useEffect(() => {
		setRoot('lifebooks');
	}, [setRoot]);

	useEffect(() => {
		setContainerWidth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [width, rightContentRef.current]);

	useMobileListCheck(!childId);

	const initialize = useCallback(
		async (childId) => {
			if (user.type === 'child' || childId === 'manage') return;

			let childResult = await services.getCurrentChild(childId);
			if (childResult && childResult.error) {
				push('/lifebooks/');
			}
		},
		[push, user.type]
	);

	useEffect(() => {
		if (!childId && user.type !== 'child') {
			setChild({});
		}
	}, [childId, user.type, setChild]);

	useEffect(() => {
		if (childId && childId !== 'exited') {
			initialize(childId);
		} else if (postId) {
			push('/lifebooks/');
		}
	}, [childId, postId, user, push, initialize]);

	const childType = includeType(['child']);

	return (
		<div className={styles.lifebooksContainer} style={mobile ? { height } : {}}>
			{!childType ? <ChildListContainer /> : null}
			<div
				ref={rightContentRef}
				className={[
					styles.lifebooksRightContent,
					fullScreen && styles.lifebooksRightFull,
				].join(' ')}
				style={mobile ? { height } : {}}>
				<div className={styles.lifebooksContent}>
					<LifebooksRoutes />
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ({ lifebooks, overLay, user, mediaQuery }) => {
	return {
		...lifebooks,
		mediaQuery,
		fullScreen: overLay.fullScreen,
		user: user.user,
	};
};

export default withRouter(connect(mapStateToProps)(Lifebooks));
